import React from "react";
import { Pivot, PivotItem, Image, SearchBox } from "@fluentui/react";
import Item from "./Item";
import { useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import StatusFilter from "../../../Assets/Constants/Enums/StatusFilter";
import './../../../Assets/Styles/Shared/ItemsSelector/ItemsSelector.css'
import { useEffect } from "react";
import { useState } from "react";
import SkeletonItemSelector from '../Skeleton/SkeletonItemSelector';
import { ReactComponent as ArrowLeft } from '../../../Assets/Images/Icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../Assets/Images/Icons/ArrowRight.svg';
import ItemSelectorSpecialFilters from "../../../Assets/Constants/Enums/ItemSelectorSpecialFilters";
import ItemFilters from "./SpecialFilters/ItemFilters";

export default function ItemsList(props) {
    const
        {
            setItems,
            statusFilter,
            setStatusFilter,
            textFilter,
            setTextFilter,
            id,
            search
        } = useGeneralStatesContext();
    const [executeGet, setExecuteGet] = useState(props.searchActiveInRender);

    //function searchAutocomplete(event) {
    //    let wordToSearch = event.target.value;
    //    let resultList = [];
    //    props.originalItems.forEach(item => {
    //        if (item.description.toString().toLowerCase().includes(wordToSearch.toLowerCase())) {
    //            resultList.push(item);
    //        }
    //    });
    //    setItems(resultList);
    //}
    function keyDowCaptureHandler(event) {
        if (event.key.toString().toLowerCase() === "enter") {
            setTextFilter(event.target.value);
            setExecuteGet(true);
        }
    }
    function pivotHandler(item, ev) {
        let status = item.key.toString().replace(".$", "");
        setStatusFilter(status);
        //setExecuteGet(true);
    }
    const onArrowLeftClick=()=>{
        props.previousPage();   
    }
    const onChangeKey = (event) => {
        const value = event?.target?.value?.trim();
        setTextFilter(value);
    };

    const onArrowRightClick=()=>{
        props.nextPage();
    }

    useEffect(() => {
        if (executeGet && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
            setExecuteGet(false);
        }
    }, [statusFilter])
    useEffect(() => {
        if (executeGet && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
            props.getTotalItems()
            setExecuteGet(false);
        }
    }, [executeGet])
    return (
        <div
            className="itemsselector-container">
            <div className="itemsselector-title">
                <div className="itemsselector-title-container">
                    <div
                        className="itemsselector-item-image">
                        <Image {...props.image} />
                    </div>
                    <div
                        className="itemsselector-title-text">
                        {props.title}
                    </div>
                </div>
            </div>
            <div
                className="itemsselector-search-container">
                <SearchBox
                    className="itemsselector-search-input"
                    placeholder="Buscar"
                    onKeyDownCapture={keyDowCaptureHandler} value={textFilter} onChange={onChangeKey}/>
            </div>
            {
                props.useSpecialFilters
                ? 
                    props.specialFiltersType==ItemSelectorSpecialFilters.Items
                        ? <ItemFilters getItems={props.getItems} setVisibleSkeleton={props.setVisibleSkeleton} />
                    :<></>
                :<></>
            }
            <div>
                <Pivot
                    onLinkClick={pivotHandler} className="pivot-center-search">
                    <PivotItem headerText="Activos" key={StatusFilter.Active}>
                        <span className="itemsselector-items-result">Resultados</span>
                        <div className={`itemsselector-items-container ${props.useSpecialFilters?"itemsselector-container-with-filters":""}`}>
                            {props.visibleSkeleton ?
                                <SkeletonItemSelector /> :
                                (props.items && props.items.length > 0 ? props.items.map((i) => (
                                    <Item
                                        key={i[props.omniKey]}
                                        id={i[props.omniKey]}
                                        description={i[props.omniValue]}
                                        textToAcronym={i[props.omniAcronym]}
                                        isSelected={i.id == id ? true : false}
                                        detailComponent={props.detailComponent}
                                    />
                                )) : search === true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                    : <p></p>
                                )
                            }

                        </div>
                        <div className="itemsselector-items-paginate text-end">
                            <span className="itemsselector-items-paginate-text">{(props.actualPage-1)*(props.pageSize)+1}-{props.totalInPage} de {props.totalItems}</span>
                            <ArrowLeft className="itemsselector-items-paginate-left" onClick={onArrowLeftClick} />
                            <ArrowRight className="itemsselector-items-paginate-right" onClick={onArrowRightClick}/>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Inactivos" key={StatusFilter.Inactive}>
                        <span className="itemsselector-items-result">Resultados</span>
                        <div className={`itemsselector-items-container ${props.useSpecialFilters?"itemsselector-container-with-filters":""}`}>
                            {props.visibleSkeleton ?
                                <SkeletonItemSelector /> :
                                (props.items && props.items.length > 0 ? props.items.map((i) => (
                                    <Item
                                        key={i[props.omniKey]}
                                        id={i[props.omniKey]}
                                        description={i[props.omniValue]}
                                        textToAcronym={i[props.omniAcronym]}
                                        isSelected={i.id == id ? true : false}
                                        detailComponent={props.detailComponent}
                                    />
                                )) : search === true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                    : <p></p>
                                )
                            }
                        </div>
                        <div className="itemsselector-items-paginate text-end">
                            <span className="itemsselector-items-paginate-text">{(props.actualPage - 1) * (props.pageSize) + 1}-{props.totalInPage} de {props.totalItems}</span>
                            <ArrowLeft className="itemsselector-items-paginate-left" onClick={onArrowLeftClick} />
                            <ArrowRight className="itemsselector-items-paginate-right" onClick={onArrowRightClick} />
                        </div>
                    </PivotItem>
                    {
                        props.useSpecialFilters
                        ? 
                            props.specialFiltersType==ItemSelectorSpecialFilters.Items
                            ?
                                <PivotItem headerText="Descontinuados" key={StatusFilter.Discontinued}>
                                    <span className="itemsselector-items-result">Resultados</span>
                                    <div className={`itemsselector-items-container ${props.useSpecialFilters?"itemsselector-container-with-filters":""}`}>
                                        {props.visibleSkeleton ?
                                            <SkeletonItemSelector /> :
                                            (props.items && props.items.length > 0 ? props.items.map((i) => (
                                                <Item
                                                    key={i[props.omniKey]}
                                                    id={i[props.omniKey]}
                                                    description={i[props.omniValue]}
                                                    textToAcronym={i[props.omniAcronym]}
                                                    isSelected={i.id == id ? true : false}
                                                    detailComponent={props.detailComponent}
                                                />
                                            )) : search === true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                                : <p></p>
                                            )
                                        }
                                    </div>
                                    <div className="itemsselector-items-paginate text-end">
                                        <span className="itemsselector-items-paginate-text">{(props.actualPage - 1) * (props.pageSize) + 1}-{props.totalInPage} de {props.totalItems}</span>
                                        <ArrowLeft className="itemsselector-items-paginate-left" onClick={onArrowLeftClick} />
                                        <ArrowRight className="itemsselector-items-paginate-right" onClick={onArrowRightClick} />
                                    </div>
                                </PivotItem>
                            :<></>
                        :<></>
                    }
                    <PivotItem headerText="Todos" key={StatusFilter.All}>
                        <span className="itemsselector-items-result">Resultados</span>
                        <div className={`itemsselector-items-container ${props.useSpecialFilters?"itemsselector-container-with-filters":""}`}>
                            {props.visibleSkeleton ?
                                <SkeletonItemSelector /> :
                                (props.items && props.items.length > 0 ? props.items.map((i) => (
                                    <Item
                                        key={i[props.omniKey]}
                                        id={i[props.omniKey]}
                                        description={i[props.omniValue]}
                                        textToAcronym={i[props.omniAcronym]}
                                        isSelected={i.id == id ? true : false}
                                        detailComponent={props.detailComponent}
                                    />
                                )) : search === true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                    : <p></p>
                                )
                            }
                        </div>
                        <div className={ "itemsselector-items-paginate text-end"}>
                            <span className="itemsselector-items-paginate-text">{(props.actualPage-1)*(props.pageSize)+1}-{props.totalInPage} de {props.totalItems}</span>
                            <ArrowLeft className="itemsselector-items-paginate-left" onClick={onArrowLeftClick} />
                            <ArrowRight className="itemsselector-items-paginate-right" onClick={onArrowRightClick}/>
                        </div>
                    </PivotItem>
                </Pivot>
            </div>
        </div>
    )
}