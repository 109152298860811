import { OmniCard } from "../../Shared/Card/OmniCard";
import { Label } from "@fluentui/react-components";
import { ReactComponent as OvalIcon } from '../../../Assets/Images/Icons/Oval.svg';
import { ReactComponent as OvalDefaultIcon } from '../../../Assets/Images/Icons/OvalDefault.svg';
import { InactiveCatalogs } from '../../../Assets/Constants/Configuration/SatCatalogs/Catalogs';
import Role from '../../../Assets/Constants/Enums/Role';
import { useEffect, useState } from 'react';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
export const CatalogListEdit = (props) => {
 
    const OnChekedhancheHandler = (id,itemDefault) => {
        if (ValidAuthorizations(itemDefault)) {
            let itemSearch = props.catalog.find(x => x.id === id);
            if (itemSearch) {
                if (itemSearch.hasItems) {
                    props.setMessages([new DangerMessage("La clave se encuentra ligada a uno o varios artículos, no se puede desactivar")]);
                }
                else {
                    let itemExist = props.itemUpdate.find(x => x.id === id);
                    if (itemExist) {
                        props.setItemUpdate((prev) => prev.map((item) => {
                            if (item.id === id) {
                                return { ...item, enabledInAlix: !itemExist.enabledInAlix, isChange: !itemExist.isChange };
                            }
                            return item;
                        }));
                    }
                    else {
                        props.setItemUpdate((prev) => [...prev, { ...itemSearch, enabledInAlix: !itemSearch.enabledInAlix, isChange: true }]);
                    }
                }
            }
            else {
                let itemExist = props.itemUpdate.find(x => x.id === id);
                if (itemExist) {
                    props.setItemUpdate((prev) => prev.map((item) => {
                        if (item.id === id) {
                            return { ...item, enabledInAlix: !itemExist.enabledInAlix, isChange: !itemExist.isChange };
                        }
                        return item;
                    }));
                }
            }
        }
    };
    function RenderRow(item) {
        let itemExist = props.itemUpdate.find(x => x.id === item.id);
        if (itemExist) {
            return itemExist.enabledInAlix;
        }
        else {
            return item.enabledInAlix;
        }
    }
    function ValidAuthorizations(itemDefault) {
        if (itemDefault === true) {
            return false;
        }
        var authorizations = JSON.parse(localStorage.getItem("authorizations"));
        var roleId = localStorage.getItem("rolId");
        if (authorizations) {
            return authorizations.some(auth => auth.authorizationId === InactiveCatalogs[props.type.type])
        }
        else if (roleId.toString() === Role.Administrador.toString()) {
            return true;
        }
        else if (roleId.toString() === Role.SinPermisos.toString()) {
            return false;
        }
        return false;
    }
    const [actualPage, setActualPage] = useState(1);
    const [list, setList] = useState([]);
    const onScrollFunction = event => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        if ((scrollTop + clientHeight) + 10 >= scrollHeight) {
            getNewItems();
        }
    }
    const getNewItems = async () => {
        var page = actualPage + 1;
        const paginatedData = props.catalog.slice(
            (page - 1) * 50,
            page * 50
        );
        if (paginatedData && paginatedData.length > 0) {
            setActualPage(page++);
            setList([...list, ...paginatedData]);
        }
    }
    useEffect(() => {
        if (props.catalog) {
            setList(props.catalog.slice(0, 50))
        }
    }, [props.catalog]);
    return (
        <OmniCard title={props.type.titleCardDetail} classCard="card-omni-without-width">
            {list &&
                <div className='row card-table-max-height mt-4' onScroll={onScrollFunction}>
                    <div className='col-lg-12 col-md-12'>
                        {props.itemUpdate &&
                            props.itemUpdate.map((itemNew) =>
                                (itemNew.enabledInAlix === true && itemNew.isChange === true) &&
                                <div key={itemNew.id} class={`row catalog catalog-border selectable`} onClick={() => OnChekedhancheHandler(itemNew.id, itemNew?.default)}>
                                        <div className='col-lg-10 col-md-12'>
                                            <Label className='text-main'> {itemNew.description}</Label>
                                            <Label className='text-secondary mt-1 text-info'> {itemNew.key}</Label>
                                        </div>
                                        <div className='col-lg-2 col-md-12'>
                                            {<OvalIcon />}
                                        </div>
                                    </div>
                            )}
                        {list.map((item) => (
                            RenderRow(item) &&
                            <div key={item.id} class={`row catalog catalog-border ${ValidAuthorizations(item?.default) ? 'selectable' : 'not-selectable'}`} onClick={() => OnChekedhancheHandler(item.id, item?.default)}>
                                <div className='col-lg-10 col-md-12'>
                                    <Label className='text-main'> {item.description}</Label>
                                    <Label className='text-secondary mt-1 text-info'> {item.key}</Label>
                                </div>
                                <div className='col-lg-2 col-md-12'>
                                    {item?.default === true ? <OvalDefaultIcon /> : <OvalIcon />}
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            }
        </OmniCard>
    )
}