import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Combobox, Option, OptionGroup, useId, } from '@fluentui/react-components';
import '../../Assets/Styles/Menu/SearchMenu.css'

export const SearchMenu = (props) => {
    const comboId = useId("combo-default");
    const [matchingOptions, setMatchingOptions] = useState(props.menu);
    const linkRef = useRef({});

    const handleLinkClick = (linkKey) => {
        const link = linkRef.current[linkKey];
        if (link) {
            link.click();
        }
    };
    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
        props.assignOptionValue(true, data.optionValue);
        if (ev.type == 'keydown') {
            handleLinkClick(data.optionValue);
        }
    };
    const onChange = (event) => {
        const value = event.target.value.trim();
        const menuList = props.menu.reduce((filteredParents, parent) => {
            if (Array.isArray(parent.links)) {
                const filteredOptions = parent.links.filter((children) => {
                    const childrenName = children.name.toLowerCase();
                    return childrenName.includes(value.toLowerCase());
                });

                if (filteredOptions.length > 0) {
                    const modifiedParent = { ...parent, links: filteredOptions };
                    filteredParents.push(modifiedParent);
                }
            }
            return filteredParents;
        }, []);
        setMatchingOptions(menuList);
    };
    useEffect(() => {
        setMatchingOptions(props.menu);
    }, [props.menu])
    return (
        <Combobox
            {...props}
            type='search'
            className='omni-search'            
            placeholder="Busqueda"
            aria-labelledby={comboId}
            onChange={onChange}            
            onOptionSelect={onOptionSelect}
            autoComplete="off"
            expandIcon={null}
        >
            {matchingOptions && matchingOptions.map(parent => (
                <OptionGroup label={parent.name} key={parent.key}>
                    {parent.links.map(children => (
                        <Link className='linkSearch' to={children.urlOption} style={{ textDecoration: 'none' }}
                            key={children.key} ref={(item) => (linkRef.current[children.key] = item)}>
                            <Option key={children.key} text={children.name} value={children.key} className='option-omni-combobox'>
                                <span className='nameOption'>{children.name}</span>
                            </Option>
                        </Link>
                    ))}
                </OptionGroup>
            ))}
            {matchingOptions && matchingOptions.length === 0 ? (
                <Option key="no-results" text="">
                    <span className='nameOption'>
                        Resultado no encontrado
                    </span>
                </Option>
            ) : null}
        </Combobox>
    );
};