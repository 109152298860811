import { useEffect, useState } from "react";
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons, leftButtons } from '../../../Assets/Constants/BarButtons/Configurations/Roles/Edit';
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { UpdateAsync, SetAxiosPrivate, ExistRoleNameEditAsync, GetUserByActive, GetDocumentsDaysAsync, GetByIdAsync } from '../../../Functions/Configuration/Roles/RoleStore';
import { Add as AddItem } from '../../Shared/ItemList/Add';
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { useGeneralStatesContext, useSetStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { OmniDropdown } from "../../Shared/Controls/Dropdown/OmniDropdown";
import { useForm } from 'react-hook-form';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import Detail from './Detail';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [openCallout, setOpenCallout] = useState();
    SetAxiosPrivate(axiosPrivate);
    const id = useIdSelectorContext();
    const [role, setRole] = useState();
    const [itemListUser, setItemListUser] = useState();
    const [documentDays, setDocumentDays] = useState();
    const [showErrors, setShowErrors] = useState({
        roleName: undefined,
        daysReportsSpecification: undefined,
        daysDocumentsSpecification: undefined,
        limitDaysCreditNotesReturn: undefined,
        limitDaysCreditNotesDiscount: undefined
    });
    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            let data = { ...result.data }
            let dayReport = documentDays.find(x => x.key == data?.daysReports);
            if (dayReport) {
                data.daysReport = dayReport.text;
                data.daysReportsSpecification = "0";
                setRole(data);
            }
            else {
                let dayReportDefault = documentDays.find(x => x.key === 1000);
                data.daysReport = dayReportDefault.text;
                data.daysReportsSpecification = data.daysReports;
                data.daysReports = 1000;
                setRole(data);
            }
            let dayDocument = documentDays.find(x => x.key == data?.daysDocuments);
            if (dayDocument) {
                data.daysDocument = dayDocument.text;
                data.daysDocumentsSpecification = "0";
                setRole(data);
            }
            else {
                let documentDayDefault = documentDays.find(x => x.key === 1000);
                data.daysDocument = documentDayDefault.text;
                data.daysDocumentsSpecification = data.daysDocuments;
                data.daysDocuments = 1000;
                setRole(data);
            }
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const Save = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            let errorMessagesRol = [];
            for (const property in showErrors) {
                if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                    const errorValue = showErrors[property];
                    if (errorValue) {
                        errorMessagesRol.push(errorValue);
                    }
                }
            }
            if (errorMessagesRol.length > 0) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessagesRol.map(x => x).join('. '))]);
            }
            else {
                let data = { ...role };
                data.daysReports = role?.daysReports == 1000 ? role.daysReportsSpecification : data.daysReports;
                data.daysDocuments = role?.daysDocuments == 1000 ? role.daysDocumentsSpecification : data.daysDocuments;
                let result = await UpdateAsync(id, data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, result.data);
                    data.id = id;
                    updateItem({ ...data, updateUserName: localStorage.getItem("userName") });
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }

    const submit = async () => {
        setOpenCallout(true);
    };
    const GetUser = async () => {
        let result = await GetUserByActive();
        if (result.isSuccessful) {
            const filteredList = result.data.filter((itemfilter) => !role.listGrantingUser.some(excluded => excluded.id == itemfilter.id));
            setItemListUser(filteredList);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const GetDocumentsDays = async () => {
        let result = await GetDocumentsDaysAsync();
        if (result.isSuccessful) {
            setDocumentDays(result.data);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        GetDocumentsDays();
    }, [])
    const onBlurRoleName = async (event) => {
        let text = event.target.value;
        if (text) {
            if (text.length <= 64) {
                let result = await ExistRoleNameEditAsync(text, id)
                if (result.isSuccessful) {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        roleName: undefined
                    }));
                }
                else {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        roleName: result.errorMessage
                    }));
                    event?.target?.focus();
                }
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    roleName: 'la longitud máxima deben ser 64 caracteres'
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                roleName: `El campo nombre es obligatorio`
            }));
            event?.target?.focus();
        }
    };
    const onBlurDaysReportsSpecification = (event) => {
        DaysSpecification(event, "daysReportsSpecification", "ver reportes");
    };
    const onBlurDaysDocumentsSpecification = (event) => {
        DaysSpecification(event, "daysDocumentsSpecification", "cancelar documentos");
    };
    const onBlurLimitDaysCreditNotesDiscount = (event) => {
        DaysSpecification(event, "limitDaysCreditNotesDiscount", "hacer una nota de crédito por descuento");
    };
    const onBlurLimitDaysCreditNotesReturn = (event) => {
        DaysSpecification(event, "limitDaysCreditNotesReturn", "cancelar documentos");
    };
    useEffect(() => {
        if (role?.listGrantingUser?.length > 0) {
            GetUser();
        }
    }, [role?.listGrantingUser])
    useEffect(() => {
        if (documentDays) {
            getById();
        }
    }, [documentDays])
    function DaysSpecification(event, property, text) {
        const input = event.target.value;
        if (input) {
            if (parseInt(input) <= 366) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    [property]: undefined
                }));
            } else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    [property]: `Los días para ${text} no deben ser mayor a 366.`
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `Los días para ${text} son obligatorios.`
            }));
            event?.target?.focus();
        }
    }
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (role) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
    return (
        <>
            {role &&
                <form onSubmit={handleSubmit(submit)}>
                    <BarButtons rightButtons={rightButtons}
                        leftButtons={leftButtons} />
                    <Pivot className="pivot-center-client" selectedKey={selectedKey}
                        onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                        overflowBehavior="menu" styles={pivotStyles} >
                        <PivotItem headerText="Información" itemKey="information">
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <OmniCard title='Datos generales'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox requiredOmni defaultValue title='Nombre'
                                                        focus property="roleName"
                                                        edit={setRole} maxLength={64} validationMessage={showErrors.roleName}
                                                        value={role?.roleName} blur={onBlurRoleName} />
                                                </div>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox title='Descripción'
                                                        property="description"
                                                        edit={setRole} maxLength={128}
                                                        value={role?.description} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Permisos" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }} >
                        </PivotItem>
                        <PivotItem headerText="Otorgantes" itemKey="grantors">
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Usuarios otorgantes'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <AddItem focus description={role}
                                                        setDescription={setRole}
                                                        itemList={itemListUser}
                                                        setItemList={setItemListUser}
                                                        titleColum="Lista de usuarios" property="listGrantingUser"
                                                        titleComboBox="Usuario que puede asignar el rol" />
                                                </div>
                                            </div>
                                        </OmniCard>

                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Documentos" itemKey="documents">
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Reportes'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniDropdown title='Días permitidos para ver reportes' items={documentDays}
                                                    property="daysReports" name="daysReport"
                                                    edit={setRole}
                                                    value={role?.daysReport}
                                                    selected={role?.daysReports} />
                                            </div>
                                            {role?.daysReports == 1000 &&
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox title='Especificar días para ver reportes' inputType={'decimal'}
                                                        max={366}
                                                        decimals={0} property="daysReportsSpecification"
                                                        edit={setRole} value={role?.daysReportsSpecification} blur={onBlurDaysReportsSpecification}
                                                        validationMessage={showErrors.daysReportsSpecification} />
                                                </div>
                                            }
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniDropdown title='Días permitidos para cancelar documentos' items={documentDays}
                                                    property="daysDocuments" name="daysDocument"
                                                    edit={setRole}
                                                    value={role?.daysDocument}
                                                    selected={role?.daysDocuments} />
                                            </div>
                                            {role?.daysDocuments == 1000 &&
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox title='Especificar días para cancelar documentos' inputType={'decimal'}
                                                        max={366}
                                                        decimals={0} property="daysDocumentsSpecification"
                                                        edit={setRole} value={role?.daysDocumentsSpecification}  blur={onBlurDaysDocumentsSpecification}
                                                        validationMessage={showErrors.daysDocumentsSpecification} />
                                                </div>
                                            }
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Notas de crédito'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Días para hacer una nota de crédito por descuento' inputType={'decimal'}
                                                    max={366}
                                                    decimals={0} property="limitDaysCreditNotesDiscount"
                                                    edit={setRole} value={role?.limitDaysCreditNotesDiscount} blur={onBlurLimitDaysCreditNotesDiscount}
                                                    validationMessage={showErrors.limitDaysCreditNotesDiscount} />
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Días para hacer una nota de crédito por devolución' inputType={'decimal'}
                                                    max={366}
                                                    decimals={0} property="limitDaysCreditNotesReturn"
                                                    edit={setRole} value={role?.limitDaysCreditNotesReturn} blur={onBlurLimitDaysCreditNotesReturn}
                                                    validationMessage={showErrors.limitDaysCreditNotesReturn} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Empleados" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }} >
                        </PivotItem>
                        <PivotItem headerText="Cambios" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                        </PivotItem>
                    </Pivot>
                </form>
            }
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Save}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>

    )
}