const deviceTypeEndpoint = {
    controller: "devicetype",
    actions: {
        getById: "GetById",
        add: "Add",
        update: "Update",
        setStatus: "SetStatus"
    }
}


var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(deviceTypeEndpoint.controller, deviceTypeEndpoint.actions.getById).url, {
            params: { id: id }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(deviceTypeEndpoint.controller, deviceTypeEndpoint.actions.add).url,
            JSON.stringify({
                ...data,
                UpdateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `El tipo de dispositivo se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(deviceTypeEndpoint.controller, deviceTypeEndpoint.actions.update).url,
            JSON.stringify({
                ...data,
                UpdateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            })
        );

        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El tipo de dispositivo se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const SetStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(deviceTypeEndpoint.controller, deviceTypeEndpoint.actions.setStatus).url,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El tipo de dispositivo ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}
export function CalculateVolume(height, width, depth, isCubicMeters) {
    height = height === '' ? 0 : parseFloat(height);
    width = width === '' ? 0 : parseFloat(width);
    depth = depth === '' ? 0 : parseFloat(depth);

    var volumen = height * width * depth;
    if (isCubicMeters) {
        volumen /= 1000000;
    }
    return volumen.toFixed(3);
}