export const leftButtonsEdit= [{
    key: 'LblTitle',
    text: 'Editando...',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const leftButtonsDetail= [{
    key: 'LblTitle',
    text: 'Consultando...',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];