import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form'
import BarButtons from '../../Shared/BarButtons/BarButtons'
import { OmniCard } from '../../Shared/Card/OmniCard'
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox'
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Categories/Edit'
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Categories/CategoriesStore'
import { useGeneralStatesContext, useIdSelectorContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const [category, setCategory] = useState([]);
    SetAxiosPrivate(axiosPrivate);
    

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };

    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = getValues();
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, id);
                data.id = id;
                updateItem({ ...data, updateUser: localStorage.getItem("userName") });
                setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    useEffect(() => {        
        const fillData = async () => {
            try {
                const dataCategory = await getById();
                setCategory(dataCategory);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" >
                <PivotItem headerText="Información" itemKey="information" >
                <div className='container-fluid container-scroll-two-bars'>
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        {
                                                category.length!== 0 && (
                                                        <OmniTextBox requiredOmni defaultValue setValue={setValue} title='Nombre' register={{ ...register('name') }} value={category.name} />
                                            )
                                        }
                                        
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        
                                    </div>
                                </div>
                            </OmniCard>
                        </div>
                    </div>
                </div>
                </PivotItem>
                <PivotItem headerText="Artículos" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                </PivotItem>
                <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                </PivotItem>   
            </Pivot>
        </form>
        {
            <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={update}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rightCommandBar'} 
            />
        }
         
        </>
    )
}