import { Input, Field } from "@fluentui/react-components";
import { ColorPicker, Callout, DirectionalHint, SwatchColorPicker } from "@fluentui/react";
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState } from 'react';
import { useEffect } from "react";
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
import '../../../../Assets/Styles/Shared/Controls/OmniTextBox.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import '../../../../Assets/Styles/Shared/Controls/OmniColorPicker.css'

export const OmniColorPicker = (props) => {
    const fieldWatcher = useFieldChangeWatcherContext();
    const [valueItem, setvalueItem] = useState(props.value ?? '');
    const initialFieldValue = props?.value?.toUpperCase() ?? '';
    const fieldEdited = useRef(false);
    const ConvertToUpperCase = (event) => {
        let itemUpperCase = event.target.value;
        if (!props.notuppercase) {
            itemUpperCase = itemUpperCase.toUpperCase();
        }
        setvalueItem(itemUpperCase);
        if (initialFieldValue === itemUpperCase) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
    };
    const formControlId = props.register?.name;


    const keyDown = (event) => {
        if (event.key === 'Enter') {
            if (props.preventDefault === undefined) {
                event.preventDefault();
            }
            else if (props.preventDefault.toUpperCase() === "TRUE") {
                event.preventDefault();
            }
        }
    }

    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === formControlId) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }
    }, [fieldEdited.current]);

    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    const Blur = (event) => {
        if (typeof props.blur === 'function') {
            props.blur(event)
        }
        if (props.property && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.property]: event.target.value }));
        }
    };
    useEffect(() => {
        setvalueItem(props.value ?? '');
    }, [props.value]);
    const handleFocus = () => {
        setShowColor(false);
    };
    const [showColor, setShowColor] = useState(true);
    const [colorSquare, setColorSquare] = useState();
    const onChangeColor = (event, data) => {
        setvalueItem(data.str?.toUpperCase());
        setColorSquare({ id: '1', label: 'color', color: data.str });
        if (initialFieldValue === data.str) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }

    };
    useEffect(() => {
        setColorSquare({ id: '1', label: 'color', color: props.value });
    }, [props.value])
    return (
        <>
            <div className={'container-controls'} >
                <Field className={"label-omni"}>
                    <OmniFieldLabel {...props} formControlId={"InputColor"} fieldEdited={fieldEdited.current}></OmniFieldLabel>
                   
                    <Input {...props} style={{ width: props.width }} autoComplete="off" {...props.register}
                        value={valueItem} maxLength={7} 
                        className={`textBox-omni ${props.notuppercase ? '' : 'uppercaseInput'}`}
                        spellCheck={props.notspellcheck ? false : true}
                        autoFocus={props.focus} onChange={ConvertToUpperCase}
                        {...props.contentAfter} title=""
                        type={props.type == undefined ? "" : props.type}
                        tabIndex={props.tabIndex == undefined ? "" : props.tabIndex}
                        onKeyDown={keyDown} onBlur={Blur} onFocus={handleFocus} id="inputColor"
                        contentBefore={
                            <SwatchColorPicker
                                columnCount={1}
                                cellShape={'square'}
                                colorCells={[colorSquare]}
                                disabled={true}
                                className="coloPicker-omni"
                            />
                        }
                    />
                    <Callout
                        gapSpace={0}
                        target={`#inputColor`}
                        onDismiss={() => {
                            setShowColor(true);
                        }}
                        hidden={showColor}
                        directionalHint={DirectionalHint.bottomCenter}>
                        <ColorPicker alphaType={'none'} color={valueItem}
                            onChange={onChangeColor} />
                    </Callout>
                </Field>
            </div>
        </>
    );
}