import { useEffect, useState } from "react";
import { Label } from "@fluentui/react-components"
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/AccountingTaxes/Details';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import {GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate} from '../../../Functions/Catalogs/AccountingTaxes/AccountingTaxStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";

export default function Detail(props) {
    const id=useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus } = useGeneralStatesContext();
    const [accountingTax, setAccountingTax]=useState({});
    const [status, setStatus] = useState('');
    const [leftButtonsDetail, setLeftButtonsDetail] = useState(leftButtons);
    
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById=async()=>{
        let result=await GetByIdAsync(id, axiosPrivate);
        if(result.isSuccessful){
            setAccountingTax(result.data);
            setStatus(!result.data.active);
        }
        else{
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);   
        }
    }

    const updateStatus=async()=>{
        
        
        let result=await UpdateStatusAsync(id,status);
        if(result.isSuccessful){
            setStatus(!status);
            updateItemStatus(id);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else{
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);   
        }
    }
    
    useEffect(()=>{
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
    },[id])

    useEffect(() => {
        const updatedButtons = leftButtonsDetail.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton} el impuesto?`,
                    handler: () => { updateStatus() }
                };
            }
            return button;
        });
        setLeftButtonsDetail(updatedButtons);
    }, [status]);

    

    return (
        <>
            <BarButtons rightButtons={rightButtons} change={"pruebaschange"}
                leftButtons={leftButtonsDetail}/>
                <OmniCard title="Datos generales">
                    <div className={'container-controls'}>
                        <Label className='label-omni'>Descripción</Label>
                        <div className='container-omni-result'>
                            <Label>{accountingTax.description}</Label>
                        </div>
                    </div>
                    <div className={'container-controls'}>
                        <Label className='label-omni'>Acrónimo</Label>
                        <div className='container-omni-result'>
                            <Label>{accountingTax.acronym}</Label>
                        </div>
                    </div>
                    <div className={'container-controls'}>
                        <Label className='label-omni'>Tasa</Label>
                        <div className='container-omni-result'>
                            <Label>{accountingTax.taxRate}</Label>
                        </div>
                    </div>
                </OmniCard>
        </>
        
        
    )
}