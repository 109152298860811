import { OmniCard } from '../../Shared/Card/OmniCard';
import { Field } from "@fluentui/react-components";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { rightButtons, leftButtons } from '../../../Assets/Constants/BarButtons/Catalog/CompanyAsset/Detail';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { useEffect, useState } from 'react';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';

const getByIdUrl = '/companyasset/getbyid';
const updateStatusUrl = '/companyasset/SetStatus';

export default function Detail() {
    const [status, setstatus] = useState('');
    const { setListMessageCatalog } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const [leftButtonsDetail, setLeftButtonsDetail] = useState(leftButtons);
    const [description, setdescription] = useState('');

    useEffect(() => {
        onSubmit();
    }, [id]);

    const onSubmit = async () => {
        try {
            const response = await axiosPrivate.get(getByIdUrl, {
                params: {id: id}
                }
            );
            if (response.data.isSuccessful) {
                setstatus(!response.data.data.active);
                setdescription(response.data.data.description);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }
        } catch (err) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
        }
    };
    useEffect(() => {
        onSubmit();
    }, [id]);
    const onChangeStatus = async () => {
        try {
            const response = await axiosPrivate.put(updateStatusUrl,
                JSON.stringify({
                    id: id,
                    active: status,
                    loggedUserName: localStorage.getItem("userName")
                })
            );

            if (response.data.isSuccessful) {
                let statustext = status ? "activado" : "desactivado";
                setstatus(!status);
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(`El activo ha sido ${statustext} exitosamente.`)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    }

    useEffect(() => {
        const updatedButtons = leftButtonsDetail.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton} el activo?`,
                    handler: () => { onChangeStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: status,
                };
            }
            return button;
        });
        setLeftButtonsDetail(updatedButtons);
    }, [status]);

    return (
        <>
            <BarButtons rightButtons={rightButtons} leftButtons={leftButtonsDetail} />
            <OmniCard title='Datos generales'>
                <div className={'container-controls'}>
                    <Field label="Descripción" className={"label-omni"} />
                    <br />
                    <Field label={description} />
                </div>
            </OmniCard>
        </>
    );
}