const url = {
    base: '/synchronizerexception',
    add: '/add',
    get: '/getbyid',
    updateStatus: '/setstatus',
}

var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(url.base + url.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    if(data.sku===undefined){
        result.errorMessage="debe seleccionar una artículo"
        return result;
    }
    if(data.exception===null){
        result.errorMessage="debe seleccionar una excepción"
        return result;
    }
    if(data.allBranches===false && data.CuvikBranchList.length===0){
        result.errorMessage="debe seleccionar una sucursal"
        return result;
    }

    try {
        let identificator = data.id ? data.id : 0;
        var stringTOSend=JSON.stringify({
            id: identificator,
            sku: data.sku,
            exception: data.exception,
            allBranches:data.allBranches,
            CuvikBranchList:data.CuvikBranchList,
            updateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
        })
        const response = await axiosPrivate.post(url.base + url.add,
            stringTOSend
        );
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `La excepción se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(url.base + url.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La excepción ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}


const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    }
    else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}
