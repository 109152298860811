import { Checkbox, Image } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
const iconCheckboxSelected = {
    src: require('../../../../Assets/Images/Icons/CheckboxSelected.svg').default
}

export function Validations(props){

    //Validaciones del artículo
    const requiresIdentificationForSale="Venta sólo para mayores de edad";
    const allowsChargeWithEBT="Permite cobro con tarjeta EBT";
    const useAmount="Usa importe";

    //Canales
    const sellinStores="Satya - Venta en tiendas";
    const presaleInRoute="Go - Preventa en ruta";
    const itemOnBoardSale="Bring - Venta con producto a bordo";
    const telemarketing="Call - Telemarketing";
    const ecommerceB2C="Site - eCommerceB2C";
    const ecommerceB2B="Portal - eCommerceB2B";
    const amazonMarketPlace="Amazon - Market Place";
    const mercadoLibreMarketPlace="MercadoLibre - Market Place";
    const walmartMarketPlace="Walmart - Market Place";

    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Validaciones del artículo'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.requiresIdentificationForSale
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={requiresIdentificationForSale} checked />
                                    :
                                        <div className="check-omni-disabled-div">{requiresIdentificationForSale}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.allowsChargeWithEBT
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={allowsChargeWithEBT} checked />
                                    :
                                        <div className="check-omni-disabled-div">{allowsChargeWithEBT}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.useAmount
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={useAmount} checked />
                                    :
                                        <div className="check-omni-disabled-div">{useAmount}</div>
                                }
                            </div>
                        </div>
                    </OmniCard>
                    <OmniCard title='Canales'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.sellinStores
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={sellinStores} checked />
                                    :
                                        <div className="check-omni-disabled-div">{sellinStores}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.presaleInRoute
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={presaleInRoute} checked />
                                    :
                                        <div className="check-omni-disabled-div">{presaleInRoute}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.itemOnBoardSale
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={itemOnBoardSale} checked />
                                    :
                                        <div className="check-omni-disabled-div">{itemOnBoardSale}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.telemarketing
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={telemarketing} checked />
                                    :
                                        <div className="check-omni-disabled-div">{telemarketing}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.ecommerceB2C
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={ecommerceB2C} checked />
                                    :
                                        <div className="check-omni-disabled-div">{ecommerceB2C}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.ecommerceB2B
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={ecommerceB2B} checked />
                                    :
                                        <div className="check-omni-disabled-div">{ecommerceB2B}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.amazonMarketPlace
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={amazonMarketPlace} checked />
                                    :
                                        <div className="check-omni-disabled-div">{amazonMarketPlace}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.mercadoLibreMarketPlace
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={mercadoLibreMarketPlace} checked />
                                    :
                                        <div className="check-omni-disabled-div">{mercadoLibreMarketPlace}</div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                {
                                    props.item.walmartMarketPlace
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={walmartMarketPlace} checked />
                                    :
                                        <div className="check-omni-disabled-div">{walmartMarketPlace}</div>
                                }
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}