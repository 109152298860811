import { Button, Checkbox, CompoundButton, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export function Restocking(props){
    return(
        <div className='container-fluid container-scroll-two-bars'>
        <div className='row'>
            <div className='col-lg-6 col-6'>
                <OmniCard title='Tipo de resurtido'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Este articulo se resurte en' value={props.item.nameRefillMeasure} />
                        </div>
                    </div>
                </OmniCard>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12 col-12'>
                <OmniCard title='Existencias por almacén'  classCard="long-card">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            ID
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Tipo
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Nombre del almácen
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Minimos
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Máximos
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Capacidad
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Resurtir
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Medida
                                        </TableHeaderCell>
                                        <TableHeaderCell className="header-cell-itemByBranch">
                                            Piezas
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                {
                                    props.item.itemRestockResults?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TableCellLayout className="cell-bold">
                                                    {item.consecutiveItem}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout className={`cell-bold ${item.isWarehouse?'cell-warehouse':'cell-store'}`}>
                                                    {item.warehouseType}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                                                {item.warehouseName}
                                            </TableCell>
                                            <TableCell >
                                                {item.minimum}
                                            </TableCell>
                                            <TableCell>
                                                {item.maximum}
                                            </TableCell>
                                            <TableCell>
                                                {item.capacity}
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>
                                                    {
                                                        item.restock
                                                        ?
                                                            <Checkbox className='cell-check-disabled' size='large' disabled checked />
                                                        :
                                                            <Checkbox className='check-omni-disabled cell-check-disabled' size='large' disabled />
                                                    }
                                                    
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout>
                                                    {
                                                        item.isWarehouse == false
                                                        ?
                                                         <CompoundButton className="restocking-button">
                                                            Piezas
                                                         </CompoundButton>
                                                        : <></>
                                                    }
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout >
                                                    {
                                                        item.isWarehouse == false
                                                        ?<span>2</span>
                                                        :<></>
                                                    }
                                                </TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </OmniCard>
            </div>
        </div>
    </div> 
    )
}