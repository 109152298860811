export const OfferStatusDescripcion = [
    { status: false, description: "Inactivo", initial: "I" },
    { status: true, description: "Activo", initial: "A" }
];

export const OfferStatus = {
    Desactive: false,
    Active: true,
}

export const OfferType = [
    { id: 1, description: "Por descuento" },
    { id: 2, description: "Por precio" },
    { id: 3, description: "Precio por cantidad" },
    { id: 4, description: "Descuento por cantidad" },
    { id: 5, description: "Precio MxN" },
    { id: 6, description: "Descuento MxN" },
    { id: 7, description: "Combo" },
    { id: 8, description: "Mayoreo" },
];