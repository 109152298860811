export const ItemCost = [
    {
        key: "1",
        value: "1",
        text: "Costo neto",
    },
    {
        key: "0",
        value: "0",
        text: "Costo sin impuestos",
    },
];