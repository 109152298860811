import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export default function Information(props){
    return(
        <>
          <div className='container-fluid container-scroll-two-bars'>
            <div className="row">
              <div className="col-lg-6 col-md-6 ">
                <OmniCard title='Datos generales'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                      <OmniLabel text='R.F.C.' value={props.supplier.rfc} />  
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <OmniLabel text='Razón social' value={props.supplier.businessName} />
                    </div>
                    <div className='col-lg-12 col-md-12'>
                      <OmniLabel text='Nombre comercial' value={props.supplier.tradename} />
                    </div>
                  </div>
                </OmniCard>
              </div>
              <div className="col-lg-6 col-md-6">
                <OmniCard title='Particulares'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                      <OmniLabel text='Saldo' value={props.supplier.balance} />  
                    </div>
                  </div>
                </OmniCard>
                <OmniCard title='Observaciones'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                      <OmniLabel text='Observaciones del proveedor' value={props.supplier.observation} />  
                    </div>
                  </div>
                </OmniCard>
              </div>
            </div>
          </div>
        </>
    );
}