import Tax from './Components/Catalogs/AccountingTaxes/Tax'
import Bank from './Components/Catalogs/Banks/Bank';
import Brand from './Components/Catalogs/Brand/Brand';
import Categories from './Components/Catalogs/Categories/Categories'
import Customer from './Components/Catalogs/Customers/Customer';
import CompanyAsset from './Components/Catalogs/CompanyAssets/CompanyAsset'
import ConvertItem from './Components/Catalogs/ConvertItems/ConvertItem'
import Creditor from "./Components/Catalogs/Creditors/Creditor";
import CreditorAccount from "./Components/Catalogs/CreditorAccount/CreditorAccount";
import CreditorSubAccount from "./Components/Catalogs/CreditorSubAccount/CreditorSubAccount";

import CustomerType from './Components/Catalogs/CustomerTypes/CustomerType';
import Employee from './Components/Catalogs/Employees/Employee';
import Maps from './Components/Maps/RoutesMap/RouteMap';
import Index from './Components/Accounting/MasterPolicy/Index';
import Measure from './Components/Catalogs/Measures/Measure';
import Family from "./Components/Catalogs/Families/Family";
import Home from "./Components/Home/Home";
import Ieps from "./Components/Catalogs/IepsTax/Ieps"
import Linebusiness from './Components/Catalogs/Linebusiness/Linebusiness';
import Item from "./Components/Catalogs/Items/Item";
import OfferCampaign from "./Components/Offers/OfferCampaigns/OfferCampaign";
import Routes from './Components/Catalogs/Routes/Routes';
import SatCatalog from './Components/Configuration/SatCatalogs/SatCatalog';
import Supplier from './Components/Catalogs/Suppliers/Supplier';
import TransferRoute from './Components/Catalogs/TransferRoutes/TransferRoute';
import Variety from './Components/Catalogs/Varieties/Variety';
import Vehicles from './Components/Catalogs/Vehicles/Vehicles';
import Role from './Assets/Constants/Enums/Role';

import DeviceType from './Components/Configuration/DeviceTypes/DeviceType';
import Document from './Components/Configuration/Documents/Document';
import Driver from "./Components/Configuration/Drivers/Driver";
import PreticketGroup from "./Components/Configuration/PreticketGroups/PreticketGroup"
import Roles from './Components/Configuration/Roles/Role';
import SynchronizerException from './Components/Configuration/SynchronizerExceptions/SynchronizerException';
import Warehouse from './Components/Configuration/Warehouses/Warehouse';
import WarehouseLocation from './Components/Configuration/WarehouseLocations/WarehouseLocation';
import XMLFile from './Components/Accounting/XMLFile/XMLFile';
import AttendanceLog from './Components/Reports/AttendanceLogs/AttendanceLog';
const AppRoutes = [
    {
        path:'catalogs/accountingtaxes',
        element:<Tax/>
    },
    {
        path: 'reports/attendancelog',
        element: <AttendanceLog />
    },
    {
        path: '/catalogs/banks',
        element: <Bank />
    },
    {
        path: '/catalogs/brand',
        element: <Brand />
    },
    {
        path: 'catalogs/customers',
        element: <Customer />
    },
    {
        path: 'catalogs/customertypes',
        element: <CustomerType />
    },
    {
        path: 'catalogs/categories',
        element: <Categories />
    },
    {
        path: 'catalogs/companyasset',
        element: <CompanyAsset />
    },
    {
        path: 'catalogs/convertItems',
        element: <ConvertItem />
    },
    {
        path: 'catalogs/creditor',
        element: <Creditor/>
    },
    {
        path: 'catalogs/creditoraccount',
        element: <CreditorAccount />
    },
    {
        path: 'catalogs/creditorsubaccount',
        element: <CreditorSubAccount />
    },
    {
        path: 'catalogs/employees',
        element: <Employee />
    },
    {
        path: 'Accounting/masterpolicy/index',
        element: <Index />
    },
    {
        path: 'catalogs/linebusiness',
        element: <Linebusiness />
    },
    {
        path: 'catalogs/Measures',
        element: <Measure />
    },
    {
        path: 'catalogs/family',
        element: <Family />
    },
    {
        path: '/',
        element: <Home/>,
    },
    {
        path:'catalogs/ieps',
        element:<Ieps/>
    },
    {
        path:'catalogs/item',
        element:<Item/>
    },
    {
        path: 'offers/offercampaign',
        element: <OfferCampaign/>
    },
    {
        path: 'catalogs/routes',
        element: <Routes />
    },
    {
        path: 'catalogs/suppliers',
        element: <Supplier />
    },
    {
        path: 'configuration/satcatalog',
        element: <SatCatalog />
    },
    {
        path: 'catalogs/transferroute',
        element: <TransferRoute />
    },
    {
        path: '/catalogs/variety',
        element:<Variety/>
    },
    {
        path: 'catalogs/vehicles',
        element: <Vehicles />
    },
    



    {
        path: 'configuration/devicetype',
        element: <DeviceType />
    },
    {
        path: 'configuration/documents',
        element: <Document />
    },
    {
        path: 'configuration/driver',
        element: <Driver />
    },
    {
        path: 'configuration/preticketgroups',
        element: <PreticketGroup />
    },
    {
        path: 'configuration/roles',
        element: localStorage?.getItem("rolId")?.toString() === Role.Administrador.toString() && <Roles />
    },
    {
        path: 'configuration/synchronizerexception',
        element: <SynchronizerException />
    },
    {
        path: 'configuration/warehouse',
        element: <Warehouse />
    },
    {
        path:'configuration/warehouselocation',
        element:<WarehouseLocation />
    },
    {
        path: 'Accounting/xmlfile',
        element: <XMLFile />
    },
    {
        path: 'maps/routemap',
        element: <Maps />
    },
];

export default AppRoutes;
