import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Creditor/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Creditors/CreditorStore';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';

export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [creditor, setCreditor] = useState([]);
    const [buttonsCreditor, setButtonsCreditor] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setCreditor({
                id: result.data.id,
                name: result.data.name,
                rfc: result.data.rfc,
                creditoraccountdescription: result.data.creditorAccountDescription,
                creditorsubaccountdescription: result.data.creditorSubAccountDescription,
                accountingAccount:result.data.accountingAccount,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !creditor.active);
        if (result.isSuccessful) {
            setCreditor((prevCreditor) => {
                return {
                    ...prevCreditor,
                    active: !creditor.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = creditor.active ? "Inactivar" : "Activar";
                let statusiconbutton = creditor.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = creditor.active ? 494 : 495
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la ubicación?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !creditor.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (creditor) {
            setButtonsCreditor(prevCreditor => ({
                ...prevCreditor,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...creditor};
            data.name = creditor.warwhouseName;
            updateItemStatus({ ...data, acronym: localStorage.getItem("userName") });
        }
    }, [creditor?.active]);

    useEffect(() => {
        if (creditor) {
            setButtonsCreditor(prevCreditor => ({
                ...prevCreditor,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);   

    return (
        <>
            {creditor.length !== 0 &&
                (<BarButtons rightButtons={buttonsCreditor.rightButtons}
                leftButtons={buttonsCreditor.leftButtons} />)}
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Datos generales'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Razón social' value={creditor.name} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='RFC' value={creditor.rfc} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Rubro' value={creditor.creditoraccountdescription} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Subrubro' value={creditor.creditorsubaccountdescription} />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
                    </div> 
                </PivotItem>
                <PivotItem headerText="Contabilidad" itemKey="accountingAccount">
                    <OmniCard title='Datos generales'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Contabilidad' value={creditor.accountingAccount} />
                            </div>
                        </div>
                    </OmniCard>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={creditor} />
                </PivotItem>
            </Pivot>
        </>
    )
}