import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/ConvertItems/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, generateObject } from '../../../Functions/Catalogs/ConvertItems/ConvertItemStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailItem } from '../../Shared/ItemList/Detail';
import { Pivot, PivotItem } from '@fluentui/react';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton } = useGeneralStatesContext();
    const [conversion, setConversion] = useState();
    const [buttonsVariety, setButtonsVariety] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setConversion({
                id: result.data.id,
                convertItemId: result.data.convertItemId,
                convertItem: result.data.convertItem,
                convertedItem: generateObject(result.data?.convertedItemResult),
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !conversion.active);
        if (result.isSuccessful) {
            setConversion((prevVariety) => {
                return {
                    ...prevVariety,
                    active: !conversion.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = conversion.active ? "Inactivar" : "Activar";
                let statusiconbutton = conversion.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = conversion.active ? 766 : 767;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la conversión?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !conversion.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (conversion) {
            setButtonsVariety(prevVariety => ({
                ...prevVariety,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...conversion };
            data.name = conversion.convertItem; 
            updateItemStatus({ ...data, updateUserName: localStorage.getItem("userName") });
        }
    }, [conversion?.active]);

    useEffect(() => {
        if (conversion) {
            setButtonsVariety(prevVariety => ({
                ...prevVariety,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);

    return (
        <>
            {conversion &&
                (<BarButtons rightButtons={buttonsVariety.rightButtons}
                    leftButtons={buttonsVariety.leftButtons} />)
            }
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Artículo a convertir' value={conversion?.convertItem} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Conversión'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            {conversion?.convertedItem &&
                                                <DetailItem description={conversion}
                                                    property="convertedItem" titleColum="Lista de artículos" />
                                            }
                                        </div>
                                    </div>
                                </OmniCard>

                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={conversion} />
                </PivotItem>
            </Pivot>
        </>
    )
}