import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { AccountingSystemEnum } from '../../../Assets/Constants/Enums/AccountingSystem';
import { useEffect, useState } from 'react';
export const AccountingAccount = (props) => {
    const { accountingSystem } = useAccountingConfigurationContext();
    const [regexPattern, setRegexPattern] = useState();
    useEffect(() => {
        const regexMicrosip = `^(?:[0-9]+(?:\.[0-9]*)?)*$`;
        const regexContpaqi = `^[a-zA-Z0-9]*$`; //= `^(?:[0-9]?)*$`;
        setRegexPattern(new RegExp(accountingSystem == AccountingSystemEnum.CONTPAQi ? regexContpaqi : regexMicrosip));
    }, [])
    return (
        <div className='row'>
            <OmniTextBox
                {...props}
                maxLength={30}
                regex={regexPattern}
            />
        </div>
    );
}