const getByIdUrl = '/customer/getbyid';
const updateStatusUrl = '/customer/SetStatus';
const updateUrl = '/customer/update';
const addUrl = '/customer/add';
const getCustomerTypeUrl = '/customertype/GetByStatus';
const getLinebusinessUrl = '/linebusiness/GetByStatus';
const getPostalCodesUrl = '/postalscodes/GetByAll';
const getContriesUrl = '/contries/GetByStatus';
const getStatesUrl = '/states/GetByStatus';
const getMunicipalitiesByStateIdUrl = '/municipalities/GetByStateId';
const getLocalitiesUrl = '/localities/GetByStatus';
const getColoniesbyMunicipalityIdUrl = '/colonies/GetByMunicipalityId';
const getTaxRegimesUrl = '/taxregimes/GetByStatus';
const getUsesCFDIUrl = '/usescfdi/GetByStatus';
const getPaymentMethodsUrl = '/paymentmethods/GetByStatus';
const getContactsRolUrl = '/contactsrol/GetByStatus';
const getCreditUrl = '/customer/GetPermitCredit';
const getColoniesByPostalCodeUrl = '/colonies/GetByPostalCode';
const getPostalCodesByClueUrl = '/postalscodes/GetByPostalCode';
const addContactsUrl = '/contacts/add';
const updateContactsUrl = '/contacts/update';
const updateStatusContactsUrl = '/contacts/SetStatus';
const addShippingAddressesUrl = '/shippingAddresses/add';
const updateShippingAddressesUrl = '/shippingAddresses/update';
const updateStatusShippingAddressesUrl = '/shippingAddresses/SetStatus';
const updateLocationShippingAddressesUrl = '/shippingAddresses/SetLocation';
const deletePhotoShippingAddressesUrl = '/shippingAddresses/DeletePhoto';
const deleteLocationShippingAddressesUrl = '/shippingAddresses/DeleteLocation';
const validateRfcEditUrl = '/customer/ValidateRfcEdit';
const validateRfcUrl = '/customer/ValidateRfc';
const getLastAccountingAccount = '/customer/GetLastAccountingAccount';
const getbyActive = '/customer/GetByActive';
var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetPermitCreditAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getCreditUrl, {
            params: { roleId: localStorage.getItem("rolId") }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getByIdUrl, {
            params: { id: id }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateStatusAsync = async (id, status,loked) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusUrl,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = ''
        if (loked) {
            statustext = status == 3 ? "bloqueado" : "desbloqueado";
        }
        else {
            statustext = status ? "activado" : "desactivado";
        }
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El cliente ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El cliente se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El cliente se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const GetPostalsCodes = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getPostalCodesUrl);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetCustomerType = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getCustomerTypeUrl,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetLinebusiness = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getLinebusinessUrl,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetContries = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getContriesUrl,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetStates = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getStatesUrl,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetMunicipalitiesStateId = async (stateId) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getMunicipalitiesByStateIdUrl, {
            params: { stateId: stateId }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetLocalities = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getLocalitiesUrl,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetColoniesbyMunicipalityId = async (municipalityId) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getColoniesbyMunicipalityIdUrl, {
            params: { municipalityId: municipalityId }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetTaxRegimes = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getTaxRegimesUrl);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetUsesCFDI = async (taxRegimeId) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUsesCFDIUrl, {
            params: { taxRegime: taxRegimeId }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetPaymentMethods = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getPaymentMethodsUrl);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetContactsRol = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getContactsRolUrl,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetColoniesByPostalCode = async (postalcode) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getColoniesByPostalCodeUrl, {
            params: { postalCode: postalcode }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetPostalCodesByClue = async (postalcode) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getPostalCodesByClueUrl, {
            params: { postalCode: postalcode }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export function generateObject(item) {
    if (item) {
        return item.map(x => ({ ...x }));
    }
}
export function generateEmailList(email) {
    if (email) {
        const correos = email.split(';').map(correo => correo.trim());
        return correos.map(correo => ({ description: correo }));
    }
}
export function generateEmailString(emailList) {
    if (emailList && emailList.length > 0) {
        return emailList.map(emailObj => emailObj.description).join('; ');
    }
    return null;
}

export const AddContactsAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addContactsUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El contacto se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateContactAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateContactsUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El contacto se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateStatusContactsAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusContactsUrl,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El contacto ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const AddShippingAddressesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addShippingAddressesUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La dirección de envío se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateShippingAddressesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateShippingAddressesUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La dirección de envío se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateStatusShippingAddressesAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusShippingAddressesUrl,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activada" : "desactivada";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La dirección de envío ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateShippingAddressesLocationAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateLocationShippingAddressesUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La dirección de envío se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const DeleteLocationShippingAddressesLocationAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(deleteLocationShippingAddressesUrl,
            JSON.stringify({
                id: id,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La dirección de envío se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const DeletePhotoShippingAddressesLocationAsync = async (id, imageId, imageExtension) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(deletePhotoShippingAddressesUrl,
            JSON.stringify({
                id: id,
                imageId: imageId,
                imageExtension: imageExtension,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La dirección de envío se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const rfcRegex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
export const ExistValidateRFCAsync = async (rfc) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(validateRfcUrl, {
            params: { rfc: rfc }
        });
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const ExistValidateRFCEditAsync = async (rfc,id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(validateRfcEditUrl, {
            params: { rfc: rfc,id:id }
        });
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const GetLastAccountingAccountAsync = async (accountingSystem, base, countAutoincrement) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        countAutoincrement = countAutoincrement == null ? '0' : countAutoincrement;
        const response = await axiosPrivate.post(getLastAccountingAccount,
            JSON.stringify({ "AccountingSystem": accountingSystem, "BaseAccountingAccount": base, "AutoincrementLength": countAutoincrement }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}

const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}