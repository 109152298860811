import { Checkbox } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export default function WeightVolume(props){
    const isStackableItem="Este artículo se puede apilar";
    const validateExpiration="Se valida la caducidad en el recibo";

    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Peso y volumen de la pieza'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Peso por pieza' value={props.item.weightByPiece} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Peso por caja' value={props.item.weightByBox} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Peso por tarima' value={props.item.weightByPallet} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Alto' value={props.item.height} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Ancho' value={props.item.width} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Fondo' value={props.item.depth} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Volumen por pieza' value={props.item.volumeByPiece} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Volumen por caja' value={props.item.volumeByBox} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Volumen por tarima' value={props.item.volumeByPallet} />
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className="col-md-auto">
                    <OmniCard title="Articulo apilable">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                {
                                    props.item.isStackableItem
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={isStackableItem} checked />
                                    :
                                        <div className="check-omni-disabled-div">{isStackableItem}</div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <OmniLabel text='Alto' value={props.item.stackableHeight} />
                        </div>
                    </OmniCard>
                    <OmniCard title="Caducidad">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                                {
                                    props.item.validateExpiration
                                    ?
                                        <Checkbox className='check-omni-disabled' size='large' disabled label={validateExpiration} checked />
                                    :
                                        <div className="check-omni-disabled-div">{validateExpiration}</div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            {
                                props.item.validateExpiration
                                ?
                                    <OmniLabel text='Meses de caducidad en el recibo' value={props.item.expirationMonthsInTicket} />
                                :
                                    <></>
                            }
                            
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}