import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import { Detail as DetailItem } from '../../../Shared/ItemList/Detail';


export default function SupplierInTruck(props)
{
    return (
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Proveedor a pie de camión'>
                            <div className='row'>
                                <OmniLabel text='Este proveedor entrega a pie de camión'
                                    value={props.supplier.inTruck ? "Si" : "No"} />                                 
                            </div>
                        </OmniCard>
                        <OmniCard title='Compras a pie de camión'>
                            {props.supplier.inTruck &&
                                <>
                                    <div className='row'>
                                        <OmniLabel text='Permite búsqueda por descripción'
                                            value={props.supplier.searchDescription ? "Si" : "No"} />
                                    </div>
                                    <div className='row'>
                                        <OmniLabel text='Solo usa piezas'
                                            value={props.supplier.piecesOnly ? "Si" : "No"} />
                                    </div>
                                    <div className='row'>
                                        <OmniLabel text='Calcula el resurtido'
                                            value={props.supplier.calculateRefill ? "Si" : "No"} />
                                    </div>
                                </>
                            }
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Captura de costos'
                                        value={ props.supplier.netCost==1 ? "Costo neto" : "Costo sin impuestos"} />
                                </div>
                            </div>
                            {props.supplier.inTruck &&
                                <>
                                    <div className='row'>
                                        <OmniLabel text='Permite aumentar la cantidad de artículos'
                                            value={props.supplier.mostSuggestedPurchase ? "Si" : "No"} />
                                    </div>
                                    <div className='row'>
                                        <OmniLabel text='Permite agregar artículos no sugerido'
                                            value={props.supplier.addProducts ? "Si" : "No"} />
                                    </div>
                                </>
                            }
                        </OmniCard>
                    </div>
                    <div className='col-md-auto'>
                        <OmniCard title='Artículos relacionados'>
                            <div className='row'>
                                <div id='divItemsContainer' className='row card-items-container col-lg-12 col-md-12' >
                                        <DetailItem description={props.supplier}
                                            property="listSupplierItemResult" titleColum="Lista de artículos" />
                                </div> 
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </>    
    );
}