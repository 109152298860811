import { Stack } from "@fluentui/react";
import '../../Assets/Styles/Home/Home.css'
const indexIcon = require('../../Assets/Images/Icons/Index.svg').default;
export default function Home (){
    return (
            <Stack>
                <Stack.Item
                    className="home-header-container">
                    <img src={indexIcon} className="home-header-image"></img>
                    <span className="home-header-text">
                        Inicio
                    </span>
                </Stack.Item>
                <Stack.Item
                    className="home-body-container">
                <span />
                </Stack.Item>
            </Stack>
    );
}