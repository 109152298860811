import Add from '../../../../../Components/Offers/OfferCampaigns/Add';
import Edit from '../../../../../Components/Offers/OfferCampaigns/Edit';
import CampaignAuthorizations from "../../../Authorizations/Offers/OfferCampaigns";
export const leftButtons = [
    {
        key: 'BtnEdit',
        text: 'Editar',
        iconName: 'editcuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        component: <Edit />,
        authorizationKey: CampaignAuthorizations.Save,

    },
    {
        key: 'BtnStatus',
        text: 'Inactivar',
        iconName: 'inactivecuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        backgroundColorButton: '',
        backgroundColorButtonHover: '',
        authorizationKey: CampaignAuthorizations.Delete,
    }
];
export const rightButtons = [
    {
        key: 'BtnNew',
        text: 'Nueva',
        iconName: 'addcuvitek',
        fontColor: '#FFFFFF',
        iconColor: '#fff',
        iconHover: '#fff',
        backgroundColorButton: 'var(--primary)',
        backgroundColorButtonHover: 'var(--primary)',
        isButton: true,
        component: <Add />,
        authorizationKey: CampaignAuthorizations.New,
    }
];