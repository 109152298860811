import { useEffect, useState } from 'react';
import axios from '../../ApiConector/axios'
import { LoginBox } from "./LoginBox"
import '../../Assets/Styles/Authentication/Login.css'
import { Stack } from '@fluentui/react/lib/Stack';
import { Player } from '@lottiefiles/react-lottie-player';
import omniLogoJson from "../../Assets/JsonSvg/Common/omni.json";
import { CustomerWidget } from "./CustomerWidget";
import { OmniSpeedTest } from "../Shared/SpeedTest/OmniSpeedTest";

const version_url = '/configurationversion/getversion';
export function Login() {
    const [version, setVersion] = useState('');
    const [versionMessageTitle, setVersionMessageTitle] = useState('');
    const [versionMessage, setVersionMessage] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [executeValidation, setExecuteValidation] = useState(false);    

    useEffect(() => {
        const getVersion = async () => {
            try {
                const response = await axios(version_url);
                if (response.data?.isSuccessful) {
                    setVersion(response?.data?.data?.databaseVersion);
                    localStorage.setItem('version', response?.data?.data?.databaseVersion);
                    setIsActive(false);
                    setExecuteValidation(true);
                } else {
                    setVersion(response?.data?.data?.databaseVersion);
                    localStorage.setItem('version', response?.data?.data?.databaseVersion);
                    setIsActive(true);
                    setVersionMessageTitle(response.data?.errorMessage || 'Error');
                    setVersionMessage('Por favor contacte a soporte para realizar la actualización.');
                    setExecuteValidation(true);
                }
                setExecuteValidation(true);
            } catch (err) {
                setIsActive(true);
                setVersionMessageTitle(err.response?.data?.errorMessage || 'Error');
                setVersionMessage('Por favor contacte a soporte.');
                setExecuteValidation(true);
                //console.log(err?.response?.data?.detail);
            }
        }
        getVersion();
    }, []);
    return (
        <>
            {
                executeValidation &&
                <Stack enableScopedSelectors vertical className="mainStackContainer" >
                    <Stack.Item className="login-header">
                        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" verticalFill gap={13} padding={9}>
                            <Stack.Item >
                                <Stack horizontal verticalAlign="center">
                                    <Stack.Item>
                                        <Player autoplay loop src={omniLogoJson} className="login-header-logo" ></Player>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <span className="login-header-label">Cuvitek Omni {version}</span>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>

                            <Stack.Item align="end" className="speed-test-container">
                                <OmniSpeedTest></OmniSpeedTest>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack enableScopedSelectors horizontal>
                            <Stack.Item grow align="start" className="customer-stack">
                                {/*<OmniInternetSpeed></OmniInternetSpeed>*/}
                                <CustomerWidget></CustomerWidget>
                            </Stack.Item>
                            <Stack.Item align="end" className="box-stack">
                                <LoginBox
                                    versionMessageTitle={versionMessageTitle}
                                    versionMessage={versionMessage}
                                    isActive={isActive}
                                    executeValidation={executeValidation}
                                    version={version}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            }
        </>
    );
}
