import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Pivot, PivotItem } from '@fluentui/react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Brand/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Brand/BrandStore';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();   
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [openCallout, setOpenCallout] = useState();
    const [brand, setBrand] = useState({
        useDiscount: false,
        discount:"1"
    });
    const [showErrors, setShowErrors] = useState({
        discount: undefined
    });
    SetAxiosPrivate(axiosPrivate);

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = getValues();
            if (brand.useDiscount) {
                if (brand.discount) {
                    let discount = parseFloat(brand.discount.replace("%", ""));
                    if (discount <= 30.00 && discount >= 1.00) {

                        data.discount = discount;
                        let result = await AddAsync(data, useForeignKey);
                        if (result.isSuccessful) {
                            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                            data.id = result.data;
                            addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                            changeComponent(<Detail />, result.data);
                        }
                        else {
                            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                        }

                    }
                    else {
                        setShowErrors(prevErrors => ({
                            ...prevErrors,
                            discount: 'El descuento debe ser entre 1 y 30'
                        }));
                        setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("El descuento debe ser entre 1 y 30")]);
                    }
                }
                else {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        discount: 'El descuento es obligatorio'
                    }));
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("El descuento es obligatorio")]);
                }
            }
            else {
                let result = await AddAsync(data, useForeignKey);
                if (result.isSuccessful) {
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                    data.id = result.data;
                    addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                    changeComponent(<Detail />, result.data);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    };
    const onBlurRate = (event) => {
        let input = event.target.value;
        if (input) {
            if (parseFloat(input.replace("%", "")) <= 30.00 && parseFloat(input.replace("%", "")) >=1.00) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    discount: undefined
                }));
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    discount: 'El descuento debe ser entre 1 y 30'
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                discount: 'El descuento es obligatorio'
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
        <form onSubmit={handleSubmit(submit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                <PivotItem headerText="Información" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <>
                                        {
                                            useForeignKey &&
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                            <OmniTextBox requiredOmni setValue={setValue} inputType='number' length={10} title='Clave SAP' register={{ ...register('id') }} focus />
                                                </div>
                                            </div>
                                        }
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox requiredOmni setValue={setValue} title='Descripción' maxLength={128} register={{ ...register('name') }} focus={useForeignKey ? false : true} />
                                            </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniCheckBox title="Usa descuento por caducidad (1% - 30%)"
                                                        register={{ ...register("useDiscount") }}
                                                        propertyId="useDiscount"
                                                        edit={setBrand}
                                                        propertyChecked={brand.useDiscount} />
                                                </div>
                                            </div>
                                            {brand?.useDiscount &&
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox title="Descuento" property="discount" max={30}
                                                            edit={setBrand} value={brand?.discount} blur={onBlurRate}
                                                            validationMessage={showErrors.discount} suffix="%" inputType={'decimal'}
                                                            decimals={2} />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Artículos" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>
            </form>
        
         {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={AddConfirm}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rigthCommandBar'}
        />
            }
        </>
    )
}