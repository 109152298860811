import BarButtons from '../../Shared/BarButtons/BarButtons';
import { useState, useEffect } from 'react';
import { leftButtons } from "../../../Assets/Constants/BarButtons/Configurations/SatCatalogs/Detail";
import { Edit } from './Edit';
import { CatalogListDetail } from './CatalogListDetail';
import { Catalogs } from '../../../Assets/Constants/Configuration/SatCatalogs/Catalogs';
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
export const Detail = (props) => {
    const [buttons, setButtons] = useState({
        leftButtons: [],
        rightButtons: []
    });
    const [view, setView] = useState({
        viewDetail: true,
        viewEdit: false
    });
    function EditContact() {
        props.setIsItemDisabled(true);
        setView({
            viewDetail: false,
            viewEdit: true
        });
    }
    useEffect(() => {
        if (view.viewDetail) {
            props.setIsItemDisabled(false);
            setButtons({
                leftButtons: leftButtons(EditContact),
                rightButtons: []
            });
        }
    }, [view]);
    return (
        <>
            {props.catalog &&
                <>
                    <BarButtons rightButtons={buttons.rightButtons}
                        leftButtons={buttons.leftButtons} />
                    {props.messages.length > 0 &&
                        <BarMessageList messages={props.messages} />
                    }
                    <div className='container-fluid'>
                        {view.viewDetail === true ?
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    {props.catalog &&
                                        <CatalogListDetail type={Catalogs.find(x => x.type === props.type)} catalog={props.catalog.filter((item) => item.enabledInAlix)} />
                                    }
                                </div>
                            </div>
                            :
                            <Edit
                                setView={setView}
                                setButtons={setButtons}
                                type={Catalogs.find(x => x.type === props.type)}
                                catalog={props.catalog} setCatalog={props.setCatalog}
                                setMessages={props.setMessages} />

                        }
                    </div>
                </>
            }
        </>
    )
}