import StatusFilter from '../../Assets/Constants/Enums/StatusFilter';
const url = {
    base: '/branchesfrom365',
    getbystatus: '/getbystatus',
}

var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetBranchesByStatusAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        let statusFilterParam: StatusFilterParam =
        {
            statusFilter: StatusFilter.Active,
            description:"",
            PageNumber:0,
            PageSize:0
        };
        const response = await axiosPrivate.post(url.base + url.getbystatus, statusFilterParam);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}