import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/ConvertItems/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate, generateObject } from '../../../Functions/Catalogs/ConvertItems/ConvertItemStore';
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { Add as AddItem } from '../../Shared/ItemList/Add';
import { GetItems, SetAxiosPrivate as SetAxiosPrivateItem, GetWhitoutConvert } from '../../../Functions/Catalogs/Items/ItemStore';
import { Pivot, PivotItem } from '@fluentui/react';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [itemSave, setItemSave] = useState();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateItem(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [disabledComboBox, setDisabledComboBox] = useState(false);
    const [itemList, setItemList] = useState();
    const [itemLoad, setItemLoad] = useState();
    const [itemListConverted, setItemListConverted] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [itemLoadWhitoutConvert, setItemLoadWhitoutConvert] = useState();
    const [serch, setSerch] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };


    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setItemSave({
                id: result.data.id,
                convertItemId: result.data.convertItemId,
                convertItem: result.data.convertItem,
                convertedItem: generateObject(result.data?.convertedItemResult),
                active: result.data.active
            });
            setItemSelected({
                convertItemId: result.data.convertItemId,
                convertItem: result.data.convertItem,
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            let errorMessagesItem = [];
            if (itemSave.convertedItem.length <= 0) {
                errorMessagesItem.push("Debe agregar artículos a la lista.");
            }
            if (errorMessagesItem.length > 0) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessagesItem.map(x => x).join('. '))]);
            }
            else {
                var data = itemSave;
                data.itemId = data.convertItemId;
                let result = await UpdateAsync(id, data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, result.data);
                    data.id = id;
                    data.name = itemSave.convertItem;
                    updateItem({ ...data, updateUserName: localStorage.getItem("userName") });
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    const OnLoadGetWhitoutConvert = async () => {
        let result = await GetWhitoutConvert();
        if (result.isSuccessful) {
            setItemLoadWhitoutConvert(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadGetItems = async () => {
        let result = await GetItems();
        if (result.isSuccessful) {
            setItemLoad(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoadGetWhitoutConvert();
        OnLoadGetItems();
        getById();
    }, [])
    useEffect(() => {
        if (itemSave?.convertItemId) {
            const resultList = [{
                key: itemSave?.convertItemId,
                value: itemSave?.convertItemId,
                text: itemSave?.convertItem
            }];
            setItemList(resultList);
        }
    }, [itemSave])
    function LoadList(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = itemLoadWhitoutConvert.data.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.description.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.description
                })).slice(0, 100);
                if (itemSelected?.convertItemId)
                resultList.push({
                    key: itemSelected.convertItemId,
                    value: itemSelected.convertItemId,
                    text: itemSelected.convertItem
                });
                setItemList(resultList);
            }
        }
    }
    useEffect(() => {
        if (itemLoadWhitoutConvert) {
            LoadList(serch)
        }
    }, [itemLoadWhitoutConvert]);
    useEffect(() => {
        if (itemLoad && itemSave?.convertItemId) {
            let dataload = itemLoad.data.filter(x => x.id != itemSave.convertItemId && !itemSave.convertedItem.some(y => y.id == x.id));
            setItemListConverted(dataload);
        }
    }, [itemLoad]);
    const OnChangeText = async (text) => {
        setShowSpinner(true);
        if (itemLoadWhitoutConvert) {
            LoadList(text)
        }
        else {
            setSerch(text);
            setShowSpinner(true);
        }
    }
    const onChangeItem = async (id, convertItem) => {
        setItemSave(prevEedit => ({
            ...prevEedit,
            convertItemId: id,
            convertItem: convertItem
        }));
        let dataload = itemLoad.data.filter(x => x.id != id && !itemSave.convertedItem.some(y => y.id == x.id));
        setItemListConverted(dataload);

    }
    const [showErrors, setShowErrors] = useState({
        convertItem: undefined
    });
    const onShowError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            convertItem: `El campo es obligatorio`
        }));
    };
    const onCleanError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            convertItem: undefined
        }));
    };
    const onBlur = (event) => {
        let text = event.target.value;
        if (text && itemSave.convertItemId === undefined) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                convertItem: `El artículo no fue encontrado`
            }));
            event?.target?.focus();
        }
        else if (itemSave.convertItemId) {
            onCleanError();
        }
        else {
            onShowError();
            event?.target?.focus();
        }
    };
    useEffect(() => {
        if (itemSave?.convertedItem) {
            if (itemSave?.convertedItem.length > 0) {
                setDisabledComboBox(true);
            }
            else {
                setDisabledComboBox(false);
            }
        }
    }, [itemSave?.convertedItem]);
    return (
        <>
            {itemSave &&
                <form onSubmit={handleSubmit(submit)}>
                    <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                    <Pivot className="pivot-center-client" >
                        <PivotItem headerText="Información" itemKey="information" >
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniCombobox
                                                        showSpinner={showSpinner}
                                                        title='Artículo a convertir'
                                                        items={itemList}
                                                        onChangeItemText={OnChangeText}
                                                        selected={itemSave?.convertItemId}
                                                        value={itemSave?.convertItem}
                                                        onChangeItem={onChangeItem} requiredOmni focus defaultValue
                                                        validationMessage={showErrors.convertItem}
                                                        onShowError={onShowError} onBlur={onBlur} onCleanError={onCleanError}
                                                        disabledItem={disabledComboBox} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Conversión'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <AddItem description={itemSave}
                                                        setDescription={setItemSave}
                                                        itemList={itemListConverted}
                                                        setItemList={setItemListConverted}
                                                        titleColum="Lista de artículos" property="convertedItem"
                                                        titleComboBox="Artículos en que se puede convertir" />
                                                </div>
                                            </div>
                                        </OmniCard>

                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Cambios" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                        </PivotItem>
                    </Pivot>
                </form>
            }
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

