import { DirectionalHint } from '@fluentui/react';
import {
    Callout,
    IconButton,
    mergeStyleSets, 
    FocusTrapCallout,
    FocusZone, 
    FocusZoneTabbableElements,
    FontWeights,
    Stack,
    Text,
    Button
} from '@fluentui/react';

export const OmniCallout = (props) => {
    const stylesLeftButton = {
        root: {
            backgroundColor: props.backgroundColorLeft + '!important',
            color: props.colorLeft + '!important',
            border: props.borderLeft ??'1px solid var(--secondary)'
        }
    };
    const stylesRightButton = {
        root: {
            backgroundColor: props.backgroundColorRight + '!important',
            color: props.colorRight + '!important',
            border: props.borderRight ?? '1px solid var(--secondary)'
        }
    };
    function executeLeftButtonFunction() {
        if (props.leftFunction !== undefined) {
            props.leftFunction();
        }
        props.updateIsOpenAlert(false);
    }
    function executeRightButtonFunction() {
        if (props.rightFunction !== undefined) {
            props.rightFunction();
        }
        props.updateIsOpenAlert(false);
    }

    function existeElementoId(elementId) {
        const elemento = document.getElementById(elementId);
        return elemento !== null;
    }

    return (
        <>
            {props.open ?
                (
                    props.notLock ?
                        <Callout                            
                            role="dialog"
                            className={styles.callout +' '+ props.className}
                            gapSpace={10}
                            target={`#${props.buttonId}`}
                            beakWidth={10}
                            onDismiss={() => {
                                props.updateIsOpenAlert(false);
                            }}
                            directionalHint={DirectionalHint.rightTopEdge}
                            setInitialFocus
                        >
                            <IconButton
                                iconProps={{ iconName: 'Cancel' }}
                                onClick={() => {
                                    props.updateIsOpenAlert(false);
                                }}
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                ariaLabel="Close"
                            />
                            <Text block variant="xLarge" className={styles.title}>{props.title}</Text>
                            <Text block variant="small">{props.content}</Text>
                            <Stack className={styles.buttons} gap={8} horizontal>
                                {props?.leftButton &&
                                    <Button
                                        styles={stylesLeftButton}
                                        onClick={executeLeftButtonFunction}
                                        className={props.classNameLeft ?? ''}
                                        iconProps={props.iconLeftButton}
                                        iconPosition={props.iconPositionLeftButton}                                       
                                    > {props.leftButton}</Button>
                                }
                                {props?.rightButton &&
                                    <Button
                                        styles={stylesRightButton}
                                        onClick={executeRightButtonFunction}
                                        className={props.classNameRight ?? ''}
                                        iconProps={props.iconRightButton}
                                        iconPosition={props.iconPositionRightButton}
                                    > {props.rightButton}</Button>
                                }
                            </Stack>
                        </Callout>
                    :
                    <FocusTrapCallout
                        role="alertdialog"
                        className={styles.callout}
                        gapSpace={0}
                        target={existeElementoId(props.barGroupKey) ? `#${props.barGroupKey}` : `#${props.buttonId}`}
                        onDismiss={() => {
                            props.updateIsOpenAlert(false);
                        }}
                        setInitialFocus
                        directionalHint={props.orientation ?? DirectionalHint.bottomCenter}
                    >
                        <Text block variant="xLarge" className={styles.title}> {props.title} </Text>
                        <Text block variant="small"> {props.content} </Text>
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack className={styles.buttons} gap={8} horizontal>
                                {props?.leftButton &&
                                    <Button styles={stylesLeftButton} onClick={executeLeftButtonFunction} className={props.classNameLeft ?? ''}>{props.leftButton}</Button>
                                }
                                {props?.rightButton &&
                                    <Button styles={stylesRightButton} onClick={executeRightButtonFunction} className={props.classNameRight ?? ''}>{props.rightButton}</Button>
                                }
                            </Stack>
                        </FocusZone>
                    </FocusTrapCallout>
                )
                : null}
        </>
    );
};

const styles = mergeStyleSets({
    callout: {
        width: 320,
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
});