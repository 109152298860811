import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/CreditorAccount/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, GetCreditorSubAccount, GenerateAcronym } from '../../../Functions/Catalogs/CreditorAccount/CreditorAccountStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import {Pivot, PivotItem} from "@fluentui/react";
import { TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableCellLayout, Image } from "@fluentui/react-components";
import "./../../../Assets/Styles/Shared/Table/DetailTable.css"
const activeIcon = require('./../../../Assets/Images/Icons/ActiveBlue.svg').default;
const inactiveIcon = require('./../../../Assets/Images/Icons/InactiveRed.svg').default;
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus } = useGeneralStatesContext();
    const [CreditorAccount, setCreditorAccount] = useState({});
    const [status, setStatus] = useState('');
    const [leftButtonsDetail, setLeftButtonsDetail] = useState(leftButtons);
    const [CreditorSubAccount, setCreditorSubAccount] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const columnsHeader = [
        { columnKey: "1", label: "Descripción", width: '70%', classAdd:'first-item' },
        { columnKey: "2", label: "IDE", width: '15%' },
        { columnKey: "3", label: "Estatus", width: '15%' }
    ];
    const getById = async () => {
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setCreditorAccount({
                description: result.data.description
            });
            setStatus(!result.data.active);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, status);
        if (result.isSuccessful) {
            setStatus(!status);
            updateItemStatus(id);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        const fillData = async () => {
            try {
                getById();
                const dataCreditorSubAccount = await ListGetCreditorAccount();
                setCreditorSubAccount(dataCreditorSubAccount);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        fillData();
        fieldWatcher.hasPendingChangesRef.current = false;
    }, [id])
    useEffect(() => {
        const updatedButtons = leftButtonsDetail.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton} el rubro?`,
                    handler: () => { updateStatus() }
                };
            }
            return button;
        });
        setLeftButtonsDetail(updatedButtons);
    }, [status]);
    const ListGetCreditorAccount = async () => {
        let result = await GetCreditorSubAccount(id);
        if (result.isSuccessful) {
            const resultList = result?.data?.map((item) => ({
                key: item.id,
                description: item.description,
                updateUser: item.updateUser,
                active: item.active ? activeIcon : inactiveIcon,
            }));
            return resultList;
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    return (
        <>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtonsDetail} />
            <Pivot>
                <PivotItem headerText="Información">
                    <OmniCard title="Datos generales">
                        <OmniLabel text='Descripción' value={CreditorAccount.description} />
                    </OmniCard>
                </PivotItem>
                <PivotItem headerText="Subrubros">
                    {CreditorSubAccount &&
                        (<div className="detail-table-scroll-complete">
                        <Table arial-label="Subrubros" className="detail-table">
                            <TableHeader>
                                <TableRow>
                                    {columnsHeader.map((column) => (
                                        <TableHeaderCell key={column.columnKey}
                                            style={{ width: column.width }}
                                            className={'detail-table-header-title ' + column?.classAdd}>
                                            {column.label}
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {
                                    CreditorSubAccount.map((item) => (
                                        <TableRow key={item.key}>
                                            <TableCell >
                                                <TableCellLayout className='detail-table-body-description'>
                                                    {item.description}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout
                                                    className={"item-acronym-table"}>
                                                    {GenerateAcronym(item.updateUser)}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell className="detail-table-body-image"> 
                                                <TableCellLayout 
                                                    media={
                                                        <Image src={item.active} alt="active" />
                                                    } />
                                            </TableCell>
                                            </TableRow>
                                    ))}
                                </TableBody>  
                        </Table>
                        </div>)}
                </PivotItem>
            </Pivot>
        </>
    )
}