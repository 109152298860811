import Add from "../../../../../Components/Catalogs/Routes/Add";
import Edit from "../../../../../Components/Catalogs/Routes/Edit";
export const leftButtons: Buttons[] = [{
    key: 'BtnEdit',
    text: 'Editar',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Edit />,
    authorizationKey: 20,
}, {
    key: 'BtnStatusRoute',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    authorizationKey: 25,
}];
export const rightButtons: Buttons[] = [{
    key: 'BtnNew',
    text: 'Nueva',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    authorizationKey: 15,
}]