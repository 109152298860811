import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form"
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/CompanyAsset/Edit';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
//import '../../../Assets/Styles/Shared/Controls/OmniTextBox.css'
//import '../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from "./Detail";

const updateUrl = '/companyasset/update';
const getByIdUrl = '/companyasset/getbyid';

export default function Edit() {
    const { register, handleSubmit, setValue } = useForm();
    const id = useIdSelectorContext();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext(); 
    const [companyAsset, setCompanyAsset] = useState([]);

    const onGet = async () => {
        try {
            const response = await axiosPrivate.get(getByIdUrl, {
                params: {
                    id: id
                }
            }
            );
            if (response.data.isSuccessful) {
                return response.data.data;
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.errorMessage)]);
            }
        } catch (err) {

        }
    };

    useEffect(() => {
        const fillData = async () => {
            try {
                const serviceData = await onGet();
                if (serviceData) {
                    setCompanyAsset(
                        {
                            description: serviceData.description
                        }
                    );
                }
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            }
        }
        fillData();
    }, []);

    useEffect(() => {
        if (Object.keys(companyAsset).length !== 0) {
            Object.keys(companyAsset).forEach(function (key) {
                setValue(key, companyAsset[key]);
            });
        }
    }, [companyAsset])

    const onSubmit = async (data) => {
        try {
            const response = await axiosPrivate.put(updateUrl,
                JSON.stringify({
                    id: id,
                    description: data.description,
                    loggedUserName: localStorage.getItem("userName")
                })
            );
            if (response.data.isSuccessful) {
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage("El activo ha sido actualizado exitosamente.")]);
                changeComponent(<Detail />, id);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <OmniCard title='Datos generales'>
                <OmniTextBox title="Descripci�n" 
                    register={{ ...register("description") }}
                    value={Object.keys(companyAsset).length !== 0 ? companyAsset.description : ''}
                    focus />
            </OmniCard>
        </form>
    );
}