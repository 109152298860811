import { Image } from '@fluentui/react/lib/Image';
export default function NavOptionText(props) {
    return (
        <>
            <Image src={props.iconOption} />
            <i className={`title-menu ${props.iconOption ? 'main-link-title ' : (props.isCollapsed?'' :'sub-link-title')} ${props.key==undefined? (props.key === props.keyOption && 'bold-text'):''}`}>
                {props.name}
            </i>
        </>
    );
}