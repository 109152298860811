import { Info16Regular } from "@fluentui/react-icons";
import { useState } from 'react';
import { Tooltip, makeStyles, mergeClasses } from "@fluentui/react-components";
const stylesTooltipAll = makeStyles({
    visible: {
        color: 'var(--primary)',
    },
});
export const OmniTooltip = (props) => {
    const [visible, setVisible] = useState(false);
    const stylesTooltip = stylesTooltipAll();

    return (
        <>
            {props.infoText &&
                <Tooltip
                    content={{
                        children: props.infoText
                    }}
                    positioning="above-start"
                    withArrow
                    onVisibleChange={(e, data) => setVisible(data.visible)}>
                    <Info16Regular
                        className={mergeClasses(visible && stylesTooltip.visible)}
                    />
                </Tooltip>}
        </>
    );
}