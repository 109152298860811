import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Linebusiness/Edit';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { Pivot, PivotItem } from '@fluentui/react';
import { UpdateAsync, SetAxiosPrivate, GetByIdAsync } from '../../../Functions/Catalogs/Linebusiness/LinebusinessStore';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const id = useIdSelectorContext();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const [linebusiness, setLinebusiness] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [showErrors, setShowErrors] = useState({
        description: undefined
    });
    const [selectedKey, setSelectedKey] = useState();

    const submit = async () => {
        setOpenCallout(true);
    };
    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setLinebusiness({ ...result.data });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const Save = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            let data = linebusiness;
            let result = await UpdateAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                linebusiness.id = id;
                updateItem({ ...linebusiness, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    useEffect(() => {
        getById();
    }, [])
    //const onBlurDescription = (event) => {
    //    ValidText(event, "description", "La descripción es obligatoria");
    //};
    //function ValidText(event, property, text) {
    //    const input = event.target.value;
    //    if (input) {
    //        setShowErrors(prevErrors => ({
    //            ...prevErrors,
    //            [property]: undefined
    //        }));
    //    }
    //    else {
    //        setShowErrors(prevErrors => ({
    //            ...prevErrors,
    //            [property]: text
    //        }));
    //        event?.target?.focus();
    //    }
    //}
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (linebusiness) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" selectedKey={selectedKey}
                    onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                    overflowBehavior="menu" styles={pivotStyles}                    >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-8 col-md-12'>
                                                <OmniTextBox
                                                    requiredOmni defaultValue
                                                    focus
                                                    title='Descripción'
                                                    maxLength={32}
                                                    property="description"
                                                    edit={setLinebusiness}
                                                    value={linebusiness?.description}
                                                    validationMessage={showErrors.description}
                                                />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Save}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    );
}