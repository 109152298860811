import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniDatePicker } from '../../Shared/Controls//DatePicker/OmniDatePicker';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Offers/OfferCampaigns/Edit';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { Pivot, PivotItem } from '@fluentui/react';
import { UpdateAsync, SetAxiosPrivate, GetByIdAsync, CalculateDaysBetweenDates, FormatDate, ToShortDate } from '../../../Functions/Offers/OfferCampaigns/OfferCampaignStore';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OffersTable } from '../../Shared/OffersTable/OffersTable';
import Detail from './Detail';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Edit() {
    const { handleSubmit } = useForm();
    const id = useIdSelectorContext();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const [campaign, setCampaign] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        startDate: undefined,
        endDate: undefined,
    });
    const submit = async () => {
        setOpenCallout(true);
    };
    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful)
        {
            console.log(result.data?.startDate);
            setCampaign(
                {
                    ...result.data,
                    daysBetweenDates: CalculateDaysBetweenDates(result.data?.startDate, result.data?.endDate),
                });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (campaign) {
            if (!campaign.name) {
                errorMessages.push("El nombre es obligatorio");
            }
            if (!campaign.startDate) {
                errorMessages.push("La fecha de inicio es obligatoria");
            }
            if (!campaign.endDate) {
                errorMessages.push("La fecha de fin es obligatoria");
            }
            if (campaign.startDate > campaign.endDate) {
                errorMessages.push("La fecha inicial no puede ser mayor a la fecha final");
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            let data = campaign;
            let result = await UpdateAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                campaign.id = id;
                updateItem({ ...campaign, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    useEffect(() => {
        getById();
    }, [])
    const onBlurName = (event) => {
        ValidText(event, "name", "El nombre obligatorio");
    };
    function ValidText(event, property, fild) {
        let text = event.target.value.trim();
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: fild
            }));
            event?.target?.focus();
        }
    }
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (campaign) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);


    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" selectedKey={selectedKey}
                    onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                    overflowBehavior="menu" styles={pivotStyles}                    >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox
                                                    focus
                                                    required
                                                    title='Nombre'
                                                    maxLength={64}
                                                    property="name"
                                                    edit={setCampaign}
                                                    value={campaign?.name}
                                                    blur={onBlurName}
                                                    validationMessage={showErrors.name}
                                                />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Vigencia'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniDatePicker
                                                    title='Fecha de inicio'
                                                    property="startDate"
                                                    edit={setCampaign}
                                                    value={campaign?.startDate}
                                                    //maxDate={new Date(campaign?.endDate)}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniDatePicker
                                                    title='Fecha de fin'
                                                    property="endDate"
                                                    edit={setCampaign}
                                                    value={campaign?.endDate}
                                                    //minDate={new Date(campaign?.startDate)}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniLabel className='colorLabelContentBlue' text='' value={CalculateDaysBetweenDates(ToShortDate(campaign?.startDate), ToShortDate(campaign?.endDate))} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Ofertas" itemKey="offer">
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Ofertas'>
                                        <div id='divItemsContainer' className='row card-items-container'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OffersTable titleheader='la campaña' items={campaign?.offers} />
                                                </div>
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Save}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>)
};