import StatusFilter from "../../../Assets/Constants/Enums/StatusFilter";

const url = {
    base: '/category',
    add: '/add',
    get: '/getbyid',
    updateStatus: '/updatestatus',
    update: '/update',
    GetItemsByCategoryId:'/GetItemsByCategoryId',
    GetByStatus:'/GetByStatus'
}
var axiosPrivate;

export const SetAxiosPrivate=(axiosPrivateParam)=>{
    axiosPrivate=axiosPrivateParam;
}

export const GetActives=async()=>{
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const statusFilterParam =
        {
            
                statusFilter: Number(StatusFilter.Active),
               description : ""
            
            
        };
        const response = await axiosPrivate.post(url.base + url.GetByStatus, 
            statusFilterParam, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,

            });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(url.base + url.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let categoryParam = {
            ...data,
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
        }

        const response = await axiosPrivate.put(url.base + url.update,
            JSON.stringify({
                id: id,
                ...categoryParam
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La categoría se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(url.base + url.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activada" : "desactivada";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La categoria ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}


export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let warehouseResult = {
            ...data,           
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
        }
        const response = await axiosPrivate.post(url.base + url.add,
            JSON.stringify({
                ...warehouseResult
            })
        );
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `La categoría se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const GetItemsByCategoryId = async (id, page) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(url.base + url.GetItemsByCategoryId,  
            JSON.stringify({
                "id": id,
                "PaginationParams":{
                    "PageNumber":page,
                    "PageSize":100
                }
            })
            //{ params: { Id: id,PaginationParams:null } }
        );
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

const getErrorText=(err)=>{
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}