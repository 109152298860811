import * as React from 'react'
import { Stack, IStackStyles } from '@fluentui/react/lib/Stack';
import Logo from './Header/Logo'
import Profile from './Header/Profile';
import '../../Assets/Styles/Layout/Header.css'
import { OmniSpeedTest } from '../Shared/SpeedTest/OmniSpeedTest';

export default function Header (){
    return (
        <Stack horizontal verticalAlign='center' className='header-container'>
            <Stack.Item grow>
                <Logo />
            </Stack.Item>
            <Stack.Item align='center' className="speed-test-widget">
                {/*<OmniSpeedTest></OmniSpeedTest>*/}
            </Stack.Item>
            <Stack.Item align='center'>
                <Profile />
            </Stack.Item>
        </Stack>
    );
}