import { Link } from 'react-router-dom';
import NavOptionText from './NavOptionText';
import '../../Assets/Styles/Menu/NavOption.css';
import { Button, Menu, MenuItem, MenuItemCheckbox, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { CutRegular, CutFilled, bundleIcon } from "@fluentui/react-icons";
import { useState } from 'react';

var options;
const CutIcon = bundleIcon(CutFilled, CutRegular);
const ItemList=()=>{
    return (
        <MenuList id="mnuList">
            {options?.map((option)=>(
                    <MenuItem key={option.key} className="menu-item">
                        <Link to={option.urlOption} className={`link-menu`}>
                            <NavOptionText {...option} isCollapsed={true} />
                        </Link>
                    </MenuItem>
                    ))
            }
        </MenuList>
    )
}

export default function NavOptionCollapsed(props) {
   options=props.subLinks;
    return (
        <>
            {
                props.subLinks===undefined
                ?
                   (props.urlOption
                    ? 
                    <Link to={props.urlOption} className={`link-menu  ${props.links ? 'sub-link' : ''}`}>
                        <NavOptionText {...props} />
                    </Link>
                    :
                    <div className={'link-menu'}>
                        <NavOptionText {...props} />
                    </div>     
                    )     
                :
                (
                    <Menu hasIcons hasCheckmarks >
                        <MenuTrigger disableButtonEnhancement >                        
                            <div className={'link-menu'}>
                                <NavOptionText {...props} />
                            </div>        
                        </MenuTrigger>
                        <MenuPopover id='mnuPopOver' className='menu-popover'>
                            <ItemList />
                        </MenuPopover>
                    </Menu>
                )
            }
        </>
    );
}