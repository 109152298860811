import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import Detail from "./Detail";
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/CreditorSubAccount/CreditorSubAccount"
import { ItemSelectorProvider } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';

const getByStatusUrl = '/creditorSubAccount/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/CreditorSubAccount.svg').default
}
export default function CreditorSubAccount() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de subrubros"}
                    image={iconCatalog}
                    urlGetItems={getByStatusUrl}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"description"}
                    omniAcronym={"updateUser"}
                    getInLoad={false}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}