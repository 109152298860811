import Add from '../../../../../Components/Catalogs/IepsTax/Add';
import Edit from '../../../../../Components/Catalogs/IepsTax/Edit';
import IepsAuthorizations from "../../../Authorizations/Ieps";
export const leftButtons = [
    {
        key: 'BtnEdit',
        text: 'Editar',
        iconName: 'editcuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        component: <Edit />,
        authorizationKey: IepsAuthorizations.Save,

    },
    {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    authorizationKey: IepsAuthorizations.Delete,
    }
];
export const rightButtons = [
    {
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    authorizationKey: IepsAuthorizations.New,
    }
];