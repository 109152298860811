import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { Text } from '@fluentui/react-components';
import '../../../Assets/Styles/Accounting/AccountingAccount/AccountingAccount.css';
import { AccountingSystemEnum } from '../../../Assets/Constants/Enums/AccountingSystem';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { useEffect, useState } from 'react';
export const ExtendedAccountingAccount = (props) => {
    const { accountingSystem } = useAccountingConfigurationContext();
    const [regexPattern, setRegexPattern] = useState();
    const [maxLengthAccountingAccount, setMaxLengthAccountingAccount] = useState();
    const [newPlaceholder, setNewPlaceholder] = useState();
    const [modifiedValue, setModifiedValue] = useState();
    useEffect(() => {        
        const regexMicrosip = `^(?:[0-9]+(?:\.[0-9]*)?)*$`;
        const regexContpaqi = `^[a-zA-Z0-9]*$`;
        setRegexPattern(new RegExp(accountingSystem == AccountingSystemEnum.CONTPAQi ? regexContpaqi : regexMicrosip));
        setMaxLengthAccountingAccount(30 - props.baseValue.length);
        let value = props.value?.startsWith(props.baseValue) ? props.value.substring(props.baseValue.length) : props.value;
        setModifiedValue(value);

        if (AccountingSystemEnum.CONTPAQi && props.placeholder) {
            let newValue = props.placeholder?.startsWith(props.baseValue) ? props.placeholder.substring(props.baseValue.length) : props.placeholder;
            let complement = newValue.padStart(props.countAutoincrement, '0');
            setNewPlaceholder(complement);
        }
        else { setNewPlaceholder(props.placeholder); }
    }, [])

    return (
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniCard title='Contabilidad'>
                        <div className='row'>
                            <OmniTextBox
                                {...props}
                                placeholder={newPlaceholder}
                                focus
                                required
                                title='Cuenta contable'
                                maxLength={ maxLengthAccountingAccount }
                                property={"accountingAccount"}
                                regex={regexPattern}
                                contentBefore={
                                    <Text size={300} id={'accountingAccount'} className={'base-color'} >
                                        {props.baseValue}
                                    </Text>
                                }
                                value={modifiedValue}
                            />
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div>
    );
}