export const DayPickerStrings = {
    months: [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],

    shortMonths: [
        'ene', 'feb', 'mar', 'abr', 'may', 'jun',
        'jul', 'ago', 'sep', 'oct', 'nov', 'dic'
    ],

    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],

    shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
    goToToday: 'Hoy',
    prevMonthAriaLabel: 'Mes anterior',
    nextMonthAriaLabel: 'Siguiente mes',
    prevYearAriaLabel: 'Año anterior',
    nextYearAriaLabel: 'Siguiente año'
};