import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Routes/Routes';
import { Pivot, PivotItem } from "@fluentui/react";
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = (props) => {
    return (
        <>
            <BarButtons rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                selectedKey={ null}>
                <PivotItem headerText="Información" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >

                </PivotItem>
                <PivotItem headerText="Lunes" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Martes" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Miércoles" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Jueves" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Viernes" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Sábado" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Domingo" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>
        </>
    )
}
