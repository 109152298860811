const url = {
    base: '/creditor',
    add: '/add',
    get: '/getbyid',
    updateStatus: '/setstatus',
    update: '/update',
}

var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(url.base + url.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let identificator = data.id ? data.id : 0;
        var dataString=JSON.stringify({
            id: identificator,
            name: data.name,
            rfc: data.rfc,
            accountingAccount:data.accountingAccount,
            creditorAccountId: data.creditorAccountId,
            creditorSubAccountId: data.creditorSubAccountId,
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
        });

        const response = await axiosPrivate.post(url.base + url.add,
            dataString 
        );
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `La ubicación se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(url.base + url.update,
            JSON.stringify({
                id: id,
                name: data.name,
                rfc: data.rfc,
                creditorAccountId: data.creditorAccountId,
                creditorSubAccountId: data.creditorSubAccountId,
                accountingAccount:data.accountingAccount,
                UpdateUserId: localStorage.getItem("userId"), 
                updateUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La ubicación se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(url.base + url.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La ubicación ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}


const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    }
    else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}
