import { OmniCallout } from '../../../Shared/Callout/OmniCallout';
import BarButtons from '../../../Shared/BarButtons/BarButtons';
import { ShippingAddressesList } from '../ShippingAddresses/ShippingAddressesList';
import { LocationAdd } from './LocationAdd';
import { LocationDetail } from './LocationDetail';
import { useState, useEffect } from 'react';
import { leftButtons as leftButtonsClientRouteDetail } from "../../../../Assets/Constants/BarButtons/Catalog/Customers/Location/Detail";
import {
    leftButtonsEdit as editingButton,
    leftButtonsDetail
} from '../../../../Assets/Constants/BarButtons/Catalog/Customers/Editing';
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { DeletePhotoShippingAddressesLocationAsync, DeleteLocationShippingAddressesLocationAsync } from '../../../../Functions/Catalogs/Customers/CustomerStore';
export const CatalogLocation = (props) => {
    const [openCalloutClients, setOpenCalloutClients] = useState();
    const [callout, setCallout] = useState({text:'',id:''});
    const { setListMessageCatalog } = useGeneralStatesContext();
    const [imagenSrc, setImagenSrc] = useState()
    function EditContact() {
        props.setIsItemDisabled(true);
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: true,
                viewDetail: false,
                viewEdit: true
            }
        }));
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: []
        }));
    }
    function DeleteLocation() {
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnDeleteLocation"
                    ? { ...button, iconColor: 'var(--danger)' }
                    : button
            )
        }));
        setCallout({ text: '¿Desea eliminar esta ubicación?', id: 'BtnDeleteLocation' });
        setOpenCalloutClients(true);
    }
    function DeletePhoto() {
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnDeletePhoto"
                    ? { ...button, iconColor: 'var(--danger)' }
                    : button
            )
        }));
        setCallout({ text: '¿Desea eliminar esta foto?', id: 'BtnDeletePhoto' });
        setOpenCalloutClients(true);
    }
    const [buttonsClientRoutesChild, setButtonsClientRoutesChild]
        = useState({
            leftButtonsChild: leftButtonsClientRouteDetail(EditContact, DeleteLocation, DeletePhoto),
            rightButtonsChild: []
        });
    useEffect(() => {
        if (props?.client && props?.property) {
            if (props?.client[props?.property].length > 0) {
                let showItem = props.itemSelected === undefined ? true : false;
                let itemShow = props.itemSelected !== undefined
                    ? props.itemSelected[props.property] === undefined ? true : false : true;
                if (showItem || itemShow) {
                    props.setItemSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: props?.client[props?.property][0].id
                    }));
                    ChangeActive(props?.client[props?.property][0]);
                    if (props.setView) {
                        props.setView((prevView) => ({
                            ...prevView,
                            [props.property]: {
                                viewAdd: false,
                                viewDetail: true,
                                viewEdit: false
                            }
                        }));
                    }
                }
            }
            else {
                setButtonsClientRoutesChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: {},
                    rightButtonsChild: {}
                }));
                props.setIsItemDisabled(false);

                //props.setbuttonsCatalog(prevEmail => ({
                //    ...prevEmail,
                //    leftButtons: leftButtonsDetail,
                //    rightButtons: {}
                //}));
            }
        }
    }, [props.client]);
    useEffect(() => {
        if (props.view[props.property].viewDetail) {
            if (props.itemSelected && props.itemSelected[props.property] !== undefined) {
                let itemCurrent = props.client[props.property].find(x => x.id == [props.itemSelected[props.property]])
                if (itemCurrent) {
                    ChangeActive(itemCurrent);
                }
            }
            else {
                setButtonsClientRoutesChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: [],
                    rightButtonsChild: []
                }));
            }
            props.setIsItemDisabled(false);

            props.setbuttonsCatalog(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        }
    }, [props.view[props.property]]);
    const AllItemsOk = async () => {
        if (props?.itemSelected && props.itemSelected[props?.property]) {
            const updatedClient = [...props.client[props?.property]];
            let itemCurrent = updatedClient.find(x => x.id == [props.itemSelected[props.property]]);
            let result = null;
            if (callout.id === "BtnDeletePhoto") {
                result = await DeletePhotoShippingAddressesLocationAsync(itemCurrent.id, itemCurrent.imageId, itemCurrent.imageExtension);
                if (result && result.isSuccessful) {
                    updatedClient.find(x => x.id == [props.itemSelected[props.property]]).imageId = undefined;
                    setImagenSrc(undefined);
                }
            }
            else if (callout.id === "BtnDeleteLocation") {
                result = await DeleteLocationShippingAddressesLocationAsync(itemCurrent.id);
                if (result && result.isSuccessful) {
                    updatedClient.find(x => x.id == [props.itemSelected[props.property]]).latitude = undefined;
                    updatedClient.find(x => x.id == [props.itemSelected[props.property]]).longitude = undefined;
                }
            }
            if (result && result.isSuccessful) {
               props.setClient((prevRoute) => {
                    return {
                        ...prevRoute,
                        [props.property]: updatedClient,
                    };
                });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                ChangeActive(itemCurrent);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
            setButtonsClientRoutesChild(prevState => ({
                ...prevState,
                leftButtonsChild: prevState.leftButtonsChild.map(button => {
                    if (button.key === "BtnDeleteLocation") {
                        return { ...button, iconColor: 'var(--secondary)' };
                    } else if (button.key === "BtnDeletePhoto") {
                        return { ...button, iconColor: 'var(--secondary)' };
                    } else {
                        return button;
                    }
                })
            }));
        }
        setOpenCalloutClients(false);
    }
    const AllItemsNo = async () => {
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button => { 
                if (button.key === "BtnDeleteLocation") {
                    return { ...button, iconColor: 'var(--secondary)' };
                } else if (button.key === "BtnDeletePhoto") {
                    return { ...button, iconColor: 'var(--secondary)' };
                } else {
                    return button;
                }
            })
        }));
        setOpenCalloutClients(false);
    }
    useEffect(() => {
        if (props?.itemSelected && props?.property) {
            let itemCurrent = props.client[props.property].find(x => x.id == [props.itemSelected[props.property]])
            if (itemCurrent) {
                ChangeActive(itemCurrent);
            }
        }
    }, [props?.itemSelected && props?.itemSelected[props?.property]]);
    function ChangeActive(itemCurrent) {
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: leftButtonsClientRouteDetail(EditContact, DeleteLocation, DeletePhoto).map(button => {
                if (button.key === "BtnDeleteLocation") {
                    return { ...button, disabled: (!itemCurrent.active || itemCurrent.active) && !(itemCurrent.latitude != 0 && itemCurrent.longitude != 0) || ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false)) };
                } else if (button.key === "BtnEdit") {
                    return { ...button, disabled: (!itemCurrent.active) || ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false)) };
                }
                else if (button.key === "BtnDeletePhoto") {
                    return { ...button, disabled: (!itemCurrent.active || itemCurrent.active) && !itemCurrent.imageId || ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false)) };
                } 
                else {
                    return button;
                }
            }),
            rightButtonsChild: []
        }));
    }

    return (
        <>
            <BarButtons rightButtons={buttonsClientRoutesChild.rightButtonsChild}
                leftButtons={buttonsClientRoutesChild.leftButtonsChild} />

            <div className='container-fluid container-scroll-three-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <ShippingAddressesList description={props.client}
                            setSelected={props.setItemSelected}
                            itemSelected={props.itemSelected}
                            setView={props.setView}
                            property={props.property} />
                    </div>
                    <div className='col-md-auto'>
                        {props.view[props.property].viewAdd &&
                            <LocationAdd description={props.client}
                                setDescription={props.setClient}
                                setSelected={props.setItemSelected}
                                itemSelected={props.itemSelected}
                                view={props.view}
                                setView={props.setView}
                                setButtons={setButtonsClientRoutesChild}
                                property={props.property}
                                clientId={props.clientId} />
                        }
                        {((props.itemSelected !== undefined && props.itemSelected[props.property] !== undefined) && props.view[props.property].viewDetail) &&
                            <LocationDetail description={props.client}
                                itemSelected={props.itemSelected} property={props.property}
                            imagenSrc={imagenSrc} setImagenSrc={setImagenSrc}                       />
                        }
                    </div>
                </div>
            </div>
            {
                <OmniCallout
                    open={openCalloutClients}
                    updateIsOpenAlert={setOpenCalloutClients}
                    leftFunction={AllItemsOk}
                    rightFunction={AllItemsNo}
                    orientation={5}
                    title='Confirmación'
                    content={callout.text}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'white'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'white'}
                    colorRight={'var(--secondary)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    classNameLeft={'callOutButtonLeft'}
                    classNameRight={'callOutButtonRight'}
                    buttonId={callout.id}
                    barGroupKey={'leftCommandBar'}
                />
            }
        </>

    )
}