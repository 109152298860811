import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Vehicles/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Vehicles/VehiclesStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';

export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [vehicle, setVehicle] = useState();
    const [buttonsVehicle, setButtonsVehicle] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setVehicle({
                id: result.data.id,
                alias: result.data.alias,
                brand: result.data.brand,
                model : result.data.model,
                year : result.data.year,
                color : result.data.color,
                sCTPermission: result.data.sctPermission,
                sCTNumberPermission: result.data.sctNumberPermission ,
                licensePlate : result.data.licensePlate,
                insuranceCarrier : result.data.insuranceCarrier,
                policyNumber : result.data.policyNumber,
                requiresPrivateTransportPermit: result.data.requiresPrivateTransportPermit,
                tons : result.data.tons,
                cubicMeters : result.data.cubicMeters,
                vehicleGrossWeight: result.data.vehicleGrossWeight,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !vehicle.active);
        if (result.isSuccessful) {
            setVehicle((prevVariety) => {
                return {
                    ...prevVariety,
                    active: !vehicle.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = vehicle.active ? "Inactivar" : "Activar";
                let statusiconbutton = vehicle.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = vehicle.active ? 534 : 535;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el vehículo?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !vehicle.active
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (vehicle) {
            setButtonsVehicle(prevVariety => ({
                ...prevVariety,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...vehicle, updateUserName: localStorage.getItem("userName") });
        }
    }, [vehicle?.active]);

    useEffect(() => {
        if (vehicle) {
            setButtonsVehicle(prevVariety => ({
                ...prevVariety,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);

    return (
        <>
            {vehicle &&
                (<BarButtons rightButtons={buttonsVehicle.rightButtons}
                leftButtons={buttonsVehicle.leftButtons} />)
            }
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    {
                                        useForeignKey &&
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                    <OmniLabel text='Clave SAP' value={id} />
                                            </div>
                                        </div>
                                    }
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Alias' value={vehicle?.alias} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Marca' value={vehicle?.brand} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Modelo' value={vehicle?.model} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Año' value={vehicle?.year} />
                                        </div>
                                    </div>
                                     <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Color' value={vehicle?.color} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Placa' value={vehicle?.licensePlate} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Toneladas' value={vehicle?.tons} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Metros cúbicos' value={vehicle?.cubicMeters} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Peso bruto vehícular' value={vehicle?.vehicleGrossWeight} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Permisos'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Requiere permiso para transporte privado' value={vehicle?.requiresPrivateTransportPermit ? "Si" : "No"} />

                                        </div>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Permiso Secretaría de Comunicaciones y Transportes' value={vehicle?.sCTPermission} />
                                        </div>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Número de permiso' value={vehicle?.sCTNumberPermission} />
                                        </div>
                                    </div>
                                   
                                </OmniCard>
                                <OmniCard title='Aseguradora'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Empresa aseguradora' value={vehicle?.insuranceCarrier} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Número de póliza' value={vehicle?.policyNumber} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={vehicle} />
                </PivotItem>
            </Pivot>
        </>
    )
}