import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import { ItemPayConditions } from '../../../../Assets/Constants/Enums/PayConditions';

export default function Credit(props){
    return(
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className="row">
                    <div className="col-lg-6 col-md-6 ">
                        <OmniCard title='Datos crediticios'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Condiciones de pago' value={ItemPayConditions.find(item => item.value === props.supplier.payConditions)!==undefined?ItemPayConditions.find(item => item.value === props.supplier.payConditions).text:""} />  
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Días de crédito' value={props.supplier.creditDays} />  
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Límite de crédito' value={'$ ' + props.supplier.creditLimit} />  
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Días para pronto pago' value={props.supplier.soonPayDays} />  
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Pago preferente' value={props.supplier.payTypeDescription} />  
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </>
    );
}