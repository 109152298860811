import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Warehouse/Warehouse';
import { Pivot, PivotItem } from '@fluentui/react';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = (props) => {
    const { useAccounting } = useAccountingConfigurationContext();
    return (
        <>
            <BarButtons rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                selectedKey={null}>
                <PivotItem headerText="Información" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                {
                    useAccounting &&
                    <PivotItem headerText="Contabilidad" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                }
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>
        </>
    )
}