import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    TableCellLayout,
    Table
} from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import {ReactComponent as WarningIcon} from "../../../../Assets/Images/Icons/Warning.svg";
import { OmniCombobox } from "../../../Shared/Controls/ComboBox/OmniCombobox";
import { useEffect, useState } from "react";
const returnWhite = require('../../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../../Assets/Images/Icons/Apply.svg').default;

export const OverlayDrawerVarietyEdit = (props) => {
    const [itemsByVariety, setItemsByVariety]=useState([]);
    const[varietyId, setVarietyId]=useState();

    const CloseOverlayDrawer = () => {
        props.setShowAttachVarietyPanel(false);
    };
    const varietyChangeItem = async (id, varietyItem)=>{
        let result=await props.getItemsByVarietyIdAsync(id)
        if(result.isSuccessful){
            setItemsByVariety(result.data);
            setVarietyId(id);
        }
    }
    const setVarietyToCopyData=async()=>{
        props.setVarietyId(varietyId)
    }

    return (
        <OverlayDrawer position="end" open={props.showAttachVarietyPanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Variedad
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <div className="row">
                    <div className="col-md-6 col-lg-6">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <OmniCombobox title="Búsqueda de variedades"
                                    items={props.varietiesList}
                                    onChangeItem={varietyChangeItem}
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                            <Table className="detail-table">
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell 
                                            className={'detail-table-header-title '}>
                                                Lista de artículos en la variedad
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {
                                        itemsByVariety.map((item, index)=>(
                                            <TableRow>
                                                <TableCell>
                                                    {item.description}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <OmniCard>
                            <div >
                                <WarningIcon/>
                                <span className="drawer-variety-card-title">
                                    Advertencia
                                </span>
                            </div>
                            <p className="drawer-variety-card-p">
                                Al utilizar una 
                                <span className="drawer-variety-card-text-blue">Variedad</span> en este artículo, <span className="drawer-variety-card-text-red">se heredarán todos los valores</span> de los artículos contenidos en la variedad seleccionada.
                                <br />
                                <br/>
                                <span>*Con la excepción del código de barras y la descripción.</span>
                            </p>
                        </OmniCard>
                    </div>
                </div>
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={setVarietyToCopyData}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}