import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './Context/AuthProvider'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
        <AuthProvider>
            <FluentProvider theme={teamsLightTheme}>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </FluentProvider>
        </AuthProvider>
    </BrowserRouter>    
);