import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';

import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Creditor/Add';

import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { Pivot, PivotItem } from '@fluentui/react';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Creditors/CreditorStore';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';

const getCreditorAccountUrl = '/creditoraccount/GetByStatus';
const getCreditorSubAccountUrl = '/creditorsubaccount/GetByIdCreditorAccount';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const { register, handleSubmit, getValues } = useForm();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const [listCreditorAccount, setListCreditorAccount] = useState();
    const [creditorAccount, setCreditorAccount] = useState('00000000-0000-0000-0000-000000000000');
    const [creditorSubAccount, setCreditorSubAccount] = useState(null);
    const [openCallout, setOpenCallout] = useState();
    SetAxiosPrivate(axiosPrivate);

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        var data = getValues();
        let result = await AddAsync(data, useForeignKey);
        if (result.isSuccessful) {
            changeComponent(<Detail />, result.data);
            data.id = result.data;
            addNewItem({description:data.name, acronym: localStorage.getItem("userName") });
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    };
    const handleChangeCreditorAccount = (event, data) => {
        setCreditorAccount(data);
    };

    const GetCreditorSubAccount = async () => {
        try {
            let resultList = [];
            let itemCreditorAccount = {};
            const response = await axiosPrivate
                .get(getCreditorSubAccountUrl,{ params: { idCreditorAccount: creditorAccount } } );
            if (response.data.isSuccessful) {
                if (response.data.data) {
                    response.data.data.map((item) => {
                        itemCreditorAccount = { key: item.id, value: item.id, text: item.description }
                        resultList.push(itemCreditorAccount);
                    })
                }
                setCreditorSubAccount(resultList)
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            } 
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    }

    useEffect(() => {        
        const GetCreditorAccount = async () => {
            try {
                let resultList = [];
                let itemCreditorAccount = {};
                const statusFilterParam = { statusFilter: 1, description: '' };
                const response = await axiosPrivate
                    .post(getCreditorAccountUrl, statusFilterParam)               
                response.data.data.map((item) => {
                    itemCreditorAccount = { key: item.id, value: item.id, text: item.description, selected: false }
                    resultList.push(itemCreditorAccount);
                })
                if (response.data.isSuccessful) {
                    setListCreditorAccount(resultList)
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
                } 
            } catch (err) {
                if (!err?.response) {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
                } else if (err.response?.status === 400) {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
                }
            }
        }
        GetCreditorAccount();
    }, []);
    useEffect(() =>    {
        GetCreditorSubAccount();
        creditorAccount == 0 ? setCreditorSubAccount(null) : GetCreditorSubAccount();
    }, [creditorAccount]);
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información">
                        <OmniCard title='Datos generales'>
                            <OmniTextBox required title='Razón social' register={{...register('name') }} focus />
                            <OmniTextBox required width='50%' title='RFC' register={{ ...register('rfc') }} />
                            <OmniSelect required title='Rubro' register={{ ...register('creditoraccountid') }} onChange={handleChangeCreditorAccount} items={listCreditorAccount} />
                            <OmniSelect required title='Subrubro' register={{ ...register('creditorsubaccountid') }} items={creditorSubAccount} />
                        </OmniCard>        
                    </PivotItem>
                    <PivotItem headerText="Contabilidad">
                        <OmniCard title='Contabilidad'>
                            <OmniTextBox required width='50%' title='Cuenta contable' register={{ ...register('accountingAccount') }} />
                        </OmniCard>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                    </PivotItem>
                </Pivot>      
            </form>
            <OmniCallout
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={AddConfirm}
                title='Confirmación'
                content='¿Desea guardar los cambios?'
                leftButton={'Si'}
                rightButton={'Regresar'}
                backgroundColorLeft={'var(--success)'}
                colorLeft={'white'}
                backgroundColorRight={'var(--secondary)'}
                colorRight={'white'}
                buttonId={'BtnGuardar'}
                barGroupKey={'rigthCommandBar'}
                />
        </>
    );
}