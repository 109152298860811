import { useForm } from "react-hook-form";
import Detail from "./Detail";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/AccountingTaxes/Add";
import {AddAsync, SetAxiosPrivate} from '../../../Functions/Catalogs/AccountingTaxes/AccountingTaxStore'
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import InputType from "../../../Assets/Constants/Enums/InputType";


export default function Add(){
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const { register, handleSubmit } = useForm();
    const changeComponent = useSetStatesContext(); 
    
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const add = async (data) => {
        let result = await AddAsync(data);
        if(result.isSuccessful){
            changeComponent(<Detail />, result.data);
            data.id=result.data;
            addNewItem(data);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);   
        }
        else{
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);   
        } 
    };
    return(
        <form onSubmit={handleSubmit(add)}>
            <BarButtons rightButtons={rightButtons} 
                leftButtons={leftButtons}/>
                
                <OmniCard title='Datos generales'>
                    <OmniTextBox title="Descripción" tabIndex={"1"} register={{ ...register("description") }} focus />
                    <OmniTextBox title="Acrónimo" tabIndex={"2"} register={{ ...register("acronym") }} />
                    <OmniTextBox title="Tasa" tabIndex={"3"} type={InputType.Number} register={{ ...register("taxRate") }} />
                </OmniCard>
                
        </form>
    )
}
