const bankEndpoint = {
    controller: "bank",
    actions: {
        getById: "GetById",
        add: "Add",
        update: "Update",
        setStatus: "SetStatus",
        validateKey:"ValidateKey"
    }
}
const bankSatEndpoint = {
    controller: "banksat",
    actions: {
        getByStatus: "GetByStatus",
    }
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(bankEndpoint.controller, bankEndpoint.actions.getById).url, {
            params: { id: id }
        });

        if (response.data.isSuccessful) {
            result.data = {
                id: response.data.data?.id,
                key: response.data.data?.key,
                name: response.data.data?.name,
                accountingAccount: response.data.data?.accountingAccount,
                active: response.data.data?.active,
                bankSatId: response.data.data?.bankSatId,
                bankSat: response.data.data?.bankSat,
                createDate: response.data.data?.createDate,
                createUserName: response.data.data?.createUserName,
                lastUpdateDate: response.data.data?.lastUpdateDate,
                updateUserName: response.data.data?.updateUserName,
                createUserFullName: response.data.data?.createUserFullName,
                updateUserFullName: response.data.data?.updateUserFullName
            };
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(bankEndpoint.controller, bankEndpoint.actions.add).url,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName"),
            }));
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `El banco se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const UpdateAsync = async (data) =>
{
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(bankEndpoint.controller, bankEndpoint.actions.update).url,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El banco se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const SetStatusAsync = async (id, status) =>
{
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(bankEndpoint.controller, bankEndpoint.actions.setStatus).url,
            JSON.stringify({
                id: id,
                active: status,
                loggedUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El banco ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const ValidateKeyAsync = async (key) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(bankEndpoint.controller, bankEndpoint.actions.validateKey).url, {
            params: { key: key }
        });
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetBanksSatByActive = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(bankSatEndpoint.controller, bankSatEndpoint.actions.getByStatus).url,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name
            }));
            result.data.unshift({ key: 0, value: 0, text: 'SELECCIONE' });
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}

