export const ItemStatusDescripcion = [
    { status: 0, description: "Inactivo", initial: "I" },
    { status: 1, description: "Activo", initial: "A" },
    { status: 2, description: "Nuevo", initial: "N" },
    { status: 3, description: "Descontinuado", initial: "D" }
];


export const ItemStatus = {
    Desactive: 0,
    Active: 1,
    Discontinued: 3
}