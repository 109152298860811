import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/CustomerType/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/CustomerTypes/CustomerTypeStore';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/CustomerType.svg').default
}

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [customerType, setCustomerTypes] = useState([]);
        SetAxiosPrivate(axiosPrivate);
    
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };


    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = getValues();
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, id);
                data.id = id;
                updateItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
            }
        }
    }
 
    useEffect(() => {
        const fillData = async () => {
            try {
                const data = await getById();                
                setCustomerTypes({
                    description:data?.description
                });
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <div className='row'>
                                    {
                                        customerType.length !== 0 &&
                                        (
                                            <>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                                <OmniTextBox title='Descripcion' maxLength={16} register={{ ...register('description') }} value={customerType.description} requiredOmni defaultValue setValue={setValue} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </OmniCard>
                        </div>
                    </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

