import { useEffect} from "react";
import { Stack } from '@fluentui/react/lib/Stack';
import { Player } from '@lottiefiles/react-lottie-player';
import SpeedTest from '@cloudflare/speedtest';
import omniSpeedTestJson from "../../../Assets/JsonSvg/Common/omniSpeedTestJson.json";
export function OmniInternetSpeed() {
    const DELAY_SECONDS_NEXT_TEST = 60 * 3;  //3 min

    const GetSpeedMeasureString = (packageSizeInBytes) => {
        let i = -1;
        const byteUnits = [" kbps"," Mbps"," Gbps"," Tbps"," Pbps"," Ebps"," Zbps"," Ybps"
        ];
        do {
            packageSizeInBytes = packageSizeInBytes / 1024;
            i++;
        } while (packageSizeInBytes > 1024);

        return Math.max(packageSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    useEffect(() => {
        const MINIMAL_SPEED_BPS = Math.max(Math.pow(1024, 2) * 5, 0).toFixed(1);     //5MB
        const NORMAL_SPEED_BPS = Math.max(Math.pow(1024, 2) * 30, 0).toFixed(1); //30 MB
        const svgPaths = document.querySelectorAll("#omniSpeedTest > svg > g path");
        svgPaths[1].setAttribute("class", "downloadSpeed");
        svgPaths[1].removeAttribute("fill");
        svgPaths[2].setAttribute("class", "uploadSpeed");
        svgPaths[2].removeAttribute("fill");
        
        let speedLabel = document.getElementById("lblTestSpeedValue");
        const speedTestEngine = new SpeedTest();

        speedTestEngine.onResultsChange = ({ type }) => {
            if (type === "download") {
                !speedTestEngine.isFinished && DisplayDownoloadSpeed(speedTestEngine.results.getDownloadBandwidth());
            }
            else if (type === "upload") {
                !speedTestEngine.isFinished && DisplayUploadSpeed(speedTestEngine.results.getUploadBandwidth());
            }
        };

        speedTestEngine.onFinish = results => {
            DisplayDownoloadSpeed(results.getDownloadBandwidth());
            DisplayUploadSpeed(results.getUploadBandwidth());
            RestartSpeedTest();
        }

        function DisplayDownoloadSpeed(downloadSpeedBps) {
            if (downloadSpeedBps !== undefined) {
                speedLabel.innerText = GetSpeedMeasureString(downloadSpeedBps);
                if (downloadSpeedBps <= MINIMAL_SPEED_BPS) {
                    speedLabel.className = "downloadSpeedText downloadSpeedTextSlow";
                    svgPaths[1].setAttribute("class", "downloadSpeed downloadSpeedSlow");
                }
                else if (downloadSpeedBps <= NORMAL_SPEED_BPS) {
                    speedLabel.className = "downloadSpeedText downloadSpeedTextNormal";
                    svgPaths[1].setAttribute("class", "downloadSpeed downloadSpeedNormal");
                }
                else {
                    speedLabel.className = "downloadSpeedText downloadSpeedTextOptimal";
                    svgPaths[1].setAttribute("class", "downloadSpeed downloadSpeedOptimal");
                }
            }
            else {
                speedLabel.innerText = "0 Mbps";
            }            
        }
        function DisplayUploadSpeed(uploadSpeedBps) {
            if (uploadSpeedBps !== undefined) {
                speedLabel.innerText = GetSpeedMeasureString(uploadSpeedBps);
                if (uploadSpeedBps <= MINIMAL_SPEED_BPS) {
                    svgPaths[2].setAttribute("class", "uploadSpeed uploadSpeedSlow");
                }
                else if (uploadSpeedBps <= NORMAL_SPEED_BPS) {
                    svgPaths[2].setAttribute("class", "uploadSpeed uploadSpeedNormal");
                }
                else {
                    svgPaths[2].setAttribute("class", "uploadSpeed uploadSpeedOptimal");
                }
            }
            else {
                speedLabel.innerText = "0 Mbps";
            }
        }
        function RestartSpeedTest() {
            if (speedTestEngine.isFinished) {
                const interval = setInterval(() => {
                    speedTestEngine.restart();
                    clearInterval(interval);
                }, (DELAY_SECONDS_NEXT_TEST*1000));
            }
        }
        function ParseBpsToMbps (bpsValue) {
            return Math.max(Math.pow(1024, 2) * bpsValue, 0).toFixed(1);
        }
        return () => {
            speedTestEngine.pause();
        };

    }, []);

    return (
        <>
            <Stack horizontal verticalAlign="center">
                <Stack.Item>
                    <Player id="omniSpeedTest" autoplay loop src={omniSpeedTestJson} className="login-header-icon" ></Player>
                </Stack.Item>
                <Stack.Item>
                    <span id="lblTestSpeedValue" className="login-header-label downloadSpeedText">0 Kbps</span>
                </Stack.Item>
            </Stack>
        </>
    );
}