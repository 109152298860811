import { Link } from 'react-router-dom';
import NavOptionText from './NavOptionText';
import '../../Assets/Styles/Menu/NavOption.css';
import { Button, Menu, MenuItem, MenuItemCheckbox, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { CutRegular, CutFilled, bundleIcon } from "@fluentui/react-icons";
import { useState } from 'react';

const CutIcon = bundleIcon(CutFilled, CutRegular);

export default function NavOption(props) {
    return (
        <>
            {
                props.urlOption
                ? 
                <Link to={props.urlOption} className={`link-menu  ${props.links ? 'sub-link' : ''}`}>
                    <NavOptionText {...props} />
                </Link>
                :
                <div className={'link-menu'}>
                    <NavOptionText {...props} />
                </div>        
            }
        </>
    );
}