import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/DeviceTypes/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, SetStatusAsync, SetAxiosPrivate, CalculateVolume } from '../../../Functions/Configuration/DeviceTypes/DeviceTypeStore';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Pivot, PivotItem } from '@fluentui/react';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import DeviceTypeAuthorizations from '../../../Assets/Constants/Authorizations/DeviceType';
import { TypeOfContent } from '../../../Assets/Constants/Enums/TypeOfContent';
const pivotStyles = {
    root: {
        width: '95%'
    }
};

export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, setChangePivotKey, changePivotKey } = useGeneralStatesContext();
    const [deviceType, setDeviceType] = useState();
    const [buttonsDeviceType, setButtonsDeviceType] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            var itemTypeOfContent = TypeOfContent.find(item => item.value === result.data.typeOfContent);
            setDeviceType({
                ...result.data,
                typeOfContent: itemTypeOfContent.text,
                capacityCubicCentimeters: CalculateVolume(result.data.heightCentimeters, result.data.widthCentimeters, result.data.depthCentimeters, true)
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await SetStatusAsync(id, !deviceType.active);
        if (result.isSuccessful) {
            setDeviceType((prev) => {
                return {
                    ...prev,
                    active: !deviceType.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = deviceType.active ? "Inactivar" : "Activar";
                let statusiconbutton = deviceType.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = deviceType.active ? DeviceTypeAuthorizations.Delete : DeviceTypeAuthorizations.Reactive;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el tipo de dispositivo?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !deviceType.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (deviceType) {
            setButtonsDeviceType(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...deviceType, updateUserName: localStorage.getItem("userName") });
        }
    }, [deviceType?.active]);
    useEffect(() => {
        if (deviceType) {
            setButtonsDeviceType(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (deviceType) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);

    return (
        <>
            {deviceType &&
                (<BarButtons rightButtons={buttonsDeviceType.rightButtons}
                    leftButtons={buttonsDeviceType.leftButtons} />)
            }
            <Pivot className="pivot-center-client" selectedKey={selectedKey}
                onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                overflowBehavior="menu" styles={pivotStyles} >
                <PivotItem headerText="Información" itemKey="information">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <OmniLabel text='Clave' value={deviceType?.deviceTypeClue} />
                                        </div>
                                        <div className='col-lg-8 col-md-12'>
                                            <OmniLabel text='Descripción' value={deviceType?.description} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-12'>
                                            <OmniLabel text={<>Alto<br />(cm)</>} value={deviceType?.heightCentimeters} />
                                        </div>
                                        <div className='col-lg-3 col-md-12'>
                                            <OmniLabel text='Ancho (cm)' value={deviceType?.widthCentimeters} />
                                        </div>
                                        <div className='col-lg-3 col-md-12'>
                                            <OmniLabel text='Fondo (cm)' value={deviceType?.depthCentimeters} />
                                        </div>
                                        <div className='col-lg-3 col-md-12'>
                                            <OmniLabel text='Capacidad mt³' value={deviceType?.capacityCubicCentimeters} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Capacidad de carga en kilos' value={deviceType?.kilos} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Tipo de contenido' value={deviceType?.typeOfContent} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Pide ID del dispositivo' value={deviceType?.requireDeviceKey == false ? 'NO' : 'SI'} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            {
                                deviceType?.requireDeviceKey &&
                                <div className='col-md-auto'>
                                    <OmniCard title='Cantidad de dispositivos'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniLabel text='IDs' value={deviceType?.numberOfDevices} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            }
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={deviceType} />
                </PivotItem>
            </Pivot>
        </>
    );
}