import { Checkbox, Image } from "@fluentui/react-components";
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import '../../../../Assets/Styles/Shared/Controls/OmniCheckBox.css'
import { useRef, useEffect, useState } from 'react';
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
import { OmniTooltip } from "../Tooltip/OmniTooltip";
export const OmniCheckBox = (props) => {
    const fieldEdited = useRef(false);
    const initialFieldValue = GetInitialSelectedOption();
    const [chekedValue, setChekedValue] = useState(initialFieldValue);
    const formControlId = props.register?.name;
    const fieldWatcher = useFieldChangeWatcherContext();
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.propertyChecked) {
            initialSelectedOpction = props?.propertyChecked 
        }
        else {
            initialSelectedOpction = false;
        }
        return initialSelectedOpction;
    };
    const OnChekedhancheHandler = (event, data) => {
        setChekedValue(data.checked);
        if (initialFieldValue === data.checked) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
        if (props.propertyId && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.propertyId]: data.checked }));
        }
       props.onChange && props.onChange(event, data);
    };
    //init control on Context
    useEffect(() => {
        if (fieldWatcher) {
            if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
                fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
            }
            fieldWatcher.updateHasPendingChanges();
        }
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher) {
            if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
                fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                    if (c.id === formControlId) {
                        return { ...c, edited: fieldEdited.current }
                    }
                    return c;
                }));
                fieldWatcher.updateHasPendingChanges();
            }
        }
    }, [fieldEdited.current]);
    return (
        <>
            <div className={'omniinfolabel-container' + (props.titleTop ? '-top' : '')} >
                <Checkbox size="large"
                    label={props.title}
                    {...props.register}
                    checked={chekedValue}
                    {...props}
                    onChange={OnChekedhancheHandler}
                    className={props.disabled ? 'check-omni-disabled-noChecked' : 'check-omni'} />
                <OmniTooltip infoText={props.infoText} />
                {fieldEdited.current === true &&
                    <div className="omniinfolabel-icon"><Image alt="" src={require("../../../../Assets/Images/Icons/Edit.svg").default} /></div>
                }
            </div>
        </>
    );
}