const itemServiceEndpoint = {
    controller: "itemservice",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus",
        getAlixEnabled:"GetAlixEnabled",
    }
}
const measureUnitEndpoint = {
    controller: "measureunit",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus",
        getAlixEnabled:"GetAlixEnabled",
    }
}
const paymentmethodsEndpoint = {
    controller: "paymentmethods",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus"
    }
}
const typesrelationshipEndpoint = {
    controller: "typesrelationship",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus"
    }
}

const taxregimesEndpoint = {
    controller: "taxregimes",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus"
    }
}
const usescfdiEndpoint = {
    controller: "usescfdi",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus"
    }
}

const contriesEndpoint = {
    controller: "contries",
    actions: {
        getAll: "GetAll",
        setStatus: "SetStatus"
    }
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetAllItemServiceAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(itemServiceEndpoint.controller, itemServiceEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetEnabledAlixItemServiceAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(itemServiceEndpoint.controller, itemServiceEndpoint.actions.getAlixEnabled).url);

        if (response.status === 200) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAllMeasureUnitAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(measureUnitEndpoint.controller, measureUnitEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetEnabledAlixIMeasuresUnitsAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(measureUnitEndpoint.controller, itemServiceEndpoint.actions.getAlixEnabled).url);

        if (response.status === 200) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAllPaymentMethodsAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(paymentmethodsEndpoint.controller, paymentmethodsEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAllTypesRelationshipAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(typesrelationshipEndpoint.controller, typesrelationshipEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAllTaxRegimesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(taxregimesEndpoint.controller, taxregimesEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAllUsesCFDIAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(usescfdiEndpoint.controller, usescfdiEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAllContriesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(contriesEndpoint.controller, contriesEndpoint.actions.getAll).url);

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusItemServiceAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(itemServiceEndpoint.controller, itemServiceEndpoint.actions.setStatus).url,
            JSON.stringify({
                items: data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusMeasureUnitAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(measureUnitEndpoint.controller, measureUnitEndpoint.actions.setStatus).url,
            JSON.stringify({
                items:data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusPaymentMethodsAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(paymentmethodsEndpoint.controller, paymentmethodsEndpoint.actions.setStatus).url,
            JSON.stringify({
                items: data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusTypesRelationshipAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(typesrelationshipEndpoint.controller, typesrelationshipEndpoint.actions.setStatus).url,
            JSON.stringify({
                items: data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusTaxRegimesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(taxregimesEndpoint.controller, taxregimesEndpoint.actions.setStatus).url,
            JSON.stringify({
                items: data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusUsesCFDIAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(usescfdiEndpoint.controller, usescfdiEndpoint.actions.setStatus).url,
            JSON.stringify({
                items: data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusContriesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(contriesEndpoint.controller, contriesEndpoint.actions.setStatus).url,
            JSON.stringify({
                items: data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `Los registros se actualizaron exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }

    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}