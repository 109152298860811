import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniCheckBox } from "../../../Shared/Controls/Checkbox/OmniCheckBox";

export default function Validations(props){

    //Validaciones del artículo
    const requiresIdentificationForSale="Venta sólo para mayores de edad";
    const allowsChargeWithEBT="Permite cobro con tarjeta EBT";
    const useAmount="Usa importe";

    //Canales
    const sellinStores="Satya - Venta en tiendas";
    const presaleInRoute="Go - Preventa en ruta";
    const itemOnBoardSale="Bring - Venta con producto a bordo";
    const telemarketing="Call - Telemarketing";
    const ecommerceB2C="Site - eCommerceB2C";
    const ecommerceB2B="Portal - eCommerceB2B";
    const amazonMarketPlace="Amazon - Market Place";
    const mercadoLibreMarketPlace="MercadoLibre - Market Place";
    const walmartMarketPlace="Walmart - Market Place";


    return(
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Validaciones del artículo'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={requiresIdentificationForSale}
                                        propertyChecked={props.item?.requiresIdentificationForSale}
                                        propertyId="requiresIdentificationForSale"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={allowsChargeWithEBT}
                                        propertyChecked={props.item?.allowsChargeWithEBT}
                                        propertyId="allowsChargeWithEBT"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={useAmount}
                                        propertyChecked={props.item?.useAmount}
                                        propertyId="useAmount"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                        </OmniCard>
                        <OmniCard title='Canales'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={sellinStores}
                                        propertyChecked={props.item?.sellinStores}
                                        propertyId="sellinStores"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={presaleInRoute}
                                        propertyChecked={props.item?.presaleInRoute}
                                        propertyId="presaleInRoute"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={itemOnBoardSale}
                                        propertyChecked={props.item?.itemOnBoardSale}
                                        propertyId="itemOnBoardSale"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={telemarketing}
                                        propertyChecked={props.item?.telemarketing}
                                        propertyId="telemarketing"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={ecommerceB2C}
                                        propertyChecked={props.item?.ecommerceB2C}
                                        propertyId="ecommerceB2C"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={ecommerceB2B}
                                        propertyChecked={props.item?.ecommerceB2B}
                                        propertyId="ecommerceB2B"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={amazonMarketPlace}
                                        propertyChecked={props.item?.amazonMarketPlace}
                                        propertyId="amazonMarketPlace"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={mercadoLibreMarketPlace}
                                        propertyChecked={props.item?.mercadoLibreMarketPlace}
                                        propertyId="mercadoLibreMarketPlace"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCheckBox title={walmartMarketPlace}
                                        propertyChecked={props.item?.walmartMarketPlace}
                                        propertyId="walmartMarketPlace"
                                        edit={props.setItem}
                                    />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div> 
        </>
    )
}