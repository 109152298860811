import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"

export const RoutesDetail = (props) => {
    const columnsHeaderRoutes = [
        { columnKey: "1", label: "Lista de rutas", width: '40%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '30%' },
        { columnKey: "3", label: "", width: '30%' },
    ];
    return (
        <>
            <div className="detail-table-scroll">
                <Table className="detail-table">
                    <TableHeader>
                        <TableRow>
                            {columnsHeaderRoutes.map((column) => (
                                <TableHeaderCell key={column.columnKey}
                                    style={{ width: column.width }}
                                    className={'detail-table-header-title ' + column?.classAdd}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.description[props.property] &&
                            props.description[props.property].map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell >
                                        <TableCellLayout className='table-body-description'>
                                            {item.route}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout className='table-body-description'>
                                            {item.visitingHours}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout className='table-body-description'>
                                            {item.deliveryTime}
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}