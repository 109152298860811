import { Label, Image } from "@fluentui/react-components";
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css';
import { OmniTooltip } from "../Tooltip/OmniTooltip";
export const OmniFieldLabel = (props) => {
    return (
        <>
            <div className="omniinfolabel-container">
                <div className="omniinfolabel-text">
                    <Label htmlFor={props.formControlId} disabled={props.disabled}>{props.title}
                    </Label>{props.requiredOmni  && <span className="required">*</span>}
                    <OmniTooltip infoText={props.infoText} />
                </div>
                {
                    props?.fieldEdited && <div className="omniinfolabel-icon"><Image alt="" src={require("../../../../Assets/Images/Icons/Edit.svg").default} /></div>
                }
            </div>
        </>
    );
}