export const ItemPayConditions = [
    {
        key: "1",
        value: "C",
        text: "Crédito",
    },
    {
        key: "2",
        value: "H",
        text: "Mismo día",
    },
    {
        key: "3",
        value: "A",
        text: "Anticipado",
    },
];