import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Offers/OfferCampaigns/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, SetAxiosPrivate, CalculateDaysBetweenDates, ToShortDate } from '../../../Functions/Offers/OfferCampaigns/OfferCampaignStore';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import { SetStatusAsync } from '../../../Functions/Offers/OfferCampaigns/OfferCampaignStore';
import CampaignAuthorizations from '../../../Assets/Constants/Authorizations/Offers/OfferCampaigns';
import { OffersTable } from '../../Shared/OffersTable/OffersTable';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, setChangePivotKey, changePivotKey } = useGeneralStatesContext();
    const [campaign, setCampaign] = useState();
    const [buttonsCampaign, setButtonsCampaign] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setCampaign(
                {
                    ...result.data,
                    startDate: ToShortDate(result.data?.startDate),
                    endDate: ToShortDate(result.data?.endDate),
                    daysBetweenDates: CalculateDaysBetweenDates(ToShortDate(result.data?.startDate), ToShortDate(result.data?.endDate)),
                });            
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await SetStatusAsync(id, !campaign.active);
        if (result.isSuccessful) {
            setCampaign((prev) => {
                return {
                    ...prev,
                    active: !campaign.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = campaign.active ? "Inactivar" : "Activar";
                let statusiconbutton = campaign.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = campaign.active ? CampaignAuthorizations.Delete : CampaignAuthorizations.Reactive;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la campaña?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !campaign.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (campaign) {
            setButtonsCampaign(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...campaign, updateUserName: localStorage.getItem("userName") });
        }
    }, [campaign?.active]);
    useEffect(() => {
        if (campaign) {
            setButtonsCampaign(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (campaign) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);

    return (
        <>
            {campaign &&
                (<BarButtons rightButtons={buttonsCampaign.rightButtons}
                leftButtons={buttonsCampaign.leftButtons} />)
            }
            <Pivot className="pivot-center-client" selectedKey={selectedKey}
                onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                overflowBehavior="menu" styles={pivotStyles} >
                <PivotItem headerText="Información" itemKey="information">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre' value={campaign?.name} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Vigencia'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Fecha de inicio' value={campaign?.startDate} />
                                        </div>                                       
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Fecha de fin' value={campaign?.endDate} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel className='colorLabelContentBlue' text='' value={campaign?.daysBetweenDates} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Ofertas" itemKey="offer">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Ofertas'>
                                    <div id='divItemsContainer' className='row card-items-container'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OffersTable titleheader='la campaña' offers={campaign?.offers}/>
                                            </div>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={campaign} />
                </PivotItem>
            </Pivot>
        </>)
};