import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form'
import BarButtons from '../../Shared/BarButtons/BarButtons'
import { OmniCard } from '../../Shared/Card/OmniCard'
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox'
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/AccountingTaxes/Add'
import {GetByIdAsync, UpdateAsync} from '../../../Functions/Catalogs/AccountingTaxes/AccountingTaxStore'
import { useGeneralStatesContext, useIdSelectorContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";

export default function Edit (){
    const axiosPrivate = useAxiosPrivate();
    const id=useIdSelectorContext();
    const { setListMessageCatalog, updateItem } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext(); 
    const { register, handleSubmit } = useForm();
    const [accountingTax, setAccountingTax]=useState([]);

    const getById=async()=>{
        let result=await GetByIdAsync(id);
        if(result.isSuccessful){
            setAccountingTax(result.data);
        }
        else{
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);   
        }
    }

    const update=async (data)=>{
        let result= await UpdateAsync(data);
        if(result.isSuccessful){
            changeComponent(<Detail />, id);
            updateItem(data);
            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);   
        }
        else{
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);   
        }
    }

    useEffect(()=>{
        getById();
    },[])
    return(
        <form onSubmit={handleSubmit(update)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <OmniCard title='Datos generales'>
            {accountingTax.length !==0&&
            (<>
                    <OmniTextBox title="Descripción" register={{ ...register("description") }} focus value={accountingTax.description}/>
                    <OmniTextBox title="Acrónimo" register={{ ...register("acronym") }} value={accountingTax.acronym}/>
                    <OmniTextBox title="Tasa" register={{ ...register("taxrate") }} value={accountingTax.taxRate.toString()}/>
            </>) }
            </OmniCard>
        </form>
    )
}