import Add from "../../../../../Components/Catalogs/AccountingTaxes/Add";
export const rightButtons: Buttons[] = [
    {
        key: 'BtnNew',
        text: 'Nuevo',
        iconName: 'addcuvitek',
        fontColor: '#FFFFFF',
        iconColor: '#fff',
        iconHover: '#fff',
        backgroundColorButton: 'var(--primary)',
        backgroundColorButtonHover: 'var(--primary)',
        isButton: true,
        component: <Add />
    }
]