import { Label, } from '@fluentui/react-components';
import { SwatchColorPicker } from "@fluentui/react";
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css';
import '../../../../Assets/Styles/Shared/Controls/OmniColorPicker.css'
import { useState, useEffect } from 'react';
export const OmniLabelColor = (props) => {
    const [colorSquare, setColorSquare] = useState();
    useEffect(() => {
        setColorSquare({ id: '1', label: 'color', color: props.value });
    }, [props.value])
    return (
        <div className={'container-controls'}>
            <Label className='label-omni'>{props.text}</Label>
           
            <div className='container-omni-result colorLabel'>
                <SwatchColorPicker
                    columnCount={1}
                    cellShape={'square'}
                    colorCells={[colorSquare]}
                    disabled={true}
                    className="coloPicker-omni"
                />
                <Label>{props.value}</Label>
            </div>
        </div >
    )
}