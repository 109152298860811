export const leftButtons = (handleClickEdit, handleClickDelete) => [{
    key: 'BtnEdit',
    text: 'Editar',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    handler: handleClickEdit,
    authorizationKey: 3
}, {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactiveCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--danger)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    handler: handleClickDelete,
    authorizationKey: 3
}];