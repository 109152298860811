import suppliersJson from '../../../../Assets/JsonSvg/Icon/Suppliers.json';
import varietiesJson from '../../../../Assets/JsonSvg/Icon/Variety.json';
import familiesJson from '../../../../Assets/JsonSvg/Icon/Families.json';
import categoriesJson from '../../../../Assets/JsonSvg/Icon/Categories.json';
import clearFiltersJson from '../../../../Assets/JsonSvg/Icon/ClearFilters.json';
import '../../../../Assets/Styles/Shared/ItemsSelector/ItemSelectorFiltersItems.css';
import { Player } from '@lottiefiles/react-lottie-player';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Tooltip } from '@fluentui/react-components';
import { OverlayDrawerSupplierItems } from '../../OverlayDrawer/OverlayDrawerSupplierItems';
import { OverlayDrawerVariety } from '../../OverlayDrawer/OverlayDrawerVariety';
import { OverlayDrawerFamily } from '../../OverlayDrawer/OverlayDrawerFamily';
import { OverlayDrawerCategory } from '../../OverlayDrawer/OverlayDrawerCategory';
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { useState, useEffect } from 'react';
export default function ItemFilters(props) {
    const [showAttachSupplierPanel, setShowAttachSupplierPanel] = useState(false);
    const [showAttachVarietyPanel, setShowAttachVarietyPanel] = useState(false);
    const [showAttachFamilyPanel, setShowAttachFamilyPanel] = useState(false);
    const [showAttachCategoryPanel, setShowAttachCategoryPanel] = useState(false);
    const
        {
            setItems,
            setComponent,
            setParamFilter,
            paramFilter,
            textFilter
        } = useGeneralStatesContext();
    const ClickClean = () => {
        setParamFilter({
            supplierType: "1",
            varietyType: "1",
            familyType: "1",
            categoryType: "1"
        });
        setItems([]);
        setComponent();
    };
    useEffect(() => {
        setParamFilter({
            supplierType: "1",
            varietyType: "1",
            familyType: "1",
            categoryType: "1"
        })
    }, [])
    useEffect(() => {
        if (!showAttachSupplierPanel && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
        }
    }, [showAttachSupplierPanel])
    useEffect(() => {
        if (!showAttachVarietyPanel && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
        }
    }, [showAttachVarietyPanel])
    useEffect(() => {
        if (!showAttachFamilyPanel && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
        }
    }, [showAttachFamilyPanel])
    useEffect(() => {
        if (!showAttachCategoryPanel && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
        }
    }, [showAttachCategoryPanel])

    return (
        <div className="text-end filter-section">
            <Stack horizontal verticalAlign='center' horizontalAlign='end'>
                <StackItem className='stack-filter' key={"stkSupplier"} id={"stkSupplier"}>
                    <Tooltip
                        content={
                            "Proveedores"
                        }
                        positioning="below"
                    >
                        <div onClick={() => { setShowAttachSupplierPanel(true) }}>
                            <Player
                                id={"plySupplier"}
                                key={"plySupplier"}
                                hover
                                loop
                                className={`${paramFilter?.supplierType === "1" ? 'filter-svg-button' : 'filter-svg-button-fill'} `}
                                src={suppliersJson}
                            />
                        </div>
                    </Tooltip>
                </StackItem>
                <StackItem className='stack-filter' key={"stkVariety"} id={"stkSupplier"}>
                    <Tooltip
                        content={
                            "Variedades"
                        }
                        positioning="below"
                    >
                        <div onClick={() => { setShowAttachVarietyPanel(true) }}>
                            <Player
                                id={"plyVariety"}
                                key={"plyVariety"}
                                hover
                                loop
                                className={`${paramFilter?.varietyType === "1" ? 'filter-svg-button' : 'filter-svg-button-fill'} `}
                                src={varietiesJson}
                            />
                        </div>
                    </Tooltip>
                </StackItem>
                <Stack className='stack-filter' key={"stkFamily"} id={"stkSupplier"}>
                    <Tooltip
                        content={
                            "Familias"
                        }
                        positioning="below"
                    >
                        <div onClick={() => { setShowAttachFamilyPanel(true) }}>
                            <Player
                                id={"plyFamily"}
                                key={"plyFamily"}
                                hover
                                loop
                                className={`${paramFilter?.familyType === "1" ? 'filter-svg-button' : 'filter-svg-button-fill'} `}
                                src={familiesJson}
                            />
                        </div>
                    </Tooltip>
                </Stack>
                <Stack className='stack-filter' key={"stkCategory"} id={"stkSupplier"}>
                    <Tooltip
                        content={
                            "Categorías"
                        }
                        positioning="below"
                    >
                        <div onClick={() => { setShowAttachCategoryPanel(true) }}>
                            <Player
                                id={"plyCategory"}
                                key={"plyCategory"}
                                hover
                                loop
                                className={`${paramFilter?.categoryType === "1" ? 'filter-svg-button' : 'filter-svg-button-fill'} `}
                                src={categoriesJson}
                            />
                        </div>
                    </Tooltip>
                </Stack>
                <Stack className='stack-filter' key={"stkClean"} id={"stkSupplier"}>
                    <Tooltip
                        content={
                            "Limpiar"
                        }
                        positioning="below"
                    >
                        <div className='div-clear-button' onClick={ClickClean}>
                            <Player
                                id={"plyClear"}
                                key={"plyClear"}
                                hover
                                loop
                                className="filter-svg-button"
                                src={clearFiltersJson}
                            />
                        </div>
                    </Tooltip>
                </Stack>
            </Stack>
            {paramFilter &&
                <>
                <OverlayDrawerSupplierItems showAttachSupplierPanel={showAttachSupplierPanel}
                    setShowAttachSupplierPanel={setShowAttachSupplierPanel}
                    setParam={setParamFilter}
                    param={paramFilter} />
                    <OverlayDrawerVariety showAttachVarietyPanel={showAttachVarietyPanel}
                        setShowAttachVarietyPanel={setShowAttachVarietyPanel}
                        setParam={setParamFilter}
                    param={paramFilter}/>
                    <OverlayDrawerFamily showAttachFamilyPanel={showAttachFamilyPanel}
                        setShowAttachFamilyPanel={setShowAttachFamilyPanel}
                        setParam={setParamFilter}
                    param={paramFilter}/>
                    <OverlayDrawerCategory showAttachCategoryPanel={showAttachCategoryPanel}
                        setShowAttachCategoryPanel={setShowAttachCategoryPanel}
                        setParam={setParamFilter}
                    param={paramFilter}/>
                </>
            }
        </div>
    )
}