import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Items/Item';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
import ItemSelectorSpecialFilters from '../../../Assets/Constants/Enums/ItemSelectorSpecialFilters'

const getByStatus = '/item/getbystatus';
const getTotalItems='/item/GetSearchTotalItems'
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Item.svg').default
}
export default function WarehouseLocation()
{
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de artículos"}
                    image={iconCatalog}
                    urlGetItems={getByStatus}
                    urlGetTotalItems={getTotalItems}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"description"}
                    omniAcronym={"acronym"}
                    getInLoad={false}
                    indexComponent={<Index />}
                    useSpecialFilters={true}
                    specialFiltersType={ItemSelectorSpecialFilters.Items}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}