import BarButtons from '../../../Shared/BarButtons/BarButtons';
import { Authorization } from './Authorization';
import { AuthorizationDetail } from './AuthorizationDetail';
import { useState, useEffect } from 'react';
import { leftButtons } from "../../../../Assets/Constants/BarButtons/Configurations/Roles/Authorizations/Detail";
import {
    leftButtonsEdit as editingButton, leftButtonsDetail
} from '../../../../Assets/Constants/BarButtons/Configurations/Roles/Editing';
export const CatalogAuthorizations = (props) => {
    const [view, setView] = useState({
        viewDetail: true,
        viewEdit: false
    });
    function EditAuthorization() {
        props.setIsItemDisabled(true);
        setView({
            viewDetail: false,
            viewEdit: true
        });
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: {}
        }));
    }

    const [buttonsChild, setButtonsChild] = useState({ rightButtonsChild: [], leftButtonsChild: [] });

    useEffect(() => {
        setButtonsChild(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons(EditAuthorization).map(button => {
                if (button.key === "BtnEdit") {
                    return { ...button, disabled: !props.role?.active || !props.role?.edit };
                }
                else {
                    return button;
                }
            })
        }));
        props.setIsItemDisabled(false);
        setView({
            viewDetail: true,
            viewEdit: false
        });
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: leftButtonsDetail,
            rightButtons: []
        }));
    }, [props.role]);
    useEffect(() => {
        if (view.viewDetail) {
            setButtonsChild(prevEmail => ({
                ...prevEmail,
                rightButtonsChild: [],
                leftButtonsChild: leftButtons(EditAuthorization).map(button => {
                    if (button.key === "BtnEdit") {
                        return { ...button, disabled: !props.role?.active || !props.role?.edit };
                    }
                    else {
                        return button;
                    }
                })
            }));

            props.setIsItemDisabled(false);

            props.setbuttonsCatalog(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: []
            }));
        }
    }, [view]);

    return (
        <>
            <BarButtons rightButtons={buttonsChild.rightButtonsChild}
                leftButtons={buttonsChild.leftButtonsChild} />
            <div className='container-fluid'>
                {view.viewEdit ?
                    <div className='row'>
                            <Authorization role={props.role}
                                setRole={props.setRole}
                                view={view}
                                setView={setView}
                                setButtons={setButtonsChild}
                            />
                    </div>
                    :
                    <div className='row'>
                        <div className='col-6'>
                        </div>
                        <div className='col-6'>
                            <AuthorizationDetail listAuthorizations={props?.role?.listAuthorizations} />
                        </div>
                    </div>
                }


            </div>
        </>
    )
}
