import { Field, Select } from '@fluentui/react-components';
import '../../../../Assets/Styles/Shared/Controls/OmniSelect.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState, useEffect } from 'react';
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"

export const OmniSelect = (props) => {
    const includeDefaultOption = props?.IncludeDefaultOption === undefined ? true : props.IncludeDefaultOption;
    const fieldWatcher = useFieldChangeWatcherContext();
    const formControlId = props?.register?.name ?? props?.property;
    const initialFieldValue = GetInitialSelectedOption();
    const [selectedValue, setSelectedValue] = useState(initialFieldValue);
    const fieldEdited = useRef(false);

    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.value) {
            initialSelectedOpction = props?.value
        }
        else if (includeDefaultOption && includeDefaultOption === true) {
            initialSelectedOpction = "";
        }
        else if (props?.items) {
            initialSelectedOpction = props.items.length > 0 ? props.items[0]?.value : '';
        }
        else {
            initialSelectedOpction = '';
        }
        return initialSelectedOpction;
    };

    const OnSelectChancheHandler = (event, data) => {
        setSelectedValue(data.value);
        if (initialFieldValue == data.value) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
        props.onChange && props.onChange(event, data.value);
        if (props.property && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.property]: data.value }));
        }
    };

    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === formControlId) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }

    }, [fieldEdited.current]);
    return (
        <>
            <div className={'container-controls'} style={{ width: props.width }}>
                <Field className={"label-omni"}
                    validationState={props.validationMessage === undefined ? "none" : "error"}
                    validationMessage={props.validationMessage ?? null} >
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>

                    <Select
                        {...props.register}
                        {...props}
                        onChange={OnSelectChancheHandler}
                        appearance="underline"
                        autoFocus={props.focus}
                        className={"select-omni"}
                        value={selectedValue} title=""
                    >
                        {includeDefaultOption && <option value="">Seleccione</option>}
                        {props?.items && props.items.map((item) => (
                            <option key={item.key} value={item.value}>
                                {item.text}
                            </option>
                        ))}
                    </Select>
                </Field>
            </div>
        </>
    );
}