import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export default function Replenishment(props){
    return(
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className="row">
                    <div className="col-lg-6 col-md-6 ">
                        <OmniCard title='Información de reabasto'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Días de entrega' value={props.supplier.deliveryDays} />  
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Días de inventario' value={props.supplier.inventoryDays} />  
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </>
    );
}