import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Vehicles/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Vehicles/VehiclesStore';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit, register, getValues, setValue } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [vehicle, setVehicle] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setVehicle({
                alias: result.data.alias,
                brand: result.data.brand,
                model: result.data.model,
                year: result.data.year,
                color: result.data.color,
                sCTPermission: result.data.sctPermission,
                sCTNumberPermission: result.data.sctNumberPermission,
                licensePlate: result.data.licensePlate,
                insuranceCarrier: result.data.insuranceCarrier,
                policyNumber: result.data.policyNumber,
                requiresPrivateTransportPermit: result.data.requiresPrivateTransportPermit,
                tons: result.data.tons,
                cubicMeters: result.data.cubicMeters,
                vehicleGrossWeight: result.data.vehicleGrossWeight,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = getValues();
            data.tons = vehicle.tons;
            data.vehicleGrossWeight = vehicle.vehicleGrossWeight;
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = id;
                updateItem({ ...data, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    useEffect(() => {
        getById();
    }, [])
    const [showErrors, setShowErrors] = useState({
        tons: undefined,
        cubicMeters: undefined,
        vehicleGrossWeight: undefined
    });
    const onBlurTons = (event) => {
        const text = event.target.value;
        if (parseFloat(text) <= 60) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                tons: undefined
            }));
            setValue('tons', text);
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                tons: `El campo toneladas no puede ser mayor a 60`
            }));
            event?.target?.focus();
        }
    };
    const onBlurCubicMeters = (event) => {
        const text = event.target.value;
        if (text) {
            if (parseInt(text) <= 100) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    cubicMeters: undefined
                }));
                setValue('cubicMeters', text);
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    cubicMeters: `El campo metros cúbicos no puede ser mayor a 100`
                }));
                event?.target?.focus();
            }
        }
    };
    const onBlurVehicleGrossWeight = (event) => {
        const text = event.target.value;
        if (parseFloat(text.replace(',', '')) < 10000) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                vehicleGrossWeight: undefined
            }));
            setValue('vehicleGrossWeight', text);
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                vehicleGrossWeight: `El campo peso bruto vehícular no pueden ser mayor a 9,999`
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll'>
                            {vehicle &&
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            {
                                                useForeignKey &&
                                                <OmniTextBox requiredOmni defaultValue setValue={setValue} inputType="number" length="10" title='Clave SAP' register={{
                                                    ...register('id')
                                                }} focus value={id} />
                                            }
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox requiredOmni defaultValue setValue={setValue} title="Alias" register={{ ...register("alias") }} focus={useForeignKey ? false : true} value={vehicle?.alias} maxLength={128} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox requiredOmni defaultValue setValue={setValue} title="Marca" register={{ ...register("brand") }} value={vehicle?.brand} maxLength={128} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6'>
                                                    <OmniTextBox requiredOmni defaultValue setValue={setValue} title="Modelo" register={{ ...register("model") }} value={vehicle?.model} maxLength={128} />
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <OmniTextBox inputType="number" length="4" title="Año" register={{ ...register("year") }} value={vehicle?.year} setValue={setValue} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6'>
                                                    <OmniTextBox title="Color" register={{ ...register("color") }} value={vehicle?.color} maxLength={16} setValue={setValue} />
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <OmniTextBox title="Placa" register={{ ...register("licensePlate") }} value={vehicle?.licensePlate} maxLength={7} setValue={setValue} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-6'>
                                                    <OmniTextBox inputType={'decimal'}
                                                        max={60}
                                                        decimals={2} title="Toneladas" property="tons"
                                                        edit={setVehicle}
                                                        value={vehicle?.tons} validationMessage={showErrors.tons}
                                                        blur={onBlurTons}/>
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <OmniTextBox inputType="number" length="3" title="Metros cúbicos" register={{ ...register("cubicMeters") }} value={vehicle?.cubicMeters}
                                                        validationMessage={showErrors.cubicMeters}
                                                        blur={onBlurCubicMeters} setValue={setValue}/>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox inputType={'decimal'}
                                                        max={9999.99}
                                                        decimals={2} title="Peso bruto vehícular" property="vehicleGrossWeight"
                                                        edit={setVehicle}
                                                        value={vehicle?.vehicleGrossWeight}
                                                        validationMessage={showErrors.vehicleGrossWeight}
                                                        blur={onBlurVehicleGrossWeight} setValue={setValue}                                                   />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Permisos'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniCheckBox title="Requiere permiso para transporte privado"
                                                        register={{ ...register("requiresPrivateTransportPermit") }}
                                                        propertyChecked={vehicle?.requiresPrivateTransportPermit} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox setValue={setValue} title="Permiso Secretaría de Comunicaciones y Transportes" register={{ ...register("sCTPermission") }} value={vehicle?.sCTPermission} maxLength={16} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox setValue={setValue} title="Número de permiso" register={{ ...register("sCTNumberPermission") }} value={vehicle?.sCTNumberPermission} maxLength={50} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                        <OmniCard title='Aseguradora'>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-6'>
                                                    <OmniTextBox setValue={setValue} title="Empresa aseguradora" register={{ ...register("insuranceCarrier") }} value={vehicle?.insuranceCarrier} maxLength={50} />

                                                </div>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniTextBox setValue={setValue} title="Número de póliza" register={{ ...register("policyNumber") }} value={vehicle?.policyNumber} maxLength={30} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            }
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

