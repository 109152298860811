import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Offers/OfferCampaigns/OfferCampaign';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatus = '/offercampaign/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Campaign.svg').default
}
export default function OfferCampaign() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                    <ItemsSelector
                        title={"Catálogo de campañas"}
                        image={iconCatalog}
                        urlGetItems={getByStatus}
                        defaultButtonsRightBar={rightButtons}
                        detailComponent={<Detail />}
                        searchActiveInRender={false}
                        omniKey={"id"}
                        omniValue={"name"}
                        omniAcronym={"acronym"}
                        getInLoad={false}
                        indexComponent={<Index />}
                    />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}