const getByIdUrl = '/creditorAccount/getbyid';
const updateStatusUrl = '/creditorAccount/SetStatus';
const updateUrl = '/creditorAccount/update';
const addUrl = '/creditorAccount/add';
const getByIdCreditorAccount = '/creditorsubaccount/GetByIdCreditorAccount';
var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GenerateAcronym = (item) => {
    let acronym = "";
    let description = item === undefined ? "undefined" : item;
    if (description !== "") {
        acronym = description.length >= 2 ? description.substring(0, 2) : GetRandomAlphaString(2);
    }
    return acronym;
}
function GetRandomAlphaString(length) {
    var alphaChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let randomString = "";
    for (let i = 0; i < length; i++) {
        let random = Math.random();
        randomString += alphaChars.charAt(random * alphaChars.length);
    }
    return randomString;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getByIdUrl, {
            params: {
                id: id
            }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}

export const GetCreditorSubAccount = async (idCreditorAccount) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate
            .get(getByIdCreditorAccount, { params: { idCreditorAccount: idCreditorAccount } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}

export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusUrl,
            JSON.stringify({
                id: id,
                active: status,
                loggedUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El rubro ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateUrl,
            JSON.stringify({
                id: id,
                ...data,
                loggedUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El rubro se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addUrl,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El rubro se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};


const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}