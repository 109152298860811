import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniRadioGroup } from '../../../Shared/Controls/Radio/OmniRadioGroup';
const returnWhite = require('../../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../../Assets/Images/Icons/Apply.svg').default;
const ItemsStatus = [
     {
        key: "1",
        value: "1",
         text: "Sin agrupar",
    },
    {
        key:"2",
        value: "2",
        text: "Por día",
    },
    {
        key: "3",
        value: "3",
        text: "Por empleado",
    }
];
export const OverlayDrawerAttendanceLog = (props) => {
    const onChangeStatus = (event, data) => {
        props.setParam(prevEdit => ({ ...prevEdit, group: data.value, groupText: ItemsStatus.find(x => x.value == data.value)?.text, }));
    };
    const addStatus = () => {
        props.setShowAttachGroupPanel(false);
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachGroupPanel(false);
        props.setParam(prevEdit => ({ ...prevEdit, status: [] }));
    };
    return (
        <OverlayDrawer position="end" open={props.showAttachGroupPanel} modalType="alert"
            style={{ width: '448px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Agrupado
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <div className='row mt-5'>
                    <div className='col-md-12'>
                        <div className='row overflow-panel'>
                            <div className='col-md-12'>
                                <OmniRadioGroup name="group"
                                    title={"Seleccione los estatus "}
                                    orientation="vertical"
                                    items={ItemsStatus}
                                    default={props.param.group} inactivefieldEdited onChange={onChangeStatus} />
                            </div>
                        </div>
                    </div>
                </div>
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addStatus}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}