import {
    Skeleton,
    SkeletonItem,
    makeStyles,
    shorthands
} from "@fluentui/react-components";


const useStyles = makeStyles({
        styleSkeleton: {
            alignItems: "center",
            display: "grid",
            paddingBottom: "10px",
            paddingTop: "10px",
            paddingLeft: "8px",
            position: "relative",
            ...shorthands.gap("10px"),
            gridTemplateColumns: "min-content 80%",
        },
    });
export default function SkeletonItemSelector(props) // numberOfItems, circleSize, itemSize, gridSize
{
    const styles = useStyles();
    return (
        <>
            <Skeleton {...props} key={props.itemKey} >
                <div className={styles.styleSkeleton}>
                    <SkeletonItem shape="circle" size={props.circleSize || 32} />
                    <SkeletonItem size={props.itemSize || 16} />  
                    <SkeletonItem shape="circle" size={props.circleSize || 32} />
                    <SkeletonItem size={props.itemSize || 16} />  
                    <SkeletonItem shape="circle" size={props.circleSize || 32} />
                    <SkeletonItem size={props.itemSize || 16} />  
                </div>
            </Skeleton>
        </>        
    );  
}