import Add from "../../../../../Components/Catalogs/CustomerTypes/Add";
import Edit from "../../../../../Components/Catalogs/CustomerTypes/Edit";
import CustomerTypeAuthorizations from "../../../Authorizations/CustomerType";
export const leftButtons= [{
    key: 'BtnEdit',
    text: 'Editar',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Edit />,
    authorizationKey: CustomerTypeAuthorizations.Edit,
}, {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    authorizationKey: CustomerTypeAuthorizations.Delete,
}];
export const rightButtons = [{
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    authorizationKey: CustomerTypeAuthorizations.Reactive,
}]