import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/CreditorSubAccount/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/CreditorSubAccount/CreditorSubAccountStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus } = useGeneralStatesContext();
    const [CreditorSubAccount, setCreditorSubAccount] = useState({});
    const [status, setStatus] = useState('');
    const [leftButtonsDetail, setLeftButtonsDetail] = useState(leftButtons);
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setCreditorSubAccount({
                description: result.data.description,
                creditoraccountdescription: result.data.creditorAccountDescription
            });
            setStatus(!result.data.active);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, status);
        if (result.isSuccessful) {
            setStatus(!status);
            updateItemStatus(id);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
    }, [id])

    useEffect(() => {
        const updatedButtons = leftButtonsDetail.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton} el subrubro?`,
                    handler: () => { updateStatus() }
                };
            }
            return button;
        });
        setLeftButtonsDetail(updatedButtons);
    }, [status]);

    return (
        <>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtonsDetail} />
            <OmniCard title="Datos generales">
                <OmniLabel text='Descripción' value={CreditorSubAccount.description} />
                <OmniLabel text='Rubro' value={CreditorSubAccount.creditoraccountdescription} />
            </OmniCard>
        </>
    )
}