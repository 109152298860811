import { ReactComponent as Bill } from '../../Images/Icons/Bill.svg';
import { ReactComponent as SalesCost } from '../../Images/Icons/SalesCost.svg';
import { ReactComponent as SupplierCreditNotes } from '../../Images/Icons/SupplierCreditNotes.svg';
import { ReactComponent as PaymentsSuppliers } from '../../Images/Icons/PaymentsSuppliers.svg';
import { ReactComponent as Shopping } from '../../Images/Icons/Shopping.svg';
import { ReactComponent as CustomerCreditNotes } from '../../Images/Icons/CustomerCreditNotes.svg';
import { ReactComponent as Payment } from '../../Images/Icons/Payment.svg';
export const MasterPolicyIcons = [
    { description: "Facturas", icon: <Bill /> },
    { description: "Abonos Clientes", icon: <Payment /> },
    { description: "Notas de crédito de clientes", icon: <CustomerCreditNotes /> },
    { description: "Compras", icon: <Shopping /> },
    { description: "Abonos Proveedores", icon: <PaymentsSuppliers /> },
    { description: "Notas de crédito de provedores", icon: <SupplierCreditNotes /> },
    { description: "Póliza costo de venta", icon: <SalesCost /> }
];