import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { emailPattern } from '../../../../Assets/Constants/Email/Email';
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { useEffect, useState } from 'react';
import { OmniCheckBox } from '../../../Shared/Controls/Checkbox/OmniCheckBox'
import { rightButtons, leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Suppliers/Contacts/Add";
import { leftButtons as leftButtonsEdit } from "../../../../Assets/Constants/BarButtons/Catalog/Suppliers/Contacts/Edit";
import {
    UpdateContactAsync, AddContactsAsync
} from '../../../../Functions/Catalogs/Suppliers/SupplierStore'
import { Text } from "@fluentui/react-components";

export const Contact = (props) => {
    const { setListMessageCatalog } = useGeneralStatesContext();
    const itemSelected = (props.view[props.property].viewEdit ? props.description[props.property].find(x => x.id == [props?.itemSelected[props.property]]) : undefined);
    const [contact, setContact] = useState(itemSelected);
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        position: undefined,
        email: undefined,
        cellPhone: undefined
    });
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [])
    function Return() {
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: false,
                viewDetail: true,
                viewEdit: false
            }
        }));
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: itemSelected !== undefined ? leftButtonsEdit : leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [contact])

    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!contact.name) {
            errorMessages.push("El nombre es obligatorio");
        }
        if (!contact.position) {
            errorMessages.push("El puesto es obligatorio");
        }
        if (!contact.email) {
            errorMessages.push("El correo es obligatorio");
        }
        if (!contact.cellPhone) {
            errorMessages.push("El celular es obligatorio");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            const updatedItem = {
                ...contact,
                supplierId: props.description.id,
                active: true
            };
            if (itemSelected !== undefined) {
                let result = await UpdateContactAsync(updatedItem);
                if (result.isSuccessful) {
                    props.setDescription((prevClient) => {
                        const updatedClient = prevClient[props?.property].map((item) => {
                            if (item.id === props.itemSelected[props.property]) {
                                return updatedItem;
                            }
                            return item;
                        });
                        return {
                            ...prevClient,
                            [props.property]: updatedClient,
                        };
                    });
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
            else {
                let result = await AddContactsAsync(updatedItem);
                if (result.isSuccessful) {
                    updatedItem.id = result.data;
                    if (props?.description[props?.property]) {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [...prevClient[props.property], updatedItem],
                        }));
                    }
                    else {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [updatedItem],
                        }));

                    }
                    props.setSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: result.data
                    }));
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                    //setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    const onBlurName = (event) => {
        ValidText(event, "name", "El nombre es obligatorio");
    };
    const onBlurPositionn = (event) => {
        ValidText(event, "position", "El puesto es obligatorio");
    };
    const onBlurCellPhone = (event) => {
        ValidText(event, "cellPhone", "El celular es obligatorio");
    };
    function ValidText(event, property, text) {
        const input = event.target.value;
        if (input) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: text
            }));
            event?.target?.focus();
        }
    }
    const onBlurEmail = (event) => {
        const inputTime = event.target.value;
        if (inputTime) {
            if (emailPattern.test(inputTime)) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    email: undefined
                }));
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    email: 'El correo no es válido'
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                email: 'El correo es obligatorio'
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
            <OmniCard title='Datos generales'>
                <div className='row'>
                    <OmniTextBox required title="Nombre del contacto"
                        focus
                        maxLength={100} edit={setContact}
                        blur={onBlurName}
                        property="name"
                        value={contact?.name}
                        validationMessage={showErrors.name}
                    />
                </div>
                <div className='row'>
                    <OmniTextBox required title="Puesto"
                        maxLength={100} edit={setContact}
                        blur={onBlurPositionn}
                        property="position"
                        value={contact?.position}
                        validationMessage={showErrors.position}
                    />
                </div>
                <div className='row'>
                    <OmniTextBox title="Correo" required
                        edit={setContact}
                        property="email"
                        value={contact?.email}                       
                        blur={onBlurEmail}
                        validationMessage={showErrors.email}
                        contentAfter={
                            <Text size={500} >
                                @
                            </Text>
                        } maxLength={50} notuppercase={true} />
                </div>
                <div className='row'>
                    <OmniTextBox title="Celular" required
                        blur={onBlurCellPhone}
                        value={contact?.cellPhone}
                        property="cellPhone" edit={setContact} customMask={"999-999-9999"} validationMessage={showErrors.cellPhone}/>
                </div>
                <div className='row'>
                    <OmniCheckBox title="Se le envía correo con la ODC en PDF"
                        propertyChecked={contact?.sendPdf}
                        propertyId="sendPdf"
                        edit={setContact} />
                </div>
                <div className='row'>
                    <OmniCheckBox title="Se le envía correo con la ODC en Excel"
                        propertyChecked={contact?.sendXLS}
                        propertyId="sendXLS"
                        edit={setContact} />
                </div>
                <div className='row'>
                    <OmniCheckBox title="Se le envía correo con información del pago"
                        propertyChecked={contact?.sendPay}
                        propertyId="sendPay"
                        edit={setContact} />
                </div>
            </OmniCard>
        </>
    )
}