import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { useState, useEffect } from 'react';
import { GetRoutesByClient } from '../../../../Functions/Catalogs/Routes/RoutesStore'
import useAxiosPrivate from "../../../../Hooks/Common/useAxiosPrivate";
import { SetAxiosPrivate } from '../../../../Functions/Catalogs/Routes/RoutesStore'
import React from 'react';
export const OtherRoutes = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const columnsHeaderRoutes = [
        { columnKey: "1", label: props.title, width: '50%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '25%' },
        { columnKey: "3", label: "", width: '25%' }
    ];
    const [clientsRoutes, setClientsRoutes] = useState();
    useEffect(() => {
        OnLoadOtherRoutes();
    }, [props?.clientId]);
    const OnLoadOtherRoutes = async () => {
        let client = await GetRoutesByClient(props?.clientId)
        if (client.isSuccessful) {
            const filteredClientList = client.data.map(parent => {
                const filteredChildren = parent.listRoutesShippingAddressesResult.filter(child => child.dayId != props?.day);
                return {
                    id: parent.id,
                    name: parent.name,
                    listRoutesShippingAddressesResult: filteredChildren
                };
            });

            //let clientList = client.data.filter(x => x.dayId != props?.day).map((item) => ({
            //    id: item.routeId,
            //    route: item.route,
            //    deliveryTime: item.deliveryTime,
            //    visitingHours: item.visitingHours
            //}))
            if (filteredClientList.length > 0) {
                setClientsRoutes(filteredClientList);
            }
            else {
                setClientsRoutes(undefined);
            }
        }
        else {
            setClientsRoutes(undefined);
        }
    }
    return (
        <>
            {clientsRoutes && clientsRoutes.length>0 &&
                <div className="detail-table-scroll">
                    <Table className="detail-table">
                        <TableHeader>
                            <TableRow >
                                {columnsHeaderRoutes.map((column) => (
                                    <TableHeaderCell key={column.columnKey}
                                        style={{ width: column.width }}
                                        className={'detail-table-header-title ' + column?.classAdd}>
                                        {column.label}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {clientsRoutes && clientsRoutes.map((item, parentIndex) => (
                               item.listRoutesShippingAddressesResult.length>0 &&
                                <React.Fragment key={parentIndex}>
                                    <TableRow key={parentIndex} className={"item-table item-header"}>
                                        <TableCell colSpan={3}>
                                            <TableCellLayout>{item.name}</TableCellLayout>
                                        </TableCell>
                                    </TableRow>

                                    {item.listRoutesShippingAddressesResult.map((childItem, childIndex) => (
                                        <TableRow key={`${parentIndex}-${childIndex}`} className={"item-table"}>
                                            <TableCell>
                                                <TableCellLayout className={"ps-1"}>{childItem.routeDayName}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{childItem.visitingHours}</TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>{childItem.deliveryTime}</TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            }
        </>
    )
}