import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form'
import BarButtons from '../../Shared/BarButtons/BarButtons'
import { OmniCard } from '../../Shared/Card/OmniCard'
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox'
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/CreditorSubAccount/Edit'
import { GetByIdAsync, UpdateAsync, GetCreditorAccount } from '../../../Functions/Catalogs/CreditorSubAccount/CreditorSubAccountStore'
import { useGeneralStatesContext, useIdSelectorContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
export default function Edit() {
    const id = useIdSelectorContext();
    const { setListMessageCatalog, updateItem } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const { register, handleSubmit } = useForm();
    const [CreditorSubAccount, setCreditorSubAccount] = useState([]);
    const [listCreditorAccount, setListCreditorAccount] = useState([]);
    const GetCreditorSubAccount = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setCreditorSubAccount({
                description: result.data.description,
                idCreditorAccount: result.data.idCreditorAccount,
            });
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async (data) => {
        let result = await UpdateAsync(id, data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, id);
            data.id = id;
            updateItem(data);
            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const ListGetCreditorAccount = async () => {
        let result = await GetCreditorAccount();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.description
            }));
            setListCreditorAccount(resultList);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        GetCreditorSubAccount();
        ListGetCreditorAccount();
    }, [])
    return (
        <form onSubmit={handleSubmit(update)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <OmniCard title='Datos generales'>
                {CreditorSubAccount.length !== 0 &&
                    (<>
                    <OmniTextBox required title="Descripción" register={{ ...register("description") }} focus value={CreditorSubAccount.description} />
                    <OmniSelect required title='Rubro' register={{ ...register('idCreditorAccount') }} items={listCreditorAccount} value={CreditorSubAccount.idCreditorAccount} />
                    </>)}
            </OmniCard>
        </form>
    )
}