import { Switch, Image } from '@fluentui/react-components';
import '../../../../Assets/Styles/Shared/Controls/OmniSwitch.css';
import { useRef, useEffect, useState } from 'react';
import { useFieldChangeWatcherContext } from '../../../../Context/Controls/FieldChangeWatcherProvider'

export const OmniSwitch = (props) => {
    const fieldEdited = useRef(false);
    //const initialFieldValue = props?.checked ? props?.checked : false;
    //const [chekedValue, setChekedValue] = useState(initialFieldValue);
    const formControlId = props.label;
    const fieldWatcher = useFieldChangeWatcherContext();
    const OnChekedhancheHandler = (event, data) => {
        props.onChange && props.onChange(event, data.checked);
        //setChekedValue(data.checked);
        if (props?.checked === data.checked) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
        if (props.itemList !== undefined && props.propertyId && props.edit) {
            props.edit(prevEdit => {
                const newState = [...prevEdit[props.propertyId]];
                newState[props.itemList] = data.checked;
                return {
                    ...prevEdit,
                    [props.propertyId]: newState
                };
            });            
        }
        else if (props.propertyId && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.propertyId]: data.checked }));
        }
    };

    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === formControlId) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }

    }, [fieldEdited.current]);


    return (
        <>
            <div className={props.start?'': 'omni-switch-end ' +'omniinfolabel-container-top-switch'}>
                 {fieldEdited.current === true &&
                    <div className='omniinfolabel-icon-switch'>
                        <Image alt="" src={require('../../../../Assets/Images/Icons/Edit.svg').default} />
                    </div>

                }
                <Switch
                    onChange={OnChekedhancheHandler}
                    {...props.onBlur}
                    {...props.selected}
                    checked={props?.checked}
                    label={props.label}
                    className='switch-omni'
                />
               
            </div>
        </>
    );
}