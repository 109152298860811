import { useState, useEffect } from 'react';
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Configurations/SatCatalogs/Edit";
import { CatalogListEdit } from './CatalogListEdit';
import { CatalogListSearch } from './CatalogListSearch';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import {
    UpdateStatusItemServiceAsync, UpdateStatusMeasureUnitAsync, UpdateStatusPaymentMethodsAsync,
    UpdateStatusTypesRelationshipAsync, UpdateStatusTaxRegimesAsync, UpdateStatusUsesCFDIAsync,UpdateStatusContriesAsync
} from '../../../Functions/Configuration/SatCatalogs/SatCatalogStore';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
export const Edit = (props) => {
    const [itemUpdate, setItemUpdate] = useState([]);
    const [openCallout, setOpenCallout] = useState();
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtons: leftButtons(props.type.titleBarButtons),
            rightButtons: rightButtons(Save, Return)
        }));
    }, [])

    const Save = async () => {
        if (itemUpdate.filter(x => x.isChange).length === 0) {
            props.setView((prevView) => ({
                ...prevView,
                viewDetail: true,
                viewEdit: false
            }));
        }
        else {
            let result = [];
            let listUpdate = itemUpdate.filter(x => x.isChange);
            switch (props.type.type) {
                case "ItemService":
                    result = await UpdateStatusItemServiceAsync(listUpdate);
                    break;
                case "MeasureUnit":
                    result = await UpdateStatusMeasureUnitAsync(listUpdate);
                    break;
                case "PaymentMethods":
                    result = await UpdateStatusPaymentMethodsAsync(listUpdate);
                    break;
                case "TypesRelationship":
                    result = await UpdateStatusTypesRelationshipAsync(listUpdate);
                    break;
                case "TaxRegimes":
                    result = await UpdateStatusTaxRegimesAsync(listUpdate);
                    break;
                case "UsesCFDI":
                    result = await UpdateStatusUsesCFDIAsync(listUpdate);
                    break;
                case "Contries":
                    result = await UpdateStatusContriesAsync(listUpdate);
                    break;
                default:
                    break;
            }
            if (result.isSuccessful) {
                props.setCatalog((prev) => prev.map((item) => {
                    let itemFind = listUpdate.find(x => x.id === item.id);
                    if (itemFind) {
                        return { ...item, enabledInAlix: itemFind.enabledInAlix};
                    }
                    return item;
                }));
                props.setView((prevView) => ({
                    ...prevView,
                    viewDetail: true,
                    viewEdit: false
                }));
                props.setMessages([new SuccesMessage(result.successMessage)]);
            }
            else {
                props.setMessages([new DangerMessage(result.errorMessage)]);
            }
        }
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtons: leftButtons(props.type.titleBarButtons),
            rightButtons: rightButtons(Save, Return)
        }));
    }, [itemUpdate])
    function Return() {
        if (itemUpdate.length > 0) {
            if (itemUpdate.some(x => x.isChange)) {
                setOpenCallout(true);
            }
            else { ReturnOk(); }
        }
        else {
            ReturnOk();
        }

    }
    const ReturnConfirm = () => {
        ReturnOk();
    }
    const ReturnOk = () => {
        props.setView((prevView) => ({
            ...prevView,
            viewDetail: true,
            viewEdit: false
        }));
        setOpenCallout(false);
    }
    return (
        <>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <CatalogListSearch type={props.type} catalog={props.catalog} setCatalog={props.setCatalog}
                        itemUpdate={itemUpdate} setItemUpdate={setItemUpdate} setMessages={props.setMessages}/>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <CatalogListEdit type={props.type} catalog={props.catalog.filter((item) => item.enabledInAlix)} setCatalog={props.setCatalog}
                        itemUpdate={itemUpdate} setItemUpdate={setItemUpdate} setMessages={props.setMessages}/>

                </div>
            </div>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={ReturnConfirm}
                    orientation={5}
                    title='Confirmación'
                    content={"¿Desea salir sin guardar los cambios?"}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--light-hover)'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'var(--light-hover)'}
                    colorRight={'var(--dark)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    buttonId={"BtnRegresar"}
                    barGroupKey={'rightCommandBar'}
                />

            }
        </>
    )
}