import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Categories/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, GetItemsByCategoryId } from '../../../Functions/Catalogs/Categories/CategoriesStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { ItemsTable } from '../../Shared/ItemsTable/ItemsTable';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton } = useGeneralStatesContext();
    const [category, setCategory] = useState({});
    const [changestatus, setChangestatus] = useState();
    const [buttonsCategory, setButtonsCategory] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [categoryItems, setCategoryItems]=useState();
    const [actualPage, setActualPage]=useState(1);

    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setCategory({
                id: result.data.id,
                key:result.data.key,
                name: result.data.name,
                items: result.data.items,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
            setCategoryItems(result.data.items)
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !category.active);
        if (result.isSuccessful) {
            setCategory((prevCategory) => {
                return {
                    ...prevCategory,
                    active: !category.active,
                };
            });
            setButtonsCategory(prevCategory => ({
                ...prevCategory,
                leftButtons: ButtonActive(!category.active),
                rightButtons: rightButtons
            }));
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive(active) {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = active ? "Inactivar" : "Activar";
                let statusiconbutton = active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = active ? 24 : 201;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la categoria?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !active,
                };
            }
            return button;
        });
        return updatedButtons;
    }

    const onScrollFunction = event => {
        var top=event.currentTarget.scrollTop;
        var max=350 * 11.5 * parseInt( actualPage);
        
        if(parseFloat(top)>=parseFloat(max)){
            getNewItems();
        }
    }

    const getNewItems = async()=>{
        var page=actualPage;
        page=page+1;
        var items=await GetItemsByCategoryId(id, page);
        setActualPage(page++);
        var actualItems=categoryItems;
        items.data.map((item)=>{
            categoryItems.push(item);
        });
        setCategoryItems(actualItems);
    }

    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);

    useEffect(() => {
        if (category) {
            setButtonsCategory(prevCategory => ({
                ...prevCategory,
                leftButtons: ButtonActive(category.active),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...category, updateUser: localStorage.getItem("userName") });
        }
    }, [category?.active]);

    useEffect(() => {
        if (category) {
            setButtonsCategory(prevCategory => ({
                ...prevCategory,
                leftButtons: ButtonActive(category.active),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);
    return (
        <>
            {category.length !== 0 &&
                (<BarButtons rightButtons={buttonsCategory.rightButtons}
                    leftButtons={buttonsCategory.leftButtons} />)
            }
            <div className='container-fluid container-scroll'>
                <div className='row'>
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Clave' value={category.key} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Nombre' value={category.name} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                    </PivotItem>
                    <PivotItem headerText="Artículos" itemKey="items">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Artículos'>
                                    <div id='divItemsContainer' className='row card-items-container' onScroll={onScrollFunction}>
                                        <div className='col-lg-12 col-md-12'>
                                         <ItemsTable titleheader='la categoría' items={categoryItems}/>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                    </PivotItem>
                        <PivotItem headerText="Cambios" itemKey="changes">
                            <DetailChanges user={category} />
                    </PivotItem>
                </Pivot>
                </div>
            </div>
        </>
    )
}