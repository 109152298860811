import { Label, Tooltip } from "@fluentui/react-components";
import { ReactComponent as XmlFile } from '../../../../Images/Icons/XmlFile.svg';
import { ReactComponent as ContractCuvitek } from '../../../../Images/Icons/Contract.svg';
import { ReactComponent as ExpandCuvitek } from '../../../../Images/Icons/Expand.svg';
import { ReactComponent as Status } from '../../../../Images/Icons/Status.svg';
import { ReactComponent as SupplierWithoutFill } from '../../../../Images/Icons/SupplierWithoutFill.svg';
export const leftButtons = () => [{
    key: 'LblTitle',
    text: <div className='row'>
        <div className='col-lg-12 col-md-12'>
            <XmlFile className="color-info-fill"/>
            <span className="home-header-text-button">
                Archivos XML
            </span>
        </div>
    </div>,
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickTrigger, handleClickNewQuery, lastDate, contract, handleClickContract, daysDocument) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar Reporte' : '',
    iconName: 'gridCuvitek',
    iconColor: daysDocument === -1 ? 'var(--secondary)':'#fff',
    iconHover: daysDocument === -1 ? 'var(--secondary)' : '#fff',   
    handler: handleClickTrigger,
    className: daysDocument === -1 ? '' : 'BtnTrigger',
    disabled: daysDocument === -1 ? true : false
}, 
    {
        key: 'BtnExcel',
        text: contract ? 'Exportar a Excel':'',
        iconName: 'excelDownloadCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
    {
        key: 'BtnDownload',
        text: contract ? 'Descargar':'',
        iconName: 'downloadCloudCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickNewQuery
    },
    {
        key: 'BtnContract',
        text:
            <div className='mt-1 contract-left contract-right'>
                {contract ? <ContractCuvitek /> : <ExpandCuvitek /> }
            </div>,
        handler: handleClickContract
    },
    {
        key: 'BtnCalendarSelectDay',
        text: lastDate,
        iconName: 'calendarSelectDayCuvitek',
    }
]
export const rightButtonsDisabled = (handleClickTrigger, lastDate, contract, handleClickContract) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar Reporte' : '',
    iconName: 'gridCuvitek',
    iconColor: '#fff',
    iconHover: '#fff',
    handler: handleClickTrigger,
    className: 'BtnTrigger'
}, 
    {
        key: 'BtnExcel',
        text: contract ? 'Exportar a Excel' : '',
        iconName: 'excelDownloadCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
    {
        key: 'BtnDownload',
        text: contract ? 'Descargar' : '',
        iconName: 'downloadCloudCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
    },
    {
        key: 'BtnContract',
        text:
            <div className='mt-1 contract-left contract-right'>
                {contract ? <ContractCuvitek /> : <ExpandCuvitek />}
            </div>,
        handler: handleClickContract
    },
    {
        key: 'BtnCalendarSelectDay',
        text: lastDate,
        iconName: 'calendarSelectDayCuvitek',
    }
]
export const rightButtonsExec = (handleClickExcel, handleClickDownload, handleClickNewQuery, lastDate, contract, handleClickContract) => [{
    key: 'BtnTrigger',
    text: contract ? 'Generar reporte':'',
    iconName: 'gridCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--secondary)',
    disabled: true
}, {
    key: 'BtnExcel',
    text: contract ? 'Exportar a Excel' : '',
    iconName: 'excelDownloadCuvitek',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    handler: handleClickExcel
},
{
    key: 'BtnDownload',
    text: contract ? 'Descargar' : '',
    iconName: 'downloadCloudCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickDownload
},
{
    key: 'BtnNewQuery',
    text: contract ? 'Nueva consulta' : '',
    iconName: 'newQueryCuvitek',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    handler: handleClickNewQuery
    },
    {
        key: 'BtnContract',
        text:
            <div className='mt-1 contract-left contract-right'>
                {contract ? <ContractCuvitek /> : <ExpandCuvitek />}
            </div>,
        handler: handleClickContract
    },
    {
        key: 'BtnCalendarSelectDay',
        text: lastDate,
        iconName: 'calendarSelectDayCuvitek',
    }
]
export const rightButtonsSecond = (handleClickCalendar, handleClickSuppliers, handleClickStatus, textDate, listSuppliers, listStatus) => [
    {
        key: 'BtnStatus',
        text: <Tooltip content={ listStatus?.length === 0 ? "Todos" : <>
            {listStatus.map((item, index) => (
                <div key={index} className="col-12">
                    {item}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-info'>
                    <Status className="icon-info-label" />
                    <Label className='cursor-pointer'>Estatus</Label>
                </div>
            </div>
        </Tooltip>,
        handler: handleClickStatus
    },
    {
        key: 'BtnSuppliers',
        text: <Tooltip content={listSuppliers?.length === 0 ? "Todos" : <>
            {listSuppliers.map((item, index) => (
                <div key={index} className="col-12">
                    {item}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-info'>
                    <SupplierWithoutFill className="icon-info-label" />
                    <Label className='cursor-pointer'>Proveedores</Label>
                </div>
            </div>
        </Tooltip>,
        handler: handleClickSuppliers
    },
    {
        key: 'BtnCalendar',
        text: textDate,
        iconName: 'dateRangeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--info)',
        handler: handleClickCalendar
    }
]
export const rightButtonsSecondDisabled = (textDate, listSuppliers, listStatus) => [
    {
        key: 'BtnStatus',
        text: <Tooltip content={listStatus?.length === 0 ? "Todos" : <>
            {listStatus.map((item, index) => (
                <div key={index} className="col-12">
                    {item}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-disabled'>
                    <Status />
                    <Label className='cursor-not-allowed'>Estatus</Label>
                </div>
            </div>
        </Tooltip>,
        iconName: 'statusCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    },
    {
        key: 'BtnSuppliers',
        text: <Tooltip content={listSuppliers?.length === 0 ? "Todos" : <>
            {listSuppliers.map((item, index) => (
                <div key={index} className="col-12">
                    {item}
                </div>
            ))}
        </>} positioning="below">
            <div className='row'>
                <div className='col-lg-12 col-md-12 btn-icon-disabled'>
                    <Status />
                    <Label className='cursor-not-allowed'>Proveedores</Label>
                </div>
            </div>
        </Tooltip>,
        disabled: true
    },
    {
        key: 'BtnCalendar',
        text: textDate,
        iconName: 'dateRangeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--secondary)',
        disabled: true
    }
]