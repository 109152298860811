import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
export const DomicileDetail = (props) => {
    return (
        <>
            {props?.itemSelected &&
                <>
                <OmniLabel text='Código Postal' value={props?.itemSelected?.postalCode} />
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                        <OmniLabel text='País' value={props?.itemSelected?.contry} />
                        </div>
                        <div className='col-lg-6 col-md-12'>
                        <OmniLabel text='Estado' value={props?.itemSelected?.state} />
                        </div>
                    </div>
                <OmniLabel text='Municipio' value={props?.itemSelected?.municipality} />
                <OmniLabel text='Colonia' value={props?.itemSelected?.colony} />
                <OmniLabel text='Localidad' value={props?.itemSelected?.location} />
                <OmniLabel text='Calle' value={props?.itemSelected?.street} />
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                        <OmniLabel text='Número exterior' value={props?.itemSelected?.outdoorNumber} />
                        </div>
                        <div className='col-lg-6 col-md-12'>
                        <OmniLabel text='Número interior' value={props?.itemSelected?.interiorNumber} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}