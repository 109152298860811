export const rightButtons = (handleClick) => [
    {
        key: 'BtnNew',
        text: 'Nueva',
        iconName: 'addcuvitek',
        fontColor: 'var(--primary)',
        iconColor: 'var(--primary)',
        iconHover: 'var(--primary)',
        backgroundColorButton: '#fff',
        backgroundColorButtonHover: '#fff',
        isButton: true,
        borderColor: 'var(--info) solid 2px',
        handler: handleClick,
        authorizationKey: 3
    }
]