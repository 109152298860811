import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/TransferRoutes/TransferRoute';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatus = '/transferroute/getbystatus';
const getTotalItems='/transferroute/GetSearchTotalItems';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/TransferRoute.svg').default
}
export default function TransferRoute()
{
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de rutas de traspaso"}
                    image={iconCatalog}
                    urlGetItems={getByStatus}
                    urlGetTotalItems={getTotalItems}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"title"}
                    omniAcronym={"acronym"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>   
    )
}