import { Skeleton, SkeletonItem, makeStyles, shorthands } from '@fluentui/react-components';
const useStyles = makeStyles({
    styleSkeleton: {
        alignItems: "left",
        display: "grid",
        paddingTop: "9px",
        paddingTop: "9px",
        position: "relative",
        ...shorthands.gap("10px"),
        gridTemplateColumns: "100%",
    },
});

export const SkeletonOmniCard = () => {
    const styles = useStyles();
    return (
        <Skeleton >
            <div className={styles.styleSkeleton}>
                <SkeletonItem size={12}/>
                <SkeletonItem size={16} />
            </div>
            <div className={styles.styleSkeleton}>
                <SkeletonItem size={12} />
                <SkeletonItem size={16} />
            </div>
        </Skeleton>
    )
}