import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Routes/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, generateObject} from '../../../Functions/Catalogs/Routes/RoutesStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniLabelColor } from '../../Shared/Controls/LabelColor/OmniLabelColor';
import { ClientRouteDays } from './ClientRoute/ClientRouteDays';
import Edit from "./Edit";
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import {
    leftButtonsDetail
} from '../../../Assets/Constants/BarButtons/Catalog/Routes/Editing';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton } = useGeneralStatesContext();
    const [route, setRoute] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [buttonsClientRoutes, setButtonsClientRoutes]
        = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    const [view, setView] = useState({
        mondayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        tuesdayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        wednesdayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        thursdayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        fridayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        saturdayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        sundayList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
    }
    )
    const [changestatus, setChangestatus] = useState();
    const getById = async () => {
        setIsItemDisabled(true);
        setItemSelected(undefined);
        setView({
            mondayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            tuesdayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            wednesdayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            thursdayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            fridayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            saturdayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            sundayList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
        });
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setRoute({
                id: result.data.id,
                name: result.data.name,
                color: result.data.color,
                sellerId: result.data.sellerId,
                seller: result.data.seller,
                mondayName: result.data.mondayName,
                tuesdayName: result.data.tuesdayName,
                wednesdayName: result.data.wednesdayName,
                thursdayName: result.data.thursdayName,
                fridayName: result.data.fridayName,
                saturdayName: result.data.saturdayName,
                sundayName: result.data.sundayName,
                mondayList: generateObject(result.data?.mondayList),
                tuesdayList: generateObject(result.data?.tuesdayList),
                wednesdayList: generateObject(result.data?.wednesdayList),
                thursdayList: generateObject(result.data?.thursdayList),
                fridayList: generateObject(result.data?.fridayList),
                saturdayList: generateObject(result.data?.saturdayList),
                sundayList: generateObject(result.data?.sundayList),
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
            setIsItemDisabled(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !route.active);
        if (result.isSuccessful) {
            setRoute((prevRoute) => {
                return {
                    ...prevRoute,
                    active: !route.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id])
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatusRoute') {
                let statustextbutton = route.active ? "Inactivar" : "Activar";
                let statusiconbutton = route.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = route.active ? 25 : 198;
                let warning = route.active ? "El vendedor quedará disponible" : "Deberá asignar un vendedor";
                const buttonStatus = {
                    ...button,
                    text: statustextbutton,
                    iconName: statusiconbutton,
                    authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la ruta de venta?`,
                    warning: warning,
                };

                if (route.active) {
                    buttonStatus.handler = () => { updateStatus() };
                } else {
                    buttonStatus.handler = <Edit />;
                }
                return {
                    ...buttonStatus
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !route.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        if (route) {
            ChangeButtons();
        }
        if (changestatus) {
            let data = { ...route };
            data.name = route.name + " - " + route.seller;
            if (!data.active) {
                setRoute((prevRoute) => {
                    return {
                        ...prevRoute,
                        sellerId: undefined,
                        seller: undefined
                    };
                });
            }
            updateItemStatus({ ...data, updateUserName: localStorage.getItem("userName") });
        }
    }, [route?.active]);
    useEffect(() => {
        if (route) {
            ChangeButtons();
        }
    }, [changestatus, id]);
    function ChangeButtons() {
        if (selectedKey && (selectedKey === 'fridayList'
            || selectedKey === 'saturdayList'
            || selectedKey === 'sundayList'
            || selectedKey === 'mondayList'
            || selectedKey === 'tuesdayList'
            || selectedKey === 'wednesdayList'
            || selectedKey === 'thursdayList')) {
            setButtonsClientRoutes(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        } else {
            setButtonsClientRoutes(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }
    const pivotItemButtonProps = {
        disabled: isItemDisabled,
        style: { color: isItemDisabled ? 'var(--secondary)' : 'var(--dark)' }
    };
   
    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else{
            return {
                disabled: false,
                style: { color:'var(--dark)' }
            }
        }

    }
    useEffect(() => {
        if (route) {
            //if (selectedKey && (selectedKey === 'information' || selectedKey === 'changes')) {
            //    setButtonsClientRoutes(prevEmail => ({
            //        ...prevEmail,
            //        leftButtons: ButtonActive(),
            //        rightButtons: rightButtons
            //    }));
            //}
            ChangeButtons();
        }
    }, [selectedKey]);
    return (
        <>
            {route &&
                <BarButtons rightButtons={buttonsClientRoutes.rightButtons}
                    leftButtons={buttonsClientRoutes.leftButtons} />
            }
            <Pivot className="pivot-center-client" overflowBehavior="menu"
                styles={pivotStyles} selectedKey={selectedKey} onLinkClick={handleLinkClick}
            >
                <PivotItem headerText="Información" headerButtonProps={pivotItemButtonProps} itemKey="information" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta' value={route?.name} />                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabelColor text='Color asignado' value={route?.color} /> 
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el lunes' value={route?.mondayName} />  
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el martes' value={route?.tuesdayName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el miércoles' value={route?.wednesdayName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el jueves' value={route?.thursdayName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el viernes' value={route?.fridayName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el sádado' value={route?.saturdayName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre de la ruta para el domingo' value={route?.sundayName} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Vendedor de ruta asignado'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre del vendedor de ruta' value={route?.seller} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Lunes" headerButtonProps={BlockPivots("mondayList")} itemKey="mondayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="mondayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.mondayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={1} routeId={id} statusCatalog={route?.active}
                         />
                </PivotItem>
                <PivotItem headerText="Martes" headerButtonProps={BlockPivots("tuesdayList")} itemKey="tuesdayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="tuesdayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.tuesdayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={2} routeId={id} statusCatalog={route?.active} 
                                            />
                </PivotItem>
                <PivotItem headerText="Miércoles" headerButtonProps={BlockPivots("wednesdayList")} itemKey="wednesdayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="wednesdayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.wednesdayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={3} routeId={id} statusCatalog={route?.active} 
                        />
                </PivotItem>
                <PivotItem headerText="Jueves" headerButtonProps={BlockPivots("thursdayList")} itemKey="thursdayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="thursdayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.thursdayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={4} routeId={id} statusCatalog={route?.active} 
                        />
                </PivotItem>
                <PivotItem headerText="Viernes" headerButtonProps={BlockPivots("fridayList")} itemKey="fridayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="fridayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.fridayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={5} routeId={id} statusCatalog={route?.active} 
                         />
                </PivotItem>
                <PivotItem headerText="Sábado" headerButtonProps={BlockPivots("saturdayList")} itemKey="saturdayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="saturdayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.saturdayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={6} routeId={id} statusCatalog={route?.active} 
                         />
                </PivotItem>
                <PivotItem headerText="Domingo" headerButtonProps={BlockPivots("sundayList")} itemKey="sundayList">
                    <ClientRouteDays route={route} setRoute={setRoute} property="sundayList"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClientRoutes}
                        day={route?.sundayName}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} dayId={7} routeId={id} statusCatalog={route?.active} 
                         />
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={pivotItemButtonProps}>
                    <DetailChanges user={route} />
                </PivotItem>
            </Pivot>
        </>


    )
}