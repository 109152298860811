import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';

export const ContactDetail = (props) => {
    const itemSelected = props.description[props.property].find(x => x.id == [props.itemSelected[props.property]]);
    return (
        <>
            {itemSelected &&
                <OmniCard title='Datos del contacto de compras'>
                    <OmniLabel text='Nombre del contacto' value={itemSelected?.name} />
                    <OmniLabel text='Puesto' value={itemSelected?.position} />
                    <OmniLabel text='Correo' value={itemSelected?.email} />
                    <OmniLabel text='Celular' value={itemSelected?.cellPhone} />
                    <OmniLabel text='Se le envía correo con la ODC en PDF' value={itemSelected?.sendPdf ? "Si" : "No"} />
                    <OmniLabel text='Se le envía correo con la ODC en Excel' value={itemSelected?.sendXLS ? "Si" : "No"} />
                    <OmniLabel text='Se le envía correo con información del pago' value={itemSelected?.sendPay ? "Si" : "No"} />
                </OmniCard>
            }
        </>
    )
}