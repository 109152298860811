import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { useState, useEffect } from 'react'; import {
    SetAxiosPrivate, GetByActiveAsync
} from '../../../Functions/Catalogs/Suppliers/SupplierStore';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { InfoMessage } from '../../../Models/Shared/BarMessages/Messages';
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerSupplierItems = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [supplierList, setSupplierList] = useState([]);
    const [messages, setMessages] = useState([]);
    const onChangeSupplierTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            setSupplierList((prev) => {
                const updated = prev.map((item) => {
                    if (item.name.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: false
                        };
                    }
                });
                return updated;
            });
        }
        else {
            onCleanTextSupplier();
        }
    };
    const onChangeCheckBoxSupplier = (event, data) => {
        if (data.checked) {
            setSupplierList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            setSupplierList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const addSupplier = () => {
        if (props.param.supplierType === "2") {
            if (supplierList.some(x => x.selected === true)) {
                props.setShowAttachSupplierPanel(false);
                onCleanTextSupplier();
                props.setParam((prev) => ({
                    ...prev,
                    supplier: supplierList.filter(x => x.selected === true).map(x => x.id),
                }))
            }
            else {
                setMessages([new InfoMessage("Debe seleccionar un proveedor")]);
            }
        }
        else {
            props.setShowAttachSupplierPanel(false);
            onCleanTextSupplier();
            props.setParam((prev) => ({
                ...prev,
                supplier: undefined,
            }))
        }
        
      
    };
    const itemsRadio = [
        {
            key: "1",
            value: "1",
            text: "Todos",
        },
        {
            key: "2",
            value: "2",
            text: "Los seleccionados",
        }
    ];
    const onChangeType = (event, data) => {
        if (data.value === "2") {
            if (supplierList.length > 0) {
                setSupplierList(supplierList.map((supplier) => ({
                    ...supplier,
                    selected: false,
                    filter: false
                })))
            }
        }
        else {
            setSupplierList(supplierList.map((supplier) => ({
                ...supplier,
                selected: false,
            })))
        }
        props.setParam(prevEdit => ({ ...prevEdit, supplierType: data.value }));
    };
    const onCleanTextSupplier = () => {
        setSupplierList((prev) => {
            const updated = prev.map((item) => {
                return {
                    ...item,
                    filter: false
                };
            });
            return updated;
        });
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachSupplierPanel(false);
        setSupplierList(supplierList.map((supplier) => ({
            ...supplier,
            selected: false,
            filter: false
        })))
        props.setParam(prevEdit => ({ ...prevEdit, supplierType: "1", supplier: undefined }));
    };
    const GetSuppliersByStatusActive = async () => {
        let result = await GetByActiveAsync();
        if (result.isSuccessful) {
            setSupplierList(result.data);
        }
    }
    useEffect(() => {
        GetSuppliersByStatusActive();
    }, [])
    useEffect(() => {
        setMessages([]);
    }, [props.showAttachSupplierPanel])
    return (
        <OverlayDrawer position="end" open={props.showAttachSupplierPanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Filtro de proveedores
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <BarMessageList messages={messages} />
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniRadioGroup name="supplierType"
                            title="Proveedores"
                            orientation="horizontal"
                            items={itemsRadio}
                            default={props.param.supplierType} inactivefieldEdited onChange={onChangeType} />
                    </div>
                </div>
                {props.param.supplierType === "2" &&
                    <div className='row mt-2'>
                        <div className='col-md-6'>
                            <OmniTextBox focus title="Búsqueda de proveedores" maxLength={64} onChange={onChangeSupplierTypeText} inactivefieldEdited />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        supplierList.map((item) => (
                                            item.filter === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxSupplier} id={item.id} key={item.id} name={item.name} propertyChecked={item.selected} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <OmniLabel text='Proveedores seleccionados' />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        supplierList.map((item) => (
                                            item.selected === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxSupplier} id={item.id} key={item.id} propertyChecked={true} />

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addSupplier}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}