import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import { Pivot, PivotItem } from '@fluentui/react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/PreticketGroups/Edit';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Configuration/PreticketGroups/PreticketGroupStore';
export default function Edit() {
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, updateItem, useForeignKey, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext(); 
    const [preticketGroup, setPreticketGroup] = useState([]);
    const [openCallout, setOpenCallout] = useState();
    SetAxiosPrivate(axiosPrivate);

    const submit = async () => {
        setOpenCallout(true);
    };

    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        var data = getValues();
        let result = await UpdateAsync(id, data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, id);
            data.id = id;
            updateItem({ ...data, acronym: localStorage.getItem("userName"), description:data.name });
            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }

    useEffect(() => {
        const fillData = async () => {
            try {
                const data = await getById();
                setPreticketGroup(data);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, []);

    return (
        <>
        <form onSubmit={handleSubmit(submit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            {
                                                preticketGroup.length !== 0 &&
                                                (
                                                    <>
                                                        {
                                                            useForeignKey &&
                                                            <div className='col-lg-12 col-md-12'>
                                                                <OmniLabel text='Clave SAP' value={id} />
                                                            </div>
                                                        }
                                                        <div className='col-lg-12 col-md-12'>
                                                            <OmniTextBox required title="Descripción" register={{ ...register("name") }} focus setValue={setValue}  value={preticketGroup.name} />
                                                        </div>
                                                    </>)}
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText='Carpetas' headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                    </PivotItem>
                    <PivotItem headerText='Lista de artículos'headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                    </PivotItem>
                    <PivotItem headerText='Vista previa'headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
        </form>
         {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={update}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rightCommandBar'}
        />
    }
    </>
    )
}                       