import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Banks/Bank';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AccountConfigurationProvider } from '../../../Context/Account/AccountConfigurationProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatus = '/bank/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Bank.svg').default
}
export default function Bank() {
    return (
        <ItemSelectorProvider>
            <AccountConfigurationProvider>
                <FieldChangeWatcherProvider>
                    <ItemsSelector
                        title={"Catálogo de bancos"}
                        image={iconCatalog}
                        urlGetItems={getByStatus}
                        defaultButtonsRightBar={rightButtons}
                        detailComponent={<Detail />}
                        searchActiveInRender={false}
                        omniKey={"id"}
                        omniValue={"name"}
                        omniAcronym={"updateUserName"}
                        getInLoad={false}
                        indexComponent={<Index />}
                    />
                </FieldChangeWatcherProvider>
            </AccountConfigurationProvider>
        </ItemSelectorProvider>
    )
}