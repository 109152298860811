import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Warehouse/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Configuration/Warehouse/WarehouseStore';
import { GetBranchsAsync, } from '../../../Functions/Branchs/BranchStore';
import Detail from './Detail';
import { Weekdays } from '../../../Assets/Constants/Enums/Weekdays';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { AccountingAccount } from '../../Shared/AccountingAccount/AccountingAccount';
import { AccountingSystemEnum } from '../../../Assets/Constants/Enums/AccountingSystem';
import { removeAccountingFormat } from '../../../Functions/Accounting/AccountingAccount/AccountingAccountStore';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const { useAccounting, accountingSystem } = useAccountingConfigurationContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [warehouseInformation, setWarehouseInformation] = useState([]);
    const [listBranch, setListBranch] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [showErrors, setShowErrors] = useState({
        clave: undefined,
        name: undefined,
    });

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };
    const sortedWeekdays = Weekdays.sort((a, b) => a.number - b.number);
    const getById = async (dataBranchs) => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            if (result.data.cuvikBranchId) {
                result.data.cuvikBranch = dataBranchs.find((x) => x.key === result.data.cuvikBranchId).text;
            }
            result.data.listBools = sortedWeekdays.map(day => {
                const dayInfo = result.data.listDaysReceiveWarehouseResult.find(d => d.day == day.number);
                return dayInfo ? dayInfo.active : false;
            });
            if (accountingSystem != AccountingSystemEnum.MICROSIP)
            {
                result.data.accountingAccount = removeAccountingFormat(result.data.accountingAccount);
                result.data.accountingAccountCostSale = removeAccountingFormat(result.data.accountingAccountCostSale);
                result.data.accountingAccountSale = removeAccountingFormat(result.data.accountingAccountSale);
                result.data.accountingAccountReturnSales = removeAccountingFormat(result.data.accountingAccountReturnSales);
                result.data.accountingAccountPurchases = removeAccountingFormat(result.data.accountingAccountPurchases);
                result.data.accountingAccountReturnPurchases = removeAccountingFormat(result.data.accountingAccountReturnPurchases);
                result.data.accountingAccountDiscountSales = removeAccountingFormat(result.data.accountingAccountDiscountSales);
            }
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const getBranchs = async () => {
        let result = await GetBranchsAsync();
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = warehouseInformation;
            let errorMessages = [];
            if (data) {
                data.maximumBoxes = data.maximumBoxes && data.maximumBoxes !== ''
                    ? data.maximumBoxes.toString().replace(",", "")
                    : data.maximumBoxes;
                if (!data.name) {
                    errorMessages.push("El campo nombre es obligatorio");
                }
                if (!data.cuvikBranchId) {
                    errorMessages.push("El campo sucursal es obligatorio");
                }
                else if (data.cuvikBranchId) {
                    var branch = listBranch.find((x) => x.key == data.cuvikBranchId);
                    data.cuvikBranchKey = branch.brachKey;
                    data.useWarehouse = branch.useWarehouse;
                }
            }
            if (useAccounting) {
                if (!data.accountingAccount) {
                    errorMessages.push("El campo almacén es obligatorio");
                }
                if (!data.accountingAccountCostSale) {
                    errorMessages.push("El campo costo de venta es obligatorio");
                }
                if (!data.accountingAccountSale) {
                    errorMessages.push("El campo ventas es obligatorio");
                }
                if (!data.accountingAccountReturnSales) {
                    errorMessages.push("El campo devolución sobre ventas es obligatorio");
                }
                if (!data.accountingAccountPurchases) {
                    errorMessages.push("El campo compras es obligatorio");
                }
                if (!data.accountingAccountReturnPurchases) {
                    errorMessages.push("El campo devolución de compras es obligatorio");
                }
                if (data.accountingAccount !== data.accountingAccountPurchases) {
                    errorMessages.push("Los campos almacén y compras deben ser iguales");
                }
            }
            if (errorMessages.length > 0) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages.map(x => x).join('. '))]);
            }
            else {
                if (useAccounting) {
                    data.accountingAccount = data.accountingAccount.endsWith(".") ? data.accountingAccount.slice(0, -1) : data.accountingAccount;
                    data.accountingAccountCostSale = data.accountingAccountCostSale.endsWith(".") ? data.accountingAccountCostSale.slice(0, -1) : data.accountingAccountCostSale;
                    data.accountingAccountSale = data.accountingAccountSale.endsWith(".") ? data.accountingAccountSale.slice(0, -1) : data.accountingAccountSale;
                    data.accountingAccountDiscountSales = data.accountingAccountDiscountSales ? data.accountingAccountDiscountSales.endsWith(".") ? data.accountingAccountDiscountSales.slice(0, -1) : data.accountingAccountDiscountSales : null;
                    data.accountingAccountReturnSales = data.accountingAccountReturnSales.endsWith(".") ? data.accountingAccountReturnSales.slice(0, -1) : data.accountingAccountReturnSales;
                    data.accountingAccountPurchases = data.accountingAccountPurchases.endsWith(".") ? data.accountingAccountPurchases.slice(0, -1) : data.accountingAccountPurchases;
                    data.accountingAccountReturnPurchases = data.accountingAccountReturnPurchases.endsWith(".") ? data.accountingAccountReturnPurchases.slice(0, -1) : data.accountingAccountReturnPurchases;
                }
                data.listDaysReceiveWarehouseResult = data.listBools;
                let result = await UpdateAsync(id, data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, id);
                    data.id = id;
                    updateItem({ ...data, acronym: localStorage.getItem("userName") });
                    setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    useEffect(() => {
        const fillData = async () => {
            try {
                const dataBranchs = await getBranchs();
                const dataWarehouse = await getById(dataBranchs);
                setListBranch(dataBranchs);
                setWarehouseInformation(dataWarehouse);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
        if (useAccounting) {
            fieldWatcher.AddRequiredControls('Almacén', true);
            fieldWatcher.AddRequiredControls('Costo de venta', true);
            fieldWatcher.AddRequiredControls('Ventas', true);
            fieldWatcher.AddRequiredControls('Devolución sobre ventas', true);
            fieldWatcher.AddRequiredControls('Compras', true);
            fieldWatcher.AddRequiredControls('Devolución de compras', true);
        }
    }, [])

    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    const onBlurName = (event) => {
        ValidText(event, "name", "nombre");
    };
    const onBlurAccountingAccount = (event) => {
        ValidText(event, "accountingAccount", "almacén");
    }
    const onBlurAccountingAccountCostSale = (event) => {
        ValidText(event, "accountingAccountCostSale", "costo de venta");
    }
    const onBlurAccountingAccountSale = (event) => {
        ValidText(event, "accountingAccountSale", "ventas");
    }
    const onBlurAccountingAccountReturnSales = (event) => {
        ValidText(event, "accountingAccountReturnSales", "devolución sobre ventas");
    }
    const onBlurAccountingAccountPurchases = (event) => {
        ValidText(event, "accountingAccountPurchases", "compras");
    }
    const onBlurAccountingAccountReturnPurchases = (event) => {
        ValidText(event, "accountingAccountReturnPurchases", "devolución de compras");
    }
    function ValidText(event, property, fild) {
        let text = event.target.value.trim();
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }
    useEffect(() => {
        if (warehouseInformation) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                {
                    <Pivot className="pivot-center-client"
                        selectedKey={selectedKey}
                        onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                        overflowBehavior="menu" styles={pivotStyles}
                    >
                        <PivotItem headerText="Información" itemKey="information" >
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            {warehouseInformation.length !== 0 && (<>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniLabel text='Clave' value={warehouseInformation.warehouseKey} />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            requiredOmni
                                                            defaultValue
                                                            width="85%"
                                                            title='Nombre'
                                                            maxLength={64}
                                                            property="name"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.name}
                                                            blur={onBlurName}
                                                            validationMessage={showErrors.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniCheckBox
                                                            title='Este almacén es bodega'
                                                            propertyId="isWarehouse"
                                                            edit={setWarehouseInformation}
                                                            propertyChecked={warehouseInformation?.isWarehouse}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        {listBranch ?
                                                            <OmniDropdown
                                                                requiredOmni
                                                                defaultValue
                                                                title='Sucursal'
                                                                items={listBranch}
                                                                property="cuvikBranchId" name="cuvikBranch"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.cuvikBranch}
                                                                selected={warehouseInformation?.cuvikBranchId}
                                                                validationMessage={showErrors.cuvikBranchId}
                                                            />
                                                            :
                                                            <OmniDropdown
                                                                requiredOmni
                                                                defaultValue
                                                                disabled
                                                                title='Sucursal'
                                                                items={null}
                                                                validationMessage={showErrors.cuvikBranchId} />
                                                        }
                                                    </div>
                                                </div>
                                            </>)}
                                        </OmniCard>
                                        {/*<OmniCard title='Resurtir almacén'>*/}
                                        {/*    {warehouseInformation.length !== 0 && (<>*/}
                                        {/*    <div className='row'>*/}
                                        {/*        <div className='col-lg-12 col-md-12'>*/}
                                        {/*            <OmniCheckBox title='Se resurten todos los artículos de este almacén' register={{ ...register('restockWarehouse') }}*/}
                                        {/*                propertyChecked={warehouseInformation.restockWarehouse} />*/}
                                        {/*        </div>*/}
                                        {/*        </div>*/}
                                        {/*    </>)}*/}
                                        {/*</OmniCard>*/}
                                    </div>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Capacidad de recibo'>
                                            {warehouseInformation.length !== 0 && (<>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Recepciones por día'
                                                            property="dailyReceptions"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.dailyReceptions.toString()}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Toneladas por día'
                                                            property="maximumTons"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.maximumTons.toString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Volúmen por día'
                                                            property="maximumVolume"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.maximumVolume.toString()}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="8"
                                                            width="85%"
                                                            title='Cajas por día'
                                                            property="maximumBoxes"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.maximumBoxes.toString()}
                                                        />
                                                    </div>
                                                </div>
                                            </>)}
                                        </OmniCard>
                                        <OmniCard title='Resurtido'>
                                            {warehouseInformation.length !== 0 && (<>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Días de inventario'
                                                            property="inventoryDays"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.inventoryDays.toString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    {
                                                        Weekdays.map((day) => (
                                                            <div className='col-lg-4 col-md-12'>
                                                                <OmniSwitch
                                                                    label={day.name}
                                                                    propertyId="listBools"
                                                                    edit={setWarehouseInformation}
                                                                    itemList={day.number}
                                                                    checked={warehouseInformation.listBools[day.number]}
                                                                />
                                                            </div>
                                                        ))}
                                                </div>
                                            </>)}
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        {
                            useAccounting &&
                            <PivotItem headerText='Contabilidad' itemKey="contabilidad">
                                    <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                            <OmniCard title='Cuentas contables'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <AccountingAccount
                                                            focus
                                                            requiredOmni
                                                            defaultValue
                                                            title='Almacén'
                                                            property="accountingAccount"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccount}
                                                            blur={onBlurAccountingAccount}
                                                            validationMessage={showErrors.accountingAccount}
                                                        />
                                                        <AccountingAccount
                                                            requiredOmni
                                                            defaultValue
                                                            title='Costo de venta'
                                                            property="accountingAccountCostSale"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccountCostSale}
                                                            blur={onBlurAccountingAccountCostSale}
                                                            validationMessage={showErrors.accountingAccountCostSale}
                                                        />
                                                        <AccountingAccount
                                                            requiredOmni
                                                            defaultValue
                                                            title='Ventas'
                                                            property="accountingAccountSale"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccountSale}
                                                            blur={onBlurAccountingAccountSale}
                                                            validationMessage={showErrors.accountingAccountSale}
                                                        />
                                                        <AccountingAccount
                                                            title='Descuentos sobre ventas'
                                                            property="accountingAccountDiscountSales"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccountDiscountSales}
                                                            validationMessage={showErrors.accountingAccountDiscountSales}
                                                        />
                                                        <AccountingAccount
                                                            requiredOmni
                                                            defaultValue
                                                            title='Devolución sobre ventas'
                                                            property="accountingAccountReturnSales"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccountReturnSales}
                                                            blur={onBlurAccountingAccountReturnSales}
                                                            validationMessage={showErrors.accountingAccountReturnSales}
                                                        />
                                                         <AccountingAccount
                                                            requiredOmni
                                                            defaultValue
                                                            title='Compras'
                                                            property="accountingAccountPurchases"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccountPurchases}
                                                            blur={onBlurAccountingAccountPurchases}
                                                            validationMessage={showErrors.accountingAccountPurchases}
                                                        />
                                                         <AccountingAccount
                                                            requiredOmni
                                                            defaultValue
                                                            title='Devolución de compras'
                                                            property="accountingAccountReturnPurchases"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccountReturnPurchases}
                                                            blur={onBlurAccountingAccountReturnPurchases}
                                                            validationMessage={showErrors.accountingAccountReturnPurchases}
                                                        />
                                                </div>
                                            </OmniCard>
                                        </div>
                                    </div>
                                </div>
                            </PivotItem>
                        }
                        <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                        </PivotItem>
                    </Pivot>
                }
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }</>
    );
}