import { Stack } from '@fluentui/react/lib/Stack';
import {Text} from '@fluentui/react/lib/Text';
import { Persona, PersonaSize, PersonaPresence } from "@fluentui/react/lib/Persona"
import useAuth from "../../../Hooks/Authentication/useAuth"

const profileStackTokens = {
    childrenGap: '10px',
  };

const personaProps= {
    /*
    imageUrl:
        'https://res-1.cdn.office.net/files/fabric-cdn-prod_20221209.001/office-ui-fabric-react-assets/persona-female.png',
        */
};

export default function Profile() {
    const { auth } = useAuth();
    return(
        <Stack 
        horizontal 
        tokens={profileStackTokens}
        verticalAlign='center'
        horizontalAlign='end'
        >
            <Stack.Item>
                <Text
                className='header-text'>
                    {localStorage.getItem("fullName")}
                </Text>
            </Stack.Item>
            <Stack.Item>
                <Persona
                    {...personaProps}
                    size={PersonaSize.size24}
                    hidePersonaDetails
                    presence={PersonaPresence.online}
                />
            </Stack.Item>
        </Stack>
    );
}