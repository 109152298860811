import { OmniCallout } from '../../../Shared/Callout/OmniCallout';
import BarButtons from '../../../Shared/BarButtons/BarButtons';
import { ContactList } from './ContactList';
import { Contact } from './Contact';
import { ContactDetail } from './ContactDetail';
import { useState, useEffect } from 'react';
import { rightButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Suppliers/Contacts/Contact";
import { leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Suppliers/Contacts/Detail";
import {
    leftButtonsEdit as editingButton,
    leftButtonsDetail
} from '../../../../Assets/Constants/BarButtons/Catalog/Suppliers/Editing';
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { UpdateStatusContactsAsync } from '../../../../Functions/Catalogs/Suppliers/SupplierStore';

export const CatalogContacts = (props) => {
    const [openCalloutSupplier, setOpenCalloutSupplier] = useState();
    const [statusMessage, setStatusMessage] = useState();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const AddContact = () => {
        props.setIsItemDisabled(true);
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: true,
                viewDetail: false,
                viewEdit: false
            }
        }));
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: {}
        }));
    }
    function EditContact() {
        props.setIsItemDisabled(true);
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: true,
                viewDetail: false,
                viewEdit: true
            }
        }));
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: {}
        }));
    }
    function DeleteContact() {
        setButtonsSupplierChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnStatus"
                    ? { ...button, iconColor: 'var(--danger)' }
                    : button
            )
        }));
        setOpenCalloutSupplier(true);
    }
    const [buttonsSupplierChild, setButtonsSupplierChild]
        = useState({
            leftButtonsChild: leftButtons(EditContact, DeleteContact),
            rightButtonsChild: rightButtons(AddContact)
        });

    useEffect(() => {
        if (props?.supplier && props?.property) {
            if (props?.supplier[props?.property]?.length > 0) {
                let showItem = props.itemSelected === undefined ? true : false;
                let itemShow = props.itemSelected !== undefined
                    ? props.itemSelected[props.property] === undefined ? true : false : true;
                if (showItem || itemShow) {
                    props.setItemSelected((prevSupplier) => ({
                        ...prevSupplier,
                        [props.property]: props?.supplier[props?.property][0].id
                    }));
                    ChangeActive(props?.supplier[props?.property][0]);
                    if (props.setView) {
                        props.setView((prevView) => ({
                            ...prevView,
                            [props.property]: {
                                viewAdd: false,
                                viewDetail: true,
                                viewEdit: false
                            }
                        }));
                    }
                }
            }
            else {
                setButtonsSupplierChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: {},
                    rightButtonsChild: rightButtons(AddContact).map(button => {
                        if (button.key === "BtnNew") {
                            return { ...button, disabled: !props.supplier.active };
                        }
                        else {
                            return button;
                        }
                    })
                }));
                props.setIsItemDisabled(false);
            }
        }
    }, [props.supplier]);
    useEffect(() => {
        if (props.view[props.property].viewDetail) {
            if (props.itemSelected && props.itemSelected[props.property] !== undefined) {
                let itemCurrent = props.supplier[props.property].find(x => x.id == [props.itemSelected[props.property]])
                if (itemCurrent) {
                    ChangeActive(itemCurrent);
                }
            }
            else {
                setButtonsSupplierChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: [],
                    rightButtonsChild: rightButtons(AddContact)
                }));
            }
            props.setIsItemDisabled(false);

            props.setbuttonsCatalog(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        }
    }, [props.view[props.property]]);
    const AllItemsOk = async () => {
        if (props?.itemSelected && props.itemSelected[props?.property]) {
            const updatedClient = [...props.supplier[props?.property]];
            let itemCurrent = updatedClient.find(x => x.id == [props.itemSelected[props.property]]);
            let result = await UpdateStatusContactsAsync(itemCurrent.id, !itemCurrent.active);
            if (result.isSuccessful) {
                updatedClient.find(x => x.id == [props.itemSelected[props.property]]).active = !itemCurrent.active;
                props.setSupplier((prevRoute) => {
                    return {
                        ...prevRoute,
                        [props.property]: updatedClient,
                    };
                });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                ChangeActive(itemCurrent);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
            setButtonsSupplierChild(prevState => ({
                ...prevState,
                leftButtonsChild: prevState.leftButtonsChild.map(button =>
                    button.key === "BtnStatus"
                        ? { ...button, iconColor: 'var(--secondary)' }
                        : button
                )
            }));
        }
        setOpenCalloutSupplier(false);
    }
    const AllItemsNo = async () => {
        setButtonsSupplierChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnStatus"
                    ? { ...button, iconColor: 'var(--secondary)' }
                    : button
            )
        }));
        setOpenCalloutSupplier(false);
    }
    useEffect(() => {
        if (props?.itemSelected && props?.property) {
            let itemCurrent = props.supplier[props.property].find(x => x.id == [props.itemSelected[props.property]])
            if (itemCurrent) {
                ChangeActive(itemCurrent);
            }
        }
    }, [props?.itemSelected && props?.itemSelected[props?.property]]);
    function ChangeActive(itemCurrent) {
        if (itemCurrent?.active) {
            setStatusMessage("¿Desea inactivar este contacto?");
        }
        else {
            setStatusMessage("¿Desea activar este contacto?");
        }
        let statustextbutton = itemCurrent.active ? "Inactivar" : "Activar";
        let statusiconbutton = itemCurrent.active ? "inactiveCuvitek" : "activeCuvitek";
        setButtonsSupplierChild(prevState => ({
            ...prevState,
            leftButtonsChild: leftButtons(EditContact, DeleteContact).map(button => {
                if (button.key === "BtnStatus") {
                    return { ...button, text: statustextbutton, iconName: statusiconbutton, disabled: !props.supplier.active };
                } else if (button.key === "BtnEdit") {
                    return { ...button, disabled: (!itemCurrent.active || !props.supplier.active) };
                } else {
                    return button;
                }
            }),
            rightButtonsChild: rightButtons(AddContact).map(button => {
                if (button.key === "BtnNew") {
                    return { ...button, disabled: !props.supplier.active };
                }
                else {
                    return button;
                }
            })
        }));
    }
    return (
        <>
            <BarButtons rightButtons={buttonsSupplierChild.rightButtonsChild}
                leftButtons={buttonsSupplierChild.leftButtonsChild} />
            <div className='container-fluid container-scroll-three-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <ContactList description={props.supplier}
                            setSelected={props.setItemSelected}
                            itemSelected={props.itemSelected}
                            setView={props.setView}
                            property={props.property} />
                    </div>
                    <div className='col-md-auto'>
                        {props.view[props.property].viewAdd &&
                            <Contact description={props.supplier}
                                setDescription={props.setSupplier}
                                setSelected={props.setItemSelected}
                                itemSelected={props.itemSelected}
                                view={props.view}
                                setView={props.setView}
                                setButtons={setButtonsSupplierChild}
                                property={props.property}
                            />
                        }
                        {((props.itemSelected !== undefined && props.itemSelected[props.property] !== undefined) && props.view[props.property].viewDetail) &&
                            <ContactDetail description={props.supplier}
                                itemSelected={props.itemSelected} property={props.property} />
                        }
                    </div>
                </div>
            </div>
            {
                <OmniCallout
                    open={openCalloutSupplier}
                    updateIsOpenAlert={setOpenCalloutSupplier}
                    leftFunction={AllItemsOk}
                    rightFunction={AllItemsNo}
                    orientation={5}
                    title='Confirmación'
                    content={statusMessage}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'white'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'white'}
                    colorRight={'var(--secondary)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    classNameLeft={'callOutButtonLeft'}
                    classNameRight={'callOutButtonRight'}
                    buttonId={"BtnStatus"}
                    barGroupKey={'leftCommandBar'}
                />
            }
        </>
    )

}