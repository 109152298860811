import Add from '../../../../../Components/Catalogs/IepsTax/Add';
import IepsAuthorizations from '../../../Authorizations/Ieps';
export const rightButtons = [
    {
        key: 'BtnNew',
        text: 'Nuevo',
        iconName: 'addcuvitek',
        fontColor: '#FFFFFF',
        iconColor: '#fff',
        iconHover: '#fff',
        backgroundColorButton: 'var(--primary)',
        backgroundColorButtonHover: 'var(--primary)',
        isButton: true,
        component: <Add />,
        authorizationKey: IepsAuthorizations.New,
    }
]