import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import { Pivot, PivotItem } from '@fluentui/react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Brand/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Brand/BrandStore';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, updateItem, useForeignKey, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext(); 
    const [brand, setBrand] = useState();
    const [openCallout, setOpenCallout] = useState();
    const [showErrors, setShowErrors] = useState({
        discount: undefined
    });
    SetAxiosPrivate(axiosPrivate);

    const submit = async () => {
        setOpenCallout(true);
    };

    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        var data = getValues();
        if (brand.useDiscount) {
            if (brand.discount) {
                let discount = parseFloat(brand.discount.toString().replace("%", ""));
                if (discount <= 30.00 && discount >= 1.00) {
                    let errorMessages = fieldWatcher.ShowRequiredControls();
                    if (errorMessages.length > 0) {
                        setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
                    }
                    else {
                        data.discount = discount;
                        let result = await UpdateAsync(id, data);
                        if (result.isSuccessful) {
                            changeComponent(<Detail />, id);
                            data.id = id;
                            updateItem({ ...data, acronym: localStorage.getItem("userName") });
                            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
                        }
                        else {
                            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
                        }
                    }
                }
                else {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        discount: 'El descuento debe ser entre 1 y 30'
                    }));
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("El descuento debe ser entre 1 y 30")]);
                }
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    discount: 'El descuento es obligatorio'
                }));
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("El descuento es obligatorio")]);
            }
        }
        else {
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, id);
                data.id = id;
                updateItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
            }
        }

    }

    useEffect(() => {
        const fillData = async () => {
            try {
                const dataBrand = await getById();
                setBrand(dataBrand);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, []);
    const onBlurRate = (event) => {
        let input = event.target.value;
        if (input) {
            if (parseFloat(input.replace("%", "")) <= 30.00 && parseFloat(input.replace("%", "")) >= 1.00) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    discount: undefined
                }));
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    discount: 'El descuento debe ser entre 1 y 30'
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                discount: 'El descuento es obligatorio'
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
        <form onSubmit={handleSubmit(submit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            {
                                                brand &&
                                                (
                                                    <>
                                                        {
                                                            useForeignKey &&
                                                            <div className='col-lg-12 col-md-12'>
                                                                <OmniLabel text='Clave SAP' value={id} />
                                                            </div>
                                                        }
                                                        <div className='col-lg-12 col-md-12'>
                                                            <OmniTextBox requiredOmni defaultValue setValue={setValue} title="Descripción" register={{ ...register("name") }} focus value={brand.name} />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-lg-12 col-md-12'>
                                                                <OmniCheckBox title="Usa descuento por caducidad (1% - 30%)"
                                                                    register={{ ...register("useDiscount") }}
                                                                    propertyId="useDiscount"
                                                                    edit={setBrand}
                                                                    propertyChecked={brand.useDiscount} />
                                                            </div>
                                                        </div>
                                                        {brand?.useDiscount === true &&
                                                            <div className='row'>
                                                                <div className='col-lg-12 col-md-12'>
                                                                    <OmniTextBox title="Descuento" property="discount" max={30}
                                                                        edit={setBrand} value={brand?.discount.toString()} blur={onBlurRate}
                                                                        validationMessage={showErrors.discount} suffix="%" inputType={'decimal'}
                                                                        decimals={2} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>)}
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Artículos" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
        </form>
         {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={update}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rightCommandBar'}
        />
    }
    </>
    )
}                       