import { OmniCard } from '../../Shared/Card/OmniCard';
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/CompanyAsset/Add';
import { useForm } from "react-hook-form"
//import '../../../Assets/Styles/Shared/Controls/OmniTextBox.css'
//import '../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from "./Detail";
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
 
const AddUrl = '/companyasset/add';

export default function Add() {
    const { register, handleSubmit } = useForm();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();

    const onSubmit = async (data) => {
        try {
            const response = await axiosPrivate.post(AddUrl,
                JSON.stringify({
                    "description": data.description,
                    "loggedUserName": localStorage.getItem("userName")
                })
            );
            if (response.data.isSuccessful) {
                setListMessageCatalog(message => [...message, new SuccesMessage("El activo se guard� exitosamente")]);
                changeComponent(<Detail />, response.data.id);
            }
            else {
                setListMessageCatalog(message => [...message, new DangerMessage(response.data.errorMessage)]);
            }

        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(message => [...message, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(message => [...message, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    };

    return (
        
            <form onSubmit={handleSubmit(onSubmit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <OmniCard title='Datos generales'>
                <OmniTextBox title="Descripci�n" register={{ ...register("description") }} focus />
                </OmniCard>
            </form>
    );
}
