import Detail from "../../../../../Components/Catalogs/Routes/Detail";
export const leftButtons: Buttons[] = [{
    key: 'LblTitle',
    text: 'Editar ruta de venta',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const rightButtons: Buttons[] = [{
    key: 'BtnGuardar',
    text: 'Guardar',
    iconName: 'saveCuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--success)',
    backgroundColorButtonHover: 'var(--success)',
    isButton: true,
    type: 'submit',
    authorizationKey: 20,
}
    , {
    key: 'BtnRegresar',
    text: 'Regresar',
    iconName: 'returnCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Detail />,
    authorizationKey: 20,
}]