import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCard } from "../../Shared/Card/OmniCard";
export const ExtendedAccountingAccountDetail = (props) => {
    return (
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniCard title='Contabilidad'>
                            <div className='row'>
                                <OmniLabel text='Cuenta contable' value={props.value?.accountingAccount} /> 
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div>
    );
}


