import { Input, Field } from "@fluentui/react-components";
import { MaskedTextField } from "@fluentui/react";
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState } from 'react';
import { useEffect } from "react";
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
import '../../../../Assets/Styles/Shared/Controls/OmniTextBox.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { NumericFormat } from 'react-number-format';

//withoutComas={true} - para colocar numero sin comas - solo con type number
//decimals={2} - para colocar cantidad de decimales - solo con type decimal
//length={10} - cantidad máxima de caracteres



export const OmniTextBox = (props) => {
    const fieldWatcher = useFieldChangeWatcherContext();
    const [valueItem, setvalueItem] = useState(props.value ?? '');
    const initialFieldValue = props?.value?.toString().toUpperCase() ?? '';
    const fieldEdited = useRef(false);
    const [hasPoint, setHasPoint] = useState(false);
    const ConvertToUpperCase = (event) => {
        setFormat(event.target.value);
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(event);
        }
        if (props.property && props.edit) {
            let itemUpperCase = event.target.value;
            if (!props.notuppercase && itemUpperCase) {
                itemUpperCase = itemUpperCase.toUpperCase();
            }
            if (props?.regex) {
                let isValid = props.regex.test(itemUpperCase);
                if (isValid) {
                    props.edit(prevEedit => ({ ...prevEedit, [props.property]: itemUpperCase }));
                }
            }
            else {
                props.edit(prevEedit => ({ ...prevEedit, [props.property]: itemUpperCase }));
            }
        }
    };
    const ChangeCurrency = (event) => {
        let text = event.target.value;
        setvalueItem(text);
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(event);
        }
        if (props.property && props.edit) {
           
            if (props?.regex) {
                let isValid = props.regex.test(text);
                if (isValid) {
                    props.edit(prevEedit => ({ ...prevEedit, [props.property]: text }));
                }
            }
            else {
                props.edit(prevEedit => ({ ...prevEedit, [props.property]: text }));
            }
        }
        if (initialFieldValue === text) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
    };
    const formControlId = props.register?.name;
    const setFormat = (text) => {
        text = text == null ? '' : text;
        let inputType = props.inputType == undefined ? '' : props.inputType;
        switch (inputType.toUpperCase()) {
            case 'DECIMAL':
                var originalValue = valueItem;
                var value = props.mask ? text.replace(props.mask, '') : text;
                value = value.toString().replaceAll(',', '');
                let firstPart = value.split('.')[0];
                let secondPart = value.split('.')[1];
                firstPart = new Intl.NumberFormat().format(firstPart);
                if (value[value.length] == '.') {
                    value = firstPart + '.';
                }
                else if (secondPart == undefined) {
                    value = firstPart;
                    setHasPoint(false);
                }
                else {
                    if (props.decimals === undefined) {
                        value = firstPart + '.' + secondPart.substring(0, 2);
                    }
                    else if (secondPart.length <= props.decimals) {
                        value = firstPart + '.' + secondPart;
                    }
                    else {
                        value = firstPart + '.' + secondPart.substring(0, parseInt(props.decimals));
                    }
                }
                var valueResult = value;
                if (props.length === undefined) {
                    if (props.mask) {
                        setvalueItem(valueResult + props.mask);
                    } else {
                        setvalueItem(valueResult);
                    }
                }
                else if (parseInt(value.replace('.', '').replace(',', '').length) <= parseInt(props.length)) {
                    if (props.mask) {
                        setvalueItem(valueResult + props.mask);
                    } else {
                        setvalueItem(valueResult);
                    }
                }
                else {
                    setvalueItem(originalValue);
                }
                break;
            case 'NUMBER':
                var originalValue = valueItem;
                var value = text;
                value = value.toString().replaceAll(',', '');
                if (props.withoutComas == false) {
                    value = new Intl.NumberFormat().format(value);
                }
                var valueResult = value;
                if (props.length === undefined) {
                    setvalueItem(valueResult);
                }
                else if (value.length <= parseInt(props.length)) {
                    setvalueItem(valueResult);
                }
                else {
                    setvalueItem(originalValue);
                }
                break;
            default:
                let itemUpperCase = text;
                if (!props.notuppercase) {
                    itemUpperCase = itemUpperCase.toString().toUpperCase();
                }
                if (props?.regex) {
                    let isValid = props.regex.test(itemUpperCase);
                    if (isValid) {
                        setvalueItem(itemUpperCase);
                    }
                }
                else {
                    setvalueItem(itemUpperCase);
                }
                if (initialFieldValue === itemUpperCase) {
                    fieldEdited.current = false;
                }
                else {
                    if (!props.inactivefieldEdited) {
                        fieldEdited.current = true;
                    }
                }
                break;
        }
    }

    const keyDown = (event) => {
        let inputType = props.inputType == undefined ? '' : props.inputType;
        if (event.key === 'Enter') {
            if (props.preventDefault === undefined) {
                event.preventDefault();
            }
            else if (props.preventDefault.toUpperCase() === "TRUE") {
                event.preventDefault();
            }
            if (props.cleanOnblur) {
                setvalueItem('');
            }
        }
        else {
            if (inputType.toUpperCase() == 'NUMBER' || inputType.toUpperCase() == 'DECIMAL') {
                let character = event.key;
                if (character !== 'Backspace' && character !== 'Delete' && character !== 'ArrowLeft'
                    && character !== 'ArrowRight' && character !== 'ArrowDown' && character !== 'ArrowUp'
                    && character !== 'Tab' && character !== 'End' && character !== 'Home') {
                    if (isNaN(parseInt(character)) && character !== '.') {
                        event.preventDefault();
                    }
                    else if (character === '.') {
                        if (hasPoint === false) {
                            setHasPoint(true);
                        }
                        else {
                            event.preventDefault();
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (fieldWatcher) {
            if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
                fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                    if (c.id === formControlId) {
                        return { ...c, edited: fieldEdited.current }
                    }
                    return c;
                }));
                fieldWatcher.updateHasPendingChanges();
            }
        }
    }, [fieldEdited.current]);

    //init control on Context
    useEffect(() => {
        if (fieldWatcher) {
            if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
                fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
            }
            fieldWatcher.updateHasPendingChanges();
            if (props.requiredOmni) {
                fieldWatcher.AddRequiredControls(props.title, props?.defaultValue);
            }
        }
    }, []);
    useEffect(() => {
        setvalueItem(props.value ?? '');
        setFormat(props.value);
    }, [props.value]);
    const Blur = (event) => {
        if (props.cleanOnblur) {
            setvalueItem('');
        }
        if (typeof props.blur === 'function') {
            props.blur(event);
        }
        if (props.property && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.property]: event.target.value }));
        }

        if (props.requiredOmni) {
            const textValueRequred = event.target.value;
            if (textValueRequred) {
                fieldWatcher.setRequiredControls(prev => {
                    const updatedControls = prev.filter(control => control.id !== props.title);
                    const updatedControl = { ...prev.find(control => control.id === props.title), visible: false, hasValue: true };
                    return updatedControls.concat(updatedControl);
                });
                if (props.register) {
                    props.setValue(props.register?.name, textValueRequred);
                }
            }
            else {
                fieldWatcher.setRequiredControls(prev => {
                    const updatedControls = prev.filter(control => control.id !== props.title);
                    const updatedControl = { ...prev.find(control => control.id === props.title), visible: true, hasValue: false };
                    return updatedControls.concat(updatedControl);
                });
                event?.target?.focus();

            }
        }
        else {
            if (props.register) {
                props.setValue(props.register?.name, event.target.value);
            }
        }
    };
    const MaxInput = (values) => {
        const { floatValue } = values;
        if (floatValue === undefined) {
            return true;
        }
        if (props.max === undefined) {
            return true;
        }
        return floatValue <= props.max;
    };
    const RenderNumericFormatBlur = () => {
        return <NumericFormat thousandSeparator={props?.thousandSeparator ?? true} decimalScale={props.decimals}
            id={formControlId} autoComplete="off"
            className={`${(fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true || props.validationMessage !== undefined) ? 'textBox-omni-validation textBox-omni-currency' : 'textBox-omni-currency'}`}
            onKeyDown={keyDown}
            onChange={ChangeCurrency} value={valueItem} isAllowed={(values) => {
                return MaxInput(values);
            }} {...props}  {...props.register} autoFocus={props.focus}
            tabIndex={props.tabIndex == undefined ? "" : props.tabIndex} suffix={props?.suffix} onBlur={Blur} />
    };
    return (
        <>
            <div className={'container-controls'} >
                <Field className={"label-omni"} validationState={(fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true || props.validationMessage !== undefined) ? "error" : "none"}
                    validationMessage={fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true ? fieldWatcher?.requiredControls.find(x => x.id === props.title)?.message : props.validationMessage !== undefined ? props.validationMessage : null } >
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>
                    {
                        props.customMask === undefined ?
                                props?.inputType?.toUpperCase() === "DECIMAL"
                                    ? RenderNumericFormatBlur()
                                    : <Input  {...props} style={{ width: props.width }}
                                        autoComplete="off" {...props.register} id={formControlId} value={valueItem}
                                        className={`${(fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true || props.validationMessage !== undefined) ? 'textBox-omni-validation' : 'textBox-omni'} ${props.notuppercase ? '' : 'uppercaseInput'}`}
                                        spellCheck={props.notspellcheck ? false : true}
                                        autoFocus={props.focus} onChange={ConvertToUpperCase}
                                        {...props.contentAfter} title=""
                                        tabIndex={props.tabIndex == undefined ? "" : props.tabIndex}
                                        onKeyDown={keyDown}
                                        onBlur={Blur}
                                    />
                            :
                            <MaskedTextField mask={props.customMask} autoComplete="off" {...props.register} id={formControlId} value={valueItem}
                                className={`${(fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true || props.validationMessage !== undefined) ? 'maskedText-omni textBox-omni-validation' : 'maskedText-omni'} ${props.notuppercase ? '' : 'uppercaseInput'}`}
                                spellCheck={props.notspellcheck ? false : true}
                                autoFocus={props.focus} onChange={ConvertToUpperCase}
                                tabIndex={props.tabIndex == undefined ? "" : props.tabIndex}
                                onKeyDown={keyDown}
                                {...props.contentAfter} title=""
                                maskChar="" onBlur={Blur}
                            />
                    }
                </Field>
            </div>
        </>
    );
}