import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Routes/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AddAsync, SetAxiosPrivate, GetUser } from '../../../Functions/Catalogs/Routes/RoutesStore';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniColorPicker } from '../../Shared/Controls/ColorPicker/OmniColorPicker';
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Routes.svg').default
}
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const {  handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const [userLoad, setUserLoad] = useState();
    const [user, setUser] = useState();
    const [serchClient, setSerchClientr] = useState('');
    const initial = {
        color:"#0F7AFF"
    };
    const [route, setRoute] = useState(initial);
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const submit = async () => {
        setOpenCallout(true);
    };

    const AddConfirm = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = route;
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                data.name = route.name + " - " + route.seller;
                addNewItem({ ...data, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
 
    const onChangeItemUser = async (id, seller) => {
        setRoute(prevEedit => ({
            ...prevEedit,
            sellerId: id,
            seller: seller
        }));
    }
    const OnLoadUser = async () => {
        let result = await GetUser();
        if (result.isSuccessful) {
            const userList = result.data.filter((item) => item.userName != 'ccarrillo').map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name,
                disabled: item.route,
                errorTooltip:"Asignado a: " +item.nameRoute
            }));
            setUserLoad(userList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoadUser();
    }, []);
    const OnChangeUserText = async (text) => {
        if (userLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }
    function ChangeText(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = userLoad.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.text.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                setUser(filteredData);
            }
        }
    }

    useEffect(() => {
        if (userLoad) {
            OnChangeUserText(serchClient)
        }
    }, [userLoad]);
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        seller: undefined
    });
    //const onBlurName = (event) => {
    //    const text = event.target.value;
    //    if (text) {
    //        setShowErrors(prevErrors => ({
    //            ...prevErrors,
    //            name: undefined
    //        }));
    //    }
    //    else {
    //        setShowErrors(prevErrors => ({
    //            ...prevErrors,
    //            name: `El campo es obligatorio`
    //        }));
    //        event?.target?.focus();
    //    }
    //};
    const onShowError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            seller: `El campo es obligatorio`
        }));
    };
    const onCleanError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            seller: undefined
        }));
    };
    const onBlurSeller = (event) => {
        if (route.sellerId) {
            onCleanError();
        }
        else {
            onShowError();
            event?.target?.focus();
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons rightButtons={rightButtons}
                    leftButtons={leftButtons} />
                <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                    >
                    <PivotItem headerText="Información" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox requiredOmni title='Nombre de la ruta'
                                                    focus property="name"
                                                    edit={setRoute}  maxLength={50} validationMessage={showErrors.name}
                                                   />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniColorPicker title='Color asignado'
                                                    property="color"
                                                    edit={setRoute}
                                                    blur={true}
                                                    value={route?.color} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el lunes'
                                                    property="mondayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox  title='Nombre de la ruta para el martes'
                                                    property="tuesdayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox  title='Nombre de la ruta para el miércoles'
                                                    property="wednesdayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox  title='Nombre de la ruta para el jueves'
                                                    property="thursdayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox  title='Nombre de la ruta para el viernes'
                                                    property="fridayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el sábado'
                                                    property="saturdayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el domingo'
                                                    property="sundayName"
                                                    edit={setRoute} blur={true} maxLength={50} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Vendedor de ruta asignado'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCombobox
                                                    title='Nombre del vendedor de ruta'
                                                    items={user}
                                                    selected={route?.sellerId}
                                                    value={route?.seller}
                                                    onChangeItem={onChangeItemUser} requiredOmni showSpinner={showSpinner}
                                                    onChangeItemText={OnChangeUserText} validationMessage={showErrors.seller}
                                                    onShowError={onShowError} onBlur={onBlurSeller} onCleanError={onCleanError}
                                                    validation={true} image={iconCatalog}                                              />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Lunes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Martes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Miércoles" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Jueves" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Viernes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Sábado" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Domingo" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'} 
                />
            }
        </>
    )
}
