import { Field, Combobox, Option } from '@fluentui/react-components';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import '../../../../Assets/Styles/Shared/Controls/OmniCombo.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState, useEffect } from 'react';
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
import { Image } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";
export const OmniCombobox = (props) => {

    const includeDefaultOption = props?.IncludeDefaultOption === undefined ? true : props.IncludeDefaultOption;
    const fieldWatcher = useFieldChangeWatcherContext();
    const formControlId = props?.register?.name;
    const initialFieldValue = GetInitialSelectedOption();
    const [selectedValue, setSelectedValue] = useState(initialFieldValue);
    const fieldEdited = useRef(false);
    const [matchingOptions, setMatchingOptions] = useState();
    const [openSelect, setOpenSelect] = useState(false);
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.value) {
            initialSelectedOpction = props?.value
        }
        else if (includeDefaultOption && includeDefaultOption === true) {
            initialSelectedOpction = "";
        }
        else if (props?.items) {
            initialSelectedOpction = props.items.length > 0 ? props.items[0]?.value : '';
        }
        else {
            initialSelectedOpction = '';
        }
        return initialSelectedOpction;
    };
    const OnSelectChancheHandler = (event) => {
        const text = event.target.value;
        props.onChangeItemText && props.onChangeItemText(text);
        if (props.items) {
            if (text) {
                //const matches = props.items.filter((option) =>
                //    option.text.toLowerCase().includes(text.toLowerCase())
                //);
                const words = text.toLowerCase().split(' ');
                let filteredData = props.items.slice();
                for (const word of words) {
                    if (word) {
                        filteredData = filteredData.filter(item =>
                            item.text.toLowerCase().includes(word)
                        );
                    }
                }
                setMatchingOptions(filteredData);
            }
            if (initialFieldValue == text) {
                fieldEdited.current = false;
            }
            else {
                if (!props.inactivefieldEdited) {
                    fieldEdited.current = true;
                }
            }
        }
            setSelectedValue(text);
    };
    useEffect(() => {
        setMatchingOptions(props.items)
    }, [props.items]);
    //init control on Context
    useEffect(() => {
        if (fieldWatcher) {
            if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
                fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
            }
            fieldWatcher.updateHasPendingChanges();
            if (props.requiredOmni) {
                fieldWatcher.AddRequiredControls(props.title, props?.value ? true :false );
            }
        }
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher) {
            if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
                fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                    if (c.id === formControlId) {
                        return { ...c, edited: fieldEdited.current }
                    }
                    return c;
                }));
                fieldWatcher.updateHasPendingChanges();
            }
        }

    }, [fieldEdited.current]);
    const [selectedOptions, setSelectedOptions] = useState([
        props?.selected?.toString()
    ]);
    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
        if (data.selectedOptions !== undefined && data.optionText !== undefined) {
            setSelectedOptions(data.selectedOptions);
            let textOption = matchingOptions.find(x => x.value == data.optionValue).text;
            setSelectedValue(textOption ?? "");
            props.onChangeItem && props.onChangeItem(data.optionValue, textOption);
            if (initialFieldValue == textOption) {
                fieldEdited.current = false;
            }
            else {
                if (!props.inactivefieldEdited) {
                    fieldEdited.current = true;
                }
            }
            if (props?.onCleanError) {
                if (typeof props.onCleanError === 'function') {
                    props.onCleanError();
                }
            }
            if (props?.cleanOnChangeItem) {
                setSelectedValue('');
            }
        }
        else {
            if (props?.onShowError) {
                if (typeof props.onShowError === 'function') {
                    props.onShowError();
                }
            }
        }

        if (props.requiredOmni) {
            if (data.selectedOptions !== undefined && data.optionText !== undefined) {
                fieldWatcher.setRequiredControls(prev => {
                    const updatedControls = prev.filter(control => control.id !== props.title);
                    const updatedControl = { ...prev.find(control => control.id === props.title), visible: false, hasValue: true };
                    return updatedControls.concat(updatedControl);
                });
                //if (props.register) {
                //    props.setValue(props.register?.name, textValueRequred);
                //}
            }
            else {
                fieldWatcher.setRequiredControls(prev => {
                    const updatedControls = prev.filter(control => control.id !== props.title);
                    const updatedControl = { ...prev.find(control => control.id === props.title), visible: true, hasValue: false };
                    return updatedControls.concat(updatedControl);
                });
                //event?.target?.focus();
            }
        }
        //else {
        //    if (props.register) {
        //        props.setValue(props.register?.name, event.target.value);
        //    }
        //}
    };
    useEffect(() => {
        setSelectedValue(props.value ?? '');
    }, [props.value]);
    useEffect(() => {
        if (props.selected) {
            setSelectedOptions([props.selected.toString()]);
        }
    }, [props.selected]);
    //const onOpenChange = () => {
    //    if (openSelect) {
    //        if (props?.onOpenChange) {
    //            if (typeof props.onOpenChange === 'function') {
    //                props.onOpenChange();
    //            }
    //        }
    //    }
    //    setOpenSelect(openSelect ? false : true);
    //};
    //const onBlur = (event) => {
    //    if (props?.onBlur) {
    //        if (typeof props.onOpenChange === 'function') {
    //            props.onBlur(event);
    //        }
    //    }
    //};


    function highlightText(option, selectedValue) {
        selectedValue = selectedValue.trim();
        function highlightWords(words, text) {
            let spanWords = [];
            var pattern = /[\^*@!"#$%&/()=?¡!¿'\\]/gi;
            let word = words[0].replace(pattern, '');
            if(text!==undefined){
                let arrayWords = text.split(new RegExp(`(${word})`, 'gi'));

                arrayWords.forEach((elementRow, index) => {
                    if (elementRow) {
                        if (elementRow.toLowerCase() === words[0].toLowerCase()) {
                            spanWords.push(
                                <span className={(option.key == selectedOptions[0] ? '' : 'searchOption')}>{elementRow}</span>
                            );
                        } else {
                            if (words.length > 1) {
                                let remainingWords = words.slice(1);
                                spanWords = spanWords.concat(highlightWords(remainingWords, elementRow));
                            } else {
                                spanWords.push(<span>{elementRow}</span>);
                            }
                        }
                    }
                });
            }
            

            return spanWords;
        }
        if (selectedValue.includes(' ')) {
            let words = selectedValue.split(' ');
            return highlightWords(words, option.text);
        }
        else {
            var pattern = /[\^*@!"#$%&/()=?¡!¿'\\]/gi;
            let word = selectedValue.replace(pattern, '');
            return <span >
                { 
                    
                        option.text!==undefined?
                            option.text.split(new RegExp(`(${word})`, 'gi')).map((part, index) => {
                                return part.toLowerCase() === selectedValue.toLowerCase() ?
                                    (option.key == selectedOptions[0] ? (<span key={index}>
                                        {part}
                                    </span>) :
                                        (<span key={index} className="searchOption">
                                            {part}
                                        </span>)
                                    ) : (
                                        <span key={index}>
                                            {part}
                                        </span>
                                    );
                            })
                        :
                    <></>
                }
            </span>

        }
    }
    return (
        <>
            <div className={'container-controls'} style={{ width: props.width }}>
                <Field className={"label-omni"} validationState={(fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true || props.validationMessage !== undefined) ? "error" : "none"}
                    validationMessage={fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true ? fieldWatcher?.requiredControls.find(x => x.id === props.title)?.message : props.validationMessage !== undefined ? props.validationMessage : null} >
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>

                    <Combobox
                        {...props}
                        {...props.register}
                        expandIcon={props?.showSpinner === true && <Spinner size={SpinnerSize.medium} />}
                        placeholder="Seleccione"
                        onChange={OnSelectChancheHandler}
                        appearance="underline"
                        autoFocus={props.focus}
                        className={((fieldWatcher?.requiredControls.find(x => x.id === props.title)?.visible === true || props.validationMessage !== undefined) ? 'combo-omni-validation ' : 'uppercaseInput combo-omni ') + props.className}
                        title="" freeform
                        value={selectedValue}
                        selectedOptions={selectedOptions}
                        onOptionSelect={onOptionSelect}
                        autoComplete="off"
                        listbox={{ className: "listBox-combo" }}
                        disabled={props.disabledItem ?? false}
                    >
                        {props.validation === undefined ?
                            matchingOptions && matchingOptions.map((option) => (
                                <Option key={option.key} value={option.value?.toString()}>

                                    <span >
                                        {
                                            highlightText(option, selectedValue)
                                        }
                                    </span>
                                </Option>
                            ))
                            :
                            matchingOptions && matchingOptions.map((option) => (
                                option.disabled ?
                                    <Tooltip content={option.errorTooltip} positioning="above-start"
                                        withArrow>
                                        <Option key={option.key} value={option.value.toString()}
                                            disabled={option.disabled}
                                            className="option-disabled">
                                            <Image {...props.image} />
                                            <span>
                                                {
                                                    highlightText(option, selectedValue)
                                                }
                                                
                                            </span>
                                        </Option>
                                    </Tooltip>
                                    : <Option key={option.key} value={option.value.toString()}>
                                        < span >
                                            {
                                                
                                                    highlightText(option, selectedValue)
                                            }
                                        </span>
                                    </Option>
                            ))}
                        {matchingOptions && matchingOptions.length === 0 ? (
                            <Option key="no-results" text="">
                                Resultado no encontrado
                            </Option>
                        ) : null}
                    </Combobox>
                </Field>
            </div>
        </>
    );
}