import { Checkbox } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import { Image, Position } from "@fluentui/react";
const iconCheckboxSelected = {
    src: require('../../../../Assets/Images/Icons/CheckboxSelected.svg').default
}

export function GeneralData(props){
    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Datos generales'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Descripción' value={props.item.description} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Medida de la pieza' value={props.item.pieceMeasure} />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Contenido' value={props.item.content} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Medida de la caja' value={props.item.boxMeasure} />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Piezas por caja' value={props.item.piecesByBox} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel className="packingDescription" text='Descripción del empaque' value={props.item.packingDescription} />
                            </div>
                        </div>
                    </OmniCard>
                    <OmniCard title="Grupos">
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Variedad' value={props.item.varietyDescription} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Categoría' value={props.item.categoryDescription} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Familia' value={props.item.familyDescription} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel text='Marca' value={props.item.brandDescription} />
                            </div>
                        </div>
                    </OmniCard>
                    <OmniCard title="catálogos SAT">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Productos y servicios' value={props.item.satItemServiceDescription} />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Unidad de medida' value={props.item.satMeasureUnitDescription} />
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}