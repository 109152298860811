import { Offline, Online } from "react-detect-offline";
import { OmniInternetSpeed } from "./OmniInternetSpeed";
import { OmniInternetSpeedOffline } from './OmniInternetSpeedOffline';
export function OmniSpeedTest() {
    const polling = {
        enabled: true,
        interval: 60000,
        timeout: 60000
    };
    return (
        <>
            <Online polling={polling}>
                <OmniInternetSpeed></OmniInternetSpeed>
            </Online>
            <Offline polling={polling}>
                <OmniInternetSpeedOffline></OmniInternetSpeedOffline>
            </Offline>
        </>
    );
}