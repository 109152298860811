import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Suppliers/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { SetAxiosPrivate, GetByIdAsync, UpdateStatusAsync } from '../../../Functions/Catalogs/Suppliers/SupplierStore'; // UpdateStatusAsync,
import Information from './Details/Information'
import TaxData from './Details/TaxData';
import Credit from './Details/Credit';
import Replenishment from './Details/Replenishment';
import Extra from './Details/Extra';
import SupplierInTruck from './Details/SupplierInTruck';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import { CatalogContacts } from './Contacts/CatalogContacts';
import {
    leftButtonsDetail
} from '../../../Assets/Constants/BarButtons/Catalog/Suppliers/Editing';
import { ExtendedAccountingAccountDetail } from '../../Shared/AccountingAccount/ExtendedAccountingAccountDetail';
const pivotStyles = {
    root: {
        width: '95%'
    }
};


export default function Detail() {
    const [supplier, setSupplier] = useState([]);
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const { extendedAccountingSupplier } = useAccountingConfigurationContext();
    const [status, setStatus] = useState('');
    const [itemSelected, setItemSelected] = useState();
    const [buttonsSupplier, setButtonsSupplier] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [selectedKey, setSelectedKey] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    const [view, setView] = useState({
        listSupplierContactResult: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        }
    });

    const getById = async () => {
        setIsItemDisabled(true);
        setItemSelected(undefined);
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setSupplier(result.data);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
        setLoadingSkeleton(false);
        setIsItemDisabled(false);

        setStatus(result.data.active);
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !supplier.active);
        if (result.isSuccessful) {
            setSupplier((prevSupplier) => {
                return {
                    ...prevSupplier,
                    active: !supplier.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = supplier.active ? "Inactivar" : "Activar";
                let statusiconbutton = supplier.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = supplier.active ? 9 : 197;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    disabled: id == 0 ? true: false,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el proveedor?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: id == 0 ? true :!supplier.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }

    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };

    function ChangeButtons() {
        if (selectedKey === 'contacts') {
            setButtonsSupplier(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        } else {
            setButtonsSupplier(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }

    useEffect(() => {
        if (supplier) {
            ChangeButtons();
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
  
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);

    useEffect(() => {
        if (supplier) {
            setButtonsSupplier(prevSupplier => ({
                ...prevSupplier,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...supplier, acronym: localStorage.getItem("userName"), name:supplier.businessName });
        }
    }, [supplier?.active]);

    useEffect(() => {
        if (supplier) {
            if (selectedKey && (selectedKey === 'contacts')) {
                setButtonsSupplier(prevEmail => ({
                    ...prevEmail,
                    leftButtons: leftButtonsDetail,
                    rightButtons: {}
                }));
            }
            else {
                setButtonsSupplier(prevSupplier => ({
                    ...prevSupplier,
                    leftButtons: ButtonActive(),
                    rightButtons: rightButtons
                }));
            }
        }
    }, [changestatus,id]);

    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else {
            return {
                disabled: false,
                style: { color: 'var(--dark)' }
            }
        }
    }
    return (
        <>
            {supplier &&
                (<BarButtons rightButtons={buttonsSupplier.rightButtons}
                    leftButtons={buttonsSupplier.leftButtons} />)
            }
           
                    <Pivot className="pivot-center-client" selectedKey={selectedKey}
                        onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                        overflowBehavior="menu" styles={pivotStyles}            >
                    <PivotItem headerText="Información" itemKey="information" >
                        <Information supplier={supplier}/>
                    </PivotItem>
                    <PivotItem headerText="Datos fiscales" itemKey="taxData" >
                        <TaxData supplier={supplier}/>
                    </PivotItem>
                        <PivotItem headerText="Contactos" headerButtonProps={BlockPivots("contacts")} itemKey="contacts" >
                            <CatalogContacts supplier={supplier} setSupplier={setSupplier} property="listSupplierContactResult"
                                setIsItemDisabled={setIsItemDisabled}
                                setbuttonsCatalog={setButtonsSupplier}
                                setItemSelected={setItemSelected}
                                itemSelected={itemSelected}
                                view={view}
                                setView={setView}
                            />
                    </PivotItem>
                    <PivotItem headerText="Crédito" itemKey="credit" >
                        <Credit supplier={supplier}/>
                    </PivotItem>
                    <PivotItem headerText="Reabasto" itemKey="Replenishment" >
                        <Replenishment supplier={supplier}/>
                    </PivotItem>
                    <PivotItem headerText="Adicionales" itemKey="extra" >
                        <Extra supplier={supplier}/>
                    </PivotItem>
                     <PivotItem headerText="Proveedor a pie de camión" itemKey="supplierInTruck" >
                        <SupplierInTruck supplier={supplier}/>
                    </PivotItem>
                {
                    extendedAccountingSupplier &&
                    <PivotItem headerText="Contabilidad" itemKey="contabilidad" >
                        <ExtendedAccountingAccountDetail value={supplier}/>
                    </PivotItem>
                }
                    <PivotItem headerText="Cambios" itemKey="changes">
                        <DetailChanges user={supplier} />
                    </PivotItem>
                </Pivot>
        </>
    );
}