import { useForm } from "react-hook-form";
import Detail from "./Detail";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/CreditorSubAccount/Add";
import { AddAsync, SetAxiosPrivate, GetCreditorAccount } from '../../../Functions/Catalogs/CreditorSubAccount/CreditorSubAccountStore'
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox"
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
import { useState, useEffect, } from 'react';
export default function Add() {
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const [listCreditorAccount, setListCreditorAccount] = useState([]);
    const { register, handleSubmit } = useForm();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const add = async (data) => {
        let result = await AddAsync(data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, result.data);
            data.id = result.data;
            addNewItem(data);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    };
    const ListGetCreditorAccount = async () => {
        let result = await GetCreditorAccount();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.description
            }));
            setListCreditorAccount(resultList);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        ListGetCreditorAccount();
    }, []);
    return (
        <form onSubmit={handleSubmit(add)}>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtons} />
            <OmniCard title='Datos generales'>
                <OmniTextBox required title="Descripción" register={{ ...register("description") }} focus />
                <OmniSelect required title='Rubro' register={{ ...register('idcreditoraccount') }} items={listCreditorAccount} />
            </OmniCard>
        </form>
    )
}
