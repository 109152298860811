import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Items/Item';
import { Pivot, PivotItem } from "@fluentui/react";
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = (props) => {
    return (
        <>
            <BarButtons rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                selectedKey={null}>
                <PivotItem headerText="Información" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} />
                <PivotItem headerText="Existencias" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} />
                <PivotItem headerText="Resurtido" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} />
                <PivotItem headerText="Artículos por sucursal" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} />
                <PivotItem headerText="Validaciones" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} />
                <PivotItem headerText="Comisiones" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} />
            </Pivot>
        </>
    )
}
