import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerEmployee= (props) => {

    const onChangeEmployeeTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            props.setEmployeeList((prev) => {
                const updated = prev.map((item) => {
                    if (item.fullName.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: false
                        };
                    }
                });
                return updated;
            });
        }
        else {
            onCleanTextEmployee();
        }
    };
    const onChangeCheckBoxEmployee = (event, data) => {
        if (data.checked) {
            props.setEmployeeList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            props.setEmployeeList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const addEmployee = () => {
        props.setShowAttachEmployeePanel(false);
        onCleanTextEmployee();
    };
    const itemsRadio = [
        {
            key: "1",
            value: "1",
            text: "Todos",
        },
        {
            key: "2",
            value: "2",
            text: "Los seleccionados",
        }
    ];
    const onChangeType = (event, data) => {
        if (data.value === "2") {
            if (props.EmployeeList.length > 0) {
                props.setEmployeeList(props.EmployeeList.map((Employee) => ({
                    ...Employee,
                    selected: false,
                    filter: false
                })))
            }
        }
        else {
            props.setEmployeeList(props.EmployeeList.map((Employee) => ({
                ...Employee,
                selected: false,
            })))
        }
        props.setParam(prevEdit => ({ ...prevEdit, employeeType: data.value }));
    };
    const onCleanTextEmployee = () => {
        props.setEmployeeList((prev) => {
            const updated = prev.map((item) => {
                return {
                    ...item,
                    filter: false
                };
            });
            return updated;
        });
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachEmployeePanel(false);
        props.setEmployeeList(props.EmployeeList.map((Employee) => ({
            ...Employee,
            selected: false,
            filter: false
        })))
        props.setParam(prevEdit => ({ ...prevEdit, employeeType: "1" }));
    };
    return (
        <OverlayDrawer position="end" open={props.showAttachEmployeePanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Empleados
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniRadioGroup name="employeeType"
                            title="Empleados"
                            orientation="horizontal"
                            items={itemsRadio}
                            default={props.param.employeeType} inactivefieldEdited onChange={onChangeType} />
                    </div>
                </div>
                {props.param.employeeType === "2" &&
                    <div className='row mt-2'>
                        <div className='col-md-6'>
                            <OmniTextBox focus title="Búsqueda de empleados" maxLength={64} onChange={onChangeEmployeeTypeText} inactivefieldEdited />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        props.EmployeeList.map((item) => (
                                            item.filter === true &&
                                            <OmniCheckBox title={item.fullName} inactivefieldEdited
                                                onChange={onChangeCheckBoxEmployee}
                                                id={item.id} key={item.id} name={item.fullName} propertyChecked={item.selected} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <OmniLabel text='Empleados seleccionados' />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        props.EmployeeList.map((item) => (
                                            item.selected === true &&
                                            <OmniCheckBox title={item.fullName} inactivefieldEdited
                                                onChange={onChangeCheckBoxEmployee} id={item.id} key={item.id} propertyChecked={true} />

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addEmployee}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}