import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { ReactComponent as EmailCuvitek } from './../../../../Assets/Images/Icons/Email.svg';
import { ReactComponent as BillCuvitek } from './../../../../Assets/Images/Icons/Bill.svg';
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Stack } from "@fluentui/react";
import { Detail } from '../../../Shared/EmailList/Detail';
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
export const ContactList = (props) => {
    const columnsHeaderRoutes = [
        { columnKey: "1", label: "Lista de contactos", width: '80%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '10%' },
        { columnKey: "3", label: "", width: '10%' }
    ];

    const changeComponentClik = (id) => {
        props.setSelected((prevClient) => ({
            ...prevClient,
            [props.property]: id
        }));
        if (props.setView) {
            props.setView((prevView) => ({
                ...prevView,
                [props.property]: {
                    viewAdd: false,
                    viewDetail: true,
                    viewEdit: false
                }
            }));
        }
    };
    return (
        <Stack vertical>
            <Stack.Item >
                <OmniCard title='Contactos'>
            <div className="detail-table-scroll">
                <Table className="detail-table">
                    <TableHeader>
                        <TableRow >
                            {columnsHeaderRoutes.map((column) => (
                                <TableHeaderCell key={column.columnKey}
                                    style={{ width: column.width }}
                                    className={'detail-table-header-title ' + column?.classAdd}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                                {props.description[props.property] &&
                                    props.description[props.property].map((item, index) => (
                                        <TableRow key={index} onClick={() => changeComponentClik(item.id)}
                                            className={(props.itemSelected && item.id == props.itemSelected[props.property]) ? "item-table-selected" : "item-table"}>
                                       <TableCell >
                                                <TableCellLayout className={(item?.active === true ? "delete-table-body-description" : "color-danger delete-table-body-description")}>
                                                    {item.contactName}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout media={item?.proofMail === true && <BillCuvitek />} className="iconList" />
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout media={item.listContactsEmail.length > 0 && <EmailCuvitek />} className="iconList" />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                    </TableBody>
                </Table>
                </div>
            </OmniCard>
            </Stack.Item>
                <Stack.Item >
                    <OmniCard title='Datos de contacto de la empresa (Hasta 2023)' classCard="card-omni-contact"
                        classTitle="card-title-danger ">
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Teléfono celular' value={props.description?.fax} />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <OmniLabel text='Teléfono fijo' value={props.description?.phone} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <OmniLabel text='Celular del propietario' value={props.description?.ownerCellphone} />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <OmniLabel text='Celular del comprador' value={props.description?.buyerCellphone} />
                        </div>
                    </div>
                    <Detail description={props.description}
                        titleColum="Lista de correos" property="currentEmails" />
                    </OmniCard>
                </Stack.Item>
        </Stack>
    )
}