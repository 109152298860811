import { Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import {ReactComponent as PieceIcon} from "../../../../Assets/Images/Icons/Piece.svg";
import {ReactComponent as BoxIcon} from "../../../../Assets/Images/Icons/Box.svg";
import { AlixMeasure } from "../../../../Assets/Constants/Enums/AlixMeasure";

export default function KeysAndCodes(props){
    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Códigos de barras o clave'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Código principal' value={props.item.mainCode} />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Caja' value={props.item.boxCode} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Lista de códigos adicionales
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                    {
                                        props.item.itemBarCodeResults?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <TableCellLayout>
                                                        <div className="row">
                                                            <div className="col-md-10 col-lg-10">
                                                                {item.barcode}
                                                            </div>
                                                            <div className="col-md-2 col-lg-2">
                                                                {
                                                                    item.alixMeasure==0
                                                                    ?<></>
                                                                    :(
                                                                        item.alixMeasure==AlixMeasure.Piece
                                                                        ?<PieceIcon />
                                                                        :<BoxIcon />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className='col-md-auto'>
                    <div className="row">
                        <OmniCard title='Número de parte'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Número de parte' value={props.item.partNumber} />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                    <div className="row">
                        <OmniCard title='Código del proveedor'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <OmniLabel text='Código del proveedor' value={props.item.supplierCode} />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </div> 
    )
}