import React, { useContext, useRef, useCallback, useState } from "react";

const fieldChangeWatcherContext = React.createContext();

export function useFieldChangeWatcherContext() {
    return useContext(fieldChangeWatcherContext);
}

export function FieldChangeWatcherProvider({ children }) {
    const contextControlsRef = useRef([]);
    const hasPendingChangesRef = useRef(false);

    const updateHasPendingChanges = useCallback(() => {
        hasPendingChangesRef.current = (contextControlsRef.current.filter(c => c.edited === true).length > 0)
    }, [contextControlsRef.current]);
    const [requiredControls, setRequiredControls] = useState([]);
    const ShowRequiredControls = () => {
        let errorMessages = [];
        setRequiredControls(prev => {
            return prev.map(control => {
                if (control.hasValue === false) {
                    errorMessages.push(control.message);
                    return { ...control, visible: true };
                }
                return control;
            });
        });
        let message = errorMessages.map(x => x).join('. ');
        if (message) {
            return message;
        }
        else {
            setRequiredControls([]);
            return ''
        }
       
    }
    const AddRequiredControls = (title, defaultValue) => {
        setRequiredControls(prev => {
            const existingControl = prev.find(control => control.id === title);
            if (!existingControl) {
                return [...prev, {
                    visible: false,
                    hasValue: defaultValue ?? false,
                    message: `El campo ${title.toLowerCase()} es obligatorio.`,
                    id: title
                }];
            }
            return prev;
        });
    }
    const CleanRequiredControls = () => {
        setRequiredControls([]);
    }
    const UpdateHasValueById = (id,value) => {
        setRequiredControls(prev => {
            return prev.map(control => {
                if (control.id === id) {
                    return { ...control, hasValue: value };
                }
                return control;
            });
        });

    }
    return (
        <fieldChangeWatcherContext.Provider value={{
            contextControlsRef,
            updateHasPendingChanges, hasPendingChangesRef,
            requiredControls, setRequiredControls, ShowRequiredControls, AddRequiredControls, CleanRequiredControls, UpdateHasValueById
        }}>
            {children}
        </fieldChangeWatcherContext.Provider>
    );
}