import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniSwitch } from '../../../Shared/Controls/Switch/OmniSwitch';
import { useState, useCallback,useEffect } from 'react';
export const ClientRoute = (props) => {
    const [checked, setChecked] = useState(true);
    const [clientsRoutes, setClientsRoutes] = useState();
    useEffect(() => {
        setClientsRoutes(props?.description[props?.property]);
        setChecked(true);
    }, [props?.description[props?.property]]);
    const onChange = useCallback(
        (ev) => {
            const newList = [...props?.description[props?.property]]; 

            if (ev.currentTarget.checked) {
                newList.sort((a, b) => {
                    const timeA = convertTo24Hour(a.visitingHours);
                    const timeB = convertTo24Hour(b.visitingHours);
                    return timeA.localeCompare(timeB);
                });
            } else {
                newList.sort((a, b) => {
                    const timeA = convertTo24Hour(a.deliveryTime);
                    const timeB = convertTo24Hour(b.deliveryTime);
                    return timeA.localeCompare(timeB);
                });
            }
             setChecked(ev.currentTarget.checked);
             setClientsRoutes(newList);
        },
        [props?.description[props?.property],setChecked] 
    );

    const columnsHeaderRoutes = [
        { columnKey: "1", label: "", width: '8%' , classAdd: 'first-item-delete' },
        { columnKey: "2", label: "Lista de clientes (" + (isNaN(clientsRoutes?.length) ? 0 : clientsRoutes?.length) + ")", width: '46%' },
        { columnKey: "3", label: "", width: '23%' },
        { columnKey: "4", label: "", width: '23%' }
    ];
    const changeComponentClik = (id) => {
        props.setSelected((prevClient) => ({
            ...prevClient,
            [props.property]: id
        }));
        if (props.setView) {
            props.setView((prevView) => ({
                ...prevView,
                [props.property]: {
                    viewAdd: false,
                    viewDetail: true,
                    viewEdit: false
                }
            }));
        }
    };
    const visitingHoursMap = {};
    const deliveryTimeMap = {};
    if (clientsRoutes) {
        clientsRoutes.forEach((item) => {
            if (visitingHoursMap[item.visitingHours] !== undefined) {
                item.duplicateVisitingHours = true;
            } else {
                visitingHoursMap[item.visitingHours] = true;
                item.duplicateVisitingHours = false;
            }

            if (deliveryTimeMap[item.deliveryTime] !== undefined) {
                item.duplicateDeliveryTime = true;
            } else {
                deliveryTimeMap[item.deliveryTime] = true;
                item.duplicateDeliveryTime = false;
            }
        });
    }
    function convertTo24Hour(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }
    return (
        <>
            <OmniCard title={'Agenda para ' + props.day}>
                {(props.description?.[props?.property].length>0)&&
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                    <OmniSwitch
                        checked={checked}
                        onChange={onChange}
                        label={checked ? "Ordenar por hora de visita" : "Ordenar por hora de entrega"}
                        inactivefieldEdited/>
                        </div>
                    </div>
                }
                <div className="detail-table-max-height">
                        <Table className="detail-table">
                            <TableHeader>
                                <TableRow >
                                    {columnsHeaderRoutes.map((column) => (
                                        <TableHeaderCell key={column.columnKey}
                                            style={{ width: column.width }}
                                            className={'detail-table-header-title ' + column?.classAdd}>
                                            {column.label}
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                            {clientsRoutes &&
                                clientsRoutes.map((item, index) => (
                                    <TableRow key={index} onClick={() => changeComponentClik(item.id)}
                                        className={(props.itemSelected && item.id == props.itemSelected[props.property])? "item-table-selected" : "item-table"}>
                                            <TableCell >
                                            <TableCellLayout className={(item?.active ? "" : "color-danger")}>
                                                    {(index+1).toString()}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                            <TableCellLayout className={(item?.active ? "" : "color-danger")} >
                                                    {item.client}
                                                </TableCellLayout>
                                            </TableCell>
                                        <TableCell >
                                            <TableCellLayout className={(item?.duplicateVisitingHours ? "color-danger" : "color-info")} >
                                                    {item.visitingHours}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                            <TableCellLayout className={(item?.duplicateDeliveryTime ? "color-danger" : "")}>
                                                    {item.deliveryTime}
                                                </TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </OmniCard>
        </>
    )
}