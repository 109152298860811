import React,{Component, useState} from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@fluentui/react/lib/Stack'
import Header from "./Header";
import NavMenu from '../Menu/NavMenu';
import '../../Assets/Styles/Layout/Layout.css';
import '../../Assets/Styles/Shared/Palette.css';


export default function Layout (){
    const [isCollapsed,changeIsCollapsed]=useState(false);
    function changeCollapsed(){
        changeIsCollapsed(isCollapsed?false:true);
    }
    return (
        <div>
            <div id='header'>
                <Header/>
            </div>
            <div id='menu'>
                <NavMenu changeCollapsed={changeCollapsed} />
            </div>
            <div id='outlet'
                className={isCollapsed?'main-container-collapsed':'main-container-extended'}>
                <Outlet />
            </div>
        </div>
    );
}