import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Map } from './Map';
import { Image } from "@fluentui/react-components";
import { useState,useEffect } from 'react';
import { GetPhotoByIdLocation } from '../../../../Functions/Catalogs/Routes/RoutesStore'
import useAxiosPrivate from "../../../../Hooks/Common/useAxiosPrivate";
import { SetAxiosPrivate } from '../../../../Functions/Catalogs/Routes/RoutesStore'
export const LocationDetail = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const itemSelected = props.description[props.property].find(x => x.id == [props.itemSelected[props.property]]);
    useEffect(() => {
        OnLoadPhoto();
    }, [itemSelected]);
    const OnLoadPhoto = async () => {
        if (itemSelected.imageId && itemSelected.imageExtension) {
            let store = await GetPhotoByIdLocation(itemSelected.imageId, itemSelected.imageExtension)
            if (store && store.data) {
                const imagenBlob = new Blob([store.data], { type: 'image/jpg' });
                const urlImagen = URL.createObjectURL(imagenBlob);
                props.setImagenSrc(urlImagen);
            }
        }
        else {
            props.setImagenSrc(undefined);
        }
    }
    return (
        <>
            {itemSelected && ((itemSelected?.latitude != 0 && itemSelected?.latitude != undefined) && (itemSelected?.longitude != 0 && itemSelected?.longitude != undefined)) &&
                <OmniCard title='Ubicación en el mapa'>
                    <Map key={`${parseFloat(itemSelected.latitude)}-${parseFloat(itemSelected.longitude)}`}
                        latitude={parseFloat(itemSelected.latitude)} longitude={parseFloat(itemSelected.longitude)} />
                </OmniCard>
            }
            {itemSelected && props.imagenSrc &&
                <OmniCard title='Foto de la ubicación'>
                    <Image src={props.imagenSrc} alt="store" fit="contain"  />
                </OmniCard>
            }
        </>
    )
}