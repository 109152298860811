import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Employees/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, generateObject, Age, DischargeDate, generateObjectFingerPrint } from '../../../Functions/Catalogs/Employees/EmployeeStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { Detail as DetailEmail } from '../../Shared/EmailList/Detail';
import { CatalogHandprint } from './Handprints/CatalogHandprint';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import {
    leftButtonsDetail
} from '../../../Assets/Constants/BarButtons/Catalog/Employees/Editing';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, setChangePivotKey, changePivotKey } = useGeneralStatesContext();
    const [user, setUser] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [buttonsUser, setButtonsUser]
        = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    //const [view, setView] = useState({
    //    listHandprints: {
    //        viewAdd: false,
    //        viewDetail: false,
    //        viewEdit: false,
    //    }
    //}
    //)
    const [changestatus, setChangestatus] = useState();
    const getById = async () => {
        setIsItemDisabled(true);
        setItemSelected(undefined);
        //setView({
        //    listHandprints: {
        //        viewAdd: false,
        //        viewDetail: false,
        //        viewEdit: false,
        //    }
        //});
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setUser({
                id: result.data.id,
                roleId: result.data.roleId,
                role: result.data.role,
                userName: result.data.userName,
                name: result.data.name,
                surname: result.data.surname,
                secondSurname: result.data.secondSurname,
                mobilePhone: result.data.mobilePhone,
                useOnlyFingerprint: result.data?.useOnlyFingerprint == 0 ? "Manual y biométrico" : 'Solo biométrico',
                gender: result.data?.gender === 1 ? "Hombre" : 'Mujer',
                payrollId: result.data.payrollId,
                allowAttendanceWithUserPassword: result.data.allowAttendanceWithUserPassword === true ? "Si" : "No",
                position: result.data.position,
                birthdate: result.data.birthdateLabel,
                age: Age(result.data.birthdate),
                dischargeDate: result.data?.dischargeDateLabel,
                dischargeDateLabel: DischargeDate(result.data?.dischargeDate),
                status: result.data.status === 1 ? true : false,
                listEmail: generateObject(result.data?.listEmail),
                //listHandprints: generateObjectFingerPrint(result.data?.listHandprints),
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
            setIsItemDisabled(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, user.status === true ? 0 : 1);
        if (result.isSuccessful) {
            setUser((prevRoute) => {
                return {
                    ...prevRoute,
                    status: !user.status,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = user.status===true ? "Inactivar" : "Activar";
                let statusiconbutton = user.status === true ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = user.status === true ? 14 : 199;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} al empleado?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: (user.status === true ? false : true),
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        if (user) {
            ChangeButtons();
        }
        if (changestatus) {
            let data = { ...user };
            data.fullName = user.name + " " + user.surname + " " + user.secondSurname;
            updateItemStatus({ ...data, updateUserName: localStorage.getItem("userName") });
        }
    }, [user?.status]);
    useEffect(() => {
        if (user) {
            ChangeButtons();
        }
    }, [changestatus, id]);
    function ChangeButtons() {
        //if (selectedKey && selectedKey === 'listHandprints') {
        //    setButtonsUser(prevEmail => ({
        //        ...prevEmail,
        //        leftButtons: leftButtonsDetail,
        //        rightButtons: {}
        //    }));
        //} else {
            setButtonsUser(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        //}
    }
    const pivotItemButtonProps = {
        disabled: isItemDisabled,
        style: { color: isItemDisabled ? 'var(--secondary)' : 'var(--dark)' }
    };

    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else {
            return {
                disabled: false,
                style: { color: 'var(--dark)' }
            }
        }

    }
    useEffect(() => {
        if (user) {
            ChangeButtons();
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
    return (
        <>
            {user &&
                <BarButtons rightButtons={buttonsUser.rightButtons}
                    leftButtons={buttonsUser.leftButtons} />
            }
            <Pivot className="pivot-center-client" overflowBehavior="menu"
                styles={pivotStyles} selectedKey={selectedKey} onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
            >
                <PivotItem headerText="Información" headerButtonProps={pivotItemButtonProps} itemKey="information" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre' value={user?.name} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Apellido paterno' value={user?.surname} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Apellido materno' value={user?.secondSurname} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Sexo' value={user?.gender} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Fecha de nacimiento' value={user?.birthdate} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='' value={user?.age} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Datos de contacto'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            {user?.listEmail &&
                                                <DetailEmail description={user}
                                                    property="listEmail" titleColum="Lista de correos" />
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Teléfono celular' value={user?.mobilePhone} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Acceso" itemKey="access" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Acceso'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Tipo de acceso' value={user?.useOnlyFingerprint} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre del usuario' value={user?.userName} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Asistencia'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Permite registro de asistencia con nombre de usuario y contraseña' value={user?.allowAttendanceWithUserPassword} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Puesto" itemKey="position" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Puesto'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre del puesto' value={user?.position} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Rol dentro del sistema' value={user?.role} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Fecha de alta' value={user?.dischargeDate} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='' value={user?.dischargeDateLabel} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nómina' value={user?.payrollId} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                {/*<PivotItem headerText="Huellas" headerButtonProps={BlockPivots("listHandprints")} itemKey="listHandprints">*/}
                {/*    <CatalogHandprint client={user} setClient={setUser} property="listHandprints"*/}
                {/*        setIsItemDisabled={setIsItemDisabled}*/}
                {/*        setbuttonsCatalog={setButtonsUser} setItemSelected={setItemSelected}*/}
                {/*        itemSelected={itemSelected}*/}
                {/*        view={view}*/}
                {/*        setView={setView} employeeId={id} statusCatalog={user?.status}/>*/}
                {/*</PivotItem>*/}
                <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={pivotItemButtonProps}>
                    <DetailChanges user={user} />
                </PivotItem>
            </Pivot>
        </>


    )
}