import { useEffect, useState } from "react";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniCombobox } from "../../../Shared/Controls/ComboBox/OmniCombobox";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import {GetActives as GetCategories, SetAxiosPrivate as SetAxiosPrivateCategories} from "../../../../Functions/Catalogs/Categories/CategoriesStore";
import {GetActives as GetFamilies, SetAxiosPrivate as SetAxiosPrivateFamilies} from "../../../../Functions/Catalogs/Families/FamilyStore";
import {GetActives as GetVarieties, SetAxiosPrivate as SetAxiosPrivateVarieties} from "../../../../Functions/Catalogs/Varieties/VarietyStore";
import {GetActives as GetBrands, SetAxiosPrivate as SetAxiosPrivateBrands} from "../../../../Functions/Catalogs/Brand/BrandStore";
import {GetEnabledAlixItemServiceAsync as GetItemsServices, GetEnabledAlixIMeasuresUnitsAsync as GetMeasuresUnits, SetAxiosPrivate as SetAxiosPrivateItemsServices} from "../../../../Functions/Configuration/SatCatalogs/SatCatalogStore";
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage } from "../../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../../Hooks/Common/useAxiosPrivate";
import { Button, Label } from "@fluentui/react-components";
import { GetByVarietyIdAsync } from "../../../../Functions/Catalogs/Items/ItemStore";
import { OverlayDrawerVarietyEdit } from '../OverlayDrawers/OverlayDrawerVarietyEdit';
import { Player } from "@lottiefiles/react-lottie-player";
import {ReactComponent as VarietyIcon} from "../../../../Assets/Images/Icons/Variety.svg";

export default function GeneralData(props){
    const[categories,setCategories]=useState([]);
    const[families,setFamilies]=useState([]);
    const[varieties,setVarieties]=useState([]);
    const[brands,setBrands]=useState([]);
    const[itemsServices,setItemsServices]=useState([]);
    const[measuresUnits,setMeasuresUnits]=useState([])
    const [showSpinner, setShowSpinner] = useState(false);
    const [showAttachVarietyPanel, setShowAttachVarietyPanel] = useState(false);
    const [varietyId,setVarietyId]=useState();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const axiosPrivate = useAxiosPrivate();
    
    SetAxiosPrivateCategories(axiosPrivate);
    SetAxiosPrivateFamilies(axiosPrivate);
    SetAxiosPrivateVarieties(axiosPrivate);
    SetAxiosPrivateBrands(axiosPrivate);
    SetAxiosPrivateItemsServices(axiosPrivate);


    const getCategories=async()=>{
        let result=await GetCategories();
        if (result.isSuccessful) {
            let categories= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setCategories(categories);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const getFamilies=async()=>{
        let result=await GetFamilies();
        if (result.isSuccessful) {
            let families= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setFamilies(families);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const getVarieties=async()=>{
        let result=await GetVarieties();
        if (result.isSuccessful) {
            let varieties= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setVarieties(varieties);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const getBrands=async()=>{
        let result=await GetBrands();
        if (result.isSuccessful) {
            let brands= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setBrands(brands);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const getItemsServices=async()=>{
        let result=await GetItemsServices();
        if (result.isSuccessful) {
            let itemsServices= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.description,
                omniKey:item.key,
            }));
            setItemsServices(itemsServices);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const getMeasuresUnits=async()=>{
        let result=await GetMeasuresUnits();
        if (result.isSuccessful) {
            let measuresUnits= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.description,
                omniKey:item.key,
            }));
            setMeasuresUnits(measuresUnits);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }

    const OnChangeText = async () => {
        setShowSpinner(true);
        if (categories) {
            setShowSpinner(false);
        }
        else {
            setShowSpinner(true);
        }
    }
    const OnChangeCategoryItem = async () => {
        setShowSpinner(true);
        if (categories) {
            setShowSpinner(false);
        }
        else {
            setShowSpinner(true);
        }
    }
    const onChangeCategory = async (id, category) => {
        props.setItem(prevEedit => ({
            ...prevEedit,
            categoryId: id,
            categoryDescription: category
        }));
    }
    const onChangeFamily = async (id, family) => {
        props.setItem(prevEedit => ({
            ...prevEedit,
            familyId: id,
            familyDescription: family
        }));
    }
    const onChangeVariety = async (id, variety) => {
        props.setItem(prevEedit => ({
            ...prevEedit,
            varietyId: id,
            varietyDescription: variety
        }));
    }
    const onChangeBrand = async (id, brand) => {
        props.setItem(prevEedit => ({
            ...prevEedit,
            brandId: id,
            brandDescription: brand
        }));
    }
    const onChangeItemServices = async (id, itemServiceDescription) => {
        let itemService=itemsServices.find(i=>i.key.toString()==id.toString());
        if(itemService!==undefined){
            props.setItem(prevEedit => ({
                ...prevEedit,
                satItemServiceID: id,
                satItemServiceDescription: itemServiceDescription,
                satItemServiceKey:itemService.omniKey,
            }));
        }
    }
    const onChangeMeasureUnit = async (id, unit) => {
        let measureUnit=measuresUnits.find(u=>u.key.toString()==id.toString());
        if(measureUnit!==undefined){
            props.setItem(prevEedit => ({
                ...prevEedit,
                satMeasureUnitId: id,
                satMeasureUnitDescription: unit,
                satMeasureUnitKey:measureUnit.omniKey,
            }));
        }
    }
    const getVarietyData=async()=>{
        let result = await GetByVarietyIdAsync(varietyId);
        if (result.isSuccessful) {
            let itemByVarietyResult=result.data;
            itemByVarietyResult.sku=props.item?.sku;
            itemByVarietyResult.description=props.item?.description;
            itemByVarietyResult.itemByBranches=props.item?.itemByBranches;
            itemByVarietyResult.itemRestockResults=props.item?.itemRestockResults;
            itemByVarietyResult.itemStockResults=props.item?.itemStockResults;
            props.setItem(itemByVarietyResult);
            setShowAttachVarietyPanel(false);
        }
    }


    useEffect(()=>{
        getCategories();
        getFamilies();
        getVarieties();
        getBrands();
        getItemsServices();
        getMeasuresUnits();
    },[])

    useEffect(()=>{
        if(varietyId){
            getVarietyData();
        }
    },[varietyId])

    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Datos generales'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniTextBox title='Descripción' maxLength={128}  value={props.item?.description} edit={props.setItem} property="description" required />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniTextBox title='Medida de la pieza' maxLength={16}  value={props.item?.pieceMeasure} edit={props.setItem} property="pieceMeasure" required/>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniTextBox title='Contenido' maxLength={8}  value={props.item?.content} edit={props.setItem} property="content" required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                {
                                    props.item?.piecesByBox==1
                                    ?
                                        <OmniLabel text='Piezas por caja' value={props.item?.boxMeasure} />
                                    :
                                        <OmniTextBox title='Medida de la caja' maxLength={16}  value={props.item?.boxMeasure} edit={props.setItem} property="boxMeasure" required />
                                }
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <OmniTextBox title='Piezas por caja' maxLength={9}  value={props.item?.piecesByBox} edit={props.setItem} property="piecesByBox" required />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniLabel className="packingDescription" text='Descripción del empaque' value={props.item?.packingDescription} />
                            </div>
                        </div>
                    </OmniCard>
                    <OmniCard title="Grupos">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <Button title="Variedad" className="button-variety-select" onClick={() => { setShowAttachVarietyPanel(true) }}>
                                    <VarietyIcon />
                                    <span className="button-variety-select-span">
                                        Seleccionar
                                    </span>
                                    <div >
                                        <Player
                                            id={"plyVeriety"}
                                            key={"plyVeriety"}
                                            hover
                                            loop
                                        />
                                    </div>
                                </Button>
                            </div>
                            <div className="col-lg-6 col-md-6">
                            <div text="Sin variedad" className="label-variety-select" >
                                {
                                    props.item?.varietyId===null
                                    ?
                                        "Sin variedad"
                                    :
                                        props.item?.varietyDescription
                                }
                            </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniCombobox
                                    showSpinner={showSpinner}
                                    title='Categoría'
                                    items={categories}
                                    onChangeItemText={OnChangeText}
                                    selected={props.item?.categoryId}
                                    value={props.item?.categoryDescription} 
                                    onChangeItem={onChangeCategory}
                                    required
                                    />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniCombobox
                                    showSpinner={showSpinner}
                                    title='Familia'
                                    items={families}
                                    onChangeItemText={OnChangeText}
                                    selected={props.item?.familyId}
                                    value={props.item?.familyDescription} 
                                    onChangeItem={onChangeFamily}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniCombobox
                                    showSpinner={showSpinner}
                                    title='Marca'
                                    items={brands}
                                    onChangeItemText={OnChangeText}
                                    selected={props.item?.brandId}
                                    value={props.item?.brandDescription} 
                                    onChangeItem={onChangeBrand}
                                    required/>
                            </div>
                        </div>
                    </OmniCard>
                    <OmniCard title="catálogos SAT">
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <OmniCombobox
                                    showSpinner={showSpinner}
                                    title='Productos y servicios'
                                    items={itemsServices}
                                    onChangeItemText={OnChangeText}
                                    selected={props.item?.satItemServiceID}
                                    value={props.item?.satItemServiceDescription} 
                                    onChangeItem={onChangeItemServices}
                                    required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-6 col-md-6'>
                                <OmniCombobox
                                    showSpinner={showSpinner}
                                    title='Unidad de medida'
                                    items={measuresUnits}
                                    onChangeItemText={OnChangeText}
                                    selected={props.item?.satMeasureUnitId}
                                    value={props.item?.satMeasureUnitDescription} 
                                    onChangeItem={onChangeMeasureUnit}
                                    required/>
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
            <OverlayDrawerVarietyEdit showAttachVarietyPanel={showAttachVarietyPanel}
                    setShowAttachVarietyPanel={setShowAttachVarietyPanel}
                    getVarietyData={getVarietyData}
                    varietiesList={props.varietiesList}
                    getItemsByVarietyIdAsync={props.getItemsByVarietyIdAsync}
                    setVarietyId={setVarietyId}
                    />
        </div> 
    )
}