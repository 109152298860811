import {React} from "react";
import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import Detail from "./Detail";
import {ItemSelectorProvider} from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import {rightButtons} from "../../../Assets/Constants/BarButtons/Catalog/AccountingTaxes/Default"
import { FieldChangeWatcherProvider } from "../../../Context/Controls/FieldChangeWatcherProvider";

const GetByStatus = '/accountingtax/getbystatus';
const iconCatalog= {
    src: require('../../../Assets/Images/Icons/Catalog.svg').default
}


export default function Tax(){ 
    return (
        <ItemSelectorProvider >
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de impuestos"}
                    image={iconCatalog}
                    urlGetItems={GetByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail/>}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"description"}
                    omniAcronym={"acronym"}
                    getInLoad={true}
                    />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )   
}
