import { MessageBar } from "@fluentui/react";
import { useEffect, useRef } from 'react';
import { useState } from "react";
const closeMessage = require('../../../Assets/Images/Icons/Cancel.svg').default;
const messageStyle: Partial<IMessageBarStyleProps> = props => {
    return {
        root: {
            backgroundColor: props.typeColor
        },
    };
};
const messageIconsStyle: Partial<IIconProps> = props => {
    return {
        imageProps: getImageStyles(props)
    }
};
const getImageStyles: Partial<IImageProps> = props => {
    return ({
        src: props.iconOption
    });
}
const messageDismissIconsStyle = {
    imageProps: {
        src: closeMessage
    }
}
const stackStyles = {
    root: {
        backgroundColor: 'white',
    },
};
export default function BarMessageList(props) {
    const [listMessageCatalog, setListMessageCatalog] = useState([]);
    const listSizeRef = useRef(0);
    function handleClose(indexItem) {
        setListMessageCatalog(listMessageCatalog.filter((itemfilter, index) => index !== indexItem));
    }
    useEffect(() => {
        const currentListSize = listMessageCatalog.length;

        if (currentListSize > listSizeRef.current) {
            setTimeout(() => {
                setListMessageCatalog((prevList) => prevList.slice(1));
            }, 3000);
        }
        listSizeRef.current = currentListSize;
    }, [listMessageCatalog]);
    useEffect(() => {
        setListMessageCatalog(props.messages)
    }, [props.messages]);
    return (
        <div id="stkBar" style={stackStyles}>
            {listMessageCatalog && listMessageCatalog.map((item, index) => (
                <MessageBar
                    key={index}
                    isMultiline={true}
                    styles={messageStyle(item)}
                    messageBarIconProps={messageIconsStyle(item)}
                    dismissIconProps={messageDismissIconsStyle}
                    onDismiss={() => handleClose(index)}>
                    {item.text}
                </MessageBar>
            ))}
        </div>
    );
}
