import { useEffect } from "react";
import { Stack } from '@fluentui/react/lib/Stack';
import { Player } from '@lottiefiles/react-lottie-player';
import omniSpeedTestJson from "../../../Assets/JsonSvg/Common/omniSpeedTestJson.json";
export function OmniInternetSpeedOffline() {
    useEffect(() => {
        const svgPaths = document.querySelectorAll("#omniSpeedTest > svg > g path");
        svgPaths[0].removeAttribute("fill");
        svgPaths[1].setAttribute("class", "downloadSpeed");
        svgPaths[1].removeAttribute("fill");
        svgPaths[2].setAttribute("class", "uploadSpeed");
        svgPaths[2].removeAttribute("fill");

        svgPaths[0].setAttribute("class", "downloadSpeedSlow");
        svgPaths[1].setAttribute("class", "downloadSpeed downloadSpeedSlow");
        svgPaths[2].setAttribute("class", "uploadSpeed uploadSpeedSlow");
    }, []);

    return (
        <>
            <Stack horizontal verticalAlign="center">
                <Stack.Item>
                    <Player id="omniSpeedTest" autoplay loop src={omniSpeedTestJson} className="login-header-icon" ></Player>
                </Stack.Item>
                <Stack.Item>
                    <span id="lblTestSpeedValue" className="login-header-label downloadSpeedText downloadSpeedTextSlow">Sin internet</span>
                </Stack.Item>
            </Stack>
        </>
    );
}