import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { useState, useEffect } from 'react'; import {
    SetAxiosPrivate, GetActives
} from '../../../Functions/Catalogs/Varieties/VarietyStore';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { InfoMessage } from '../../../Models/Shared/BarMessages/Messages';
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerVariety = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [varietyList, setVarietyList] = useState([]);
    const [messages, setMessages] = useState([]);
    const onChangeVarietyTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            setVarietyList((prev) => {
                const updated = prev.map((item) => {
                    if (item.name.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: false
                        };
                    }
                });
                return updated;
            });
        }
        else {
            onCleanTextVariety();
        }
    };
    const onChangeCheckBoxVariety = (event, data) => {
        if (data.checked) {
            setVarietyList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            setVarietyList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const addVariety = () => {
        if (props.param.varietyType === "2") {
            if (varietyList.some(x => x.selected === true)) {
                props.setShowAttachVarietyPanel(false);
                onCleanTextVariety();
                props.setParam((prev) => ({
                    ...prev,
                    variety: varietyList.filter(x => x.selected === true).map(x => x.id),
                }))
            }
            else {
                setMessages([new InfoMessage("Debe seleccionar una variedad")]);
            }
        }
        else {
            props.setShowAttachVarietyPanel(false);
            onCleanTextVariety();
            props.setParam((prev) => ({
                ...prev,
                variety: undefined,
            }))
        }
    };
    const itemsRadio = [
        {
            key: "1",
            value: "1",
            text: "Todos",
        },
        {
            key: "2",
            value: "2",
            text: "Las seleccionadas",
        }
    ];
    const onChangeType = (event, data) => {
        if (data.value === "2") {
            if (varietyList.length > 0) {
                setVarietyList(varietyList.map((variety) => ({
                    ...variety,
                    selected: false,
                    filter: false
                })))
            }
        }
        else {
            setVarietyList(varietyList.map((variety) => ({
                ...variety,
                selected: false,
            })))
        }
        props.setParam(prevEdit => ({ ...prevEdit, varietyType: data.value }));
    };
    const onCleanTextVariety = () => {
        setVarietyList((prev) => {
            const updated = prev.map((item) => {
                return {
                    ...item,
                    filter: false
                };
            });
            return updated;
        });
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachVarietyPanel(false);
        setVarietyList(varietyList.map((variety) => ({
            ...variety,
            selected: false,
            filter: false
        })))
        props.setParam(prevEdit => ({ ...prevEdit, varietyType: "1", variety: undefined }));
    };
    const GetVarietysByStatusActive = async () => {
        let result = await GetActives();
        if (result.isSuccessful) {
            setVarietyList(result.data);
        }
    }
    useEffect(() => {
        GetVarietysByStatusActive();
    }, [])
    useEffect(() => {
        setMessages([]);
    }, [props.showAttachVarietyPanel])
    return (
        <OverlayDrawer position="end" open={props.showAttachVarietyPanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                     Filtro de variedades
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <BarMessageList messages={messages} />
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniRadioGroup name="varietyType"
                            title="Variedades"
                            orientation="horizontal"
                            items={itemsRadio}
                            default={props.param.varietyType} inactivefieldEdited onChange={onChangeType} />
                    </div>
                </div>
                {props.param.varietyType === "2" &&
                    <div className='row mt-2'>
                        <div className='col-md-6'>
                            <OmniTextBox focus title="Búsqueda de variedades" maxLength={64} onChange={onChangeVarietyTypeText} inactivefieldEdited />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        varietyList.map((item) => (
                                            item.filter === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxVariety} id={item.id} key={item.id} name={item.name} propertyChecked={item.selected} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <OmniLabel text='Variedades seleccionadas' />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        varietyList.map((item) => (
                                            item.selected === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxVariety} id={item.id} key={item.id} propertyChecked={true} />

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addVariety}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}