import { Field, Combobox, Option } from '@fluentui/react-components';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import '../../../../Assets/Styles/Shared/Controls/OmniCombo.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState, useEffect } from 'react';
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
export const OmniTimePicker = (props) => {
    
    const includeDefaultOption = props?.IncludeDefaultOption === undefined ? true : props.IncludeDefaultOption;
    const fieldWatcher = useFieldChangeWatcherContext();
    const formControlId = props?.register?.name;
    const initialFieldValue = GetInitialSelectedOption();
    const [selectedValue, setSelectedValue] = useState(initialFieldValue);
    const fieldEdited = useRef(false);
    
    const [matchingOptions, setMatchingOptions] = useState();
    const [openSelect, setOpenSelect] = useState(false);
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.value) {
            initialSelectedOpction = props?.value
        }
        else if (includeDefaultOption && includeDefaultOption === true) {
            initialSelectedOpction = "";
        }
        else if (props?.items) {
            initialSelectedOpction = props.items.length > 0 ? props.items[0]?.value : '';
        }
        else {
            initialSelectedOpction = '';
        }
        return initialSelectedOpction;
    };
    const OnSelectChancheHandler = (event) => {
        const text = event.target.value;
        const numberPattern = /^[0-9]+$/;
        if (numberPattern.test(text) || event.nativeEvent.inputType === 'deleteContentBackward') {
            props.onChangeItemText && props.onChangeItemText(text);
            if (props.items) {
                const matches = props.items.filter((option) =>
                    option.text
                        .toLowerCase()
                        .includes(text.toLowerCase())
                );
                setMatchingOptions(matches);
                if (initialFieldValue == text) {
                    fieldEdited.current = false;
                }
                else {
                    if (!props.inactivefieldEdited) {
                        fieldEdited.current = true;
                    }
                }
            }
            setSelectedValue(text);
        }

    };
    useEffect(() => {
        setMatchingOptions(props.items)
    }, [props.items]);
    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === formControlId) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }

    }, [fieldEdited.current]);
    const [selectedOptions, setSelectedOptions] = useState([
        props?.selected?.toString()
    ]);
    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {

        if (data.selectedOptions !== undefined && data.optionText !== undefined) {
            setSelectedOptions(data.selectedOptions);
            let textOption = matchingOptions.find(x => x.value == data.optionValue).text;
            setSelectedValue(textOption ?? "");
            if (props.edit && props.property) {
                props.edit(prevEedit => ({ ...prevEedit, [props.property]: textOption}));
            }
            props.onChangeItem && props.onChangeItem(data.optionValue, textOption);
            if (initialFieldValue == textOption) {
                fieldEdited.current = false;
            }
            else {
                if (!props.inactivefieldEdited) {
                    fieldEdited.current = true;
                }
            }
        }
    };
    useEffect(() => {
        setSelectedValue(props.value ?? '');
    }, [props.value]);
    useEffect(() => {
        if (props.selected) {
            setSelectedOptions([props.selected.toString()]);
        }
    }, [props.selected]);
    //const onOpenChange = (event) => {
    //    if (openSelect) {
    //        if (props?.onOpenChange) {
    //            if (typeof props.onOpenChange === 'function') {
    //                props.onOpenChange(event);
    //            }
    //        }
    //    }
    //    setOpenSelect(openSelect ? false : true);
    //};
    const onBlur = (event) => {
        if (props?.onBlur) {
            if (typeof props.onBlur === 'function') {
                props.onBlur(event);
            }
        }
    };
    return (
        <>
            <div className={'container-controls'} style={{ width: props.width }}>
                <Field className={"label-omni"} validationState={props.validationMessage === undefined ? "none" : "error"}
                    validationMessage={props.validationMessage ?? null}>
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>

                    <Combobox
                        {...props}
                        {...props.register}
                        expandIcon={props?.showSpinner === true && <Spinner size={SpinnerSize.medium} />}
                        placeholder="Seleccione"
                        onChange={OnSelectChancheHandler}
                        appearance="underline"
                        autoFocus={props.focus}
                        className={"uppercaseInput combo-omni " + props.className}
                        title="" freeform
                        value={selectedValue}
                        selectedOptions={selectedOptions}
                        onOptionSelect={onOptionSelect}
                        autoComplete="off"
                        listbox={{ className: "listBox-combo" }}
                        onBlur={onBlur}
                    >
                       
                        {matchingOptions && matchingOptions.map((option) => (
                                <Option key={option.key} value={option.value.toString()}>

                                    <span >
                                        {option.text.split(new RegExp(`(${selectedValue})`, 'gi')).map((part, index) => {

                                            return part.toLowerCase() === selectedValue.toLowerCase() ?
                                                (option.key == selectedOptions[0] ? (<span key={index}>
                                                    {part}
                                                </span>) :
                                                    (<span key={index} className="searchOption">
                                                        {part}
                                                    </span>)
                                                ) : (
                                                    <span key={index}>
                                                        {part}
                                                    </span>
                                                );
                                        })}
                                    </span>
                                </Option>
                            ))}
                        {matchingOptions && matchingOptions.length === 0 ? (
                            <Option key="no-results" text="">
                                Resultado no encontrado
                            </Option>
                        ) : null}
                    </Combobox>
                </Field>
            </div>
        </>
    );
}