export const AccountingSystemEnum = {
    CONTPAQi: 1,
    MICROSIP: 2,
    ASPELCOI: 3,
}

export const AccountingTypeEnum = {
    summary: 1,
    extended: 2,
}
