import { Label, } from '@fluentui/react-components';
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css';
import { useState } from 'react';
import { Tooltip } from "@fluentui/react-components";
export const OmniLabelTooltip = (props) => {
    const [visible, setVisible] = useState(false);
    return (
       <>
            {props.infoText ? <Tooltip
                content={{
                    children: props.infoText
                }}
                positioning="above-start"
                withArrow
                onVisibleChange={(e, data) => setVisible(data.visible)}>
                <div className={'container-controls'}>
                    <Label className='label-omni'>{props.text}</Label>
                    <div className='container-omni-result'>
                        <Label className={props.className}>{props.value}</Label>
                    </div>
                </div >
            </Tooltip> :
                <div className={'container-controls'}>
                    <Label className='label-omni'>{props.text}</Label>
                    <div className='container-omni-result'>
                        <Label className={props.className}>{props.value}</Label>
                    </div>
                </div >
            }
        </>
    )
}