import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import { Detail } from '../../../Shared/EmailList/Detail';
export const ContactDetail = (props) => {
    const itemSelected = props.description[props.property].find(x => x.id == [props.itemSelected[props.property]]);
    return (
        <>
            {itemSelected &&
                <OmniCard title='Datos generales'>
                    <OmniLabel text='Nombre del contacto' value={itemSelected?.contactName} />
                    <OmniLabel text='Rol' value={itemSelected?.rol} />
                    <OmniLabel text='Teléfono celular' value={itemSelected?.cellPhone} />
                    <OmniLabel text='Se le envía comprobante de venta por correo' value={itemSelected?.proofMail ? "Si" : "No"} />
                    <Detail description={itemSelected}
                        property="listContactsEmail" titleColum="Lista de correos" />
                </OmniCard>
            }
        </>
    )
}