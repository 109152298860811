import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Pivot, PivotItem } from '@fluentui/react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Items/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { AddAsync, SetAxiosPrivate, GetItemsByVarietyIdAsync } from '../../../Functions/Catalogs/Items/ItemStore';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"
import GeneralData from './Add/GeneralData';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import { GetActives as GetVarietiesAsync } from "../../../Functions/Catalogs/Varieties/VarietyStore";
import Comission from './Add/Comission';
import Validations from './Add/Validations';
import WeightVolume from './Add/WeightVolume';
import ItemsByBranch from './Add/ItemsByBranch';
import Resctocking from './Add/Resctocking';
import Stock from './Add/Stock';
import { GetActivesWithAllPropertiesAsync as GetWarehousesAsync, SetAxiosPrivate as SetAxiosPrivateWarehouses } from '../../../Functions/Configuration/Warehouse/WarehouseStore';


const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit, getValues} = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [openCallout, setOpenCallout] = useState();
    const[item, setItem]=useState();
    const[branches,setBranches]=useState();
    const[varieties, setVarieties]=useState();
    const[warehouses,setWarehouses]=useState();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateWarehouses(axiosPrivate);
    

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            item.commission=item.commission!=null?item.commission.replace('%',''):item.commission;
            let result = await AddAsync(item, useForeignKey);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                //data.id = result.data;
                addNewItem({ ...item, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };

    const getBranches = async () => {
        let result = await GetBranchsAsync();
        if(result.isSuccessful){
            var branchesList= result.data.map((branch) => ({
                key:branch.brachKey,
                id:branch.key,
                description: branch.value+" - " + branch.text,
                useWarehouse:branch.useWarehouse
            }));
            setBranches(branchesList);
        }
    }

    const getVarieties= async ()=>{
        let result=await GetVarietiesAsync();
        if(result.isSuccessful){
            let varietyToCombo=[];
            result.data.map((varietyItem)=>{
                let varietyItemToFilter={
                    value:varietyItem.id,
                    text:varietyItem.name
                }
                varietyToCombo.push(varietyItemToFilter)
            });
            setVarieties(varietyToCombo);
        }
    }

    const getWarehouses=async()=>{
        let result=await GetWarehousesAsync();
        if(result.isSuccessful){
            setWarehouses(result.data);
        }
    }

    useEffect(()=>{
        getBranches();
        getVarieties();
        getWarehouses();
    },[])

    return (
        <>
        <form onSubmit={handleSubmit(submit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" >
                <PivotItem headerText="Datos Generales">
                    <GeneralData item={item} setItem={setItem} 
                        varietiesList={varieties}
                        getItemsByVarietyIdAsync={GetItemsByVarietyIdAsync}
                        />
                </PivotItem>
                <PivotItem headerText="Claves y códigos">
                    {/*
                    <KeysAndcodes item={item} setItem={setItem} />
                    */}
                    
                </PivotItem>
                <PivotItem headerText="Peso y volumen">
                    <WeightVolume item={item} setItem={setItem} />
                </PivotItem>
                <PivotItem headerText="Existencias">
                    <Stock item={item} setItem={setItem} warehouses={warehouses} branches={branches}/>
                </PivotItem>
                <PivotItem headerText="Resurtido">
                    <Resctocking item={item} setItem={setItem} />
                </PivotItem>
                <PivotItem headerText="Artículos por sucursal">
                    <ItemsByBranch item={item} setItem={setItem} branches={branches} />
                </PivotItem>
                <PivotItem headerText="Validaciones">
                    <Validations item={item} setItem={setItem} />
                </PivotItem>
                <PivotItem headerText="Comisiones">
                    <Comission item={item} setItem={setItem} />
                </PivotItem>
            </Pivot>
        </form>
        {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={AddConfirm}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rigthCommandBar'}
        />
            }
        </>
    )
}
