export const Catalogs = [
    {
        type: 'ItemService',
        titleBarButtons: 'Editando estatus de artículos y servicios...',
        titleCardDetail: 'Artículos y servicios activos',
        titleCardEdit: 'Listado de artículos y servicios'
    },
    {
        type: 'MeasureUnit',
        titleBarButtons: 'Editando estatus de medidas...',
        titleCardDetail: 'Medidas activas',
        titleCardEdit: 'Listado de medidas'
    },
    {
        type: 'PaymentMethods',
        titleBarButtons: 'Editando estatus de formas de pago...',
        titleCardDetail:'Formas de pago activas',
        titleCardEdit: 'Listado de formas de pago'
    },
    {
        type: 'TypesRelationship',
        titleBarButtons: 'Editando estatus de tipos de relación...',
        titleCardDetail:'Tipos de relación activas',
        titleCardEdit: 'Listado de tipos de relación'
    },
    {
        type: 'TaxRegimes',
        titleBarButtons: 'Editando estatus de regímenes fiscales...',
        titleCardDetail: 'Regímenes fiscales activos',
        titleCardEdit: 'Listado de regímenes fiscales'
    },
    {
        type: 'UsesCFDI',
        titleBarButtons: 'Editando estatus de usos de CFDI...',
        titleCardDetail:'Usos del CFDI activos',
        titleCardEdit: 'Listado de usos del CFDI',
    },
    {
        type: 'Contries',
        titleBarButtons: 'Editando estatus de países...',
        titleCardDetail: 'Países activos',
        titleCardEdit:'Listado de países'
    }
];
export const ActiveCatalogs = {
    ItemService: 393,
    MeasureUnit: 395,
    Contries: 402,
    UsesCFDI: 408
}
export const InactiveCatalogs = {
    ItemService: 392,
    MeasureUnit: 394,
    UsesCFDI: 407
}










