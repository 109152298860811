const offerCampaignEndpoint = {
    controller: "offercampaign",
    actions: {
        getById: "GetById",
        add: "Add",
        update: "Update",
        setStatus: "SetStatus"
    }
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(offerCampaignEndpoint.controller, offerCampaignEndpoint.actions.getById).url, {
                params: { id: id }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(offerCampaignEndpoint.controller, offerCampaignEndpoint.actions.add).url,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName"),
            }));
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `La campaña se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(offerCampaignEndpoint.controller, offerCampaignEndpoint.actions.update).url,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La campaña se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const SetStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(offerCampaignEndpoint.controller, offerCampaignEndpoint.actions.setStatus).url,
            JSON.stringify({
                id: id,
                active: status,
                loggedUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activada" : "desactivada";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La campaña ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}


export const FormatDate = (date) => {
    if (date == undefined) {
        return undefined;
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
export const ToShortDate = (dateParam) => {
    if (dateParam) {
        const date = new Date(dateParam);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dayFormat = day < 10 ? '0' + day : day;
        const monthFormat = month < 10 ? '0' + month : month;
        const dateFormateada = `${dayFormat}/${monthFormat}/${year}`;
        return dateFormateada;
    }
    return "";
}
export const CalculateDaysBetweenDates = (startDate, endDate) => {
    if (startDate == undefined || endDate == undefined) {
        return '';
    }
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("/").map(Number);
        return new Date(year, month - 1, day);
    };
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;
    const roundDays = Math.ceil(differenceInDays);
    const days = roundDays == 1 ? roundDays + ' día' : roundDays + ' días';
    return days;
};


function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}