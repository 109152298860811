
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { Pivot, PivotItem } from "@fluentui/react";
import { useState, useEffect } from 'react';
import { Detail } from './Detail'; import {
    SetAxiosPrivate, GetAllItemServiceAsync, GetAllMeasureUnitAsync, GetAllPaymentMethodsAsync, GetAllTypesRelationshipAsync,
    GetAllTaxRegimesAsync, GetAllUsesCFDIAsync, GetAllContriesAsync
} from '../../../Functions/Configuration/SatCatalogs/SatCatalogStore';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import "./../../../Assets/Styles/Configuration/SatCatalogs/SatCatalog.css";
import "./../../../Assets/Styles/Shared/Controls/OmniTree.css";
export default function SatCatalog() {
    const pivotStyles = {
        root: {
            width: '95%'
        }
    };
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    const [selectedKey, setSelectedKey] = useState("ItemService");
    const [catalog, setCatalog] = useState();
    const [messages, setMessages] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else {
            return {
                disabled: false,
                style: { color: 'var(--dark)' }
            }
        }
    }
    const getCatalog = async (selectedKey) => {
        let result = [];
        switch (selectedKey) {
            case "ItemService":
                setMessages([]);
                result = await GetAllItemServiceAsync();
                break;
            case "MeasureUnit":
                setMessages([]);
                result = await GetAllMeasureUnitAsync();
                break;
            case "PaymentMethods":
                setMessages([]);
                result = await GetAllPaymentMethodsAsync();
                break;
            case "TypesRelationship":
                setMessages([]);
                result = await GetAllTypesRelationshipAsync();
                break;
            case "TaxRegimes":
                setMessages([]);
                result = await GetAllTaxRegimesAsync();
                break;
            case "UsesCFDI":
                setMessages([]);
                result = await GetAllUsesCFDIAsync();
                break;
            case "Contries":
                setMessages([]);
                result = await GetAllContriesAsync();
                break;
            default:
                break;
        }
        if (result.isSuccessful) {
            if (result.data && result.data.data.length > 0) {
                setCatalog(result.data.data);
            }
            else {
                setMessages([new DangerMessage("No se encontraron resultados")]);
            }
        }
        else {
            setMessages([new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        if (selectedKey) {
            getCatalog(selectedKey)
        }
    }, [selectedKey]);
    return (
        <>
            <BarButtons
                leftButtons={[{
                    key: 'LblTitle',
                    text: isItemDisabled ?'Editando...':'Consultando...',
                    fontColor: 'var(--primary)',
                    fontSize: '16px',
                    isText: true
                }]} />
            <div className="background-sat">
                <Pivot className="pivot-center-client"
                    overflowBehavior="menu" styles={pivotStyles} onLinkClick={handleLinkClick}           >
                    <PivotItem headerText="Artículos y servicios" itemKey="ItemService" headerButtonProps={BlockPivots("ItemService")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"ItemService"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                    <PivotItem headerText="Medidas" itemKey="MeasureUnit" headerButtonProps={BlockPivots("MeasureUnit")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"MeasureUnit"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                    <PivotItem headerText="Formas de pago" itemKey="PaymentMethods" headerButtonProps={BlockPivots("PaymentMethods")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"PaymentMethods"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                    <PivotItem headerText="Tipos de relación" itemKey="TypesRelationship" headerButtonProps={BlockPivots("TypesRelationship")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"TypesRelationship"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                    <PivotItem headerText="Regímenes fiscales" itemKey="TaxRegimes" headerButtonProps={BlockPivots("TaxRegimes")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"TaxRegimes"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                    <PivotItem headerText="Uso de CFDI" itemKey="UsesCFDI" headerButtonProps={BlockPivots("UsesCFDI")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"UsesCFDI"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                    <PivotItem headerText="Países" itemKey="Contries" headerButtonProps={BlockPivots("Contries")}>
                        <Detail setIsItemDisabled={setIsItemDisabled} type={"Contries"} catalog={catalog} setCatalog={setCatalog} messages={messages} setMessages={setMessages} />
                    </PivotItem>
                </Pivot>
            </div>
        </>
    )
}