import { Label } from "@fluentui/react-components";
const satFileEndpoint = {
    controller: "satFile",
    actions: {
        getLastDate: "GetLastDate",
    }
}
const cfdiDocumentEndpoint = {
    controller: "cfdiDocument",
    actions: {
        getByFilter: "GetByFilter",
        getFileXmlById: "GetFileXmlById",
        updateStatusById: "UpdateStatusById",
        getFileZipById: "GetFileZipById"
    }
}
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetByFilterAsync = async (data,suppliers,status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(cfdiDocumentEndpoint.controller, cfdiDocumentEndpoint.actions.getByFilter).url,
            JSON.stringify({
                startDate: data.startDate,
                endDate: data.endDate,
                suppliers:suppliers,
                status: status,
                supplierType: data.supplierType,
                loggedUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            if (response.data.data && response.data.data.length > 0) {
                result.data = response.data.data.map(x => ({
                    ...x,
                    date: toShortDate(x.date)
                }))
            }
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(cfdiDocumentEndpoint.controller, cfdiDocumentEndpoint.actions.updateStatusById).url,
            JSON.stringify({
                id: id,
                loggedUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetLastDate = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(satFileEndpoint.controller, satFileEndpoint.actions.getLastDate).url);
        if (response.data.isSuccessful) {
            if (response.data.data) {
                result.data = response.data.data;
            }
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetFileXmlByIdAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(cfdiDocumentEndpoint.controller, cfdiDocumentEndpoint.actions.getFileXmlById).url,
            {
                params: {
                    fileXmlId: data,
                },
                responseType: 'blob'
            }
        );

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetFileZipByIdAsync = async (data, suppliers, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(cfdiDocumentEndpoint.controller, cfdiDocumentEndpoint.actions.getFileZipById).url,
            {
                params: {
                    startDate: data.startDate,
                    endDate: data.endDate,
                    suppliers: suppliers.join(","),
                    status: status.join(","),
                    supplierType: data.supplierType
                },
                responseType: 'blob'
            }
        );

        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        console.log(err);
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export function DateDefault(date) {
    return <span className='cursor-pointer'> <Label>Desde el</Label> <Label className="color-info">{toShortDate(date.startDate)}</Label> <Label>hasta el</Label> <Label className="color-info">{toShortDate(date.endDate)}</Label></span>
}
export function DateDefaultDisabled(date) {
    return <span className='cursor-not-allowed'> <Label>Desde el</Label> <Label className="color-secondary">{toShortDate(date.startDate)}</Label> <Label>hasta el</Label> <Label className="color-secondary">{toShortDate(date.endDate)}</Label></span>
}
export function LastDate(date) {
    return <span className='cursor-not-allowed'> <Label>Última consulta </Label> <Label className="color-secondary">{date}</Label></span>
}
export const GetDate = () => {
    let day = new Date();
    let dayMonday = new Date();
    if (day.getDay() === 1) {
        return { startDate: new Date(day.setDate(day.getDate() - 2)), endDate: new Date(dayMonday.setDate(dayMonday.getDate() - 1)), dayDiff: '2 días' };
    }
    else {
        return { startDate: day, endDate: day, dayDiff: '1 día' };

    }
}
export const FormatMoney = (data) => {
    return Intl.NumberFormat('es-MX', {
        style: 'currency', currency: 'MXN',
        maximumFractionDigits: 2
    }).format(data)
        ;
}
/*              PRIVATE FUNC            */
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action);
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}

const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage ?? err.response?.data.title
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail ?? (err.message + "  (" + err.code + ")");
    }
}
function toShortDate(dateParam) {
    if (dateParam) {
        const date = new Date(dateParam);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dayFormat = day < 10 ? '0' + day : day;
        const monthFormat = month < 10 ? '0' + month : month;
        const dateFormateada = `${dayFormat}/${monthFormat}/${year}`;
        return dateFormateada;
    }
    return "";
}
export function ToShortDateNow() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dayFormat = day < 10 ? '0' + day : day;
    const monthFormat = month < 10 ? '0' + month : month;
    const dateFormateada = `${dayFormat}${monthFormat}${year}`;
    return dateFormateada;
}