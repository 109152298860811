import { Checkbox, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { useEffect } from "react";

export function ItemsByBranch(props){

    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-12 col-12'>
                    <OmniCard title='Artículos por sucursal'  classCard="long-card">
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                ID
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Nombre de la sucursal
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Se muestra
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                    {
                                        props.item.itemByBranches?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <TableCellLayout className="cell-bold">
                                                        {item.cuvikBranchKey}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell >
                                                    <TableCellLayout>
                                                        {   
                                                            props.branches?.find(b=>b.id==item.cuvikBranchId)?.description?.split('-')[1]
                                                        }
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell >
                                                    <TableCellLayout>
                                                        {
                                                            item.show
                                                            ?
                                                                <Checkbox className='cell-check-disabled' size='large' disabled checked />
                                                            :
                                                                <Checkbox className='check-omni-disabled cell-check-disabled' size='large' disabled />
                                                        }
                                                        
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}