import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { ReactComponent as PhotoCuvitek } from './../../../../Assets/Images/Icons/Photo.svg';
import { ReactComponent as PinCuvitek } from './../../../../Assets/Images/Icons/Pin.svg';
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Stack } from "@fluentui/react";
export const ShippingAddressesList = (props) => {
    const columnsHeaderRoutes = [
        { columnKey: "1", label: "Lista de direcciones", width: '80%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '10%' },
        { columnKey: "3", label: "", width: '10%' }
    ];
    const changeComponentClik = (id) => {
        props.setSelected((prevClient) => ({
            ...prevClient,
            [props.property]: id
        }));
        if (props.setView) {
            props.setView((prevView) => ({
                ...prevView,
                [props.property]: {
                    viewAdd: false,
                    viewDetail: true,
                    viewEdit: false
                }
            }));
        }
    };
    return (
        <>
            <Stack vertical>
                <Stack.Item >
                    <OmniCard title='Direcciones'>
            <div className="detail-table-scroll">
                <Table className="detail-table">
                    <TableHeader>
                        <TableRow >
                            {columnsHeaderRoutes.map((column) => (
                                <TableHeaderCell key={column.columnKey}
                                    style={{ width: column.width }}
                                    className={'detail-table-header-title ' + column?.classAdd}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.description[props.property] &&
                            props.description[props.property].map((item, index) => (
                                <TableRow key={index} onClick={() => changeComponentClik(item.id)}
                                    className={(props.itemSelected && item.id == props.itemSelected[props.property]) ? "item-table-selected" : "item-table"}>
                                    <TableCell >
                                        <TableCellLayout className={(item?.active ? "delete-table-body-description" : "delete-table-body-description color-danger")}>
                                            {item.branchName}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell >
                                        <TableCellLayout media={item.imageId && <PhotoCuvitek />} className="iconList" />
                                    </TableCell>
                                    <TableCell >
                                        <TableCellLayout media={(item?.latitude != 0 && item?.longitude != 0 && item?.latitude != undefined && item?.longitude != undefined) && <PinCuvitek />} className="iconList" />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                        </div>
                    </OmniCard>
                </Stack.Item>
            </Stack>
        </>
    )
}