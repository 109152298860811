import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import { Login } from './Components/Authentication/Login'
import RequireAuth from './Functions/Authentication/RequireAuth';
import PersistLogin from './Functions/Authentication/PersistLogin';
import './Assets/Styles/Site.css'
import AppRoutes from './AppRoutes';

function App() {

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Layout />}>
                {/*<Route path="register" element={<Register />} />*/}
                {/*<Route path="unauthorized" element={<Unauthorized />} />*/}

                {/*    ROUTES WITH AUTHENTICATION*/}
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth  />}>
                        
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />
                        })}
                    </Route>
                </Route>

                {/* ANY ELSE */}
                {/*<Route path="*" element={<Missing />} />*/}
            </Route>
        </Routes>
    );
}

export default App;