export const leftButtons: Buttons[] = [{
    key: 'LblTitle',
    text: 'Nuevo cliente en la ruta',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickSave, handleClickReturn) => [{
    key: 'BtnGuardar',
    text: 'Guardar',
    iconName: 'saveCuvitek',
    fontColor: 'var(--success)',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    backgroundColorButton: '#fff',
    backgroundColorButtonHover: '#fff',
    isButton: true,
    borderColor: 'var(--success) solid 2px',
    handler: handleClickSave,
    authorizationKey: 20
}
    , {
        key: 'BtnRegresar',
        text: 'Regresar',
        iconName: 'returnCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        handler: handleClickReturn
    }]