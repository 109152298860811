import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
export const Map = (props) => {
    const mapContainerStyle = {
        width: "100%",
        height: "200px",
    };
    const center = {
        lat: props.latitude,
        lng: props.longitude, 
    };
    return (
        <LoadScript googleMapsApiKey="AIzaSyDhv9l_yb3DDJqtCKGqsES-qW0ak83x4Ko">
            <GoogleMap 
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={17}
                options={{
                    scrollwheel: false, draggable: false, mapTypeControl: false,
                    disableDefaultUI: true,
                }}
            >
                <Marker position={center} />
            </GoogleMap>
            <a className="mapsLink" href={`https://www.google.com/maps?q=${props.latitude},${props.longitude}`} target="_blank" rel="noopener noreferrer">
                Abrir en Google Maps
            </a>
        </LoadScript>
    );
};