import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Roles/Role';
import { Pivot, PivotItem } from "@fluentui/react";
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = () => {
    return (
        <>
            <BarButtons rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                selectedKey={null}>
                <PivotItem headerText="Información" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Permisos" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Otorgantes" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Documentos" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Empleados" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>
        </>
    )
}