import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/PreticketGroups/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Configuration/PreticketGroups/PreticketGroupStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import Folder from './DetailComponents/Folder'
import {
    leftButtonsDetail
} from '../../../Assets/Constants/BarButtons/Catalog/Routes/Editing';

export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [preticketGroup, setPreticketGroup] = useState([]);
    const [buttonsPreticketGroup, setButtonsPreticketGroup] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [view, setView] = useState()
    const [itemSelected, setItemSelected] = useState();

    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };

    const pivotItemButtonProps = {
        disabled: isItemDisabled,
        style: { color: isItemDisabled ? 'var(--secondary)' : 'var(--dark)' }
    };
    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else{
            return {
                disabled: false,
                style: { color:'var(--dark)' }
            }
        }

    }
    function ChangeButtons() {
        if (selectedKey && (selectedKey === 'fridayList'
            || selectedKey === 'saturdayList')) {
            setButtonsPreticketGroup(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        } else {
            setButtonsPreticketGroup(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }
    const getById = async () => {
        setLoadingSkeleton(true);
        setIsItemDisabled(true);
        setItemSelected(undefined);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setPreticketGroup({
                id: result.data.id,
                name:result.data.name,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
            setIsItemDisabled(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !preticketGroup.active);
        if (result.isSuccessful) {
            setPreticketGroup((prevPreticketGroup) => {
                return {
                    ...prevPreticketGroup,
                    active: !preticketGroup.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = preticketGroup.active ? "Inactivar" : "Activar";
                let statusiconbutton = preticketGroup.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = preticketGroup.active ? 494 : 495
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el grupo?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !preticketGroup.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }



    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (preticketGroup) {
            ChangeButtons();
            setButtonsPreticketGroup(prevPreticketGroup => ({
                ...prevPreticketGroup,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...preticketGroup };
            updateItemStatus({ ...data, acronym: localStorage.getItem("userName"), description:data.documentTypeDescription });
        }
    }, [preticketGroup?.active]);

    useEffect(() => {
        if (preticketGroup) {
            ChangeButtons();
            setButtonsPreticketGroup(prevPreticketGroup => ({
                ...prevPreticketGroup,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);   

    return (
        <>
            {document.length !== 0 &&
                (<BarButtons rightButtons={buttonsPreticketGroup.rightButtons}
                leftButtons={buttonsPreticketGroup.leftButtons} />)}
            <Pivot className="pivot-center-client" onLinkClick={handleLinkClick}>
                <PivotItem headerText="Información" headerButtonProps={pivotItemButtonProps} >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre del grupo' value={preticketGroup.name} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div> 
                </PivotItem>
                <PivotItem headerText="Carpetas" headerButtonProps={BlockPivots("tuesdayList")} >
                    <Folder 
                        setIsItemDisabled={setIsItemDisabled}
                        setView={setView} 
                        view={view}
                        setbuttonsCatalog={setButtonsPreticketGroup}
                        route={preticketGroup} 
                        property="mondayList"
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        />
                </PivotItem>
                <PivotItem headerText="Lista de artículos" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}></PivotItem>
                <PivotItem headerText="Vista previa" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}></PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={preticketGroup} />
                </PivotItem>
            </Pivot>
        </>
    )
}