import { ReactComponent as Save } from '../../../Images/Icons/Save.svg';
import { ReactComponent as AddBlack } from '../../../Images/Icons/AddBlack.svg';
import { ReactComponent as InactiveBlack } from '../../../Images/Icons/InactiveBlack.svg';
import { ReactComponent as ActiveBlack } from '../../../Images/Icons/ActiveBlack.svg';

export const RolesIconsActions = [
    { description: "Nuevo", icon: <AddBlack /> },
    { description: "Salvar", icon: <Save /> },
    { description: "Eliminar", icon: <InactiveBlack /> },
    { description: "Reactivar", icon: <ActiveBlack /> },
];