import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Roles/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, GetDocumentsDaysAsync } from '../../../Functions/Configuration/Roles/RoleStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
import { leftButtonsDetail } from '../../../Assets/Constants/BarButtons/Configurations/Roles/Editing';
import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import { Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from '@fluentui/react-components';
import { CatalogAuthorizations } from './Authorizations/CatalogAuthorizations';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, setChangePivotKey, changePivotKey } = useGeneralStatesContext();
    const [role, setRole] = useState();
    const [itemSelected, setItemSelected] = useState();
    const [buttonsRole, setButtonsRole]
        = useState({ leftButtons: [], rightButtons: [] });
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    //const [view, setView] = useState({
    //    listGrantingUser: {
    //        viewAdd: false,
    //        viewDetail: false,
    //        viewEdit: false,
    //    }
    //});
    const [documentDays, setDocumentDays] = useState();
    const [changestatus, setChangestatus] = useState();
    const getById = async () => {
        setIsItemDisabled(true);
        setItemSelected(undefined);
        //setView({
        //    listGrantingUser: {
        //        viewAdd: false,
        //        viewDetail: false,
        //        viewEdit: false,
        //    }
        //});
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setRole({ ...result.data });
            setLoadingSkeleton(false);
            setIsItemDisabled(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const GetDocumentsDays = async () => {
        let result = await GetDocumentsDaysAsync();
        if (result.isSuccessful) {
            setDocumentDays(result.data);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !role.active);
        if (result.isSuccessful) {
            setRole((prevRoute) => {
                return {
                    ...prevRoute,
                    active: !role.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = role.active ? "Inactivar" : "Activar";
                let statusiconbutton = role.active ? "inactiveCuvitek" : "activeCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el rol?`,
                    disabled: !role.edit,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !role.edit || !role.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        if (role) {
            ChangeButtons();
        }
        if (changestatus) {
            updateItemStatus({ ...role, updateUserName: localStorage.getItem("userName") });
        }
    }, [role?.active]);
    useEffect(() => {
        if (role) {
            ChangeButtons();
        }
    }, [role?.edit]);
    useEffect(() => {
        if (role) {
            ChangeButtons();
        }
    }, [changestatus, id]);
    function ChangeButtons() {
        if (selectedKey === 'permissions') {
            setButtonsRole(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));

        } else {
            setButtonsRole(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }
    const pivotItemButtonProps = {
        disabled: isItemDisabled,
        style: { color: isItemDisabled ? 'var(--secondary)' : 'var(--dark)' }
    };

    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else {
            return {
                disabled: false,
                style: { color: 'var(--dark)' }
            }
        }

    }
    useEffect(() => {
        if (role) {
            ChangeButtons();
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
    useEffect(() => {
        GetDocumentsDays();
    }, []);
    return (
        <>
            {role &&
                <BarButtons rightButtons={buttonsRole.rightButtons}
                    leftButtons={buttonsRole.leftButtons} />
            }
            <Pivot className="pivot-center-client" overflowBehavior="menu"
                styles={pivotStyles} selectedKey={selectedKey} onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
            >
                <PivotItem headerText="Información" headerButtonProps={pivotItemButtonProps} itemKey="information" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre' value={role?.roleName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Descripción' value={role?.description} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Permisos" headerButtonProps={BlockPivots("permissions")} itemKey="permissions" >
                    <CatalogAuthorizations role={role} setRole={setRole} property="listAuthorizations"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsRole}
                        setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                    />
                </PivotItem>
                <PivotItem headerText="Otorgantes" itemKey="grantors" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Usuarios otorgantes'>
                                    <div className='row card-items-container'>
                                        <div className='col-lg-12 col-md-12'>
                                            <Table className="detail-table">
                                                <TableHeader>
                                                    <TableRow>
                                                        <TableHeaderCell className='detail-table-header-title-left' style={{ width: '100%' }}>
                                                            Lista de usuarios
                                                        </TableHeaderCell>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {
                                                        role?.listGrantingUser?.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell >
                                                                    <TableCellLayout>
                                                                        {item.description}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div> 
                </PivotItem>
                <PivotItem headerText="Documentos" itemKey="documents" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Reportes'>
                                    {documentDays &&
                                        <>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniLabel text='Días permitidos para ver reportes' value={documentDays.find(x => x.key == role?.daysReports)?.text || documentDays.find(x => x.key === 1000)?.text} />
                                                </div>
                                                {!documentDays.some(x => x.key === role?.daysReports) &&
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniLabel text='Especificar días para ver reportes' value={role?.daysReports} />
                                                    </div>
                                                }
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniLabel text='Días permitidos para cancelar documentos' value={documentDays.find(x => x.key == role?.daysDocuments)?.text || documentDays.find(x => x.key === 1000)?.text} />
                                                </div>
                                                {!documentDays.some(x => x.key === role?.daysDocuments) &&
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniLabel text='Especificar días para cancelar documentos' value={role?.daysDocuments} />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                </OmniCard>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Notas de crédito'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Días para hacer una nota de crédito por descuento' value={role?.limitDaysCreditNotesDiscount} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Días para hacer una nota de crédito por devolución' value={role?.limitDaysCreditNotesReturn} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Empleados" itemKey="employees" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Empleados'>
                                    <div className='row card-items-container'>
                                        <div className='col-lg-12 col-md-12'>
                                            <Table className="detail-table">
                                                <TableHeader>
                                                    <TableRow>
                                                        <TableHeaderCell className='detail-table-header-title-left' style={{ width: '70%' }}>
                                                            Empleados con el rol
                                                        </TableHeaderCell>
                                                        <TableHeaderCell className='detail-table-header-title'>
                                                            Estatus
                                                        </TableHeaderCell>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {
                                                        role?.listUserWithRole?.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell >
                                                                    <TableCellLayout className={item?.active === 1 ? '' : 'color-danger'}>
                                                                        {item.fullName}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell className='detail-table-cell'>
                                                                    <TableCellLayout className={item?.active === 1 ? '' : 'color-danger'}>
                                                                        {item?.active === 1 ? "A" : "I"}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={pivotItemButtonProps}>
                    <DetailChanges user={role} />
                </PivotItem>
            </Pivot>
        </>
    )
}