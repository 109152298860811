import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/WarehouseLocations/Add';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Configuration/WarehouseLocations/WarehouseLocationStore';
import { GetToAutocomplete, SetAxiosPrivate as SetAxiosPrivateUsers} from '../../../Functions/Configuration/Warehouse/WarehouseStore'
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/WarehouseLocation.svg').default
}
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const[warehouseLocation, setWarehouseLocation]=useState();
    const [warehouses,setWarehouse]=useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [userLoad, setUserLoad] = useState();
    const [serchClient, setSerchClientr] = useState('');
    SetAxiosPrivateUsers(axiosPrivate);
    

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            if (warehouseLocation === undefined || warehouseLocation.warehouseId === undefined) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage("El nombre es obligatorio")]);
            }
            else {
                var data = getValues();
                data.warehouseId = warehouseLocation.warehouseId;
                data.name = warehouseLocation.warehouseName;
                let result = await AddAsync(data, useForeignKey);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, result.data);
                    data.id = result.data;
                    data.name = warehouseLocation.warehouseName;
                    addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    };
    const GetWarehouses = async()=>{
        let result = await GetToAutocomplete();
        if (result.isSuccessful) {
            var warehouses= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setWarehouse(warehouses);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const onChangeItemWarehouse = async (id, warehouseLocation) => {
        setWarehouseLocation(prevEedit => ({
            ...prevEedit,
            warehouseId: id,
            warehouseLocation: warehouseLocation
        }));
    }

    const OnChangeWarehouseText = async (text) => {
        if (userLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }

    function ChangeText(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = userLoad.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.text.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                setWarehouse(filteredData);
            }
        }
    }
    useEffect(()=>{
        GetWarehouses();
    },[])

    
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información">
                        <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>                                   
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniCombobox
                                                    title='Almacen'
                                                    items={warehouses}
                                                    selected={warehouseLocation?.warehouseId}
                                                    value={warehouseLocation?.warehouseName}
                                                    onChangeItem={onChangeItemWarehouse} requiredOmni 
                                                    onChangeItemText={OnChangeWarehouseText} 
                                                    image={iconCatalog}/>
                                        </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Ubicación' maxLength={16} register={{ ...register('location') }} requiredOmni setValue={setValue} />
                                            </div>
                                        </div>
                                </OmniCard>
                            </div>
                        </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            <OmniCallout
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={AddConfirm}
                title='Confirmación'
                content='¿Desea guardar los cambios?'
                leftButton={'Si'}
                rightButton={'Regresar'}
                backgroundColorLeft={'var(--success)'}
                colorLeft={'white'}
                backgroundColorRight={'var(--secondary)'}
                colorRight={'white'}
                buttonId={'BtnGuardar'}
                barGroupKey={'rigthCommandBar'}
            />
        </>
    )
}
