import React, { useState, useEffect, useContext, createContext } from "react";
import { AccountingSystemEnum, AccountingTypeEnum } from '../../Assets/Constants/Enums/AccountingSystem';

const accountConfigurationContext = createContext();

export function useAccountingConfigurationContext() {
    return useContext(accountConfigurationContext);
}

export const AccountConfigurationProvider = ({ children }) => {
    const [useAccounting, setUseAccounting] = useState(false);
    const [accountingSystem, setAccountingSystem] = useState(0);

    const [extendedAccountingCustomer, setExtendedAccountingCustomer] = useState(false);
    const [extendedAccountingSupplier, setExtendedAccountingSupplier] = useState(false);
    const [baseCustomers, setBaseCustomers] = useState('');
    const [baseSuppliers, setBaseSuppliers] = useState('');
    const [countAutoincrementCustomers, setCountAutoincrementCustomers] = useState(null);
    const [countAutoincrementSuppliers, setCountAutoincrementSuppliers] = useState(null);


    function separateAccountingAccount(account) {
        let base = "";
        let autoIncrement = "";
        let lastNonZeroIndex = account.length - 1;
        for (let i = account.length - 1; i >= 0; i--) {
            if (account[i] !== '0') {
                lastNonZeroIndex = i;
                break;
            }
        }
        base = account.substring(0, lastNonZeroIndex + 1);
        autoIncrement = account.substring(lastNonZeroIndex + 1).length;
        return { base, autoIncrement };
    }



    useEffect(() => {
        const accountConfigurations = localStorage.getItem("AccountConfigurations");
        if (accountConfigurations !== null && accountConfigurations !== 'null') {
            const configurations = JSON.parse(accountConfigurations);
            setUseAccounting(configurations.useAccounting);

            if (configurations.useAccounting) {
                const accountingSystem = configurations.accounting.accountingSystem;
                setAccountingSystem(accountingSystem);

                const isExtendedAccountingCustomer = configurations.accounting.accountingTypeCustomer === AccountingTypeEnum.extended;
                setExtendedAccountingCustomer(isExtendedAccountingCustomer);

                if (isExtendedAccountingCustomer) {
                    if (accountingSystem === AccountingSystemEnum.MICROSIP) {
                        setBaseCustomers(configurations.accounting.customers + '.');
                    }
                    else if (accountingSystem === AccountingSystemEnum.CONTPAQi)
                    {
                        let dataAccount = separateAccountingAccount(configurations.accounting.customers);
                        setBaseCustomers(dataAccount.base);
                        setCountAutoincrementCustomers(dataAccount.autoIncrement);
                    }
                }
                const isExtendedAccountingSupplier = configurations.accounting.accountingTypeSupplier === AccountingTypeEnum.extended;
                setExtendedAccountingSupplier(isExtendedAccountingSupplier);

                if (isExtendedAccountingSupplier) {
                    if (accountingSystem === AccountingSystemEnum.MICROSIP) {
                        setBaseSuppliers(configurations.accounting.suppliers + '.');
                    }
                    else if (accountingSystem === AccountingSystemEnum.CONTPAQi)
                    {
                        let dataAccount = separateAccountingAccount(configurations.accounting.suppliers);
                        setBaseSuppliers(dataAccount.base);
                        setCountAutoincrementSuppliers(dataAccount.autoIncrement);
                    }
                }
            }
        }
    }, []);

    return (
        <accountConfigurationContext.Provider
            value={{
                useAccounting,
                accountingSystem,
                extendedAccountingCustomer,
                extendedAccountingSupplier,
                baseCustomers,
                baseSuppliers,
                countAutoincrementCustomers,
                countAutoincrementSuppliers
            }}
        >
            {children}
        </accountConfigurationContext.Provider>
    )
}