const roleEndpoint = {
    controller: "role",
    actions: {
        getById: "GetById",
        updateStatus: "SetStatus",
        update: "update",
        add: "add",
        existRoleName: "existRoleName",
        existRoleNameEdit: "existRoleNameEdit",
        getDocumentsDays: "getDocumentsDays",
        getAuthorizationsAll: "getAuthorizationsAll",
        updateAuthorizations: "updateAuthorizations",
        getDaysDocumentByRolId:"getDaysDocumentByRolId"
    }
}
const userEndpoint = {
    controller: "user",
    actions: {
        getByActive: "GetByActive",
        getUserWithAdministratorRole:"GetUserWithAdministratorRole"
    }
}

var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(roleEndpoint.controller, roleEndpoint.actions.getById).url, {
            params: { id: id }
        });
        if (response.data.isSuccessful) {
            result.data = {
                id: response.data.data.id,
                roleName: response.data.data?.roleName,
                description: response.data.data?.description,
                active: response.data.data?.active,
                edit: response.data.data?.edit,
                daysReports: response.data.data?.daysReports,
                daysDocuments: response.data.data?.daysDocuments,
                limitDaysCreditNotesReturn: response.data.data?.limitDaysCreditNotesReturn,
                limitDaysCreditNotesDiscount: response.data.data?.limitDaysCreditNotesDiscount,
                listGrantingUser: generateObject(response.data.data?.listGrantingUserResult),
                listUserWithRole: generateObject(response.data.data?.listUserWithRoleResult),
                listAuthorizations: generateObject(response.data.data?.listAuthorizationsResult),
                createDate: response.data.data?.createDate,
                createUserName: response.data.data?.createUserName,
                lastUpdateDate: response.data.data?.lastUpdateDate,
                updateUserName: response.data.data?.updateUserName,
                createUserFullName: response.data.data?.createUserFullName,
                updateUserFullName: response.data.data?.updateUserFullName
            };
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateStatusAsync = async (id, active) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(roleEndpoint.controller, roleEndpoint.actions.updateStatus).url,
            JSON.stringify({
                id: id,
                active: active,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = active ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !active;
            result.successMessage = `El rol ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(roleEndpoint.controller, roleEndpoint.actions.update).url,
            JSON.stringify({
                id: id,
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El rol se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(roleEndpoint.controller, roleEndpoint.actions.add).url,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El rol se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const ExistRoleNameAsync = async (roleName) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(roleEndpoint.controller, roleEndpoint.actions.existRoleName).url,
            JSON.stringify({
                roleName: roleName,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")

            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const ExistRoleNameEditAsync = async (roleName,id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(roleEndpoint.controller, roleEndpoint.actions.existRoleNameEdit).url,
            JSON.stringify({
                roleName: roleName,
                id:id,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetUserByActive = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(userEndpoint.controller, userEndpoint.actions.getByActive).url);
        if (response.data.isSuccessful) {
            result.data = response.data.data.map((item) => ({
                id: item.id,
                description: item.fullName
            }));
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetUserWithAdministratorRoleAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(userEndpoint.controller, userEndpoint.actions.getUserWithAdministratorRole).url);
        if (response.data.isSuccessful) {
            result.data = response.data.data.map((item) => ({
                id: item.id,
                description: item.text
            }));
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetAuthorizationsAllAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(roleEndpoint.controller, roleEndpoint.actions.getAuthorizationsAll).url);
        if (response.data.isSuccessful) {
            result.data = response.data.data
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const updateAuthorizationsAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(getUrl(roleEndpoint.controller, roleEndpoint.actions.updateAuthorizations).url,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El rol se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
function generateObject(item) {
    return item.map(x => ({ ...x }));
}
export const GetDocumentsDaysAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUrl(roleEndpoint.controller, roleEndpoint.actions.getDocumentsDays).url);
        if (response.data.isSuccessful) {
            result.data = response.data.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.text
            }));
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetDaysDocumentByRolIdAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getUrl(roleEndpoint.controller, roleEndpoint.actions.getDaysDocumentByRolId).url,
            JSON.stringify({
                id: localStorage.getItem("rolId"),
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));

        result.data = response.data.id;
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
function getUrl(controller, action) {
    var result = {
        isSuccessful: false,
        url: "",
        errorMessage: ""
    }
    if (controller && action) {
        result.url = ("/" + controller + "/" + action).toLowerCase();
    } else {
        result.errorMessage = "Controller or action undefined.";
    }

    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
    else if (err.response?.status === 500) {
        return err.response?.data?.detail;
    }
}