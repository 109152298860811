import { useEffect, useState } from "react";
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons, leftButtons } from '../../../Assets/Constants/BarButtons/Configurations/Roles/Add';
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { AddAsync, SetAxiosPrivate, ExistRoleNameAsync, GetUserByActive, GetDocumentsDaysAsync, GetUserWithAdministratorRoleAsync } from '../../../Functions/Configuration/Roles/RoleStore';
import { Add as AddItem } from '../../Shared/ItemList/Add';
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { OmniDropdown } from "../../Shared/Controls/Dropdown/OmniDropdown";
import { useForm } from 'react-hook-form';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import Detail from './Detail';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"

const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [openCallout, setOpenCallout] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [role, setRole] = useState({
        listGrantingUser: [],
        daysReports: -1,
        daysDocuments: -1,
        daysReport: "Ninguno",
        daysDocument: "Ninguno",
        daysDocumentsSpecification:"0",
        daysReportsSpecification: "0",
        limitDaysCreditNotesReturn: "0",
        limitDaysCreditNotesDiscount: "0"
    });
    const [itemListUser, setItemListUser] = useState();
    const [documentDays, setDocumentDays] = useState();
    const [showErrors, setShowErrors] = useState({
        roleName: undefined,
        daysReportsSpecification: undefined,
        daysDocumentsSpecification: undefined,
        limitDaysCreditNotesReturn: undefined,
        limitDaysCreditNotesDiscount: undefined
    });
    const Save = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            let errorMessagesRol = [];
            for (const property in showErrors) {
                if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                    const errorValue = showErrors[property];
                    if (errorValue) {
                        errorMessagesRol.push(errorValue);
                    }
                }
            }
            if (errorMessagesRol.length > 0) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessagesRol.map(x => x).join('. '))]);
            }
            else {
                let data = { ...role };
                data.daysReports = role?.daysReports == 1000 ? role.daysReportsSpecification : data.daysReports;
                data.daysDocuments = role?.daysDocuments == 1000 ? role.daysDocumentsSpecification : data.daysDocuments;
                let result = await AddAsync(data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, result.data);
                    role.id = result.data;
                    addNewItem({ ...role, updateUserName: localStorage.getItem("userName") });
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }

    const submit = async () => {
        setOpenCallout(true);
    };
    const GetUser = async () => {
        let result = await GetUserByActive();
        if (result.isSuccessful) {
            setItemListUser(result.data);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const GetDocumentsDays = async () => {
        let result = await GetDocumentsDaysAsync();
        if (result.isSuccessful) {
            setDocumentDays(result.data);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const GetUserWithAdministratorRole = async () => {
        let result = await GetUserWithAdministratorRoleAsync();
        if (result.isSuccessful) {
            setRole(prev => ({
                ...prev,
                listGrantingUser: result.data
            }));
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        GetUser();
        GetDocumentsDays();
        GetUserWithAdministratorRole();
    }, [])
    const onBlurRoleName = async (event) => {
        let text = event.target.value;
        if (text) {
            if (text.length <= 64) {
                let result = await ExistRoleNameAsync(text)
                if (result.isSuccessful) {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        roleName: undefined
                    }));
                }
                else {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        roleName: result.errorMessage
                    }));
                    event?.target?.focus();
                }
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    roleName: 'la longitud máxima deben ser 64 caracteres'
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                roleName: `El campo nombre es obligatorio`
            }));
            event?.target?.focus();
        }
    };
    const onBlurDaysReportsSpecification = (event) => {
        DaysSpecification(event, "daysReportsSpecification", "ver reportes");
    };
    const onBlurDaysDocumentsSpecification = (event) => {
        DaysSpecification(event, "daysDocumentsSpecification", "cancelar documentos");
    };
    const onBlurLimitDaysCreditNotesDiscount = (event) => {
        DaysSpecification(event, "limitDaysCreditNotesDiscount", "hacer una nota de crédito por descuento");
    };
    const onBlurLimitDaysCreditNotesReturn = (event) => {
        DaysSpecification(event, "limitDaysCreditNotesReturn", "cancelar documentos");
    };
    function DaysSpecification(event, property, text) {
        const input = event.target.value;
        if (input) {
            if (parseInt(input) <= 366) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    [property]: undefined
                }));
            } else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    [property]: `Los días para ${text} no deben ser mayor a 366.`
                }));
                event?.target?.focus();
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `Los días para ${text} son obligatorios.`
            }));
            event?.target?.focus();
        }
    }
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}>
                <PivotItem headerText="Información" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox requiredOmni title='Nombre'
                                                focus property="roleName"
                                                edit={setRole} maxLength={64} validationMessage={showErrors.roleName}
                                                value={role?.roleName} blur={onBlurRoleName} />
                                        </div>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniTextBox title='Descripción'
                                                property="description"
                                                edit={setRole} maxLength={128}
                                                value={role?.description} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Permisos" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Otorgantes">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Usuarios otorgantes'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <AddItem focus description={role}
                                                setDescription={setRole}
                                                itemList={itemListUser}
                                                setItemList={setItemListUser}
                                                titleColum="Lista de usuarios" property="listGrantingUser"
                                                titleComboBox="Usuario que puede asignar el rol" />
                                        </div>
                                    </div>
                                </OmniCard>

                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Documentos">
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Reportes'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <OmniDropdown title='Días permitidos para ver reportes' items={documentDays}
                                            property="daysReports" name="daysReport"
                                            edit={setRole}
                                            value={role?.daysReport}
                                            selected={role?.daysReports} />
                                    </div>
                                    {role?.daysReports == 1000 &&
                                        <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Especificar días para ver reportes' inputType={'decimal'}
                                                    max={366}
                                                    decimals={0} property="daysReportsSpecification"
                                                edit={setRole} value={role?.daysReportsSpecification} blur={onBlurDaysReportsSpecification}
                                                validationMessage={showErrors.daysReportsSpecification}                                            />
                                        </div>
                                    }
                                    <div className='col-lg-12 col-md-12'>
                                        <OmniDropdown title='Días permitidos para cancelar documentos' items={documentDays}
                                            property="daysDocuments" name="daysDocument"
                                            edit={setRole}
                                            value={role?.daysDocument}
                                            selected={role?.daysDocuments} />
                                    </div>
                                    {role?.daysDocuments == 1000 &&
                                        <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Especificar días para cancelar documentos' inputType={'decimal'}
                                                    max={366}
                                                    decimals={0} property="daysDocumentsSpecification"
                                                edit={setRole} value={role?.daysDocumentsSpecification}  blur={onBlurDaysDocumentsSpecification}
                                                validationMessage={showErrors.daysDocumentsSpecification}                                            />
                                        </div>
                                    }
                                </div>
                            </OmniCard>
                        </div>
                        <div className='col-md-auto'>
                            <OmniCard title='Notas de crédito'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                            <OmniTextBox title='Días para hacer una nota de crédito por descuento' inputType={'decimal'}
                                                max={366}
                                                decimals={0} property="limitDaysCreditNotesDiscount"
                                            edit={setRole} value={role?.limitDaysCreditNotesDiscount}  blur={onBlurLimitDaysCreditNotesDiscount}
                                            validationMessage={showErrors.limitDaysCreditNotesDiscount} />
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                            <OmniTextBox title='Días para hacer una nota de crédito por devolución' inputType={'decimal'}
                                                max={366}
                                                decimals={0} property="limitDaysCreditNotesReturn"
                                            edit={setRole} value={role?.limitDaysCreditNotesReturn} blur={onBlurLimitDaysCreditNotesReturn}
                                            validationMessage={showErrors.limitDaysCreditNotesReturn} />
                                    </div>
                                </div>
                            </OmniCard>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Empleados" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Save}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>

    )
}