import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { useState, useEffect } from 'react'; import {
    SetAxiosPrivate, GetActives
} from '../../../Functions/Catalogs/Categories/CategoriesStore';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { InfoMessage } from '../../../Models/Shared/BarMessages/Messages';
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerCategory = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [categoryList, setCategoryList] = useState([]);
    const [messages, setMessages] = useState([]);
    const onChangeCategoryTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            setCategoryList((prev) => {
                const updated = prev.map((item) => {
                    if (item.name.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: false
                        };
                    }
                });
                return updated;
            });
        }
        else {
            onCleanTextCategory();
        }
    };
    const onChangeCheckBoxCategory = (event, data) => {
        if (data.checked) {
            setCategoryList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            setCategoryList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const addCategory = () => {
        if (props.param.categoryType === "2") {
            if (categoryList.some(x => x.selected === true)) {
                props.setShowAttachCategoryPanel(false);
                onCleanTextCategory();
                props.setParam((prev) => ({
                    ...prev,
                    category: categoryList.filter(x => x.selected === true).map(x => x.id),
                }))
            }
            else {
                setMessages([new InfoMessage("Debe seleccionar una categoría")]);
            }
        }
        else {
            props.setShowAttachCategoryPanel(false);
            onCleanTextCategory();
            props.setParam((prev) => ({
                ...prev,
                category: undefined,
            }))
        }
    };
    const itemsRadio = [
        {
            key: "1",
            value: "1",
            text: "Todos",
        },
        {
            key: "2",
            value: "2",
            text: "Las seleccionadas",
        }
    ];
    const onChangeType = (event, data) => {
        if (data.value === "2") {
            if (categoryList.length > 0) {
                setCategoryList(categoryList.map((category) => ({
                    ...category,
                    selected: false,
                    filter: false
                })))
            }
        }
        else {
            setCategoryList(categoryList.map((category) => ({
                ...category,
                selected: false,
            })))
        }
        props.setParam(prevEdit => ({ ...prevEdit, categoryType: data.value }));
    };
    const onCleanTextCategory = () => {
        setCategoryList((prev) => {
            const updated = prev.map((item) => {
                return {
                    ...item,
                    filter: false
                };
            });
            return updated;
        });
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachCategoryPanel(false);
        setCategoryList(categoryList.map((category) => ({
            ...category,
            selected: false,
            filter: false
        })))
        props.setParam(prevEdit => ({ ...prevEdit, categoryType: "1", category: undefined }));
    };
    const GetCategorysByStatusActive = async () => {
        let result = await GetActives();
        if (result.isSuccessful) {
            setCategoryList(result.data);
        }
    }
    useEffect(() => {
        GetCategorysByStatusActive();
    }, [])
    useEffect(() => {
        setMessages([]);
    }, [props.showAttachCategoryPanel])
    return (
        <OverlayDrawer position="end" open={props.showAttachCategoryPanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Filtro de categorías
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <BarMessageList messages={messages} />
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniRadioGroup name="categoryType"
                            title="Categorías"
                            orientation="horizontal"
                            items={itemsRadio}
                            default={props.param.categoryType} inactivefieldEdited onChange={onChangeType} />
                    </div>
                </div>
                {props.param.categoryType === "2" &&
                    <div className='row mt-2'>
                        <div className='col-md-6'>
                            <OmniTextBox focus title="Búsqueda de categorías" maxLength={64} onChange={onChangeCategoryTypeText} inactivefieldEdited />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        categoryList.map((item) => (
                                            item.filter === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxCategory} id={item.id} key={item.id} name={item.name} propertyChecked={item.selected} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <OmniLabel text='Categorías seleccionadas' />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        categoryList.map((item) => (
                                            item.selected === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxCategory} id={item.id} key={item.id} propertyChecked={true} />

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addCategory}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}