import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerSearchValue = (props) => {
    const getItemPage = (item, modifiedData) => {
        const index = modifiedData.indexOf(item);
        const page = Math.ceil((index + 1) / props.pageSize);
        return page;
    };
    const handleClickSearchValue = () => {
        let foundId = 1;
        props.setShowAttachSearchValuePanel(false);
        const modifiedData = props.totalPaginate.map((item) => {
            const found = Object.values(item)
                .some(value => typeof value === 'string' && value.includes(props.searchValueText));
            return {
                ...item,
                found: found === true ? foundId++ : undefined
            };
        });
        props.setTotalPaginate(modifiedData);
        if (modifiedData.some(x => x.found !== undefined)) {
            props.setFoundValue(1);
            let pageRender = getItemPage(modifiedData.find(x => x.found === 1), modifiedData)
            if (pageRender !== props.actualPage) {
                props.setActualPage(pageRender)
            }
        }
        else{
            props.setFoundValue(0);
        }
    };
    const onChangeEmployeeTypeText = (event) => {
        const value = event.target.value.toUpperCase().trim();
        if (value) {
            props.setSearchValueText(value)
        }
    };
    return (
        <OverlayDrawer position="end" open={props.showAttachSearchValuePanel} modalType="alert"
            style={{ width: '448px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Buscar valor
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniTextBox focus title="Valor" maxLength={64} onChange={onChangeEmployeeTypeText} inactivefieldEdited />
                    </div>
                </div>
               
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={() => {
                            props.setShowAttachSearchValuePanel(false);
                            props.setSearchValueText(undefined)
                        }}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={handleClickSearchValue}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}