import {
    leftButtons,
    rightButtons,
    rightButtonsSecond,
    rightButtonsDisabled,
    rightButtonsSecondDisabled,
    rightButtonsExec,
    rightButtonsExecUngrouped
} from '../../../Assets/Constants/BarButtons/Reports/AttendanceLogs/AttendanceLog';
import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { useState, useEffect, useRef } from 'react';
import {
    SetAxiosPrivate, GetDate, DateDefaultDisabled, DateDefault, ToShortDateNow, GetByFilterAllAsync, GetByFilterAllTxtAsync
} from '../../../Functions/Reports/AttendanceLogs/AttendanceLogStore';
import {
    SetAxiosPrivate as SetAxiosPrivateEmployee, GetByActiveAsync
} from '../../../Functions/Catalogs/Employees/EmployeeStore';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { OverlayDrawerDate } from '../../Shared/OverlayDrawer/OverlayDrawerDate';
import { OverlayDrawerEmployee } from '../../Shared/OverlayDrawer/OverlayDrawerEmployee';
import { OverlayDrawerAttendanceLog } from '../../Shared/OverlayDrawer/Group/OverlayDrawerAttendanceLog';
import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import { ReactComponent as TextIcon } from '../../../Assets/Images/Icons/Text.svg';
import { ReactComponent as FingerprintWithoutFill } from '../../../Assets/Images/Icons/FingerprintWithoutFill.svg';
import { DayPickerStrings } from '../../../Assets/Constants/Enums/TraslateDatePicker';
import * as XLSX from 'xlsx-color';
import React from 'react';
import { PaginateTable } from '../../Shared/Paginate/PaginateTable';
import {
    SetAxiosPrivate as SetAxiosPrivateRole, GetDaysDocumentByRolIdAsync
} from '../../../Functions/Configuration/Roles/RoleStore';
export default function AttendanceLog() {
    const columnsHeader = [
        { columnKey: "1", label: "Registro", width: '10%' },
        { columnKey: "2", label: "Fecha", width: '10%' },
        { columnKey: "3", label: "IDE", width: '10%' },
        { columnKey: "4", label: "Nombre", width: '20%' },
        { columnKey: "5", label: "Entrada", width: '10%' },
        { columnKey: "6", label: "TR", width: '10%' },
        { columnKey: "7", label: "Salida", width: '10%' },
        { columnKey: "8", label: "TR", width: '10%' },
        { columnKey: "9", label: "Intervalo", width: '10%' },
    ];
    const axiosPrivate = useAxiosPrivate();
    const tableRef = useRef(null);
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateEmployee(axiosPrivate);
    SetAxiosPrivateRole(axiosPrivate);
    const [messages, setMessages] = useState([]);
    const [showAttachEmployeePanel, setShowAttachEmployeePanel] = useState(false);
    const [showAttachDatePanel, setShowAttachDatePanel] = useState(false);
    const [showAttachGroupPanel, setShowAttachGroupPanel] = useState(false);
    const [policylist, setPolicylist] = useState();
    const [contract, setContract] = useState(true);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [totalPaginate, setTotalPaginate] = useState([]);
    const [foundValue, setFoundValue] = useState();
    const backgroundStyle = { fill: { fgColor: { rgb: "E3E3E3" } } };
    const [daysDocument, setDaysDocument] = useState();
    const [param, setParam] = useState({
        startDate: GetDate().startDate,
        endDate: GetDate().endDate,
        employeeType: "1",
        group: "1",
        groupText: 'Sin agrupar'
    });
    const handleClickNewQuery = () => {
        setParam({
            startDate: GetDate().startDate,
            endDate: GetDate().endDate,
            employeeType: "1",
            group: "1",
            groupText: 'Sin agrupar'
        });
        setEmployeeList(EmployeeList.map((Employee) => ({
            ...Employee,
            selected: false,
        })));
        setPolicylist(undefined);
    }
    const HandleClickContract = () => {
        setContract(!contract)
    }

    const UpdateButtons = () => {
        if (policylist && policylist.length > 0) {
                setButtons(prevEdit => ({
                    ...prevEdit,
                    rightButtons: rightButtonsExec(() =>
                        handleClickExcel(), handleClickNewQuery, contract, HandleClickContract)
                }));
        }
        else {
            setButtons(prevEdit => ({
                ...prevEdit,
                rightButtons: rightButtons(handleClickTrigger, handleClickNewQuery, contract, HandleClickContract, daysDocument)
            }));
        }
    }
    const handleClickTrigger = async () => {
        setButtons(prevEdit => ({
            ...prevEdit,
            rightButtons: rightButtonsDisabled(handleClickTrigger, contract, HandleClickContract)
        }));
        if (param.startDate.setHours(0, 0, 0, 0) > param.endDate.setHours(0, 0, 0, 0)) {
            setMessages([new DangerMessage("La fecha inicial no puede ser mayor a la fecha final")]);
        }
        else {
            const paramFilter = {
                ...param,
                users: EmployeeList.filter(x => x.selected).map(x => x.id)
            };
            let result = await GetByFilterAllAsync(paramFilter);
            if (result.isSuccessful) {
                if (result.data && result.data.length > 0) {
                    const paginatedData = result.data.slice(0,50);
                    setTotalPaginate(result.data);
                    setPolicylist(paginatedData);
                    setButtonsSecond({
                        rightButtons: rightButtonsSecondDisabled(
                            DateDefaultDisabled(param),
                            EmployeeList.filter(x => x.selected).map(x => x.fullName),
                            param.groupText)
                    });
                    if (param.group === "1") {
                        setButtons(prevEdit => ({
                            ...prevEdit,
                            rightButtons: rightButtonsExecUngrouped(() =>
                                handleClickDownload(), () =>
                                handleClickExcel(), handleClickNewQuery, contract, HandleClickContract)
                        }));
                    }
                    else {
                        setButtons(prevEdit => ({
                            ...prevEdit,
                            rightButtons: rightButtonsExec(() =>
                                handleClickExcel(), handleClickNewQuery, contract, HandleClickContract)
                        }));
                    }
                }
                else {
                    setMessages([new DangerMessage("No se encontraron resultados")]);
                    UpdateButtons();
                }
            }
            else {
                setMessages([new DangerMessage(result.errorMessage)]);
                UpdateButtons();
            }
        }
    }
    const [buttons, setButtons] = useState({
        leftButtons: leftButtons(),
        rightButtons: []
    });
    const [buttonsSecond, setButtonsSecond] = useState({
        rightButtons: []
    });
    const GetEmployeesByStatusActive = async () => {
        let result = await GetByActiveAsync();
        if (result.isSuccessful) {
            setEmployeeList(result.data);
        }
        else {
            setMessages([DangerMessage(result.errorMessage)]);
        }
    }

    const GetDaysDocumentByRolId = async () => {
        let result = await GetDaysDocumentByRolIdAsync();
        if (result.data === -1) {
            setMessages([new DangerMessage("No tienes permisos para generar el reporte")]);
        }
        setDaysDocument(result.data);
    }
    useEffect(() => {
        GetEmployeesByStatusActive();
        GetDaysDocumentByRolId();
    }, [])
    const UpdateButtonsSecond = () => {
        setButtonsSecond({
            rightButtons: rightButtonsSecond(
                () => {
                    setShowAttachDatePanel(true);
                }, () => {
                    setShowAttachEmployeePanel(true);
                },
                DateDefault(param),
                EmployeeList.filter(x => x.selected).map(x => x.fullName),
                param.groupText,
                () => {
                    setShowAttachGroupPanel(true);
                }
            )
        });
    }
    useEffect(() => {
        UpdateButtons();
    }, [contract]);
    useEffect(() => {
        UpdateButtons();
        UpdateButtonsSecond();
        setPolicylist(undefined);
    }, [param]);
    useEffect(() => {
        if (EmployeeList) {
            UpdateButtonsSecond();
            UpdateButtons();
        }
    }, [EmployeeList]);
    const handleClickDownload = async () => {
        const paramFilter = {
            ...param,
            users: EmployeeList.filter(x => x.selected).map(x => x.id)
        };
        let result = await GetByFilterAllTxtAsync(paramFilter);
        if (result.isSuccessful) {
            if (result && result.data) {
                const urlImagen = URL.createObjectURL(result.data);
                const link = document.createElement("a");
                link.download = '001_GLog.txt';
                link.href = urlImagen;
                link.click();
            }
            else {
                setMessages([new DangerMessage("No se encontraron resultados")]);
            }

        }
    }
    const handleClickExcel = async () => {
        const paramFilter = {
            ...param,
            users: EmployeeList.filter(x => x.selected).map(x => x.id)
        };
        let result = await GetByFilterAllAsync(paramFilter);
        if (result.isSuccessful) {
            if (result.data && result.data.length > 0) {
                const wb = XLSX.utils.book_new();
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
                if (param.group === "3") {
                    let Heading = [["Nombre", "", "IDE", "Nómina", "Rol", "", "", "", "", "Total de horas"],
                    ['Registro', 'Fecha', '', '','', 'Entrada', 'TR', 'Salida', 'TR', 'Intervalo']];
                    XLSX.utils.sheet_add_aoa(ws, Heading);
                    const groupByCategory = Map.groupBy(result.data, policy => {
                        return policy.userId;
                    });
                    const groupedPolicies = [];
                    for (const [userId, list] of groupByCategory) {
                        let user = result.data.find(x => x.userId === userId);
                        const sortedGroupByDate = new Map([...list.entries()].sort((a, b) => {
                            const dateA = formatDateOrder(a[1].date);
                            const dateB = formatDateOrder(b[1].date);
                            return dateA.getTime() - dateB.getTime();
                        }));
                        groupedPolicies.push([
                            {
                                v: user.user ?? '',
                                s: { ...backgroundStyle, alignment: { wrapText: true } }
                            },
                            {
                                v: '',
                                s: { ...backgroundStyle }
                            },
                            {
                                v: userId,
                                s: { ...backgroundStyle }
                            },
                            {
                                v: user.payrollId ?? '',
                                s: { ...backgroundStyle, alignment: { wrapText: true } }
                            },
                            {
                                v: user.role ?? '',
                                s: { ...backgroundStyle, alignment: { wrapText: true } }
                            },
                            ...Array(4).fill({ v: '', s: backgroundStyle }),
                            {
                                v: WorkTime(sortedGroupByDate.values().map(x => x.workTime)) ?? '',
                                s: { ...backgroundStyle }
                            }
                        ]);

                        const values = Array.from(sortedGroupByDate.values());
                        values.forEach((element) =>
                            groupedPolicies.push([element.id,
                            element.date,
                            '',
                            '',
                                '',
                            element.checkIn,
                            TypeCheckText(element.checkInMethod),
                            element.checkOut,
                            TypeCheckText(element.checkOutMethod),
                            element.workTime
                            ])
                        );
                    }
                    XLSX.utils.sheet_add_json(ws, groupedPolicies, { origin: 'A3', skipHeader: true });
                }
                else {
                    let Heading = [['Registro', 'Fecha', 'IDE', 'Nombre', 'Entrada', 'TR', 'Salida', 'TR', 'Intervalo']];
                    XLSX.utils.sheet_add_aoa(ws, Heading);
                    if (param.group === "1") {
                        const modifiedData = result.data.map(item => ({
                            id: item.id,
                            date: item.date,
                            userId: item.userId,
                            user: item.user,
                            checkIn: item.checkIn,
                            checkInMethod: TypeCheckText(item.checkInMethod),
                            checkOut: item.checkOut,
                            checkOutMethod: TypeCheckText(item.checkOutMethod),
                            workTime: item.workTime
                        }));
                        XLSX.utils.sheet_add_json(ws, modifiedData, { origin: 'A2', skipHeader: true });
                    } else {
                        const groupByCategory = Map.groupBy(result.data, item => {
                            return item.date;
                        });
                        const sortedGroupByCategory = new Map([...groupByCategory.entries()].sort((a, b) => {
                            const dateA = formatDateOrder(a[0]);
                            const dateB = formatDateOrder(b[0]);
                            return dateA.getTime() - dateB.getTime();
                        }));
                        const groupedPolicies = [];
                        for (const [date, list] of sortedGroupByCategory) {
                            groupedPolicies.push([{ v: '', s: backgroundStyle },
                                {
                                    v: formatDate(date),
                                    s: { ...backgroundStyle, alignment: { wrapText: true } }
                                },
                                ...Array(7).fill({ v: '', s: backgroundStyle })]);
                            list.forEach((element) =>
                                groupedPolicies.push([element.id,
                                element.date,
                                element.userId,
                                element.user,
                                element.checkIn,
                                TypeCheckText(element.checkInMethod),
                                element.checkOut,
                                TypeCheckText(element.checkOutMethod),
                                element.workTime
                                ])
                            );
                        }
                        XLSX.utils.sheet_add_aoa(ws, groupedPolicies, { origin: 'A2', skipHeader: true });
                    }
                }
                XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
                XLSX.writeFile(wb, `Asistencias ${ToShortDateNow()}.xlsx`);
            }
        }
    }
    const PopoverRender = (content, className) => {
        return <TableCell>
            <TableCellLayout className={className} >
                {content}
            </TableCellLayout>
        </TableCell>
    }
    function TypeCheck(chek) {
        switch (chek) {
            case 1:
                return <FingerprintWithoutFill fill="var(--info)" />; 
            case 2:
                return <TextIcon fill="var(--orange)" />;
            default:
                return "";
        }
    }
    function TypeCheckText(chek) {
        switch (chek) {
            case 1:
                return "Biométrico";
            case 2:
                return "Manual";
            default:
                return "";
        }
    }
    const renderPolicyRow = (item, index) => {
        const tableRow = (
            <TableRow key={index} className={`row-${item.id} ${item.found !== undefined && foundValue === item.found ? 'item-found' : ''}`}>
                {PopoverRender(item.id, `justify-content-center`)}
                {PopoverRender(item.date, "justify-content-center")}
                {PopoverRender(item.userId, "justify-content-center")}
                {PopoverRender(item.user, "justify-content-start")}
                {PopoverRender(item.checkIn, "justify-content-center")}
                {PopoverRender(TypeCheck(item.checkInMethod), "justify-content-center")}
                {PopoverRender(item.checkOut, "justify-content-center")}
                {PopoverRender(TypeCheck(item.checkOutMethod), "justify-content-center")}
                {PopoverRender(item.workTime, "justify-content-center")}
            </TableRow>
        );
        return tableRow;
    };
    const formatDateOrder = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
    };
    const renderPolicyRowGroup = () => {
        const groupByCategory = Map.groupBy(policylist, policy => {
            return policy.date;
        });
        const sortedGroupByCategory = new Map([...groupByCategory.entries()].sort((a, b) => {
            const dateA = formatDateOrder(a[0]);
            const dateB = formatDateOrder(b[0]);
            return dateA.getTime() - dateB.getTime();
        }));
        const groupedPolicies = [];
        for (const [date, list] of sortedGroupByCategory) {
            groupedPolicies.push({ date, list });
        }
        return groupedPolicies;
    };
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(year, month - 1, day);
        const dayOfWeek = DayPickerStrings.days[date.getDay()];
        const monthName = DayPickerStrings.months[date.getMonth()];

        return `${dayOfWeek}, ${day} de ${monthName.toLowerCase()} de ${year}`;
    }
    const renderPolicyRowGroupUser = () => {
        const groupByCategory = Map.groupBy(policylist, policy => {
            return policy.userId;
        });
        const groupedPolicies = [];
        for (const [userId, list] of groupByCategory) {
            let user = policylist.find(x => x.userId === userId);
            const sortedGroupByDate = new Map([...list.entries()].sort((a, b) => {
                const dateA = formatDateOrder(a[1].date);
                const dateB = formatDateOrder(b[1].date);
                return dateA.getTime() - dateB.getTime();
            }));
            const values = Array.from(sortedGroupByDate.values());
            let item = { userId, list: values, user: user.user, payrollId: user.payrollId, role: user.role }
            groupedPolicies.push(item);
        }
        return groupedPolicies;
    };
    function WorkTime(hoursStrings) {
        const totalSeconds = hoursStrings.reduce((total, hoursString) => {
            const [hours, minutes, seconds] = hoursString.split(':').map(Number);
            return total + (hours * 3600) + (minutes * 60) + seconds;
        }, 0);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        if (totalPaginate.length > 0 && foundValue && policylist?.length>0) {
            setPolicylist(prevEdit => prevEdit.map(item => {
                const matchingItem = totalPaginate.find(x => x.id === item.id);
                return {
                    ...item,
                    found: matchingItem.found
                };
            }));
        }
    }, [totalPaginate])
    useEffect(() => {
        if (foundValue > 0 && policylist && policylist.length > 0) {
            let foundId = totalPaginate.find(x => x.found === foundValue)
            if (foundId) {
                const targetRow = tableRef.current.querySelector(`.row-${foundId.id}`);
                if (targetRow) {
                    targetRow.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [foundValue, policylist]);
    useEffect(() => {
        if (daysDocument) {
            UpdateButtons();
        }
    }, [daysDocument]);
    return (
        <>
            <BarButtons rightButtons={buttons.rightButtons}
                leftButtons={buttons.leftButtons} className="background-color-light-hover border-gray"
                leftButtonsClassName="col-lg-4 col-md-4" rightButtonsClassName="col-lg-8 col-md-8" />
            <BarButtons rightButtons={buttonsSecond.rightButtons} className="background-color-light-hover barButtons" />
            <BarMessageList messages={messages} />
            <div className='row'>
                <div className='col-lg-12 col-md-12 '>
                    {policylist &&
                        <>
                            <div className="container-table-paginate">
                                {param.group === "3" ?
                                    <Table size="small" className="table table-hover table-updater table-sm">
                                    <TableHeader className="header-fixed">
                                            <TableRow className="withoutborder">
                                                <TableHeaderCell key={1} colSpan={2}
                                                    style={{ width: '20%' }}
                                                    className={'detail-table-header-title justify-content-start-header padding-left-header-title'}>
                                                    {"Nombre"}
                                                </TableHeaderCell>

                                                <TableHeaderCell key={2}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title justify-content-start-header'}>
                                                    {"IDE"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={3}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title justify-content-start-header'}>
                                                    {"Nómina"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={4} colSpan={6}
                                                    style={{ width: '50%' }}
                                                    className={'detail-table-header-title justify-content-start-header'}>
                                                    {"Rol"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={5}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title justify-content-start-header'}>
                                                    {"Total de horas"}
                                                </TableHeaderCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHeaderCell key={1}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title'}>
                                                    {"Registro"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={2} colSpan={4}
                                                    style={{ width: '40%' }}
                                                    className={'detail-table-header-title justify-content-start-header'}>
                                                    {"Fecha"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={3}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title'}>
                                                    {"Entrada"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={4}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title'}>
                                                    {"TR"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={5}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title'}>
                                                    {"Salida"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={6}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title'}>
                                                    {"TR"}
                                                </TableHeaderCell>
                                                <TableHeaderCell key={6} colSpan={2}
                                                    style={{ width: '10%' }}
                                                    className={'detail-table-header-title justify-content-start-header'}>
                                                    {"Intervalo"}
                                                </TableHeaderCell>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>

                                            {renderPolicyRowGroupUser().map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow key={index} className={"item-header"}>
                                                        <TableCell colSpan={2}>
                                                            <TableCellLayout className={"font-weight-header padding-left-header"}>{item.user}</TableCellLayout>
                                                        </TableCell>
                                                        <TableCell >
                                                            <TableCellLayout className={"font-weight-header"}>{item.userId}</TableCellLayout>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableCellLayout className={"font-weight-header"}>{item.payrollId}</TableCellLayout>
                                                        </TableCell>
                                                        <TableCell colSpan={6} >
                                                            <TableCellLayout className={"font-weight-header"}>{item.role}</TableCellLayout>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableCellLayout className={"font-weight-header justify-content-start italic"}>{WorkTime(item.list.map(x => x.workTime))}</TableCellLayout>
                                                        </TableCell>
                                                    </TableRow >
                                                    {
                                                        item.list.map((itemDetail, index) => (
                                                            <TableRow key={index} className={itemDetail?.found === undefined ? '' : foundValue === itemDetail?.found && 'item-found'}>
                                                                <TableCell>
                                                                    <TableCellLayout className={"justify-content-center"} >
                                                                        {itemDetail.id}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell colSpan={4}>
                                                                    <TableCellLayout className={"justify-content-start"} >
                                                                        {itemDetail.date}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TableCellLayout className={"justify-content-center"} >
                                                                        {itemDetail.checkIn}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TableCellLayout className={"justify-content-center"} >
                                                                        {TypeCheck(itemDetail.checkInMethod)}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TableCellLayout className={"justify-content-center"} >
                                                                        {itemDetail.checkOut}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TableCellLayout className={"justify-content-center"} >
                                                                        {TypeCheck(itemDetail.checkOutMethod)}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                                <TableCell colSpan={2}>
                                                                    <TableCellLayout className={"justify-content-start"} >
                                                                        {itemDetail.workTime}
                                                                    </TableCellLayout>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    :
                                <Table size="small" className="table table-hover table-updater" ref={tableRef}>
                                    <TableHeader className="header-fixed">
                                            <TableRow>
                                                {columnsHeader.map((column) => (
                                                    <TableHeaderCell key={column.columnKey}
                                                        style={{ width: column.width }}
                                                        className={'detail-table-header-title ' + column?.classAdd}>
                                                        {column.label}
                                                    </TableHeaderCell>
                                                ))}
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {param.group === "1" ?
                                                policylist.map(renderPolicyRow)
                                                :
                                                renderPolicyRowGroup().map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <TableRow key={index} className={"item-header"}>
                                                            <TableCell colSpan={9} >
                                                                <TableCellLayout className={"font-weight-header padding-left-header"}>{formatDate(item.date)}</TableCellLayout>
                                                            </TableCell>
                                                        </TableRow>
                                                        {item.list.map(renderPolicyRow)}
                                                    </React.Fragment>
                                                ))

                                            }
                                        </TableBody>
                                    </Table>
                                }
                        </div>
                        <PaginateTable totalPaginate={totalPaginate}
                            setTotalPaginate={setTotalPaginate}
                            setItemsList={setPolicylist} totalPaginateLength={totalPaginate.length}
                            foundValue={foundValue}
                            setFoundValue={setFoundValue}/>
                        </>
                    }
                </div>
            </div>
            <OverlayDrawerDate showAttachDatePanel={showAttachDatePanel}
                setShowAttachDatePanel={setShowAttachDatePanel}
                setParam={setParam}
                param={param} daysDocument={daysDocument}/>
            <OverlayDrawerEmployee showAttachEmployeePanel={showAttachEmployeePanel}
                setShowAttachEmployeePanel={setShowAttachEmployeePanel}
                setParam={setParam}
                param={param}
                EmployeeList={EmployeeList}
                setEmployeeList={setEmployeeList} />
            <OverlayDrawerAttendanceLog showAttachGroupPanel={showAttachGroupPanel}
                setShowAttachGroupPanel={setShowAttachGroupPanel}
                setParam={setParam}
                param={param} />
        </>
    )
}