import {
    OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle,
    DrawerFooter, Image, CompoundButton
} from "@fluentui/react-components";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { useState, useEffect } from 'react'; import {
    SetAxiosPrivate, GetActives
} from '../../../Functions/Catalogs/Families/FamilyStore';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate'
import BarMessageList from "../../Shared/BarMessages/BarMessageList";
import { InfoMessage } from '../../../Models/Shared/BarMessages/Messages';
const returnWhite = require('../../../Assets/Images/Icons/ReturnWhite.svg').default;
const apply = require('../../../Assets/Images/Icons/Apply.svg').default;
export const OverlayDrawerFamily = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [familyList, setFamilyList] = useState([]);
    const [messages, setMessages] = useState([]);
    const onChangeFamilyTypeText = (event) => {
        const value = event.target.value.toLowerCase().trim();
        if (value) {
            setFamilyList((prev) => {
                const updated = prev.map((item) => {
                    if (item.name.toLowerCase().includes(value)) {
                        return {
                            ...item,
                            filter: true
                        };
                    }
                    else {
                        return {
                            ...item,
                            filter: false
                        };
                    }
                });
                return updated;
            });
        }
        else {
            onCleanTextFamily();
        }
    };
    const onChangeCheckBoxFamily = (event, data) => {
        if (data.checked) {
            setFamilyList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: true
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
        else {
            setFamilyList((prev) => {
                const updated = prev.map((item) => {
                    if (item.id == event.target.id) {
                        return {
                            ...item,
                            selected: false
                        };
                    }
                    return item;
                });
                return updated;
            });
        }
    };
    const addFamily = () => {
        if (props.param.familyType === "2") {
            if (familyList.some(x => x.selected === true)) {
                props.setShowAttachFamilyPanel(false);
                onCleanTextFamily();
                props.setParam((prev) => ({
                    ...prev,
                    family: familyList.filter(x => x.selected === true).map(x => x.id),
                }))
            }
            else {
                setMessages([new InfoMessage("Debe seleccionar una familia")]);
            }
        }
        else {
            props.setShowAttachFamilyPanel(false);
            onCleanTextFamily();
            props.setParam((prev) => ({
                ...prev,
                family: undefined,
            }))
        }
    };
    const itemsRadio = [
        {
            key: "1",
            value: "1",
            text: "Todos",
        },
        {
            key: "2",
            value: "2",
            text: "Las seleccionadas",
        }
    ];
    const onChangeType = (event, data) => {
        if (data.value === "2") {
            if (familyList.length > 0) {
                setFamilyList(familyList.map((family) => ({
                    ...family,
                    selected: false,
                    filter: false
                })))
            }
        }
        else {
            setFamilyList(familyList.map((family) => ({
                ...family,
                selected: false,
            })))
        }
        props.setParam(prevEdit => ({ ...prevEdit, familyType: data.value }));
    };
    const onCleanTextFamily = () => {
        setFamilyList((prev) => {
            const updated = prev.map((item) => {
                return {
                    ...item,
                    filter: false
                };
            });
            return updated;
        });
    };
    const CloseOverlayDrawer = () => {
        props.setShowAttachFamilyPanel(false);
        setFamilyList(familyList.map((family) => ({
            ...family,
            selected: false,
            filter: false
        })))
        props.setParam(prevEdit => ({ ...prevEdit, familyType: "1", family: undefined }));
    };
    const GetFamilysByStatusActive = async () => {
        let result = await GetActives();
        if (result.isSuccessful) {
            setFamilyList(result.data);
        }
    }
    useEffect(() => {
        GetFamilysByStatusActive();
    }, [])
    useEffect(() => {
        setMessages([]);
    }, [props.showAttachFamilyPanel])
    return (
        <OverlayDrawer position="end" open={props.showAttachFamilyPanel} modalType="alert"
            style={{ width: '896px' }}>
            <DrawerHeader>
                <DrawerHeaderTitle>
                    Filtro de familias
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <BarMessageList messages={messages} />
                <div className='row'>
                    <div className='col-md-12 mt-4'>
                        <OmniRadioGroup name="familyType"
                            title="Familias"
                            orientation="horizontal"
                            items={itemsRadio}
                            default={props.param.familyType} inactivefieldEdited onChange={onChangeType} />
                    </div>
                </div>
                {props.param.familyType === "2" &&
                    <div className='row mt-2'>
                        <div className='col-md-6'>
                            <OmniTextBox focus title="Búsqueda de familias" maxLength={64} onChange={onChangeFamilyTypeText} inactivefieldEdited />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        familyList.map((item) => (
                                            item.filter === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxFamily} id={item.id} key={item.id} name={item.name} propertyChecked={item.selected} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <OmniLabel text='Familias seleccionadas' />
                            <div className='row overflow-panel'>
                                <div className='col-md-12'>
                                    {
                                        familyList.map((item) => (
                                            item.selected === true &&
                                            <OmniCheckBox title={item.name} inactivefieldEdited onChange={onChangeCheckBoxFamily} id={item.id} key={item.id} propertyChecked={true} />

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </DrawerBody>
            <DrawerFooter >
                <div className="drawer-footer-container">
                    <CompoundButton id="btnCloseAttachSqlPanel" className="tile-button-panel"
                        icon={<Image src={returnWhite} alt="return" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={CloseOverlayDrawer}
                    >Cerrar</CompoundButton>

                    <CompoundButton id="btnAddSqQueryPanel" className="tile-button-panel tile-button-panel-primary"
                        icon={<Image src={apply} alt="apply" />}
                        iconPosition="after"
                        shape="square" size="small"
                        onClick={addFamily}
                    >Aplicar</CompoundButton>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    )
}