import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";

export default function Comission(props){
    return(
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Comisiones'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniTextBox title='Comisión por artículo' value={props.item?.commission} edit={props.setItem} property="commission" suffix="%" 
                                        inputType={'decimal'}
                                        decimals={2}
                                        max={100} 
                                        />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div> 
        </>
    )
}