import { Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export function Stock(props){
    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniCard title='Resumen de las existencias'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Totales' className="cell-bold" value={props.item.stockTotal} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='En las bodegas' className="cell-bold cell-warehouse"  value={props.item.stockInWarehouses} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='En las tiendas'className="cell-bold cell-store"  value={props.item.stockInStore} />
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className="col-lg-6 col-md-12">
                    <OmniCard title='Almacenes con faltantes'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Bodegas' className= {`cell-bold ${props.item.warehousesEmty==0?'cell-warehouse':'cell-stock-empty'}`}  value={props.item.warehousesEmty} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Tiendas' className={`cell-bold ${props.item.storesEmpty==0?'cell-warehouse':'cell-stock-empty'}`} value={props.item.storesEmpty} />
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12" >
                    <OmniCard title="Existencias por almacen" classCard="long-card">
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                ID
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Tipo
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Nombre del almácen
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Existencias
                                            </TableHeaderCell>
                                            <TableHeaderCell className="header-cell-itemByBranch">
                                                Ubicación
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                    {
                                        props.item.itemStockResults?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <TableCellLayout className="cell-bold">
                                                        {item.warehouseConsecutive}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout className={`cell-bold ${item.isWarehouse?'cell-warehouse':'cell-store'}`}>
                                                        {item.warehouseType}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell >
                                                    {item.warehouseName}
                                                </TableCell>
                                                <TableCell >
                                                    <TableCellLayout className={`cell-bold ${item.stock=='0/0'?'cell-stock-empty':(item.isWarehouse?'cell-warehouse':'cell-store')}`}>
                                                        {item.stock}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    {item.location}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}