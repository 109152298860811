import { React } from "react";
import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import { ItemSelectorProvider } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/CompanyAsset/CompanyAsset"
import Detail from './Detail'
import { FieldChangeWatcherProvider } from "../../../Context/Controls/FieldChangeWatcherProvider"

const GetByStatus = '/companyasset/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Catalog.svg').default
}

export default function CompanyAsset() {

    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de activos"}
                    image={iconCatalog}
                    urlGetItems={GetByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"description"}
                    getInLoad={true}
                    />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}