export const leftButtonsEdit: Buttons[] = [{
    key: 'LblTitle',
    text: 'Editando...',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const leftButtonsDetail: Buttons[] = [{
    key: 'LblTitle',
    text: 'Consultando...',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];