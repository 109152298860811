import '../../../../Assets/Styles/Configuration/Roles/Role.css'
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { RolesIconsCatalogs } from '../../../../Assets/Constants/Catalogs/Roles/RolesIconsCatalogs';
import { RolesIconsActions } from '../../../../Assets/Constants/Catalogs/Roles/RolesIconsActions';
export const AuthorizationDetail = (props) => {
    console.log(props?.listAuthorizations)
    return (
        <OmniCard title='Permisos asignados' classCard='card-omni-without-width'>
            {props?.listAuthorizations &&
                <div className='row card-items-container card-items-container-authorizations-detail'>
                    <div className='col-lg-12 col-md-12'>
                        <div role="tree" aria-label="Item Controlled" >
                            {props?.listAuthorizations?.map((item) => (
                                <>
                                    {(item?.isSelected === true || item?.isNotAssigned === true) &&
                                        <div>
                                            <div className="row catalog catalog-border catalog-font">
                                                <div className={`col-12 ${item?.isNotAssigned === true ? 'color-danger' : ''}`} >
                                                    {item.description}
                                                </div>
                                            </div>
                                            {item.listAutorizacions?.map((itemFirstLevel) => (
                                                <>
                                                    {(itemFirstLevel?.isSelected === true || itemFirstLevel?.isNotAssigned === true) &&
                                                        <div role="group">
                                                            <div className="row catalog catalog-border catalog-font">
                                                                <div className='col-2 text-center'>
                                                             
                                                                        {RolesIconsCatalogs.find(x => x.id === itemFirstLevel.id)?.icon}
                                                                  
                                                                </div>
                                                                <div className={`col-10 ${itemFirstLevel?.isNotAssigned === true ? 'color-danger' : ''}`}>
                                                                    {itemFirstLevel.description}
                                                                </div>
                                                            </div>
                                                            <div role="group">
                                                                {itemFirstLevel.listAutorizacions?.map((itemSecondLevel) => (
                                                                    <>
                                                                        {itemSecondLevel?.isSelected === true &&
                                                                            <div className="row catalog catalog-border">
                                                                                <div className='col-3 text-end'>
                                                                                    {RolesIconsActions.find(x => x.description == itemSecondLevel?.description)?.icon}
                                                                              
                                                                                    </div>
                                                                                <div className='col-9'>
                                                                                    {itemSecondLevel.description}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </OmniCard>
    )
}