import Add from "../../../../../Components/Catalogs/CompanyAssets/Add";
export const rightButtons: Buttons[] = [{
    key: 'BtnNuevo',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add/>
}]