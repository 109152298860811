import { ReactComponent as Warehouse } from '../../../Images/Icons/Warehouse.svg';
import { ReactComponent as Categories } from '../../../Images/Icons/Categories.svg';
import { ReactComponent as Drivers } from '../../../Images/Icons/Drivers.svg';
import { ReactComponent as CustomerBlue } from '../../../Images/Icons/CustomerBlue.svg';
import { ReactComponent as Conversion } from '../../../Images/Icons/Conversion.svg';
import { ReactComponent as Employee } from '../../../Images/Icons/Employee.svg';
import { ReactComponent as Family } from '../../../Images/Icons/Family.svg';
import { ReactComponent as Ieps } from '../../../Images/Icons/Ieps.svg';
import { ReactComponent as Brand } from '../../../Images/Icons/Brand.svg';
import { ReactComponent as Measure } from '../../../Images/Icons/Measure.svg';
import { ReactComponent as Routes } from '../../../Images/Icons/Routes.svg';
import { ReactComponent as WarehouseLocation } from '../../../Images/Icons/WarehouseLocation.svg';
import { ReactComponent as Variety } from '../../../Images/Icons/Variety.svg';
import { ReactComponent as Vehicle } from '../../../Images/Icons/Vehicle.svg';
import { ReactComponent as Bank } from '../../../Images/Icons/Bank.svg';
import { ReactComponent as Linebusiness } from '../../../Images/Icons/Linebusiness.svg';
import { ReactComponent as Supplier } from '../../../Images/Icons/Supplier.svg';
import { ReactComponent as TransferRoute } from '../../../Images/Icons/TransferRoute.svg';
import { ReactComponent as CustomerType } from '../../../Images/Icons/CustomerType.svg';
import { ReactComponent as MasterPolicy } from '../../../Images/Icons/MasterPolicy.svg';
import { ReactComponent as XmlFile } from '../../../Images/Icons/XmlFile.svg';
import { ReactComponent as DeviceType } from '../../../Images/Icons/DeviceType.svg';
import { ReactComponent as DocumentsCatalog } from '../../../Images/Icons/DocumentsCatalog.svg';
import { ReactComponent as SynchronizerException } from '../../../Images/Icons/SynchronizerException.svg';

export const RolesIconsCatalogs = [
    { id: 16, icon: <Warehouse /> },
    { id: 772,  icon: <Bank /> },
    { id: 21, icon: <Categories /> },
    { id: 526,  icon: <Drivers /> },
    { id: 1,  icon: <CustomerBlue /> },
    { id: 763,  icon: <Conversion /> },
    { id: 11, icon: <Employee /> },
    { id: 186,  icon: <Family /> },
    { id: 191,  icon: <Ieps /> },
    { id: 797, icon: <Linebusiness /> },
    { id: 536,  icon: <Brand /> },
    { id: 445, icon: <Measure /> },
    { id: 6, icon: <Supplier /> },
    { id: 5, icon: <Routes /> },
    { id: 418, icon: <TransferRoute /> },
    { id: 788, icon: <CustomerType /> },
    { id: 491,  icon: <WarehouseLocation /> },
    { id: 34,  icon: <Variety /> },
    { id: 531, icon: <Vehicle /> },
    { id: 786, icon: <MasterPolicy /> },
    { id: 802, icon: <XmlFile className="color-info-fill" /> },
    { id: 803, icon: <DeviceType /> },
    { id: 26, icon: <DocumentsCatalog /> },
    { id: 435, icon: <SynchronizerException /> }
];