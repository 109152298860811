import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { ReactComponent as Excel } from './../../../../Assets/Images/Icons/Excel.svg';
import { ReactComponent as PaymentsToSuppliers } from './../../../../Assets/Images/Icons/PaymentsToSuppliers.svg';
import { ReactComponent as PurchaseOrder } from './../../../../Assets/Images/Icons/PurchaseOrder.svg';
import { OmniCard } from "../../../Shared/Card/OmniCard";

export const ContactList = (props) =>
{
    const columnsHeaderRoutes = [
        { columnKey: "1", label: "Lista de contactos", width: '70%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '10%' },
        { columnKey: "3", label: "", width: '10%' },
        { columnKey: "4", label: "", width: '10%' }
    ];

    const changeComponentClik = (id) => {
        props.setSelected((prevClient) => ({
            ...prevClient,
            [props.property]: id
        }));
        if (props.setView) {
            props.setView((prevView) => ({
                ...prevView,
                [props.property]: {
                    viewAdd: false,
                    viewDetail: true,
                    viewEdit: false
                }
            }));
        }
    };

    return (
    
        <OmniCard title='Contactos'>
            <div className="detail-table-scroll">
                <Table className="detail-table">
                    <TableHeader>
                        <TableRow>
                            {columnsHeaderRoutes.map((column) => (
                                <TableHeaderCell key={column.columnKey}
                                    style={{ width: column.width }}
                                    className={'detail-table-header-title ' + column?.classAdd}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.description[props.property] &&
                            props.description[props.property].map((item, index) => (
                                <TableRow key={index} onClick={() => changeComponentClik(item.id)}
                                    className={(props.itemSelected && item.id == props.itemSelected[props.property]) ? "item-table-selected" : "item-table"}>
                                    <TableCell >
                                        <TableCellLayout className={(item?.active === true ? "delete-table-body-description" : "color-danger delete-table-body-description")}>
                                            {item.name}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell >
                                        <TableCellLayout media={item.sendXLS === true && <Excel />} className="iconList" />
                                    </TableCell>
                                    <TableCell >
                                        <TableCellLayout media={item.sendPdf === true && <PaymentsToSuppliers />} className="iconList" />
                                    </TableCell>
                                    <TableCell >
                                        <TableCellLayout media={item.sendPay === true && <PurchaseOrder />} className="iconList" />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </OmniCard>
    )
}



