import { OmniCard } from "../../Shared/Card/OmniCard";
import { Label } from "@fluentui/react-components";
import { ReactComponent as OvalIcon } from '../../../Assets/Images/Icons/Oval.svg';
import { SearchBox } from "@fluentui/react";
import { useEffect, useState } from 'react';
import { ActiveCatalogs, InactiveCatalogs } from '../../../Assets/Constants/Configuration/SatCatalogs/Catalogs';
import Role from '../../../Assets/Constants/Enums/Role';
import { DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
export const CatalogListSearch = (props) => {
    const [listFilter, setListFilter] = useState([]);
    const OnChekedhancheHandler = (itemRow) => {
        let itemSearch = props.catalog.find(x => x.id === itemRow.id);
        if (itemSearch) {
            if (itemSearch.hasItems) {
                props.setMessages([new DangerMessage("La clave se encuentra ligada a uno o varios artículos, no se puede desactivar")]);
            }
            else {
                let itemExist = props.itemUpdate.find(x => x.id === itemRow.id);
                if (itemExist) {
                    props.setItemUpdate((prev) => prev.map((item) => {
                        if (item.id === itemRow.id) {
                            return { ...item, enabledInAlix: !itemExist.enabledInAlix, isChange: !itemExist.isChange };
                        }
                        return item;
                    }));
                }
                else {
                    props.setItemUpdate((prev) => [...prev, { ...itemSearch, enabledInAlix: !itemSearch.enabledInAlix, isChange: true }]);
                }
            }
        }
    };
    function keyDowCaptureHandler(event) {
        if (event.key.toString().toLowerCase() === "enter") {
            const value = event.target.value.toLowerCase().trim();
            if (value) {
                const updatedCatalog = props.catalog.map((item) => ({
                    ...item,
                    isFilter: !item.description.toLowerCase().includes(value)
                }));
                let listFilter = updatedCatalog.filter((item) => item.isFilter === false);
                setListFilter(listFilter)
                setList(listFilter.slice(0, 50));
            }
            else {
                setListFilter([]);
                ClearList();
            }
        }
    }
    function RenderRow(item) {
        let itemExist = props.itemUpdate.find(x => x.id === item.id);
        if (itemExist) {
            return itemExist.enabledInAlix;
        }
        else {
            return item.enabledInAlix;
        }
    }
    function ClearList() {
        const updatedCatalog = [...props.catalog];
        updatedCatalog.forEach((item) => {
            item.isFilter = false;
        });
        setList(updatedCatalog.slice(0, 50));
        props.setCatalog(updatedCatalog);
    }
    useEffect(() => {
        ClearList();
    }, [])
    function toShortDate(dateParam) {
        if (dateParam) {
            const date = new Date(dateParam);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const dayFormat = day < 10 ? '0' + day : day;
            const monthFormat = month < 10 ? '0' + month : month;
            const dateFormateada = `vigencia: ${dayFormat}/${monthFormat}/${year}`;
            return dateFormateada;
        }
        return "";
    }
    function ValidAuthorizations(item) {
        if (item.endDateValidation) {
            return false
        }
        var authorizations = JSON.parse(localStorage.getItem("authorizations"));
        var roleId = localStorage.getItem("rolId");
        if (authorizations) {
            if (RenderRow(item)) {
                return authorizations.some(auth => auth.authorizationId === InactiveCatalogs[props.type.type])
            }
            else {
                return authorizations.some(auth => auth.authorizationId === ActiveCatalogs[props.type.type])
            }
        }
        else if (roleId.toString() === Role.Administrador.toString()) {
            return true;
        }
        else if (roleId.toString() === Role.SinPermisos.toString()) {
            return false;
        }
        return false;
    }
    const RenderDiv = (item) => {
        if (item?.default === true) {
            return <div>
            </div>
        }
        if (ValidAuthorizations(item)) {
            return <div key={item.id} class={`row catalog catalog-border selectable ${RenderRow(item) ? 'selected' : ''}`}
                onClick={() => OnChekedhancheHandler(item)}>
                <div className='col-lg-10 col-md-12'>
                    <Label className='text-main'> {item.description}</Label>
                    <Label className={`text-secondary mt-1 ${RenderRow(item) ? 'text-info' : ''}`}> {item.key + ' ' + toShortDate(item.endDateValidation)}</Label>
                </div>
                {RenderRow(item) &&
                    <div className='col-lg-2 col-md-12'>
                        <OvalIcon />
                    </div>
                }
            </div>
        }
        else {
            return <div key={item.id} class={`row catalog catalog-border not-selectable ${RenderRow(item) ? 'selected' : ''}`}>
                <div className='col-lg-10 col-md-12'>
                    <Label className='text-main'> {item.description}</Label>
                    <Label className={`text-secondary mt-1 ${RenderRow(item) ? 'text-info' : ''}`}> {item.key + ' ' + toShortDate(item.endDateValidation)}</Label>
                </div>
                {RenderRow(item) &&
                    <div className='col-lg-2 col-md-12'>
                        <OvalIcon />
                    </div>
                }
            </div>
        }
    }
    const [actualPage, setActualPage] = useState(1);
    const [list, setList] = useState([]);
    const onScrollFunction = event => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        if ((scrollTop + clientHeight) + 10 >= scrollHeight) {
            getNewItems();
        }
    }
    const getNewItems = async () => {
        var page = actualPage + 1;
        if (listFilter.length > 0) {
            const paginatedData = listFilter.slice(
                (page - 1) * 50,
                page * 50
            );
            if (paginatedData && paginatedData.length > 0) {
                setActualPage(page++);
                setList([...list, ...paginatedData]);
            }
        } else {
            const paginatedData = props.catalog.slice(
                (page - 1) * 50,
                page * 50
            );
            if (paginatedData && paginatedData.length > 0) {
                setActualPage(page++);
                setList([...list, ...paginatedData]);
            }
        }

    }
    useEffect(() => {
        if (props.catalog) {
            setList(props.catalog.slice(0, 50))
        }
    }, [props.catalog]);
    return (
        <OmniCard title={props.type.titleCardEdit} classCard="card-omni-without-width">
            <div className='row pt-3'>
                <div className='col-lg-1 col-md-12'>
                </div>
                <div className='col-lg-10 col-md-12'>
                    <SearchBox className="itemsselector-search-input" placeholder="Buscar"
                        onKeyDownCapture={keyDowCaptureHandler} />
                </div>
                <div className='col-lg-1 col-md-12'>
                </div>
            </div>
            {list &&
                <div className='row card-table-max-height-search mt-4' onScroll={onScrollFunction}>
                    <div className='col-lg-12 col-md-12'>
                        {list.map((item) => 
                            (item.isFilter === undefined || item.isFilter === false) &&
                            RenderDiv(item)
                        )}
                    </div>
                </div>
            }
        </OmniCard>
    )
}