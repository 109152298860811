import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import { Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from '@fluentui/react-components';
import { OfferStatusDescripcion, OfferStatus, OfferType } from '../../../Assets/Constants/Enums/OfferStatus';
export const OffersTable = (props) => {
    return (
        <>
            <Table className="detail-table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell className='detail-table-header-title-left' style={{ width: '30%' }}>
                            Tipo
                        </TableHeaderCell>
                        <TableHeaderCell className='detail-table-header-title-left' style={{ width: '60%' }}>
                            Ofertas en {props.titleheader}
                        </TableHeaderCell>
                        <TableHeaderCell className='detail-table-header-title'>
                            Estatus
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        props.offers?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell >
                                    <TableCellLayout className={item.status === OfferStatus.Desactive ? 'color-danger' : ''}>
                                        {OfferType.find(x => x.id == item.offerType)?.description}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell >
                                    <TableCellLayout className={item.status === OfferStatus.Desactive ? 'color-danger' : ''}>
                                        {item.description}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell className='detail-table-cell'>
                                    <TableCellLayout className={item.status === OfferStatus.Desactive ? 'color-danger' : ''}>
                                        {OfferStatusDescripcion.find(status => status.status === item.status)?.initial}
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>);

}
