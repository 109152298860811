export const leftButtons = (handleClickEdit,deleteLocation,deletePhoto) => [{
    key: 'BtnEdit',
    text: 'Editar ubicación',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    handler: handleClickEdit,
    authorizationKey: 3
},
{
    key: 'BtnDeleteLocation',
    text: 'Borrar ubicación',
    iconName: 'pincuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--danger)',
    handler: deleteLocation,
    authorizationKey: 3
},
{
    key: 'BtnDeletePhoto',
    text: 'Borrar fotografía',
    iconName: 'photocuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--danger)',
    handler: deletePhoto,
    authorizationKey: 3
}
];