import Add from "../../../../../Components/Catalogs/AccountingTaxes/Add";
import Edit from "../../../../../Components/Catalogs/AccountingTaxes/Edit";
export const leftButtons: Buttons[] = [{
    key: 'BtnEdit',
    text: 'Editar',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Edit />
}, {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: ''
}
]
export const rightButtons: Buttons[] = [{
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />
}]