import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../Assets/Styles/Shared/Table/DetailTable.css"

export const Detail = (props) => {
    const columnsHeaderEmails = [
        { columnKey: "1", label: props.titleColum, width: '100%', classAdd: 'first-item-delete' },
    ];
    return (
        <>
            {props.description[props.property] &&
                <div className="detail-table-scroll">
                    <Table className="detail-table">
                        <TableHeader>
                            <TableRow>
                                {columnsHeaderEmails.map((column) => (
                                    <TableHeaderCell key={column.columnKey}
                                        style={{ width: column.width }}
                                        className={'detail-table-header-title ' + column?.classAdd}>
                                        {column.label}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                props.description[props.property].map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell >
                                            <TableCellLayout className='delete-table-body-description'>
                                                {item.description}
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
            }
        </>
    )
}