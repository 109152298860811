import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export function Commission(props){
    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Comisiones'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <OmniLabel text='Comisión por artículo' value={props.item.commission + "%"} />
                            </div>
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}