import '../../../Assets/Styles/Shared/Paginate/OmniPaginate.css';
import { ReactComponent as ArrowLeft } from '../../../Assets/Images/Icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../Assets/Images/Icons/ArrowRight.svg';
import { ReactComponent as SearchWithoutFill } from '../../../Assets/Images/Icons/SearchWithoutFill.svg';
import { ReactComponent as CheckEmpty } from '../../../Assets/Images/Icons/CheckEmpty.svg';
import { useState, useEffect } from 'react';
import { Button, Label } from "@fluentui/react-components";
import { OverlayDrawerSearchValue } from '../../Shared/OverlayDrawer/OverlayDrawerSearchValue';
export const PaginateTable = (props) => {
    const [actualPage, setActualPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [showAttachSearchValuePanel, setShowAttachSearchValuePanel] = useState(false);
    const [searchValueText, setSearchValueText] = useState();
    const onArrowLeftClick = () => {
        if (actualPage > 1) {
            setActualPage(actualPage - 1)
        }
    }
    const onArrowRightClick = () => {
        if (actualPage * pageSize < props.totalPaginateLength) {
            setActualPage(actualPage + 1)
        } 
    };
    const getItems = () => {
        let result = props.totalPaginate;
        const paginatedData = result.slice(
            (actualPage - 1) * pageSize,
            actualPage * pageSize
        );
        props.setItemsList(paginatedData);
    }
    useEffect(() => {
        if (props.totalPaginateLength !== undefined) {
            getItems();
        }
    }, [actualPage])
    const getItemPage = (item) => {
        const index = props.totalPaginate.indexOf(item);
        const page = Math.ceil((index + 1) / pageSize);
        return page;
    };
    const onArrowLeftFoundClick = () => {
        if (props.foundValue > 1) {
            let pageRender = getItemPage(props.totalPaginate.find(x => x.found === props.foundValue - 1))
            if (pageRender !== actualPage) {
                setActualPage(pageRender)
            }
            props.setFoundValue(props.foundValue - 1);
        }
    }
    const onArrowRightFoundClick = () => {
        if (props.foundValue > 0 && props.foundValue < props.totalPaginate.filter(x => x.found !== undefined).length) {
            let pageRender = getItemPage(props.totalPaginate.find(x => x.found === props.foundValue + 1))
            if (pageRender !== actualPage) {
                setActualPage(pageRender)
            }
            props.setFoundValue(props.foundValue + 1);
        }
    };
    return (
        <>
        <div className="items-paginate row">
                <div className="col-lg-9 col-md-9 text-start mt-1">
                    <Button appearance="subtle"
                        icon={<SearchWithoutFill className={searchValueText ? "search-icon-value" : "search-icon"} />}
                        onClick={() => {
                            setShowAttachSearchValuePanel(true);
                        }} >
                    Buscar valor
                    </Button>
                    {searchValueText &&
                        <>
                            <Label weight="fontWeightRegular" className="search-icon-value">{searchValueText}</Label>
                            <Button appearance="subtle" icon={<CheckEmpty className={"clean-value-icon"} />} onClick={() => {
                                setSearchValueText(undefined);
                                props.setItemsList(prev => prev.map(item => ({
                                    ...item,
                                    found: undefined
                                })));
                            }}  >
                                Limpiar valor
                            </Button>
                            <span className="items-paginate-text">
                                {props.foundValue} de {props.totalPaginate.filter(x => x.found !== undefined).length}
                            </span>
                            <ArrowLeft className="icon-search-left" onClick={onArrowLeftFoundClick} />
                            <ArrowRight className="icon-search-right" onClick={onArrowRightFoundClick} />
                        </>
                    }
            </div>
                <div className="col-lg-3 col-md-3 text-end mt-2">
                <span className="items-paginate-text">
                    {(actualPage - 1) * pageSize + 1} - {
                        (actualPage * pageSize < props.totalPaginateLength) ?
                            (actualPage * pageSize) :
                            props.totalPaginateLength
                    } de {props.totalPaginateLength}
                </span>
                <ArrowLeft className="items-paginate-left" onClick={onArrowLeftClick} />
                <ArrowRight className="items-paginate-right" onClick={onArrowRightClick} />
            </div>
        </div>
            <OverlayDrawerSearchValue showAttachSearchValuePanel={showAttachSearchValuePanel}
                setShowAttachSearchValuePanel={setShowAttachSearchValuePanel}
                setSearchValueText={setSearchValueText}
                searchValueText={searchValueText}
                totalPaginate={props.totalPaginate}
                setTotalPaginate={props.setTotalPaginate}
                setFoundValue={props.setFoundValue}
                actualPage={actualPage}
                setActualPage={setActualPage}
                pageSize={pageSize}
            />
        </>
    );

}