import { Card, Label} from '@fluentui/react-components';
import '../../../Assets/Styles/Shared/Card/OmniCard.css';
import { SkeletonOmniCard } from '../Skeleton/SkeletonOmniCard';
import { useGeneralStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';

// props: title, children
export const OmniCard = (props) => {
    const { loadingSkeleton } = useGeneralStatesContext();
    return (        
        <Card {...props} className={props?.classCard ?? 'card-omni'} title="">
            <div className='row'>
                <div className={'card-container-title'}>
                    {loadingSkeleton ||
                        <Label className={props?.classTitle ?? 'card-title'}>{props.title}</Label>
                    }
                </div>    
                {
                    props.skeletonDisable
                        ? props.children
                        : (loadingSkeleton ? <SkeletonOmniCard/> : props.children)
                }
            </div>
        </Card>      
    );
};