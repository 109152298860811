import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Drivers/Driver';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatus = '/driver/getbystatus';
const getTotalItems='/driver/GetSearchTotalItems'
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Drivers.svg').default
}
export default function Driver()
{
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de choferes"}
                    image={iconCatalog}
                    urlGetItems={getByStatus}
                    urlGetTotalItems={getTotalItems}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"name"}
                    omniAcronym={"acronym"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}