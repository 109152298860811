import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { OmniCheckBox } from "../../../Shared/Controls/Checkbox/OmniCheckBox";

export default function WeightVolume(props){
    const isStackableItem="Este artículo se puede apilar";
    const validateExpiration="Se valida la caducidad en el recibo";

    const blurWeightByPiece=(event, data)=>{
        var value=event.target.value;
        props.setItem((prev)=>({
            ...prev,
            weightByBox:value * props.item.piecesByBox,
            weightByPiece: value
        }))
    }

    const blurHeight=(event, data)=>{
        var value=event.target.value;
        calculateVolume(value,props.item.width, props.item.depth);
    }

    const blurWidth=(event, data)=>{
        var value=event.target.value;
        calculateVolume(props.item.height,value, props.item.depth);
    }

    const blurDepth=(event, data)=>{
        var value=event.target.value;
        calculateVolume(props.item.height,props.item.width, value);
    }

    const calculateVolume=(height, width, depth)=>{
        props.setItem((prev)=>({
            ...prev,
            volumeByPiece:height*width*depth,
            volumeByBox:height*width*depth*props.item.piecesByBox,
            volumeByPallet:height*width*depth*props.item.piecesByBox*props.item.boxesByPalletRow,
            height:height,
            width:width,
            depth:depth
        }))
    }

    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-md-auto'>
                    <OmniCard title='Peso y volumen de la pieza'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniTextBox title='Peso por pieza' 
                                    name={props.item.weightByPiece} 
                                    value={props.item.weightByPiece} 
                                    blur={blurWeightByPiece} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Peso por caja' value={props.item.weightByBox} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Peso por tarima' value={props.item.weightByPallet} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniTextBox title='Alto' 
                                    value={props.item.height} 
                                    blur={blurHeight} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniTextBox title='Ancho' 
                                    value={props.item.width} 
                                    blur={blurWidth} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniTextBox title='Fondo' 
                                    value={props.item.depth} 
                                    blur={blurDepth}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Volumen por pieza' 
                                    value={props.item.volumeByPiece} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Volumen por caja' 
                                    value={props.item.volumeByBox} />
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <OmniLabel text='Volumen por tarima' 
                                    value={props.item.volumeByPallet} />
                            </div>
                        </div>
                    </OmniCard>
                </div>
                <div className="col-md-auto">
                    <OmniCard title="Articulo apilable">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <OmniCheckBox title={isStackableItem}
                                    propertyChecked={props.item.isStackableItem}
                                    propertyId="isStackableItem"
                                    edit={props.setItem}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <OmniTextBox title='Alto' value={props.item.stackableHeight} edit={props.setItem} property="stackableHeight" />
                        </div>
                    </OmniCard>
                    <OmniCard title="Caducidad">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                                <OmniCheckBox title={validateExpiration}
                                    propertyChecked={props.item.validateExpiration}
                                    propertyId="validateExpiration"
                                    edit={props.setItem}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {
                                props.item.validateExpiration
                                ?
                                <OmniLabel text='Meses de caducidad en el recibo' value={props.item.expirationMonthsInTicket} />
                                :
                                    <></>
                            }
                            
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div> 
    )
}