export const leftButtons = [{
    key: 'LblTitle',
    text: 'Editando permisos del rol...',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickSave, handleClickReturn, handleClickAllRemove, handleClickAllAdd) => [{
    key: 'BtnGuardar',
    text: 'Guardar',
    iconName: 'saveCuvitek',
    fontColor: 'var(--success)',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    backgroundColorButton: '#fff',
    backgroundColorButtonHover: '#fff',
    isButton: true,
    borderColor: 'var(--success) solid 2px',
    handler: handleClickSave
}
    , {
    key: 'BtnRegresar',
    text: 'Regresar',
    iconName: 'returnCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    handler: handleClickReturn
    },
    {
        key: 'BtnAllRemove',
        text: 'Quitar todos',
        iconName: 'notChekedTreeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        backgroundColorButton: '',
        backgroundColorButtonHover: '',
        handler: handleClickAllRemove
    },
    {
        key: 'BtnAllAdd',
        text: 'Agregar todos',
        iconName: 'chekedTreeCuvitek',
        iconColor: 'var(--secondary)',
        iconHover: 'var(--primary)',
        backgroundColorButton: '',
        backgroundColorButtonHover: '',
        handler: handleClickAllAdd
    }
]