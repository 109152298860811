import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export default function Extra(props){
    return(
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className="row">
                    <div className="col-lg-6 col-md-6 ">
                        <OmniCard title='Información del reabasto'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Muestra precio con impuestos en reportes' value={props.supplier.showsPurchasesWithTaxes?"Si":"No"} />  
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </>
    );
}