import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import Detail from "./Detail";
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Categories/Categories"
import { ItemSelectorProvider } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatusUrl = '/category/GetByStatus';
const getTotalItems='/category/GetSearchTotalItems'
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Categories.svg').default
}
export default function Categories() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de categorías"}
                    image={iconCatalog}
                    urlGetItems={getByStatusUrl}
                    urlGetTotalItems={getTotalItems}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"name"}
                    omniAcronym={"updateUser"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}