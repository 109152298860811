import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DirectionalHint } from "@fluentui/react";
import { Image } from '@fluentui/react/lib/Image';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Nav } from '@fluentui/react/lib/Nav';
import { Stack } from '@fluentui/react/lib/Stack';
import { SearchMenu } from './SearchMenu';
import NavLinkGroups from '../../Assets/Constants/Menu/NavLinkGroups';
import useLogout from '../../Hooks/Authentication/useLogout';
import NavOption from './NavOption';
import { GetByIdAsync, SetAxiosPrivate } from '../../Functions/Shared/Authorizations/AuthorizationStore';
import useAxiosPrivate from '../../Hooks/Common/useAxiosPrivate';
import Role from '../../Assets/Constants/Enums/Role';
import NavOptionCollapsed from './NavOptionCollapsed';
import { OmniCallout } from '../Shared/Callout/OmniCallout';
import '../../Assets/Styles/Menu/NavMenu.css';
import imageSignout from '../../Assets/Images/Icons/SignoffWithoutFill.svg';
const imageMenu = require('../../Assets/Images/Icons/Menu.svg').default;
const imageSearch = require('../../Assets/Images/Icons/Search.svg').default;
const imageCuvitekCompact = require('../../Assets/Images/Icons/CuvitekCompact.svg').default;
const imageCuvitekExtended = require('../../Assets/Images/Icons/CuvitekExtended.svg').default;
const signOutIcon = {
    imageProps: {
        src: imageSignout,
        style: {
            filter: 'invert(1)',
            marginRight: '5px'
        }
    }
};
const navStyles = {
    root: {
        width: '206px',
        selectors: {
            '.ms-Nav-link:hover': {
                borderLeftColor: 'var(--primary)',
                backgroundColor: 'var(--light) !important',                
            },
            '.ms-Nav-link:hover i': {
                fontWeight: '600',
            },
            '.ms-Nav-link:after': {               
                content: "",
                inset: '0px',
                pointerEvents: 'none',
                borderLeftColor: 'var(--primary)',
                backgroundColor: 'var(--light) !important',
            },
        },
    },
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
        padding: '0px 0px 0px 18px',
        borderLeftWidth: '2px',
        borderRadius: '0px', 
        position: 'initial',
        fontWeight: '600',
    },
    chevronButton: {
        right: '10px',
        left: 'none',
        display: 'flex',
        justifyContent: 'flex- end',
    },   
};
const customSpacingStackTokens= {
    childrenGap: '8%'
  
};
const stackStyles= {
    root: [
        {
            paddingLeft: '18px',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
        }
    ],
};
initializeIcons();
export default function NavMenu(props) {
    const [keyOption, setKeyOption] = useState();   
    const [keyOptionPrevious, setKeyOptionPrevious] = useState();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [menu, setMenu] = useState('');
    const [originalMenu, setOriginalMenu] = useState('');
    const [convertMenu, setConvertMenu] = useState([]);
    const navigate = useNavigate();
    const logout = useLogout();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [openCallout, setOpenCallout] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getAuthorizations=async()=>{
        var roleId=localStorage.getItem("rolId")
        var result=await GetByIdAsync(roleId);
        var authorizations = result.data;
        localStorage.setItem('authorizations', JSON.stringify(authorizations));
        getMenuFiltered(authorizations, roleId)
    }
    const getMenuFiltered = (authorizations, roleId) => {
        var newMenu = NavLinkGroups();
        if(authorizations!=null){
            newMenu.map((firstLevel)=>{
                firstLevel.links?.map((secondLevel)=>{
                    secondLevel.links?.map((thirdLevel)=>{
                        var option=authorizations.filter(o=>o.authorizationId.toString()===thirdLevel.key.toString());
                        if(option.length===0){
                            secondLevel.links=secondLevel.links.filter(item=>item!=thirdLevel);
                        }
                    })
                    if(secondLevel.links!==undefined && secondLevel.links.length===0 &&secondLevel.key!=='Logout'){
                        firstLevel.links=firstLevel.links.filter((item)=>item!==secondLevel );
                    }
                })
            });
            setMenu(newMenu);
            setOriginalMenu(newMenu);
        }
        /* ADMINISTRADOR */
        else if(roleId?.toString()===Role.Administrador.toString()){
            setMenu(newMenu);
            setOriginalMenu(newMenu);
        }
        else{
            newMenu.map((firstLevel)=>{
                firstLevel.links = firstLevel.links.filter((link) => (link.key === 'Logout' || link.key === 'Index') && link.key !== 'Role');
            });
            setMenu(newMenu);
            setOriginalMenu(newMenu);
        }
        setConvertMenu(newMenu[0].links.reduce((listValues, parent) => {
            if (Array.isArray(parent.links)) {
                listValues.push(parent);
            }
            return listValues;
        }, []));
    }

    useEffect(() => {
        getAuthorizations();
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
        window.removeEventListener('resize', handleWindowResize);
        };        
    }, []);

    useEffect(()=>{
        if(windowSize.innerWidth<1200){
            if (isCollapsed==false) {
                handleClickMenu();
            }
        }
        else {
            if (localStorage.getItem("menuCollapsed") === 'true' || isCollapsed) {
                handleClickMenu();
            }
        }       
    }, [windowSize]);

    const signOut = async () => {
        await logout();
        navigate('/login', { replace: true });
        removeValues();      
    }
    function removeValues()
    {
        localStorage.removeItem('useForeignKey');
        localStorage.removeItem('listBranch');
        localStorage.setItem('menuCollapsed', isCollapsed);
        localStorage.removeItem('authorizations');
        localStorage.removeItem('version');
    }

    function handleClickOption(ev, item) {
        setKeyOption(item.key);
        if (item.links !== undefined)
        {
            setKeyOptionPrevious(null);
            if (!item.isExpanded && keyOptionPrevious) {
                setKeyOption(keyOptionPrevious);
            }
        }
        if (isCollapsed) {
            assignOptionValue(false, keyOption);
        }
        if (item.key === "Logout") {
            setOpenCallout(true);
        }
    }

    function handleClickMenu() {
        if (!isCollapsed) {
            assignOptionValue(false, keyOption);
        }
        setIsCollapsed((isCollapsed) ? false : true);
        props.changeCollapsed();
    }
    function assignOptionValue(showOption, selectedKey) {
        const updatedMenu = menu && menu.map(group => ({...group,
            links: group.links.map(parent => {
                let isSeleccted = false;
                if (Array.isArray(parent.links)) {
                    parent.links = parent.links.map(children => {
                        if (children.key === selectedKey) {                            
                            setKeyOptionPrevious(keyOption);
                            setKeyOption(showOption ? selectedKey : parent.key);
                            isSeleccted = showOption;
                        }
                        return { ...children };
                    });
                }
                return { ...parent, isExpanded: isSeleccted };
            }),
        }));
        setMenu(updatedMenu);
    }
    return (
        <>
            <Stack vertical className={`container-menu ${(isCollapsed) && 'smallMenu'}`} >
                <Stack.Item onClick={handleClickMenu} className='container-menu-collapse'>
                    <Image src={imageMenu} className='icon-menu-collapse' />
                </Stack.Item>

                <Stack horizontal styles={stackStyles} tokens={customSpacingStackTokens} >
                    <Stack.Item align='center' onClick={handleClickMenu}>
                            <Image src={imageSearch}/>
                    </Stack.Item>
                    <Stack.Item className={isCollapsed ? 'hide' : 'show'}>
                        <SearchMenu menu={convertMenu} assignOptionValue={assignOptionValue} />
                    </Stack.Item>                    
                </Stack>     
                
                <Stack.Item>
                    <Stack horizontal className={`container-menu-options ${isCollapsed && 'smallMenu'}`}>
                        {
                            isCollapsed
                            ?
                            <Nav onLinkClick={handleClickOption}
                                selectedKey={keyOption}
                                styles={navStyles}
                                groups={menu}
                                onRenderLink={(propsLink) => { return (<NavOptionCollapsed {...propsLink} subLinks={propsLink.links} links={originalMenu} keyOption   />)}}
                                />
                            :
                            <Nav onLinkClick={handleClickOption}
                                selectedKey={keyOption}
                                styles={navStyles}
                                groups={menu}
                                onRenderLink={(propsLink) => { return (<NavOption {...propsLink} keyOption  />)}}
                                />
                        }
                        
                    </Stack> 
                </Stack.Item>
                            
                <Stack.Item className='container-menu-cuvitek'>
                    <Image src={isCollapsed ? imageCuvitekCompact : imageCuvitekExtended}/>
                </Stack.Item>         
            </Stack>

            <OmniCallout
                className={isCollapsed ? 'position-callout-signout-small': 'position-callout-signout'}
                notLock={true}
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={signOut}
                title={
                    <>
                        <b style={{ fontWeight: '600' }}>Cerrar sesión</b>
                    </>
                }
                content={
                    <>
                        Si deseas salir haz click en <b>Cerrar sesión</b> o da
                        <br />
                        click fuera del mensaje para seguir trabajando.
                    </>
                }
                leftButton={'Cerrar sesión'}
                backgroundColorLeft={'var(--danger)'}
                iconLeftButton={signOutIcon}
                iconPositionLeftButton={"before"}
                colorLeft={'white'}
                buttonId={'Logout'}
                directionalHint={DirectionalHint.rightCenter}
            />
        </>
    );
};

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}