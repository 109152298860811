import { OmniCard } from "../../Shared/Card/OmniCard";
import { Label } from "@fluentui/react-components";
import { useEffect, useState } from 'react';
export const CatalogListDetail = (props) => {
    const [actualPage, setActualPage] = useState(1);
    const [list, setList] = useState([]);
    const onScrollFunction = event => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        if ((scrollTop + clientHeight) + 10 >= scrollHeight) {
            getNewItems();
        }
    }
    const getNewItems = async () => {
        var page = actualPage + 1;
        const paginatedData = props.catalog.slice(
            (page - 1) * 50,
            page * 50
        );
        if (paginatedData && paginatedData.length > 0) {
            setActualPage(page++);
            setList([...list, ...paginatedData]);
        }
    }
    useEffect(() => {
        if (props.catalog) {
            setList(props.catalog.slice(0, 50))
        }
    }, [props.catalog]);
    return (
        <OmniCard title={props.type.titleCardDetail} classCard="card-omni-without-width">
            {list &&
                <div className='row card-table-max-height mt-4' onScroll={onScrollFunction}>
                    <div className='col-lg-12 col-md-12'>
                        {list.map((item) => 
                            <div key={item.id} class="row catalog catalog-border detail-table-hover">
                                <div className='col-lg-12 col-md-12'>
                                    <Label className='text-main'> {item.description}</Label>
                                    <Label className='text-secondary mt-1 text-info'> {item.key}</Label>
                                </div>
                            </div> 
                        )}
                    </div>
                </div>
            }
        </OmniCard>
    )
}