import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import { Detail } from '../../../Shared/EmailList/Detail';
import { DomicileDetail } from '../Domicile/DomicileDetail';
export const ShippingAddressesDetail = (props) => {
    const itemSelected = props.description[props.property].find(x => x.id == [props.itemSelected[props.property]]);
    return (
        <>
            {itemSelected &&
                <>
                    <OmniCard title='Datos generales'>
                        <OmniLabel text='Nombre de la sucursal' value={itemSelected?.branchName} />
                        <OmniLabel text='Razón de envío' value={itemSelected?.shippingReason} />
                        <OmniLabel text='Nombre del encargado' value={itemSelected?.managerName} />
                        <Detail description={itemSelected}
                            property="listShippingAddressesEmail" titleColum="Lista de correos" />
                        <OmniLabel text='Teléfono de la sucursal' value={itemSelected?.branchPhone} />
                        <OmniLabel text='Distancia para el complemento de la carta porte' value={itemSelected?.distanceConsignmentNoteSupplement} />
                    </OmniCard>
                    <OmniCard title='Dirección de envío'>
                        <DomicileDetail itemSelected={itemSelected} />
                        <OmniLabel text='Referencias' value={itemSelected?.references} />
                    </OmniCard>
                </>
            }
        </>
    )
}