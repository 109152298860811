import { Stack } from "@fluentui/react";
import { React, useState } from "react";
import { useSetStatesContext, useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import "./../../../Assets/Styles/Shared/ItemsSelector/Item.css"


export default function Item(props) {
    const [isSelected, changeIsSelected] = useState(false);
    const changeComponent = useSetStatesContext();
    const { setListMessageCatalog, textFilter } = useGeneralStatesContext();
    const changeComponentClik = () => {
        changeComponent(props.detailComponent, props.id)
        if (!props.isSelected) {
            setListMessageCatalog([]);
        }
    };

    const GenerateAcronym = () => {
        let acronym = "";
        let description = props.textToAcronym === undefined ? "undefined" : props.textToAcronym;
        if (description !== null && description !== "") {
            acronym = description.length >= 2 ? description.substring(0, 2) : "--";
            //acronym=description.length>=2?description.substring(0,2):GetRandomAlphaString(2);
        }
        else {
            acronym = "--";
            //acronym=GetRandomAlphaString(2);
        }
        return acronym;
    }

    function GetRandomAlphaString(length) {
        var alphaChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        let randomString = "";
        for (let i = 0; i < length; i++) {
            let random = Math.random();
            randomString += alphaChars.charAt(random * alphaChars.length);
        }
        return randomString;
    }
    function GetDescription() {
        var text = props.description;
        var textToSearch = textFilter != undefined ? textFilter.replace('*', '') : '';
        var newRegex = new RegExp(`(${textToSearch === '*' ? '' : textToSearch})`, 'gi')
        var textArray = text.split(newRegex);
        if (textArray.length > 0) {
            return <span>
                {
                    textArray.map((part, index) => {
                        return part.toUpperCase() === textToSearch.toUpperCase()
                            ?
                            (<span className="item-text-find">
                                {part}
                            </span>)
                            : (
                                <span >
                                    {part}
                                </span>
                            );
                    })
                }
            </span>

        }
        else {
            return <span>{text}</span>
        }
    }
    return (
        <Stack
            horizontal
            className={props.isSelected ? "item-container-selected" : "item-container"}
            onClick={() => changeComponentClik()}
        >
            <Stack.Item
                className={props.isSelected ? "item-acronym-selected" : "item-acronym"} >
                {GenerateAcronym()}
            </Stack.Item>
            <Stack.Item id={"item"+props.id}
                className="item-description">
                {props.description == undefined ? "undefined" : GetDescription()}
            </Stack.Item>
        </Stack>
    )
}